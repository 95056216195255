import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import styled from 'styled-components'
import {
    TextField,
    Checkbox
} from '@material-ui/core'
import { 
    getProfileMetric, 
    getAssessmentList, 
    updateProfileMetricAll, 
    updateAssessmentTitle 
} from '../../../crud/profile.crud'
import { accountAsses } from "../../../crud/info.crud";
import Loader from '../../../components/Loader'
import Logout from '../../auth/Logout'
import { Modal, Button } from 'react-bootstrap'

export default ({ assessment, id, modal, setModal, assessmentList }) => {

    const dispatch = useDispatch();

    const [editData, setEditData] = useState([])
    
    const [metric, setMetric] = useState([])
    const [assessmentTitle, setAssessmentTitle] = useState('')
    const [logout, setLogout] = useState(null)
 
    const [loaderProfileMetric, setLoaderProfileMetric] = useState(false)
    const [loaderAssessmentList, setLoaderAssessmentList] = useState(false)
    const [loaderUpdateProfileMetric, setLoaderUpdateProfileMetric] = useState(false)
    const [loaderUpdateAssessmentTitle, setLoaderUpdateAssessmentTitle] = useState(false)

    useEffect(() => {
        loadData() 
        // eslint-disable-next-line
    }, [id, assessment])

    const loadData = () => {
        if (assessment) {
            setLoaderProfileMetric(true)
            getAssessmentList(id)
            .then(res => {
                setLoaderProfileMetric(false)
                setAssessmentTitle(res.data.data.find(item => item.name === assessment.name).title)
            })
            .catch(({ response }) => {
                setLoaderProfileMetric(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if(response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                } 
            })
            
            setLoaderAssessmentList(true)
            
            getProfileMetric(id)
                .then(res => {
                    setEditData([])
                    setLoaderAssessmentList(false)
                    setMetric(res.data.data)
                })
                .catch(({ response }) => {
                    setLoaderAssessmentList(false)
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    } else if(response && (response.status === 403)) {
                        setLogout(<Redirect to="/profile-list" />)
                    } 
                })
        }
    }

    const findValue = (item, id) => {
        
        if (!assessment) return false
        let res = item.find(elem => elem.assessment === assessment.name)

        let edit = editData.find(elem => elem.metric_id === id)

        return !edit 
            ? res.value
            : edit.value
    }
    
    const upMetric = (metric_id, value) => {
        let data = metric_id.map(item => ({
            metric_id: item,
            value: value === '1' ? '0' : '1'
        }))

        let temp = [...editData]
        
        data.map(data => {
            let res = temp.findIndex(elem => elem.metric_id === data.metric_id)

            if (res === -1) {
                temp.push(data)
            } else {
                temp[res] = data
            }
            return ''
        })
        setEditData(temp)
    }

    // useEffect(() => {
    //     console.log(editData)
    // }, [editData])

    const updateTitle = value => {
        setLoaderUpdateAssessmentTitle(true)
        updateAssessmentTitle(id, {
            [assessment.name]: value,
        })
            .then(res => {
                dispatch(AlertState.actions.alert({
                    text: 'Title is updated',
                    variant: true
                }));
                setLoaderUpdateAssessmentTitle(false)
            })
            .catch(({ response }) => {
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderUpdateAssessmentTitle(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if(response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                } 
            })
    }

    const submit = () => {
        setLoaderUpdateProfileMetric(true)
        updateProfileMetricAll(id, editData.map(item => {
            return {
                metric_id: item.metric_id,
                [assessment.name]: item.value
            }
        }))
            .then(res => {
                setEditData([])
                setLoaderUpdateProfileMetric(false)
                loadData()
                setModal(false)
                dispatch(AlertState.actions.alert({
                    text: 'Metrics are updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderUpdateProfileMetric(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if(response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                } 
            })
    }

    const [errors, setErrors] = useState('')
    const [title, setTitle] = useState({})

    useEffect(() => {
        accountAsses()
            .then(res => {
                setTitle(res.data.data[0])
            })
    }, [])

    const editError = (value) => {
        if (!value) {
            setErrors('Assessment name should not be blank')
        } else if (value.length < 2) {
            setErrors('Assessment name should be at least 2 of characters')
        } else if (Object.values(title).filter(item => item === value && assessment.title !== item).length > 0) {
            setErrors('Assessment name must be unique')
        } else {
            setErrors('')
        }
    }

    return (
        <StyledModal>
            <Modal 
                size="lg"
                show={modal} 
                onHide={e => {
                    setModal(false)
                    setEditData([])
                }}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Metrics</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loader visible={loaderProfileMetric || loaderAssessmentList || loaderUpdateProfileMetric || loaderUpdateAssessmentTitle} />
                    { logout }

                    <table className="settingtable">
                        <thead>
                            <tr>
                                <td>
                                    Label
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        className='textField textField-custom'
                                        name='assessment'
                                        style={{
                                            width: 250
                                        }}
                                        value={assessmentTitle}
                                        onChange={e => {
                                            editError(e.target.value)
                                            setTitle({
                                                ...title,
                                                [assessment.name]: e.target.value
                                            })
                                            setAssessmentTitle(e.target.value)
                                        }}
                                        onBlur={e => {
                                            setTitle({
                                                ...title,
                                                [assessment.name]: e.target.value
                                            })
                                            if (!errors) {
                                                updateTitle(assessmentTitle)
                                            } else {
                                                dispatch(AlertState.actions.alert({
                                                    text: errors,
                                                    variant: false
                                                }))
                                                setAssessmentTitle(assessmentList.find(item => item.name === assessment.name).title)
                                            }
            
                                        }}
                                        margin="normal"
                                        inputProps={{
                                            style: {
                                                textAlign: 'center'
                                            },
                                            maxLength: 15
                                        }}
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                metric.map((item, index) => {
                                    return item.is_show ? (
                                        <tr key={index}>
                                            <td>
                                                { item.metric }
                                            </td>
                                            <td>
                                                <Checkbox
                                                    checked={`${findValue(item.assessment, item.metric_id)}` === '1'}
                                                    onChange={e => {
                                                        if (item.metric_id === 4 || item.metric_id === 5) {
                                                            upMetric([4,5], e.target.value)
                                                        } else {
                                                            upMetric([item.metric_id], e.target.value)
                                                        }
                                                    }}
                                                    value={findValue(item.assessment, item.metric_id)}
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': 'secondary checkbox',
                                                    }}
                                                />
                                            </td>
                                        </tr> 
                                    ) : null
                                })
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="primary"
                        onClick={e => {
                            setModal(false)
                            setEditData([])
                        }}
                    >
                        Close
                    </Button>
                    <Button 
                        variant="primary"
                        className="btn-blue"
                        onClick={e => {
                            submit()
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </StyledModal>
    )
}

const StyledModal = styled.div`

`