import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { TextField } from "@material-ui/core";
import Dropdown from '../../../../../components/SimpleDropdown';
import ConfirmModal from '../../../../../components/ConfirmationModal';
import { DragAndDropForm } from './DragAndDropForm/DragAndDropForm';
import { createForm, updateForm } from '../../../../../crud/forms';
import { catchError } from './catchError';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import * as AlertState from '../../../../../store/ducks/auth.duck';
import * as Yup from 'yup';
// import 
import './form.scss';

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too short - should be at least 2 characters')
        .max(255, 'Too long - should not exceed 255 characters')
        .required('Required'),
    tab_name: Yup.string()
        .min(2, 'Too short - should be at least 2 characters')
        .max(15, 'Too long - should not exceed 15 characters')
        .required('Required'),
    description: Yup.string()
        .min(2, 'Too short - should be at least 2 characters')
        .max(255, 'Too long - should not exceed 255 characters')
        .nullable(),
    access: Yup.string()
        .required('Required')
        .nullable(),
    pages: Yup.array()
        .required('Required')
});

export const FormComponent = ({ setLoader, data, formId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [inputsStatus, setInputsStatus] = useState({});
    const [initialValues, setInitialValues] = useState({
        name: '',
        tab_name: '',
        description: '',
        access: 'Myself',
        pages: [],
        inputs: [],
    });
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [prevState, setPrevState] = useState(null);

    useEffect(() => {
        if (data) {
            // set InitialValues
            setInitialValues({
                name: data.name,
                tab_name: data.tab_name,
                description: data.description,
                access: data.access === 'myself'
                    ? 'Myself'
                    : data.access === 'share'
                        ? 'Share with Users'
                        : '',
                pages: data.pages.map(elem => {
                    if (elem === 'create-profile') {
                        return 'Create Profile'
                    } else {
                        return 'Profile Info'
                    }
                }),
                inputs: data?.inputs
                    ? data?.inputs
                        .sort((a, b) => (+a.sort - +b.sort))
                        .map(elem => ({ ...elem, id: elem.id + '' }))
                    : [],
            });
            setPrevState({
                name: data.name,
                tab_name: data.tab_name,
            });

            // set inputsStatus
            const result = {};
            data.inputs.forEach(elem => {
                let specificData = {};
                if (elem.type === 'checkbox') {
                    specificData = {
                        options: elem.options.map(option => {
                            return {
                                ...option,
                                value: option.label,
                                touched: false,
                                hasError: false,
                                errorText: '',
                            }
                        })
                    }
                } else if (elem.type === 'radio') {
                    specificData = {
                        options: elem.options.map(option => {
                            return {
                                ...option,
                                value: option.label,
                                touched: false,
                                hasError: false,
                                errorText: '',
                            }
                        })
                    }
                } else if (elem.type === 'number') {
                    specificData = {
                        from: {
                            touched: false,
                            hasError: false,
                            errorText: '',
                            value: elem.from,
                        },
                        to: {
                            touched: false,
                            hasError: false,
                            errorText: '',
                            value: elem.to,
                        },
                    }
                };

                result[elem.id] = {
                    ...elem,
                    value: elem.label,
                    status: {
                        touched: false,
                        hasError: false,
                        errorText: '',
                    },
                    ...specificData,
                }
            });

            setInputsStatus(result);
        }
    }, [data]);

    const submitForm = (values, setSubmitting, setFieldError, force = 0) => {
        setLoader(true);
        const formattedData = { ...values };

        // pages
        formattedData.pages = formattedData.pages.map(elem => {
            if (elem === 'Create Profile') return 'create-profile';
            if (elem === 'Profile Info') return 'profile-info';
            return '';
        })

        // access
        formattedData.access = formattedData.access === 'Myself'
            ? 'myself'
            : formattedData.access === 'Share with Users'
                ? 'share'
                : '';

        // inputs
        formattedData.inputs = formattedData.inputs.map((elem, index) => {
            if (elem.type === 'checkbox' || elem.type === 'radio') {
                let obj = {
                    type: elem.type,
                    label: inputsStatus[elem.id].value,
                    sort: index,
                    id: formId ? +elem.id : '',

                    // options
                    options: inputsStatus[elem.id].options.map((option, index) => {
                        if (option.id < 1) {
                            return { label: inputsStatus[elem.id].options[index].value }
                        } else {
                            return {
                                label: inputsStatus[elem.id].options[index].value,
                                id: formId ? +option.id : '',
                            }
                        }
                    })
                };
                if (elem.id < 1) delete obj.id;
                return obj;
            } else if (elem.type === 'number') {
                // range
                let obj = {
                    type: elem.type,
                    label: inputsStatus[elem.id].value,
                    sort: index,
                    from: inputsStatus[elem.id].from.value,
                    to: inputsStatus[elem.id].to.value,
                    id: formId ? +elem.id : '',
                };
                if (elem.id < 1) delete obj.id;
                return obj;
            } else {
                let obj = {
                    type: elem.type,
                    label: inputsStatus[elem.id].value,
                    sort: index,
                    id: formId ? +elem.id : '',
                };
                if (elem.id < 1) delete obj.id;
                return obj;
            }
        });

        if (formId) {
            if (
                prevState.name === formattedData.name &&
                prevState.tab_name === formattedData.tab_name
            ) {
                force = 1;
            };

            updateForm(formattedData, formId, force)
                .then(() => {
                    setLoader(false);
                    setSubmitting(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Form updated successfully',
                        variant: true
                    }));
                    history.push('/library/forms/list');
                })
                .catch(res => {
                    setLoader(false);
                    setSubmitting(false);
                    catchError(res, setFieldError, setConfirmModal, setConfirmText);
                    if (res?.response?.data?.error === 'Element does not found') {
                        dispatch(AlertState.actions.alert({
                            text: 'The form cannot be updated. It was previously removed',
                            variant: false
                        }));
                        history.push('/library/forms/list');
                    } else if (res?.response?.data?.error && typeof res?.response?.data?.error === 'string') {
                        dispatch(AlertState.actions.alert({
                            text: res.response.data.error,
                            variant: false
                        }));
                    }
                })
        } else {
            createForm(formattedData, force)
                .then(() => {
                    setLoader(false);
                    setSubmitting(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Form created successfully',
                        variant: true
                    }));
                    history.push('/library/forms/list');
                })
                .catch(res => {
                    setLoader(false);
                    setSubmitting(false);
                    catchError(res, setFieldError, setConfirmModal, setConfirmText);
                })
        }
    };

    return (
        <div className='intake-form-body'>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    submitForm(values, setSubmitting, setFieldError);
                }}
            >
                {({
                    values,
                    setValues,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    validateForm,
                    setErrors,
                    setTouched,
                    setSubmitting,
                    setFieldError,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                    >
                        <ConfirmModal
                            modal={confirmModal}
                            setModal={setConfirmModal}
                            handleConfirm={() => submitForm(values, setSubmitting, setFieldError, 1)}
                            title={confirmText}
                            submitText="Yes"
                            isCancelButton={true}
                        />

                        <div className='create-form__form-header'>
                            <div className="intake-form-body__row-container">
                                <div className="intake-form-body__row-elem">
                                    <div className="intake-form-body__input-container">
                                        <TextField
                                            name="name"
                                            variant="outlined"
                                            type="text"
                                            label="Form Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className="intake-form-body__textfield"
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            value={values.name}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </div>
                                </div>

                                <div className="intake-form-body__row-elem">
                                    <div className="intake-form-body__input-container">
                                        <TextField
                                            name="tab_name"
                                            variant="outlined"
                                            type="text"
                                            className="intake-form-body__textfield"
                                            label="Short Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{
                                                maxLength: 15,
                                            }}
                                            value={values.tab_name}
                                            error={Boolean(touched.tab_name && errors.tab_name)}
                                            helperText={touched.tab_name && errors.tab_name}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="intake-form-body__input-container">
                                <TextField
                                    name="description"
                                    variant="outlined"
                                    type="text"
                                    label="Form Description"
                                    className="intake-form-body__textfield"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                    value={values.description}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </div>

                            <div className="intake-form-body__row-container">
                                <div className="intake-form-body__row-elem">
                                    <div className="intake-form-body__input-container">
                                        <Dropdown
                                            width="100%"
                                            name="access"
                                            label="Access"
                                            value={values.access}
                                            onChange={(value) => handleChange({ target: { value, name: 'access' } })}
                                            error={Boolean(touched.access && errors.access)}
                                            helperText={touched.access && errors.access}
                                            options={[
                                                'Myself',
                                                'Share with Users',
                                            ]}
                                            onBlur={handleBlur}
                                            disableClearable={true}
                                        />
                                    </div>
                                </div>

                                <div className="intake-form-body__row-elem">
                                    <div className="intake-form-body__input-container intake-form-body__input-comntainer-multiselect">
                                        <Dropdown
                                            width="100%"
                                            name="pages"
                                            label="Page"
                                            value={values.pages}
                                            onChange={(value) => handleChange({ target: { value, name: 'pages' } })}
                                            error={Boolean(touched.pages && errors.pages)}
                                            helperText={touched.pages && errors.pages}
                                            options={[
                                                'Create Profile',
                                                'Profile Info',
                                            ]}
                                            multiple={true}
                                            disableClearable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DragAndDropForm
                            isSubmitting={isSubmitting}
                            handleSubmit={handleSubmit}
                            values={values}
                            setValues={setValues}
                            validateForm={validateForm}
                            setErrors={setErrors}
                            setTouched={setTouched}
                            inputsStatus={inputsStatus}
                            setInputsStatus={setInputsStatus}
                            formId={formId}
                        />
                    </form>
                )}
            </Formik>
        </div>
    )
};
