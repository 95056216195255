import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const createForm = (data, ignore_uniqueness = 0) => {
    return axios.post(`${BASE_URL}account/forms`,
        { ...data, ignore_uniqueness }
    );
};

export const fetchForms = params => {
    return axios.get(`${BASE_URL}account/forms`, { params });
};

export const fetchForm = id => {
    return axios.get(`${BASE_URL}account/forms/${id}`);
};

export const updateForm = (data, id, ignore_uniqueness = 0) => {
    return axios.put(`${BASE_URL}account/forms/${id}`,
        { ...data, ignore_uniqueness }
    );
};

export const deleteForms = ids => {
    return axios.delete(`${BASE_URL}account/forms`, { data: { ids: [...ids] } });
};

export const storeForm = (prodileId, data) => {
    return axios.post(`${BASE_URL}profile/${prodileId}/forms?is_all_required=0`, { ...data });
};

export const getProfileForms = prodileId => {
    return axios.get(`${BASE_URL}profile/${prodileId}/forms?page=1&perPage=100`);
};

export const getProfileForm = (prodileId, formId) => {
    return axios.get(`${BASE_URL}profile/${prodileId}/forms/${formId}`);
};

export const getFormPDF = id => {
    return axios.get(`${BASE_URL}account/forms/${id}/pdf`);
};

export const emailForm = (profileId, form_id) => {
    return axios.post(`${BASE_URL}profile/${profileId}/forms/notifications`, { form_id });
};

export const emailForms = (formId, profile_ids) => {
    return axios.post(`${BASE_URL}account/forms/${formId}/notifications`, { profile_ids });
};

export const updateProfileForm = (id, data) => {
    return axios.put(`${BASE_URL}profile/${id}/forms/${data.form_id}?is_all_required=0`, { ...data });
};
