import React, { useState, useEffect } from 'react';
import './radio.scss';

export const CustomRadio = ({
    name,
    data,
    value,
    onChange,
    error,
    helperText,
    variant,
    disabled = false,
}) => {
    const [checked, setChecked] = useState(value || '');

    useEffect(() => {
        setChecked(value);
    }, [value]);

    const handleChange = (elem) => {
        if (!onChange) {
            setChecked(elem);
        } else {
            onChange(elem, name);
            setChecked(elem);
        }
    };

    return (
        <div className={`custom-radio ${variant}`}>
            {data.map(elem => {
                return (
                    <div
                        className={`custom-radio__elem ${variant}`} key={elem}
                        onClick={() => {
                            !disabled && handleChange(elem)
                        }}
                    >
                        <div
                            className={`custom-radio__checkbox ${checked === elem ? 'custom-radio__checkbox_checked' : ''} ${error ? 'custom-radio__checkbox_error' : ''}`}>
                            {checked === elem && (
                                <div className="custom-radio__dot" />
                            )}
                        </div>
                        <div className="custom-radio__title">{elem || ''}</div>
                    </div>
                )
            })}

            {error && (
                <div className="custom-radio__err-container">
                    <div className="custom-radio__err-text">{helperText}</div>
                </div>
            )}
        </div>
    )
};
