import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    Modal,
    Button
} from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import { createCoupon } from '../../../crud/biling.crud'

export default ({
    modal, 
    setModal,
    coupon,
    refreshCurrentSubscription
}) => {

    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState('')
    const [codeTouched, setCodeTouched] = useState(false)

    useEffect(() => {
        setCode('')
    }, [coupon, modal]);

    const submit = () => {
        setCodeTouched(true)
        createCoupon(code)
            .then((res) => {
                if (res.data.applied) {
                    setModal(false)
                    refreshCurrentSubscription()
                } else {
                    setCodeError('Coupon not found')
                }
            })
            .catch(({response}) => {
                setCodeError(response.data?.errors?.coupon[0] === "" ? 'Coupon not found' : response.data?.errors?.coupon[0])
            })
    }

    return (
        <>
            <Modal 
                show={modal !== false} 
                onHide={e => {
                    setModal(false)
                }}
            >
                <ModalPlan>
                    <Modal.Body>
                        <TextField
                            label="Coupon"
                            margin="normal"
                            variant="outlined"
                            onChange={e => {
                                setCodeTouched(true)
                                setCode(e.target.value)
                            }}
                            id="coupon"
                            value={code}
                            helperText={codeTouched && codeError}
                            error={Boolean(codeTouched && codeError)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button 
                        variant="secondary"
                        onClick={e => {
                            setModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={submit}
                        className="btn-blue"
                    >
                        Add Coupon
                    </Button>
                </Modal.Footer>
                </ModalPlan>
            </Modal>
        </>
    )
}

const ModalPlan = styled.div`

`