import React, { useState } from 'react'
import {
    TextField
} from '@material-ui/core'
import TimeInput from '../../../components/TimePicker'

export default ({ disabled, elem, editData, getData, errors, setErrors, id }) => {

    const [touched, setTouched] = useState(false)
    const [error, setError] = useState(false)

    const validation = (value) => {

        const setEr = (valid) => {
            setError(valid)
            setErrors({ ...errors, [`${elem.category}.${elem.name}`]: valid})
        }
        if (value !== '') {
            if (elem.rules.type === 'integer') {
                if (parseInt(value) > elem.rules.max) {
                    setEr(`${elem.label} can be from ${elem.rules.min} to ${elem.rules.max}`)
                } else if (parseInt(value) < elem.rules.min) {
                    setEr(`${elem.label} can be from ${elem.rules.min} to ${elem.rules.max}`)
                } else if ((!parseInt(value) || parseInt(value) != value) && parseInt(value) !== 0 ) {  // eslint-disable-line
                    setEr('Field is not valid')
                } else {
                    setEr('')
                }
            } else if (elem.rules.type === 'decimal') {
                if (parseFloat(value) > elem.rules.max) {
                    setEr(`${elem.label} can be from ${elem.rules.min} to ${elem.rules.max}`)
                } else if (parseFloat(value) < elem.rules.min) {
                    setEr(`${elem.label} can be from ${elem.rules.min} to ${elem.rules.max}`)
                } else if (!parseFloat(value) && parseFloat(value) !== 0) {
                    setEr('Field is not valid')
                } else {
                    setEr('')
                }
            } else if (elem.rules.type === 'time') {
                if (value.split(':').length !== 3) {
                    setEr('Field is not valid (HH:MM:SS)')
                } else {
                    const time = value.split(':').map(item => parseInt(item))
                    const sec = time[2] + time[1] * 60 + time[0] * 60 * 60
                    if (sec === 0) {
                        setEr('')
                    } else if (sec > elem.rules.max) {
                        const h = Math.floor(elem.rules.max / 60 / 60)
                        const m = Math.floor(elem.rules.max / 60) - h * 60
                        const s = elem.rules.max % 60
                        setEr(`${elem.label} can be to ${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}:${s >= 10 ? s : `0${s}`}`)
                    } else if (sec < elem.rules.min) {
                        const h = Math.floor(elem.rules.min / 60 / 60)
                        const m = Math.floor(elem.rules.min / 60) - h * 60
                        const s = elem.rules.min % 60
                        setEr(`${elem.label} can be from ${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}:${s >= 10 ? s : `0${s}`}`)
                    } else {
                        setEr('')
                    }
                }
            } else {
                setErrors('')
            }
        } else {
            setEr('')
        }
    }

    const onChangeCustom = (e) => {

        let value = e.target.value
        
        if (elem.rules.type === 'integer') {
            value = value.replace(/[^\d]/ig, "")
        } else {
            value = value.replace(/[^\d.]/ig, "")
            value = value.replace('..', ".")
            const index = value.indexOf('.')
            if (index !== -1 && elem.rules.decimal_places) {
                value = value.slice(0, index + elem.rules.decimal_places + 1)
            }
        }

        editData(value, elem.name, elem.category)
        setTouched(true)
        validation(value)
    }

    return elem.rules.type === 'time'
        ? !disabled
            ? (
                <TimeInput
                    onChange={e => { editData(e, elem.name, elem.category); validation(e) }}
                    value={`${getData(elem.name, elem.category) || '00:00:00'}`}
                    title={elem.label}
                    setDefault={false}
                    error={touched ? error : ''}
                    onBlur={() => { setTouched(true); }}
                />
            )
            :  <TextField
                    id={id}
                    disabled={disabled}
                    variant="outlined"
                    type={'text'}
                    label={elem.label}
                    margin="normal"
                    className="kt-width-full"
                    name={elem.name}
                    inputProps={{
                        onChange: e => onChangeCustom(e),
                        // min: elem.rules.min,
                        // max: elem.rules.max,
                        // step: elem.rules.type === 'decimal' ? 1 / Math.pow(10, elem.rules.decimal_places) : 1
                    }}
                    helperText={touched && (error || errors[`${elem.category}.${elem.name}`])}
                    error={Boolean(touched && (error || errors[`${elem.category}.${elem.name}`]) )}
                    value={getData(elem.name, elem.category)}
                />
        : (
            <TextField
                id={id}
                disabled={disabled}
                variant="outlined"
                type={'text'}
                label={elem.label}
                margin="normal"
                className="kt-width-full"
                name={elem.name}
                inputProps={{
                    onChange: e => onChangeCustom(e),
                    // min: elem.rules.min,
                    // max: elem.rules.max,
                    // step: elem.rules.type === 'decimal' ? 1 / Math.pow(10, elem.rules.decimal_places) : 1
                }}
                helperText={touched && (error || errors[`${elem.category}.${elem.name}`])}
                error={Boolean(touched && (error || errors[`${elem.category}.${elem.name}`]) )}
                value={getData(elem.name, elem.category)}
            />
        )
}