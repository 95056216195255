import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import objectPath from "object-path";
import HMenuItem from "./HMenuItem";
import * as builder from "../../../ducks/builder";
import KTMenu from "../../../_assets/js/menu";
import KTOffcanvas from "../../../_assets/js/offcanvas";
import { fieldsUser, fieldsGroups, getAccount } from '../../../../app/crud/info.crud';
import { getImg } from '../../../../app/crud/media.crud';
import { useSelector } from "react-redux";
import { setAccount } from '../../../../app/store/actions';
import { useDispatch } from "react-redux";
import './style.scss'

const offcanvasOptions = {
  overlay: true,
  baseClass: "kt-header-menu-wrapper",
  closeBy: "kt_header_menu_mobile_close_btn",
  toggleBy: {
    target: "kt_header_mobile_toggler",
    state: "kt-header-mobile__toolbar-toggler--active"
  }
};

const HMenu = (props) => {
  const dispatch = useDispatch();
  const [permUser, setPermUser] = useState(false)
  const [permGroup, setPermGroup] = useState(false)
  const [headerLogo, setHeaderLogo] = useState('')
  const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.activation?.is_activate === true));
  const plan = useSelector(state => state.user_info?.subscription?.plan?.name)
  const offCanvasCommonRef = useRef();
  const ktMenuCommonRef = useRef();
  const { refresh } = props
  const [items] = useState(props.menuConfig?.header.items);
  const role = useSelector(state => state.auth.user.roles);

  const currentUrl = () => {
    return props.location.pathname.split(/[?#]/)[0];
  }

  useEffect(() => {
    // Canvas
    initOffCanvas();
    // Menu
    initKTMenu();

    getImg(props.headerLogo)
      .then(res => setHeaderLogo(res))
  }, [props.headerLogo])

  useEffect(() => {
    getAccount({})
      .then(res => {
        dispatch(setAccount(res.data.data));
      })

    fieldsUser()
      .then(() => {
        setPermUser(true)
      })
      .catch(() => {
        setPermUser(false)
      })

    fieldsGroups()
      .then(() => {
        setPermGroup(true)
      })
      .catch(() => {
        setPermGroup(false)
      })
  }, [refresh])

  const initOffCanvas = () => {
    // eslint-disable-next-line no-undef
    new KTOffcanvas(offCanvasCommonRef.current, offcanvasOptions);
  };

  const initKTMenu = () => {
    let menuOptions = {
      submenu: {
        desktop: "dropdown",
        tablet: "accordion",
        mobile: "accordion"
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50
      }
    };

    let menuDesktopMode = "accordion";
    if (
      ktMenuCommonRef.current.getAttribute("data-ktmenu-dropdown") === "1"
    ) {
      menuDesktopMode = "dropdown";
    }

    if (typeof objectPath.get(menuOptions, "submenu.desktop") === "object") {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode
      };
    }

    new KTMenu(ktMenuCommonRef.current, menuOptions);
  };

  const {
    // eslint-disable-next-line
    disabledAsideSelfDisplay,
    ktMenuClasses,
    ulClasses,
    rootArrowEnabled
  } = props;

  return (
    <>
      <button
        className="kt-header-menu-wrapper-close"
        id="kt_header_menu_mobile_close_btn"
      >
        <i className="la la-close" />
      </button>
      <div
        className="kt-header-menu-wrapper"
        id="kt_header_menu_wrapper"
        ref={offCanvasCommonRef}
      >
        {true && (
          <div className="kt-header-logo">
            <Link to="/">
              {headerLogo && <img alt="logo" key={headerLogo} src={headerLogo} />}
            </Link>
          </div>
        )}

        <div
          id="kt_header_menu"
          className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
          ref={ktMenuCommonRef}
        >
          <ul className={`kt-menu__nav ${ulClasses}`}>
            {items?.filter(item => {
              return (
                (item.page === 'user-list' && permUser) ||
                (item.page === 'group-list' && permGroup) ||
                item.page === 'profile-list' ||
                item.page === 'account/setting' ||
                item.page === 'account/biling' ||
                item.page === 'account/info' ||
                item.page === 'reports-list' ||
                item.page === 'logout' ||
                (item.page === 'account/crm/connect' && crm) ||
                (item.page === 'library/forms/list' && (plan === 'Gym' || plan === 'Enterprise')) ||
                (item.page === 'analytics' && role !== 'client')
              )
            })
              .map((item, index, arr) => {
                return (
                  <React.Fragment key={`hmenuList${index}`}>
                    {item.title && (
                      <HMenuItem
                        item={item}
                        currentUrl={currentUrl}
                        rootArrowEnabled={rootArrowEnabled}
                        dtnone={arr.filter(item => item.desktop).length === 1}
                      />
                    )}
                  </React.Fragment>
                )
              })
            }
            <li>
              {((props.role === 'super-admin') || (props.role === 'admin')) && <div className="profile-line mobile-none">
                <div className="profile-line__title">Active Profile Limit</div>
                <div className="profile-line__elem">
                  <div className="profile-line__value" style={{ width: `${100 / props.profileLimit * props.active_profiles}%` }} />
                </div>
              </div>}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = store => {
  return ({
    refresh: store.auth.refreshStatistic,
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    headerLogo: builder.selectors.getLogo(store),
    profileLimit: store.user_info.subscription?.plan?.active_profiles || 1,
    active_profiles: store.appData.account.active_profiles,
    ktMenuClasses: builder.selectors.getClasses(store, {
      path: "header_menu",
      toString: true
    }),
    role: store.auth.user.roles,
    rootArrowEnabled: builder.selectors.getConfig(
      store,
      "header.menu.self.root-arrow"
    ),
    headerSelfSkin: builder.selectors.getConfig(store, "header.self.skin"),
    ulClasses: builder.selectors.getClasses(store, {
      path: "header_menu_nav",
      toString: true
    }),
    disabledAsideSelfDisplay:
      objectPath.get(store.builder.layoutConfig, "aside.self.display") === false
  })
};

export default withRouter(connect(mapStateToProps)(HMenu));
