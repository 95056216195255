import React, { useState } from 'react';
import styled from 'styled-components';
import { Portlet } from "../../../../../partials/content/Portlet";
import UpdateModal from './UpdateModal';
import DeleteModal from './DeleteModal';
import moment from 'moment';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../../../store/ducks/auth.duck';
import * as media from '../../../../../crud/media.crud';
import { deleteFile } from '../../../../../crud/file.crud';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
    Dropdown,
} from 'react-bootstrap';

import fileDoc from '../../../../../../_metronic/_assets/media/svg-icon/doc.svg';
import fileXls from '../../../../../../_metronic/_assets/media/svg-icon/xls.svg';
import fileZip from '../../../../../../_metronic/_assets/media/svg-icon/zip.svg';
import filePdf from '../../../../../../_metronic/_assets/media/svg-icon/pdf.svg';
import fileTxt from '../../../../../../_metronic/_assets/media/svg-icon/txt.svg';
import fileCsv from '../../../../../../_metronic/_assets/media/svg-icon/csv.svg';

const fileIcon = {
    doc: fileDoc,
    docx: fileDoc,
    xls: fileXls,
    xlsx: fileXls,
    zip: fileZip,
    pdf: filePdf,
    txt: fileTxt,
    csv: fileCsv
};

export default (props) => {

    return (
        <CardStyled>
            {props.data.map(item => {
                return <Card
                    key={item.id}
                    item={item}
                    {...props}
                />
            })}
        </CardStyled>
    )
};

const Card = ({ item, loader, setLoader, id, fetchData }) => {
    const dispatch = useDispatch();

    const [menu, setMenu] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const update = () => {
        setUpdateModal(true)
    };

    const download = () => {
        setLoader(true)
        media.getImg(item.file.path)
            .then(res => {
                setLoader(false)
                const link = document.createElement('a')
                const full_name = item.file.file_name + '.' + item.file.extension
                link.href = res
                link.target = '_blank';
                link.setAttribute('download', `${full_name}`)
                document.body.appendChild(link)
                if (link.hasAttribute('download')) {
                    link.click();
                    setTimeout(() => {
                        link.remove();
                    }, 100)
                } else {
                    link.download = full_name
                    link.click();
                    setTimeout(() => {
                        link.remove();
                    }, 100)
                }
            })
            .catch(err => {
                setLoader(false)
            })
    };

    const remove = () => {
        setDeleteModal(true)
    };

    const handleDelete = () => {
        setLoader(true)
        deleteFile(id, item.id)
            .then(() => {
                setDeleteModal(false)
                setLoader(false)
                fetchData()
                dispatch(AlertState.actions.alert({
                    text: 'File is deleted',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                setLoader(false)
                dispatch(AlertState.actions.alert({
                    text: response.data.error,
                    variant: false
                }));
            })
    };

    return <div>
        <UpdateModal
            modal={updateModal}
            setModal={setUpdateModal}
            loader={loader}
            setLoader={setLoader}
            item={item}
            id={id}
            fetchData={fetchData}
        />

        <DeleteModal
            modal={deleteModal}
            setModal={setDeleteModal}
            handleDelete={handleDelete}
        />

        <Portlet
            key={item.id}
            className="portlet carditem"
            onClick={() => setMenu(!menu)}
            onMouseLeave={() => setMenu(false)}
        >
            <div className="carditem-pre">
                <img className="carditem-pre-icon" src={fileIcon[item.file.extension]} alt="file" />
                <div className="carditem-pre-format">{item.file.extension}</div>
            </div>
            <div className="carditem-name">{item.file.file_name}</div>
            <div className="carditem-date">{moment(item.test_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</div>

            <Dropdown className="carditem-dd">
                <Dropdown.Toggle>
                    <MoreHorizIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={update}>Update</Dropdown.Item>
                    <Dropdown.Item onClick={download}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={remove}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Portlet>
    </div>
}

const CardStyled = styled.div`
    padding: 25px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .carditem {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
        margin: 15px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &-dd {
            position: absolute;
            top: 0;
            right: 0;

            .dropdown {

                &-menu {
                    right: 0px!important;
                    left: auto!important;
                }

                &-toggle{
                    background: transparent!important;

                    &::after {
                        display: none;
                    }

                    .MuiSvgIcon-root {
                        fill: #646c9a!important;
                    }
                }
            }
        }

        &-pre {
            height: 150px;
            width: 150px;
            margin: 5px;
            margin-bottom: 10px;
            background: #eeeeee;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &-format {
                width: 80px;
                background: #e1e1e1;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #646c9a;
                text-transform: uppercase;
                font-weight: bold;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &-icon {
                height: 75px;
                width: 75px;
                object-fit: contain;
            }
        }

        &-name {
            font-weight: 500;
        }
    }

    .carditem-name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
`