import React, { useState } from 'react';
import styled from 'styled-components'
import SearchIcon from '../../_metronic/_assets/media/searchIcon.png'
import SearchIconM from '@material-ui/icons/Search';


export default ({ search, setSearch, openSearch = false}) => {
  const [open, setOpen] = useState(openSearch)
  
  return (
    <StyledSearch>
      <div className={`search-wrap`}>
        <img src={SearchIcon} alt="search" onClick={() => setOpen(!open)} />
        {open &&
        <div className={`search-input`}>
          <SearchIconM />
          <input
            type='text'
            defaultValue={search}
            onBlur={(e) => setSearch(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setSearch(e.target.value)
                setOpen(false)
              }
            }}
            placeholder="Search..."
          />
        </div>
        }
      </div>
    </StyledSearch>
  )
}

const StyledSearch = styled.div`
  .search {

        &-input {
            width: 320px;
            position: absolute;
            top: 120%;
            right: -15px;
            display: flex;
            background-color: #ffffff;
            border-radius: 4px;
            padding: 22px 15px;
            box-shadow: 2px 2px 4px 2px #ededed;
            z-index: 99;

            input {
                width: 100%;
                margin-left: 5px;
                border: none;
                outline: none;
            }
        }
        
        &-wrap {
            position: relative;
            height: 44px;
            width: 44px;
            border-radius: 4px;
            background-color: #f2f3f8;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`
