import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck';
import styled from 'styled-components';
import { getMetric, updateMetric, changeMeasurementSystem, getAccount } from "../../../crud/info.crud";
import Logout from '../../../pages/auth/Logout';
import {
    Checkbox,
    MenuItem,
    TextField,
    Select,
    InputLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    OutlinedInput,
    Tab,
    Tabs
} from '@material-ui/core';
import { setAccount } from '../../../store/actions';

export default ({ setLoader, setRedirect }) => {
    const dispatch = useDispatch();
    const [MMData, setMMData] = useState([]);
    const [loaderMetric, setLoaderMetric] = useState(false);
    const [loaderUpdateMetric, setLoaderUpdateMetric] = useState(false);
    const [system, setSystem] = useState(false);
    const [accountId, setAccountId] = useState(null);

    useEffect(() => {
        updateData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        getAccount()
            .then(res => {
                dispatch(setAccount(res.data.data));
                let system = res.data.data.measurement_system
                setSystem(system);
                setAccountId(res.data.data.id);
            })
    }, []);

    useEffect(() => {
        setLoader(loaderMetric || loaderUpdateMetric)
        // eslint-disable-next-line
    }, [loaderMetric, loaderUpdateMetric]);

    const updateData = () => {
        setLoaderMetric(true)
        getMetric()
            .then(res => {
                setLoaderMetric(false)
                setMMData(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderMetric(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
            });
    };

    const upMetric = (id, day, show) => {
        setLoaderUpdateMetric(true)

        let data = {
            metric_id: id,
            days_between: day || 0,
            is_show: parseInt(show)
        }

        updateMetric(data)
            .then(res => {
                setLoaderUpdateMetric(false)
                updateData()
                dispatch(AlertState.actions.alert({
                    text: 'Metrics Management is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
                setLoaderUpdateMetric(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
            });
    };

    const handleSwitch = (e) => {
        setLoader(true);
        const system = e.target.value
        changeMeasurementSystem(accountId, system)
            .then(res => {
                setSystem(system)
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                console.log('changeMeasurementSystem', { ...err })
            })
    };

    return (
        <>
            <StyledDropdown>
                <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root">
                    <InputLabel htmlFor="system" className="MuiFormLabel-root">System</InputLabel>
                    <Select
                        value={system}
                        onChange={handleSwitch}
                        input={<OutlinedInput id="system" name="system" />}
                    >
                        <MenuItem value={"imperial"}>Imperial</MenuItem>
                        <MenuItem value={"metric"}>Metric</MenuItem>
                    </Select>
                </FormControl>
            </StyledDropdown>

            <StyledMM className="settingtable">
                <thead>
                    <tr>
                        <td>
                            Category
                        </td>
                        <td style={{ textAlign: 'start' }}>
                            Metric
                        </td>
                        <td style={{ textAlign: 'start' }}>
                            Days Between Metrics
                        </td>
                        <td>
                            Show
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {MMData.map((item, index) => {
                        return (
                            <MetricRow
                                key={`MM-${index}`}
                                change={upMetric}
                                index={index}
                                item={item}
                            />
                        )
                    })}
                </tbody>
            </StyledMM>
        </>
    )
}

const StyledMM = styled.table`
    .MuiFormControl-root {
        width: 50px;
    }
`;

const StyledDropdown = styled.div`
    width: 250px;

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background-color: #fff;
    }
`;


const MetricRow = ({ item, index, change }) => {
    const [day, setDay] = useState(item.pivot.days_between || 0)

    return (
        <tr>
            <td className="min">
                {item.category}
            </td>
            <td className="min" style={{ textAlign: 'start' }}>
                {item.metric}
            </td>
            <td className="min" style={{ textAlign: 'start' }}>
                <TextField
                    variant="outlined"
                    type="text"
                    // eslint-disable-next-line
                    value={day == 0 ? '--' : day}
                    onBlur={e => change(item.id, day, item.pivot.is_show)}
                    onChange={(e) => {
                        setDay(e.target.value.replace(/[^0-9]/gim, ''))
                    }}
                    placeholder="--"
                    margin="normal"
                />
            </td>
            <td className="min">
                <Checkbox
                    checked={item.pivot.is_show === 1}
                    onChange={e => change(item.id, item.pivot.days_between, e.target.value)}
                    value={item.pivot.is_show === 1 ? 0 : 1}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
        </tr>
    )
};
