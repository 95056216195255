import {
  // eslint-disable-next-line
  Portlet, PortletBody, PortletHeader
} from "../../../partials/content/Portlet";
import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import TableList from '../../../components/TableList'
import TabNav from './TabNav'
import Search from '../../../components/Search'
import ShowEntries from '../../../components/ShowEntries'
import Modal from '../Records/Modal'
import NotifyModal from './NotifyModal'

import {Button, ButtonToolbar, Pagination} from "react-bootstrap";


export default (props) => {
  const {
    header,
    data,
    loadData,
    menu,
    lastPage,
    par,
    setPar,
    addInputs,
    addModalFields,
    updateInputs,
    updateModalFields,
    modalLabel,
    createRecord,
    updateRecord,
    deleteRecord,
  } = props
  const [category, setCategory] = useState(props.category)
  const [subcategory, setSubcategory] = useState(props.subcategory)
  const [pagination, setPagination] = useState(1)
  const [sort, setSort] = useState(["testDate", "asc"])
  const [check, setCheck] = useState([])
  
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [show, setShow] = useState(10)
  const [search, setSearch] = useState(null)
  const [openSearch, setOpenSearch] = useState(false)
  
  
  useEffect(() => {
    setPar(par => {
      return {...par, page: pagination}
    })
    // eslint-disable-next-line
  }, [pagination])
  
  useEffect(() => {
    setPar(par => {
      return {
        ...par,
        sort: sort[0],
        sortBy: sort[1]
      }})
      // eslint-disable-next-line
  }, [sort])
  
  useEffect(() => {
    setPar(par => {
      return {
        ...par,
        category: category
      }})
      // eslint-disable-next-line
  }, [category])
  
  useEffect(() => {
    setPar(par => {
      return {
        ...par,
        subcategory: subcategory
      }})
      // eslint-disable-next-line
  }, [subcategory])
  
  useEffect(() => {
    setPar(par => {
      return {
        ...par,
        perPage: show
      }
    })
    // eslint-disable-next-line
  }, [show])
  
  useEffect(() => {
    if(search) {
      setPar(par => {
        return {
          ...par,
          search: search
        }
      })
    }
    // eslint-disable-next-line
  }, [search])
  
  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [par])
  
  useEffect(() => {
    if(menu.length && !category) {
      handleCategory(menu[0].category)
    }
    // eslint-disable-next-line
  }, [menu])
  
  const handleCategory =(categoryItem) => {
    setCategory(categoryItem)
    setCheck([])
  }
  
  const handleAddModal = () => {
    addModalFields(category, subcategory)
    setTimeout(()=>{
      setAddModal(true)
    }, 500)
  }
  const handleUpdateModal = () => {
    updateModalFields(check[0], category, subcategory)
    setTimeout(()=>{
      setUpdateModal(true)
    }, 500)
  }
  const handleDelete = () => {
    const data = {
      category,
      id: check
    }
    deleteRecord(data)
    setDeleteModal(false)
    setCheck([])
  }
  
  
  
  return (
    <StyledRecords>
  
      <Modal
        modal={addModal}
        setModal={setAddModal}
        label={modalLabel}
        inputs={addInputs}
        save={createRecord}
        category={category}
        setCheck={setCheck}
      />
      <Modal
        modal={updateModal}
        setModal={setUpdateModal}
        label={modalLabel}
        inputs={updateInputs}
        save={updateRecord}
        category={category}
        setCheck={setCheck}
      />
      <NotifyModal
        title={"Delete selected data?"}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDelete={handleDelete}
      />
      <ButtonToolbar className="custom-btn">
        {
          menu.map((item, index) => {
            return (
              <Button
                variant="light"
                key={item.title}
                // eslint-disable-next-line
                className = {item.category == category ? 'active' : ''}
                
              >
                <TabNav
                  key={index}
                  id={index}
                  item={item}
                  setCategory={handleCategory}
                  setSubcategory={setSubcategory}
                />
              </Button>
            )
          })
        }
      </ButtonToolbar>
      <div className={'table-panel'}
           onMouseLeave={() => setOpenSearch(false)}
      >
        <div style={{display: 'flex',alignItems: 'center'}}>
          {
            <>
              <Button variant="success" onClick={e => {
                handleAddModal()
              }}>Add Data</Button>
              <Button variant="info" onClick={e => {
                if(check.length){
                  handleUpdateModal()
                }
              }}>Update Data</Button>
              <Button variant="danger" onClick={e => {
                if (check.length) {
                  setDeleteModal(true)
                }
              }
              }
              >
                Delete Data
                </Button>
              
              <ShowEntries show={show} setShow={setShow}>
              </ShowEntries>
            </>
          }
        </div>
        <Search search={search} setSearch={setSearch} openSearch={openSearch}>
        </Search>
      </div>
      
      
      
      <TableList
        header={header}
        data={data}
        check={check}
        setCheck={setCheck}
        sort={sort}
        setSort={setSort}
      >
      </TableList>
      <Pagination size='lg'>
        <Pagination.First onClick={() => setPagination(1)}  disabled={pagination === 1}/>
        <Pagination.Prev onClick={() => setPagination(p => p - 1)} disabled={pagination === 1}/>
        
        <Pagination.Item>{pagination}</Pagination.Item>
        
        <Pagination.Next onClick={() => setPagination(p => p + 1)} disabled={pagination === lastPage}/>
        <Pagination.Last onClick={() => setPagination(lastPage)} disabled={pagination === lastPage}/>
      </Pagination>
    
    </StyledRecords>
  )
}

const StyledRecords = styled.div`
  .pagination {
          margin: 10px auto;
      }
  .table-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    & button {
      margin-right: 15px;
    }
  }
  .custom-btn{
    display: flex;
    align-items: center;

    .btn {
      padding: 0;
    }
  }

`
