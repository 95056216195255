import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import Loader from '../../../components/Loader';
import * as media from '../../../crud/media.crud';
import { emailForms } from '../../../crud/forms';
import { Avatar } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck';
import '../style.scss';

const ImgComponent = ({ src, alt, className, style }) => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} style={style} />
};

export const EmailFormModal = ({ emailFormModal, setEmailFormModal, selectedForm, check, data }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        if (data.length && check.length) {
            setProfiles(data
                .filter(elem => {
                    let res = false;
                    check.forEach(item => {
                        if (item === elem.profile_id) {
                            res = true;
                        };
                    })
                    return res;
                })
            );
        }
    }, [check, data]);

    const handleSubmit = () => {
        setLoader(true);
        emailForms(selectedForm?.value, check)
            .then(() => {
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'Form sent successfully',
                    variant: true
                }));
                setEmailFormModal(false);
            })
            .catch(err => {
                setLoader(false);
                const errorMessage = err?.response?.data?.message || 'Email form Error';
                dispatch(AlertState.actions.alert({
                    text: errorMessage,
                    variant: false
                }));
            })
    };

    return (
        <>
            <Loader visible={loader} />
            <Modal
                show={emailFormModal}
                onHide={e => {
                    setEmailFormModal(false);
                }}
            >
                <StyledEmailFormModal>
                    <Modal.Header closeButton>
                        <Modal.Title>Email Form</Modal.Title>
                    </Modal.Header>
                    <div className='email-form-title'>
                        {selectedForm?.title}
                    </div>
                    <div className='modal-body'>
                        {profiles.map(elem => {
                            return (
                                <div className='d-flex align-items-center' style={{ marginBottom: '15px' }}>
                                    <div className='photo-container'>
                                        {!!elem.pic
                                            ? <ImgComponent src={elem.pic} alt="img" className="profile-photo" />
                                            : <Avatar className="profile-photo">{elem.first_name[0]}{elem.last_name[0]}</Avatar>
                                        }
                                    </div>
                                    <div className='profile-text'>{`${elem.first_name} ${elem.last_name}`}</div>
                                </div>
                            )
                        })}
                    </div>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="btn-blue"
                            onClick={handleSubmit}
                            disabled={!selectedForm}
                        >Email Form</Button>
                    </Modal.Footer>
                </StyledEmailFormModal>
            </Modal>
        </>
    )
};

const StyledEmailFormModal = styled.div`
    .email-form-title {
        font-size: 18px;
        font-weight: 500;
        margin: 15px 0 20px 20px;
    }

    .modal-body {
        max-height: 300px;
        overflow-y: auto;
        padding-top: 0;
    }

    .photo-container {
        width: 45px;
        height: 45px;
        margin-right: 20px;
        background-color: #bdbdbd;
        border-radius: 50%;
    }

    .profile-photo {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 50%;
        font-size: 16px;
        text-transform: uppercase;
    }

    .profile-text {
        font-size: 16px;
    }
`;
