import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

export const TableRow = ({ item, setModal, setItem }) => {
    return (
        <tr>
            <td>
                {item.id}
            </td>
            <td className="left-content">
                {moment(item.created_at).format('MM/DD/YYYY hh:mm:ss a') || '--'}
            </td>
            <td className="left-content">
                {item.crm_activity_type_format || '--'}
            </td>
            <td className="left-content">
                {item.crm_activity_sync_type_format || '--'}
            </td>
            <td className="left-content">
                {item.is_system ? 'SYSTEM' : item.user_full_name || '--'}
            </td>
            <td className="left-content">
                <Button
                    variant="primary"
                    className="btn-blue"
                    onClick={() => {
                        setModal(true);
                        setItem(item.changes_log);
                    }}
                >Show Data</Button>
            </td>
        </tr>
    )
};
