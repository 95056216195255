import React, { useState, useEffect } from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap'
import { Typography } from '@material-ui/core';
import * as media from '../../../crud/media.crud'

const ImgComponent = ({src, alt, className, style}) => {
    const [url, setUrl] = useState('')

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} style={style} />
}


export default ({ modal, setModal, data, label }) => {

    return (
        <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Protocol: { label && label.label }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Typography variant="h5" gutterBottom>
                    { data.title && data.title.label}
                </Typography>
                {data.purpose && 
                    <Typography variant="body1" gutterBottom>
                        <strong>Purpose: </strong>{ data.purpose }
                    </Typography>
                }
                {data.equipment && 
                    <Typography variant="body1" gutterBottom>
                        <strong>Equipment: </strong>{ data.equipment }
                    </Typography>
                }
                {data.procedure && (<>
                    <Typography variant="body1" gutterBottom>
                        <strong>Procedure: </strong>{ data.procedure.text }
                    </Typography>
                    {data.procedure.content.map((item, index) => <Content data={item} type={item.type} key={index} /> )}
                </>)} 
                {data.notes && (<>
                    <Typography variant="body1" gutterBottom>
                        <strong>Notes: </strong>{ data.notes.text }
                    </Typography>
                    {data.notes.content.map((item, index) => <Content data={item} type={item.type} key={index} id={index}/> )}
                </>)} 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setModal(false)}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const Content = ({data, type, id}) => type === 'list' ? <ContentList data={data} id={id} /> : type === 'table' ? <ContentTable data={data} id={id}/> : type === 'img' ? <ContentImg data={data} id={id}/> : <ContentText data={data} />

const ContentText = ({ data }) => 
    <Typography variant="body1" gutterBottom>
        {data.underline &&
            <u>{ data.underline } </u>}
        { data.title || data.text }
    </Typography>

const ContentList = ({ data, id }) => 
    <>
        <Typography variant="body1" gutterBottom>
            {data.underline &&
                <u>{ data.underline } </u>}
            { data.title || data.text }
        </Typography>
        {data.items && 
            <ul key={id}>
                {data.items.map((item, index) => <li key={`${id}${index}`}><Content data={item} type={item.type} id={`${id}${index}`}/></li>)}
            </ul>}
    </>

const ContentImg = ({ data, id }) => 
    <ImgComponent
        src={data.url}
        style={{
            height: 200,
            width: '100%',
            objectFit: 'contain'
        }}
        alt={id}
    />

const ContentTable = ({ data, id }) => 
    <>
        <Typography variant="body1" gutterBottom>
            {data.underline &&
                <u>{ data.underline } </u>}
            { data.title || data.text }
        </Typography>
        <table className="settingtable">
            {data.items.map((item, index) => {
                return item.type === 'table_header'
                    ? (<>
                        <thead key={index}>
                            <tr>
                                {
                                    item.items.map((item, index2) => <td key={`${id}${index2}`} >{ item.text }</td>)
                                }
                            </tr>
                        </thead>   
                    </>)
                    : item.type === 'table_rows'
                        ? (<>
                            <tbody key={index}>
                                {
                                    item.items.map((item, index2) => {
                                        return (
                                            <tr key={`${id}${index}${index2}`}>
                                                {
                                                    item.map((item, index3) => {
                                                        return (
                                                            <td key={`${id}${index}${index2}${index3}`}>
                                                                { item.text }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody> 
                        </>)
                        : null
            })}
        </table>
    </>