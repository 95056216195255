import React from 'react'
import styled from 'styled-components'
import { Radio } from '@material-ui/core'

// import { getParQ } from '../../../crud/profile.crud'

const PARRow = ({ item, change, data }) => {
    const current = () => {
        return data.find(elem => elem.parq_id === item.id) || ''
    }
    return (
        <StyledPARRow className="parrow">
            <div className="parrow__col col1">
                <Radio color="primary" checked={current().value === 1} onClick={() => change(item.id, 1)}/>
            </div>
            <div className="parrow__col col2">
                <Radio color="primary" checked={current().value === 0} onClick={() => change(item.id, 0)}/>
            </div>
            <div className="parrow__col col3">
                {item.text}
            </div>
        </StyledPARRow>
    )
}

const StyledPARRow = styled.div`

    display: flex;
    padding-bottom: 10px;

    .parrow__col {
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.col1 {
            min-width: 50px;
            width: 50px;
        }

        &.col2 {
            min-width: 50px;
            width: 50px;
        }

        &.col3 {
            width: 100%;
            justify-content: flex-start;
            padding-left: 20px;
        }
    }
`

export default PARRow