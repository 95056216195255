import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck'
import { Formik } from "formik";
import { connect } from "react-redux";
import { Button } from 'react-bootstrap'
import { Redirect } from "react-router-dom";
// import { FormattedMessage, injectIntl } from "react-intl";
// import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword, checkTokenPassword } from "../../crud/auth.crud";
import Logo from '../../../_metronic/_assets/media/logo.svg'
import clsx from "clsx";
import Footer from '../../../_metronic/layout/footer/FooterAuth'

import BG from '../../../_metronic/_assets/media/grid.png'

function RefreshPassword(props) {

  const { intl, match: { params: { id }} } = props;
  document.title = 'TrainerMetrics - Refresh Password'
  const [redirected, setRedirected] = useState(null)
  const [errors, setErrors] = useState({})
  const [tokenError, setTokenError] = useState('')
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };
  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  useEffect(() => {
    checkTokenPassword(id)
      .then(res => {
        // console.log(res)
      })
      .catch(({response}) => {
        setTokenError(response.data.error)
      })
  }, [id])

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: ""
      }}
      validate={values => {
        const temp = {};

        if (!values.password) {
          temp.password = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        } else if (values.password.length > 32 || values.password.length < 8) {
          temp.password = intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_FIELD"
          });
        } else if (
          !/[\W_]/.test(values.password) ||
          !/[0-9]/.test(values.password) ||
          !/[a-z]/.test(values.password) ||
          !/[A-Z]/.test(values.password)
        ) {
          temp.password = intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_FIELD"
          });
        }

        if (!values.confirmPassword) {
          temp.confirmPassword = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        } else if (values.password !== values.confirmPassword) {
          temp.confirmPassword =
            "Password and Confirm Password didn't match";
        }
        
        setErrors(temp)
        return temp;
      }}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        enableLoading()
        resetPassword(
          id,
          values.password
        )
          .then(res => {
            disableLoading()
            dispatch(AlertState.actions.alert({
            text: 'Password is updated',
            variant: true
        }));
            setRedirected(<Redirect to="/auth" />)
          })
          .catch(({ response }) => {
            disableLoading()
            setSubmitting(false);
            !!response.data && setStatus(response.data.message || response.data.error); 
            !!response && !!response.data && !!response.data.errors && setErrors({
              password: response.data.errors.password && response.data.errors.password[0]
            })
          });
      }}
    >
      {({
        values,
        status,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (<>
        <div className="login" style={{backgroundImage: `url(${BG})`}}>
          <Link to="/"><img src={Logo} alt="logo" className="login-logo" /></Link>
          <form 
            onSubmit={handleSubmit} 
            noValidate 
            autoComplete="off"
            className="kt-form login-wrap forgot"
          >
            
            <div className="login-top">
              New Password
            </div>

            { tokenError
                ? (
                  <div className="login-main">
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{tokenError}</div>
                    </div>
                    <div className="kt-login__actions">
                      <Link
                        to="/auth/login"
                      >
                        <button className="btn btn-elevate kt-login__btn-primary btn-login">Go to Login</button>
                      </Link>
                    </div>
                  </div>
                  
                ) : (
                  <div className="login-main">

                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}

                    <div className="form-group mb-0">
                      <TextField
                        key='10'
                        variant="outlined"
                        type="password"
                        margin="normal"
                        label="Password"
                        className="kt-width-full"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{
                            maxLength: 32
                        }}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                      />
                    </div>

                    {Boolean(touched.password && errors.password) && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">
                          Your password must:
                          <ul>
                            <li>Be at least 8 characters in length</li>
                            <li>Contain at least 1 lowercase and 1 uppercase letter</li>
                            <li>Contain at least 1 special character {'(!@#$%^&*)'}</li>
                            <li>Contain at least 1 number (0–9)</li>
                          </ul>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <TextField
                        key='11'
                        variant="outlined"
                        type="password"
                        margin="normal"
                        label="Confirm Password"
                        className="kt-width-full"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{
                            maxLength: 32
                        }}
                        value={values.confirmPassword}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                      />
                    </div>

                    <div className="kt-login__actions">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn-elevate kt-login__btn-primary btn-login btn-blue ${clsx(
                          {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                          }
                        )}`}
                        style={loadingButtonStyle}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )
            }
            {redirected}
          </form>
        </div>
        <Footer style={{position: 'fixed', bottom: 0}}/>
        </>
      )}
    </Formik>
  );
}

export default injectIntl(
  connect(
    (store) => {
      return { user: store.auth.authToken }
    },
    auth.actions
  )(RefreshPassword)
);
