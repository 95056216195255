import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './deletemodal.scss';

export const DeleteModal = ({ setIsDeleteModal, handleDelete }) => {
    return (
        <Modal
            show={() => setIsDeleteModal(true)}
            onHide={() => setIsDeleteModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Report deletion confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="delete-report-modal-body">
                    Please confirm report(s) deletion confirmation.
                    If you will proceed with this action,
                    data will be removed without ability to restore it.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setIsDeleteModal(false)}
                >Cancel</Button>
                <button
                    className="btn-blue btn btn-primary"
                    onClick={handleDelete}
                >Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}