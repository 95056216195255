import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck'
import styled from 'styled-components'
import DataOutput from '../../components/DataOutput'
import UserInfo from '../../components/UserInfo'
import GoalsTable from '../../components/GoalTable'
import { Portlet } from "../../partials/content/Portlet"
import { getGoal, updateGoals, getProfile } from "../../crud/profile.crud"
import { Button, ButtonToolbar, Modal } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Logout from '../auth/Logout'
import { Redirect } from 'react-router-dom'

const Goals = (props) => {

    const dispatch = useDispatch();

    document.title = 'TrainerMetrics - Goals'
    const id = props.match.params.id
    const [data, setData] = useState([])
    const [edit, setEdit] = useState([])
    const [loaderGoals, setLoaderGoals] = useState(false)
    const [loaderUpdateGoals, setLoaderUpdateGoals] = useState(false)
    const [archived, setArchived] = useState(false)
    const [profileInfo, setProfileInfo] = useState(null);
    const [logout, setLogout] = useState(null)

    useEffect(() => {
        refresh();
        // eslint-disable-next-line
    }, [])

    const fixValue = value => {
        if (!(value && value.indexOf)) {
            return ''
        } else if (
            (value.indexOf(':') !== -1)
            || (value.indexOf('"') !== -1)
            || (typeof value === 'object')
        ) {
            return value
        }
        return parseInt(parseFloat(value) * 100) / 100
    }

    const refresh = () => {
        setLoaderGoals(true)
        getGoal(id)
            .then(res => {
                setLoaderGoals(false)
                setData(res.data.data.map(item => ({
                    ...item,
                    result: fixValue(item.result),
                    goal_value: fixValue(item.goal_value)
                })))
                setEdit([])
            })
            .catch(({ response }) => {
                setLoaderGoals(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
        getProfile(id)
            .then((res) => {
                setProfileInfo(res.data.data);
                setArchived(res.data.data.status_id === 3)
            })
    }

    const editDataToos = (item, value, type) => {
        setEdit(edit => {
            const result = edit.findIndex(elem => {
                return elem.category === item.category && elem.name === item.name
            })
            let temp = [...edit];
            let secondtemp = { ...item }
            if (result === -1) {
                if (typeof secondtemp.goal_value === 'number' || typeof secondtemp.goal_value === 'string') {
                    if (type === 0) secondtemp.goal_value = value;
                    temp.push(secondtemp);
                } else {
                    secondtemp.goal_value = type === 0
                        ? [{ ...secondtemp.goal_value[0], value: value }, secondtemp.goal_value[1]]
                        : type === 1
                            ? [secondtemp.goal_value[0], { ...secondtemp.goal_value[1], value: value }]
                            : [secondtemp.goal_value[0], secondtemp.goal_value[1]]

                    temp.push(secondtemp);
                }
            } else {
                if (typeof secondtemp.goal_value === 'number' || typeof secondtemp.goal_value === 'string') {
                    if (type === 0) {
                        secondtemp.goal_value = value;
                        temp[result] = secondtemp;
                    }
                } else {
                    secondtemp.goal_value = type === 0
                        ? [{ ...secondtemp.goal_value[0], value: value }, secondtemp.goal_value[1]]
                        : type === 1
                            ? [secondtemp.goal_value[0], { ...secondtemp.goal_value[1], value: value }]
                            : [secondtemp.goal_value[0], secondtemp.goal_value[1]]

                    temp[result] = secondtemp;
                }
            }
            return temp
        })
    }

    const editData = (item, value, type) => {
        setEdit(edit => {
            const arr = [...edit]
            const result = edit.findIndex(elem => {
                return elem.category === item.category && elem.name === item.name
            })
            result === -1
                ? arr.push({
                    category: item.category,
                    name: item.name,
                    goal_value: type === 'goal_value' ? value : item.goal_value,
                    goal_date: type === 'goal_date' ? value : item.goal_date
                })
                : arr[result] = {
                    ...arr[result],
                    [type]: value
                }
            const resultData = data.findIndex(elem => {
                return elem.category === item.category && elem.name === item.name
            })
            resultData !== -1 &&
                setData(arr => {
                    let temp = [...arr]
                    temp[resultData] = {
                        ...temp[resultData],
                        [type]: value
                    }
                    return temp
                })
            // console.log(arr)
            return arr
        })
    }

    const saveData = () => {
        setLoaderUpdateGoals(true)
        updateGoals(id, edit.filter(item => (item.goal_date !== '  /  /    ') && (item.goal_value !== '')))
            .then(res => {
                setLoaderUpdateGoals(false)
                setModal(true)
                setData(res.data.data)
                dispatch(AlertState.actions.alert({
                    text: 'Goals data is saved',
                    variant: true
                }));
                setLogout(<Redirect to={`/profile/${id}/overview`} />)
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
                setLoaderUpdateGoals(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
    }

    const [modal, setModal] = useState(false)

    return (
        <StyledGoals>
            {logout}
            <Loader visible={loaderGoals || loaderUpdateGoals} />

            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} />
            </div>

            <Modal
                show={modal}
                onHide={e => {
                    setModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Goals are updated</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="primary" onClick={e => {
                        setModal(false)
                    }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row row-full-height">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <Portlet className="portlet">
                        <GoalsTable
                            disabled={archived}
                            data={data}
                            format={'MM/DD/YYYY'}
                            editData={editData}
                            edit={edit}
                            editDataToos={editDataToos}
                            profileInfo={profileInfo}
                        />
                        <ButtonToolbar style={{ margin: '25px' }}>
                            <Button variant="light" style={{ marginRight: 15, border: '1px solid #282a3c' }} onClick={refresh}>Refresh</Button>
                            <Button variant="primary" className="btn-blue" onClick={saveData} disabled={data.length === 0 || archived} style={{ marginLeft: 10 }}>Save</Button>
                        </ButtonToolbar>
                    </Portlet>

                </div>
            </div>
        </StyledGoals>
    )
}

export default Goals

const StyledGoals = styled.div`

`