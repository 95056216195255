import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { fetchForm } from '../../../../../crud/forms';
import Loader from '../../../../../components/Loader';
import { TextField, Checkbox } from "@material-ui/core";
import { CustomRadio } from '../../../../../components/CustomRadio/CustomRadio';
import './form-view-modal.scss';

export const FormViewModal = ({ currentForm, formViewModal, setFormViewModal }) => {
    const [form, setForm] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        fetchForm(currentForm.id)
            .then(res => {
                setForm(res.data.data);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            })
    }, [currentForm]);

    return (
        <div className='form-view-modal'>
            <Loader visible={loader} />

            <Modal show={formViewModal !== false} onHide={() => setFormViewModal(false)}>
                <StyledModalDeactivateCRM>
                    <div
                        className="close-button"
                        onClick={() => setFormViewModal(false)}
                    >×</div>
                    <Modal.Title>
                        <h5 className="title form-view-modal__title">{form?.name}</h5>
                    </Modal.Title>
                    <Modal.Body>
                        {form?.inputs.map(elem => {
                            if (elem.type === 'section') {
                                return <div className='form-view-modal__section' key={elem.id}>{elem.label}</div>
                            } else if (elem.type === 'survey') {
                                return (
                                    <div key={elem.id} className="form-view-modal__question form-view-modal__question-survey">
                                        <div className="form-view-modal__question-text">{elem.label}</div>
                                        <CustomRadio
                                            data={['Yes', 'No']}
                                            disabled
                                        />
                                    </div>
                                )
                            } else if (elem.type === 'short') {
                                return (
                                    <div key={elem.id} className="form-view-modal__question">
                                        <div className="form-view-modal__question-text" style={{ marginBottom: 10 }}>{elem.label}</div>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            label='Your answer'
                                            disabled
                                        />
                                    </div>
                                )
                            } else if (elem.type === 'checkbox') {
                                return (
                                    <div key={elem.id} className="form-view-modal__question form-view-modal__question-checkbox">
                                        <div className="form-view-modal__question-text">{elem.label}</div>
                                        <div style={{ marginLeft: '-11px' }}>
                                            {elem.options.map(option => {
                                                return (
                                                    <div className='d-flex align-items-center' key={option.id}>
                                                        <Checkbox
                                                            color="primary"
                                                            disabled
                                                        />
                                                        <div className='pointer'>{option.label}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            } else if (elem.type === 'number') {
                                return (
                                    <div key={elem.id} className="form-view-modal__question">
                                        <div className="form-view-modal__question-text" style={{ marginBottom: 10 }}>{elem.label}</div>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            label='Your answer'
                                            disabled
                                        />
                                    </div>
                                )
                            } else if (elem.type === 'radio') {
                                return (
                                    <div key={elem.id} className="form-view-modal__question">
                                        <div className="form-view-modal__question-text">{elem.label}</div>
                                        <CustomRadio
                                            data={elem.options.map(elem => {
                                                return elem.label
                                            })}
                                            variant='column'
                                            disabled
                                        />
                                    </div>
                                )
                            }
                        })}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant='primary'
                            onClick={() => setFormViewModal(false)}
                        >Close</Button>
                    </Modal.Footer>
                </StyledModalDeactivateCRM>
            </Modal>
        </div>
    )
};

const StyledModalDeactivateCRM = styled.div`
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 20px 0 0 20px;
    }

    .subtitle {
        margin: 10px 0 0 20px;
        color: #000;
        font-weight: 600;
    }

    .static-text {
        font-size: 1.2em;
        margin: 5px;
        color: #000;
    }
`;
