import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'react-bootstrap'
import DropZone from '../../DropZone'
import DatePicker from '../../../../../components/DatePicker'
import {
    TextField
} from '@material-ui/core'
import { updateFile } from '../../../../../crud/file.crud'
import moment from 'moment'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../../../store/ducks/auth.duck'
import Loader from '../../../../../components/Loader';

export default ({ modal, setModal, id, item, loader, setLoader, fetchData }) => {
    const dispatch = useDispatch()
    const [fileName, setFileName] = useState('')
    const [fileId, setFileId] = useState(0)
    const [fileDate, setFileDate] = useState()
    const [files, setFiles] = useState([])

    const [touched, setTouched] = useState({
        name: false,
        test_date: false
    })
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (files[0] && files[0].success) {
            setFileId(files[0].data.id)
        }
    }, [files])

    useEffect(() => {
        if (item) {
            setFileId(item.file.id)
            setFileName(item.file.file_name)
            setFileDate(moment(item.test_date, 'YYYY-MM-DD').format('MM/DD/YYYY'))
            setFiles([])
            setErrors({})
            setTouched({
                name: false,
                test_date: false
            })
        } else {
            setFileId(0)
            setFileName('')
            setFileDate()
            setFiles([])
            setErrors({})
            setTouched({
                name: false,
                test_date: false
            })
        }
    }, [item, modal])

    const handleSave = () => {
        setLoader(true)
        updateFile(id, {
            id: item.id,
            file_data: {
                id: fileId,
                name: fileName
            },
            test_date: moment(fileDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        })
            .then(() => {
                setLoader(false)
                setModal(false)
                fetchData()
                dispatch(AlertState.actions.alert({
                    text: 'Photo is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                setLoader(false)
                let touch = touched

                Object.keys(touch).forEach(key => touch[key] = true)
                setTouched(touch)

                let errors = {}
                response.data.errors && Object.keys(response.data.errors).forEach(item => {
                    const key = item.replaceAll('file_data', '').replaceAll('.', '')
                    errors[key] = response.data.errors[item]
                })
                setErrors(errors)
            })
    }

    return <UpdateModalStyled>
        <Modal
            show={modal}
            onHide={() => setModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loader visible={loader} />
                <DropZone
                    setData={setFiles}
                    label={'Upload New File'}
                />
                <DatePicker
                    value={fileDate}
                    label="Date"
                    format={'MM/DD/YYYY'}
                    onChange={(value) => {
                        setFileDate(value, 0)
                    }}
                    onBlur={() => setTouched({ ...touched, name: true })}
                    touchPicker={touched.test_date}
                    helperText={touched.test_date && errors.test_date}
                    error={Boolean(touched.test_date && errors.test_date)}
                />
                {files.length <= 1 && <TextField
                    variant="outlined"
                    type="text"
                    label="File Name"
                    margin="normal"
                    className="kt-width-full"
                    name="file_name"
                    inputProps={{
                        maxLength: 300
                    }}
                    onChange={e => setFileName(e.target.value)}
                    onBlur={() => setTouched({ ...touched, name: true })}
                    value={fileName}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                />}
            </Modal.Body>
            <Modal.Footer>
                <Loader visible={loader} spinner={false} />
                <Button
                    variant="secondary"
                    onClick={() => setModal(false)}
                >Close</Button>
                <button
                    className="btn-blue btn btn-primary"
                    onClick={() => handleSave()}
                >Save</button>
            </Modal.Footer>
        </Modal>
    </UpdateModalStyled>
}

const UpdateModalStyled = styled.div`

`