import React, { useState, useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const defaultOptions = [{ title: '1', value: '1' }, { title: '2', value: '2' }, { title: '3', value: '3' }];

export const DropdownComponent = ({ label, error, options, setData, name, value, data, multiple }) => {
    const [inputValue, setInputValue] = useState('');
    const handleChange = (e, value) => {
        if (name === 'age_from' && +value?.title > +data.age_to?.title) {
            setData(prev => ({ ...prev, 'age_from': value, 'age_to': value }))
        } else if (name === 'age_to' && !data.age_from?.title) {
            setData(prev => ({ ...prev, "age_to": value }))
        } else {
            setData(prev => ({ ...prev, [name]: value }))
        };
    };

    const handleKey = e => {
        if (e.key === 'Backspace') {
            setData(prev => ({ ...prev, [name]: '' }))
        };
    };
    return (
        <Autocomplete
            value={value || null}
            id="combo-box-demo"
            options={options || defaultOptions}
            getOptionLabel={option => option.title}
            style={{ width: 150 }}
            onChange={handleChange}
            multiple={multiple}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, value: value || params.inputProps.value }}
                        label={label || 'Combo box'}
                        variant="outlined"
                        error={!!error}
                        helperText={error ? error : ''}
                        onKeyDown={handleKey}
                    />
                )
            }}
        />
    );
}