/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from '../../../components/DatePicker';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getProfileMetric } from '../../../crud/profile.crud';
import { getNote, createNote, updateNote } from '../../../crud/notes.crud';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";

export default ({
  id,
  noteId,
  updateModal,
  setUpdateModal,
  clearCheck,
  setLoader,
  fetchData,
  AlertState,
  dispatch,
  setOpenInfoModal,
  currentItem,
  setCurrentItem,
  check,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [metrics, setMetrics] = useState();
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({});
  const [errorsMetric, setErrorsMetric] = useState('');
  const [date, setDate] = useState(moment().format('MM/DD/YYYY'));
  const [metric, setMetric] = useState(null);
  const [text, setText] = useState('');
  const [errorsNote, setErrorsNote] = useState('');

  useEffect(() => {
    if (currentItem) {
      let item = metrics?.find(elem => elem.metric === currentItem.metric?.name)
      if (item && check.length < 2) {
        setMetric({ ...item, title: item.metric });
      } else {
        setMetric({ title: 'General', metric: "General" });
      }
    }
  }, [check.length, currentItem, metrics]);

  const handleChangeMetric = (c, value) => {
    setErrorsMetric('');
    setMetric(value);
  };

  const handleChangeText = (event) => {
    setErrorsNote('')
    setText(event.target.value);
  };

  const validate = () => {
    let tempError = {}

    if (date && (
      (parseInt(moment(date, 'MM/DD/YYYY').format('YYYY')) < 1970) ||
      (moment(date, 'MM/DD/YYYY') === 'Invalid date') ||
      date.indexOf(' ') !== -1
    )) {
      setIsSubmitting(false)
      tempError = { ...tempError, note_date: 'The minimum value of the year is 1970' }
    } else {
      setErrors({})
      setIsSubmitting(false)
    }
    setErrors(tempError)
    return JSON.stringify(tempError) === '{}'
  };

  const handleSubmit = () => {
    console.log('metric', metric)
    setLoader(true)
    setIsSubmitting(true)
    if (validate()) {
      noteId
        ? updateNote(id, {
          id: noteId,
          note_date: moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          metric_id: metric && metric.title !== 'General' ? metric.metric_id : null,
          note_text: text
        })
          .then(res => {
            setMetric({ title: 'General', metric: "General" });
            setLoader(false)
            clearCheck()
            setIsSubmitting(false)
            setUpdateModal(false)
            fetchData()
            dispatch(AlertState.actions.alert(
              { text: 'Note updated successfully', variant: true }));

          })
          .catch(({ response, errors }) => {
            if (response.status === 422) {
              let er = response?.data?.errors?.metric_id;
              let erNote = response?.data?.errors?.note_text
              if (erNote) setErrorsNote(erNote[0])
              if (er) setErrorsMetric(er[0]);
            }
            if (response.status === 404) {
              setUpdateModal(false)
              setOpenInfoModal(true)
              fetchData();
              clearCheck();
            }
            setLoader(false)
            setIsSubmitting(false)
            //parseError(response)
          })

        : createNote(id, {
          note_date: moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          metric_id: metric && metric.title !== 'General' ? metric.metric_id : null,
          note_text: text,
        })
          .then(res => {
            setMetric({ title: 'General', metric: "General" });
            setLoader(false)
            setIsSubmitting(false)
            setUpdateModal(false)
            fetchData()
            dispatch(AlertState.actions.alert(
              { text: 'Note created successfully', variant: true }));
          })
          .catch(err => {
            const { response } = err
            if (response?.status === 422) {
              let er = response?.data?.errors?.metric_id;
              let erNote = response?.data?.errors?.note_text
              if (erNote) setErrorsNote(erNote[0])
              if (er) setErrorsMetric(er[0]);
            }
            dispatch(AlertState.actions.alert({
              text: response?.data?.errors?.note_text || `Server error ${response?.status}`,
              variant: false,
            }))
            setLoader(false)
            setIsSubmitting(false)
            clearCheck()
          })
    }
  }

  useEffect(() => {
    setDate(moment().format('MM/DD/YYYY'))
    setText('')
    setErrors({})
    setLoader(true)
    getProfileMetric(id)
      .then(res => {
        setErrorsMetric("")
        setErrorsNote('')
        setLoader(false)
        let arr = res.data.data
          .map(elem => ({ ...elem, title: elem.metric }))
          .filter(elem => elem.is_show === 1)
        setMetrics(arr);
        noteId && getNote(id, noteId)
          .then(res2 => {
            setDate(moment(res2.data.data.note_date, 'YYYY-MM-DD').format('MM/DD/YYYY'))
            setText(res2.data.data.note_text)
          })
      })
      .catch(err => {
        setLoader(false)
      })
    // eslint-disable-next-line
  }, [updateModal]);

  const useStyles = makeStyles((theme) => ({
    root: {},
    inputRoot: {
      paddingTop: '1px!important',
      paddingBottom: '1px!important',
      paddingLeft: '0px!important',
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 0
      },
    }
  }));

  const classes = useStyles();

  return (
    <div>
      <Button variant="primary" className="btn-blue" onClick={() => { setUpdateModal(true); clearCheck() }}>Create Note</Button>
      <Modal
        show={updateModal}
        onHide={e => {
          setCurrentItem({});
          setUpdateModal(false);
          setMetric({ title: 'General', metric: "General" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{noteId ? 'Update' : 'Create'} Note</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DatePicker
            future={true}
            past={false}
            label="Note Date"
            value={date}
            format={'MM/DD/YYYY'}
            onChange={(value) => {
              setDate(value, 0)
              validate()
            }}
            error={!!errors.note_date}
            helperText={errors.note_date ? errors.note_date : ''}
          />

          <Autocomplete
            style={{ margin: '20px 0' }}
            classes={classes}
            value={metric}
            options={metrics?.length ? [{ title: 'General', metric: 'General' }, ...metrics] : []}
            getOptionLabel={option => option.title}
            onChange={handleChangeMetric}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={'Metric'}
                  variant="outlined"
                  error={!!errorsMetric}
                  helperText={errorsMetric ? errorsMetric : ''}
                />
              )
            }}
          />

          <TextField
            label="Note Text"
            multiline
            rows={4}
            variant="outlined"
            style={{ width: '100%' }}
            value={text}
            onChange={handleChangeText}
            error={!!errorsNote}
            helperText={errorsNote ? errorsNote : ''}
            inputProps={{
              maxLength: 1000,
            }}
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => {
            setCurrentItem({});
            setUpdateModal(false);
            setMetric({ title: 'General', metric: "General" });
          }}>
            Close
          </Button>
          <Button className="btn-blue" disabled={isSubmitting} onClick={e => {
            setErrorsNote('');
            setErrorsMetric("");
            handleSubmit();
          }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
};
