import React from 'react';
import styled from 'styled-components'
// import { Redirect } from "react-router-dom";
import { Checkbox } from '@material-ui/core/';
import ArrowDown from '@material-ui/icons/ArrowDropDown'


export default ({ header, data, check, setCheck, sort, setSort }) => {
  const sortEdit = item => {
    setSort([item, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
  }
  const chbAll = () => {
    if (check.length === data.length) {
      setCheck([])
    } else {
      setCheck(data.map(item => item.id))
    }
  }
  
  return (
    <StyledTablePl className="settingtable">
      <table className={'records-table'}>
        <thead>
          <tr>
            <td>
              <Checkbox
                checked={check.length === data.length}
                onChange={chbAll}
                color="primary"
              />
            </td>
            {
              header.map((headerItem, headerIndex) => {
                return (
                  <td key={headerIndex} onClick={() => sortEdit(headerItem.name)} className={`left-content ${sort[0] === headerItem.name ? ' active' : ''}`}>
                    { headerItem.title }
                    <ArrowDown
                      className={`sort-arrow${sort[0] === headerItem.name ? ' active' : ''}${sort[0] === headerItem.name && sort[1] === 'desc' ? ' reverse' : ''}`}
                    />
                  </td>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => {
              return (
                <TableRow
                  header={header}
                  item={item}
                  key={index}
                  check={check}
                  setCheck={setCheck}
                />
              )
            })
          }
          
        </tbody>
      </table>
      {
        data.length === 0
          ?  (
            <div
              style={{
                margin: '20px auto',
                textAlign: 'center'
              }}
            >
              No data available in table
            </div>
          ) : null
      }
    </StyledTablePl>
  )
}

const StyledTablePl = styled.div`
  cursor: pointer;

  td.active {
    font-weight: 600;
  }
  
  .sort-arrow {
    opacity: 0.7;
    transition: all .3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg)
    }
  }
  
  table thead tr td.left-content {
    text-align: left;
  }
  table tbody tr td.left-content {
    text-align: left;
  }
`

const TableRow = ({header, item, check, setCheck}) => {
  const getCheck = () => {
    return check.findIndex(id => id === item.id) !== -1
  }

  const editCheck = () => {
    let temp = [...check]
    temp.splice(check.indexOf(item.id), 1)
    getCheck()
      ? setCheck(temp)
      : setCheck([...check, item.id])
  }


  return (
    <Row>
      <td>
        <Checkbox
          checked={getCheck()}
          onChange={() => editCheck()}
          color="primary"
        />
      </td>
      {
        header.map((headerItem, headerIndex) => {
          return (
            <td key={headerIndex} className="left-content">
              {item.values[headerItem.name] || ''}
            </td>
          )
        })
      }
    </Row>
  )
}

const Row = styled.tr`
  .profile-photo {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
  }
`
