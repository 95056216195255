import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../../components/Spacer';
import Dropdown from '../../../../../components/DropdownComponent';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import Loader from '../../../../../components/Loader';
import { getGroup } from '../../../../../crud/info.crud';
import { useDispatch, useSelector } from "react-redux";
import { postAddMapping, fetchMappings, updateMappings, fetchCRMList } from '../../../../../crud/crm.crud';
import { setCRMAction } from '../../../../../store/actions';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import question from '../../../../../../_metronic/_assets/media/svg-icon/question.svg';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const AddGroupModal = ({
    modal,
    setModal,
    getMappings,
}) => {
    const [formData, setFormData] = useState({});
    const [crmGroupList, setCrmGroupList] = useState([]);
    const [trainerMetricsGroupList, seTtrainerMetricsGroupList] = useState([]);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.activation?.is_activate === true));
    const dispatch = useDispatch();

    const loadData = search => {
        setLoader(true);
        fetchMappings('groups', 500, 1, null, 'asc', 1, search, 0, 1)
            .then(res => {
                setLoader(false);
                const list = res.data.data
                    .map(elem => ({ ...elem, title: elem.external_name || '', value: elem.external_id }))
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                setCrmGroupList(sorted);
            })
            .catch(err => {
                setLoader(false);
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    const loadTMGroups = search => {
        setLoader(true);
        getGroup(search, 1)
            .then(res => {
                setLoader(false);
                const list = res.data.data.map(elem => ({ ...elem, title: elem.name, value: elem.id }));
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                seTtrainerMetricsGroupList(sorted)
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    useEffect(() => {
        loadTMGroups();
        loadData();
    }, []);

    const handleClose = () => {
        setModal(false);
        setErrors({});
        setFormData({});
    };

    const validate = () => {
        setErrors({});
        let isValid = true;

        if (!formData.external_id) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    external_id: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (!formData.internal_id) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    internal_id: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        return isValid;
    };

    const handleSave = () => {
        if (validate()) {
            setLoader(true);
            const data = {
                internal_id: +formData.internal_id?.value || null,
                external_id: +formData.external_id?.value || null
            };
            postAddMapping('groups', data)
                .then(() => {
                    loadData();
                    handleClose();
                    getMappings();
                    setLoader(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Mapping added',
                        variant: true,
                    }));
                })
                .catch(err => {
                    let errText = 'request failed';
                    if (err.response?.data?.error) {
                        errText = err.response?.data?.error;
                    } else if (err.response?.data?.message) {
                        errText = err.response.data.message;
                    } else {
                        errText = 'request failed';
                    }
                    dispatch(AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    }));
                    setLoader(false);
                    handleClose()
                })
        }
    };

    const handleUpdate = () => {
        setLoader(true);
        updateMappings('groups')
            .then(() => {
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'CRM list updating has started',
                    variant: true,
                }));
                fetchCRMList()
                    .then(res => {
                        setLoader(false);
                        dispatch(setCRMAction(res.data.data))
                    })
                    .catch(err => {
                        setLoader(false);
                    });
                loadData();
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
                setLoader(false);
                handleClose()
            })
    };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader} />

            <StyledAddUserModal>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>
                <Modal.Title>
                    <h5 className="title">New Group Mapping</h5>
                </Modal.Title>

                <SpacerHorizontal height="25px" />

                <Modal.Body>
                    {crm?.name !== "Motionsoft" && (
                        <>
                            <div className='d-flex align-items-center'>
                                {crm?.last_update_started?.groups
                                    ? (
                                        <div className='d-flex align-items-center'>
                                            <div className="last-sync-block">
                                                {`CRM list last updated ${moment(crm.last_update_started?.groups).format('MM/DD/YYYY hh:mm:ss a')}`}
                                            </div>
                                            <StyledToolTip
                                                title={<>Synchronization may take some time. <br />
                                                    To see if the synchronization is complete, please refresh the page and observe the changes in this table.
                                                </>}
                                                placement="bottom"
                                            >
                                                <img src={question} style={{ cursor: 'pointer', margin: '0 5px 0 10px', height: '18px' }} alt="tooltip" />
                                            </StyledToolTip>
                                        </div>
                                    )
                                    : <div style={{ marginRight: '10px' }}>CRM has not been updated yet.</div>
                                }
                                <span onClick={handleUpdate} className='update-link'>
                                    Update Now
                                </span>
                            </div>
                            <SpacerHorizontal height="25px" />
                        </>
                    )}

                    <div className="crm-dropdown">
                        <Dropdown
                            label="Start typing to search CRM Group"
                            error={errors.external_id?.error}
                            options={crmGroupList}
                            setFormData={val => {
                                setFormData(prev => ({ ...prev, external_id: val }));
                                setErrors(prev => ({ ...prev, external_id: {} }));
                            }}
                            name="CRM Group"
                            value={formData.external_id}
                            width={'100%'}
                            helperText={errors.external_id?.helperText}
                            fetchOptions={loadData}
                        />
                    </div>

                    <div className="crm-dropdown">
                        <Dropdown
                            label="TrainerMetrics Group"
                            options={trainerMetricsGroupList}
                            setFormData={val => {
                                setFormData(prev => ({ ...prev, internal_id: val }));
                                setErrors(prev => ({ ...prev, internal_id: {} }))
                            }}
                            name="TrainerMetrics Group"
                            value={formData.internal_id}
                            width={'100%'}
                            error={errors.internal_id?.error}
                            helperText={errors.internal_id?.helperText}
                            fetchOptions={loadTMGroups}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        className="btn-blue"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </StyledAddUserModal>
        </Modal >
    )
}

const StyledAddUserModal = styled.div`
    .close-button {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        color: #696890;
        margin: 25px 0 0 25px;
        font-size: 20px;
    }

    .modal-body {
        padding: 25px 25px 0px 25px;
        border-top: 1px solid #ebedf2;
    }

    .update-link {
        cursor: pointer;
        color: blue;
    }
`;

export default AddGroupModal;