import React from 'react'
import styled from 'styled-components'
import MetricRow from './MetricRow'

export default ({ data, updateMetrics, updateMetrics45 }) => {

    return (
        <StyledMM className="settingtable" style={{ width: "100%" }}>
            <thead>
                <tr>
                    <td> 
                        Category
                    </td>
                    <td style={{textAlign: 'start'}}>
                        Metric
                    </td>
                    <td>
                        Days Between Metrics
                    </td>
                    <td>
                        Show
                    </td>
                </tr>
            </thead>
            <tbody>
            {data.map((item, index) => {
                return (
                    <MetricRow
                        key={`MM-${index}`}
                        item={item}
                        updateMetrics={updateMetrics}
                        updateMetrics45={updateMetrics45}
                    />
                )
            })}  
            </tbody>
        </StyledMM>
    )
}

const StyledMM = styled.table`
    min-width: 600px;
`
