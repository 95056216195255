import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const InfoModal = ({ openInfoModal, handleClose }) => {
  return (
    <Modal
      show={openInfoModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>This note was deleted</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
        >Close</Button>
      </Modal.Footer>
    </Modal>
  )
}