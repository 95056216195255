import React from 'react';
import styled from 'styled-components'
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

export default ({show, setShow}) => {
  return (
    <StyledMetrics>
      <FormControl className="formControl">
        <InputLabel htmlFor="age-simple">Show Entries</InputLabel>
        <Select
          value={show}
          onChange={e => {
            console.log(e.target.value)
            setShow(e.target.value)
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
    </StyledMetrics>
  )
}

const StyledMetrics = styled.div`
    .formControl {
        width: 150px;
        margin: 5px 10px;
    }
`
