import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from "react-redux";
import {
    Tabs, 
    Tab
} from '@material-ui/core'
import { 
    Portlet, 
    // PortletHeader, 
    PortletBody 
} from "../../partials/content/Portlet"
import UserInfoTab from './AccountInfoTabs/UserInfo'
import BusinessInfoTab from './AccountInfoTabs/BusinessInfo'

const AccountInfo = (props) => {

    const [key, setKey] = useState(0);

    document.title = 'TrainerMetrics - Account Info'

    return (
        <StyledAccountInfo>

            <Portlet>
                {/* <PortletHeader title="Account info" /> */}
                <PortletBody>
                    <div className="acord-none-mob">
                        <Tabs
                            value={key}
                            onChange={(e, value) => setKey(value)}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            <Tab label="User Info"/>
                            { props.user.roles !== 'client' && <Tab label="Business Info"/> }
                        </Tabs>
                    </div>

                    <div className="acord-title acord-none-dt" onClick={() => setKey(key === 0 ? -2 : 0)}>
                        User Info
                    </div>
                    { key === 0 && <UserInfoTab />}
                    <div className="acord-title acord-none-dt" onClick={() => setKey(key === 1 ? -2 : 1)}>
                    Business Info
                    </div>
                    { key === 1 && <BusinessInfoTab />}

                </PortletBody>
            </Portlet>
        </StyledAccountInfo>
    )
}

const StyledAccountInfo = styled.div`

    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }

    /* .nav.nav-tabs {
        margin: 0;
        font-size: 14px;
        font-weight: 450;
    } */

`

const mapStateToProps = ({ auth: { user } }) => ({
    user,
  });
  
  export default connect(mapStateToProps)(AccountInfo);