import React from "react";
import { 
  // Link, 
  Switch, 
  Route, 
  Redirect 
} from "react-router-dom";
import { connect } from "react-redux";
// import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import RegistrationBilling from "./RegistrationBilling";
import RegistrationTrial from "./RegistrationTrial";
import ForgotPassword from './ForgotPassword'
// import Footer from '../../../_metronic/layout/footer/FooterAuth'
import Alert from '../../../_metronic/layout/Alert'

function AuthPage(props) {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <Alert data={props.alert} />
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper" style={{padding: 0, display: 'flex', flexDirection: 'column'}}>
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/signup" component={Registration} />
                <Route path="/auth/signup-subscribe" component={RegistrationBilling} />
                <Route path="/auth/create-trial-account" component={RegistrationTrial} />
                
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (store) => ({ alert: store.auth.alert })
)(AuthPage);