import React from 'react'
import styled from 'styled-components'
import PARrow from './PARRow'
import Loader from '../../../components/Loader'

export default ({parq, parData, change, error, touched}) => {

    return (
        <StyledPARQ className="par">
            {
                touched && (<div style={{color: '#fd397a', marginTop: 10}}>
                    {error}
                </div>)
            }
            <div className="par-row">
                <div className="par-row__col col1">
                    Yes
                </div>
                <div className="par-row__col col2">
                    No
                </div>
                <div className="par-row__col col3">
                    Questions
                </div>
            </div>
            {
                parData.length > 0
                    ? parData.map((item, index) => {
                        return (
                            <PARrow
                                key={`par-${index}`}
                                item={item}
                                change={change}
                                data={parq}
                            />
                        )
                    })
                    : <Loader visible={true} />
            }
        </StyledPARQ>
    )
}

const StyledPARQ = styled.div`
    .par {

        &-row {
            display: flex;
            height: 50px;
            border-bottom: 1px solid #dee2e6;
            margin-bottom: 10px;

            &__col {
                font-size: 14px;
                font-weight: 450;
                display: flex;
                align-items: center;
                justify-content: center;

                &.col1 {
                    min-width: 50px;
                    width: 50px;
                }

                &.col2 {
                    min-width: 50px;
                    width: 50px;

                }

                &.col3 {
                    width: 100%;
                    justify-content: flex-start;
                    padding-left: 20px;
                }
            }
        }
    }
`
