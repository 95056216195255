import React from 'react'
import styled from 'styled-components'

export default (props) => {

    return (
        <StyledRMO className='settingtable'>
            <thead>
                <tr>
                    <td>DATE</td>
                    {
                        props.data.map((item, index) => {
                            return (
                                <td key={index}>
                                    { item.date }
                                </td>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
            <tr>
                    <td>
                        { props.label1 }
                    </td>
                    {
                        props.data.map((item, index) => {
                            return (
                                <td className={"theme-data"} key={index}>
                                    { (item.value || (item.value === 0)) ? item.value : '--' }
                                </td>
                            )
                        })
                    }
                </tr>
                {
                    (props.data[0] && props.data[0].classification) && (<tr>
                        <td >
                            {props.label2} Classification
                        </td>
                        {
                            props.data.map((item, index) => {
                                return (
                                    <td key={index}>
                                        { (item.classification || (item.classification === 0)) ? item.classification : '--'}
                                    </td>
                                )
                            })
                        }
                    </tr>)
                }
            </tbody>
        </StyledRMO>
    )
}

const StyledRMO = styled.table`

`
