import React, { useState } from 'react'
import styled from 'styled-components'

export default ({ item, setId, id, setMetric, setCategory, metric, category }) => {

    const [open, setOpen] = useState(false)

    return (
        <NavTab 
            onMouseMove={() => setOpen(true)} 
            onMouseLeave={() => setOpen(false)}
        >
            <span style={{color: item.category === category ? '#5d78ff' : 'rgba(0, 0, 0, 0.54)',}}>{item.label && item.label.toUpperCase()}</span>
            {
                open && item.childs && 
                    <div className="drop-menu" onClick={() => setCategory(item.category)}>
                        {
                            item.childs.map((elem, index) => {
                                return (
                                    <Submenu 
                                        key={index} 
                                        data={elem} 
                                        setId={setId} 
                                        id={id}
                                        idChild={index}
                                        metric={metric}
                                        setMetric={setMetric}
                                    />
                                )
                            })
                        }
                    </div>
            }
        </NavTab>
    )
}

const Submenu = ({data, setId, id, idChild, metric, setMetric}) => {

    const [open, setOpen] = useState(false)

    return (
        <StyledSubmenu 
            onMouseMove={() => setOpen(true)} 
            onMouseLeave={() => setOpen(false)}
            className={`drop-menu__item${((data.metric === metric) || (data.childs && data.childs.find(elem => elem.metric === metric))) ? ' active' : ''}`}
        >
            <span 
                onClick={() => {
                    setId([id, idChild])
                    data.metric ? setMetric(data.metric) : setMetric(data.childs[0].metric)
                }}
            >
                {data.label}
            </span>
            {
                open && data.childs &&
                    <div className="drop-menu child">
                        {
                            data.childs.map((elem, index) => {
                                return (
                                    <div 
                                        key={index} 
                                        className={`drop-menu__item${elem.metric === metric ? ' active' : ''}`}
                                        onClick={() => {
                                            setId([id, idChild])
                                            elem.metric && setMetric(elem.metric)
                                        }}
                                    >
                                        { elem.label }
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </StyledSubmenu>
    )
}

const NavTab = styled.div`
    position: relative;
    padding: 7px 13px;
    color: rgba(0, 0, 0, 0.54);

    .drop-menu {
        min-width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 8px 0;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        z-index: 101;
        box-shadow: 0 15px 50px 0 rgba(82, 63, 105, .15);

        &.child {
            top: 0;
            left: 100%;
        }

        &__item {
            position: relative;
            width: 100%;
            padding: 5px 10px;
            white-space: nowrap;
            font-weight: normal;
        }
    }
`

const StyledSubmenu = styled.div`

`
