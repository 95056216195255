import {
    SET_CRM_LIST,
    SET_CURRENT_PLAN,
    SET_CRM_FIELDS,
    RESET_CRM_FIELDS,
    SET_CURRENT_MAPPING,
    SET_REFRESH_DATE_GROUPS,
    SET_REFRESH_DATE_USERS,
    SET_REFRESH_DATE_PROFILES,
    SET_ACCOUNT,
    SET_TEMPORARY_FORMS,
    RESET_TEMPORARY_FORM,
    RESET_FORMS,
} from './constants';

export const setCurrentMapping = payload => {
    return {
        type: SET_CURRENT_MAPPING,
        payload,
    }
};

export const setCRMAction = payload => {
    return {
        type: SET_CRM_LIST,
        payload,
    }
};

export const setRefreshDateActionGroups = payload => {
    return {
        type: SET_REFRESH_DATE_GROUPS,
        payload,
    }
};

export const setRefreshDateActionUsers = payload => {
    return {
        type: SET_REFRESH_DATE_USERS,
        payload,
    }
};

export const setRefreshDateActionProfiles = payload => {
    return {
        type: SET_REFRESH_DATE_PROFILES,
        payload,
    }
};

export const setPlanAction = payload => {
    return {
        type: SET_CURRENT_PLAN,
        payload,
    }
};

export const setCRMFields = payload => {
    return {
        type: SET_CRM_FIELDS,
        payload,
    }
};

export const resetCRMFields = () => {
    return {
        type: RESET_CRM_FIELDS
    }
};

export const setAccount = payload => {
    return {
        type: SET_ACCOUNT,
        payload,
    }
};

export const setTemporaryForms = payload => {
    return {
        type: SET_TEMPORARY_FORMS,
        payload,
    }
};

export const resetTemporaryForm = payload => {
    return {
        type: RESET_TEMPORARY_FORM,
        payload,
    }
};

export const resetForms = () => {
    return {
        type: RESET_FORMS,
    }
};
