import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import styled from 'styled-components';
import {
    Portlet,
    PortletBody,
} from "../../../partials/content/Portlet";
import {
    TextField,
    ListItemText,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    OutlinedInput,
    FormHelperText
} from '@material-ui/core';
import { Formik } from "formik";
import Logout from '../../auth/Logout';
import { fieldsGroups, createGroups } from '../../../crud/info.crud';
import Loader from '../../../components/Loader';

export default () => {
    document.title = 'TrainerMetrics - Create Group';
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [staff, setStaff] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [supervisorData, setSupervisorData] = useState([]);
    const [loaderFieldsGroups, setLoaderFieldsGroups] = useState(true);
    const [loaderCreateGroups, setLoaderCreateGroups] = useState(false);
    const fields = useSelector(state => state.crmFields || '');

    useEffect(() => {
        setLoaderFieldsGroups(true)
        fieldsGroups()
            .then(res => {
                setLoaderFieldsGroups(false);
                setStaff(res.data.data.find(item => item.name === 'staff').options);
                setSupervisor(res.data.data.find(item => item.name === 'supervisor').options);
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
                setLoaderFieldsGroups(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />);
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />);
                }
            })
    }, []);

    const inputLabel = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    const inputLabel_2 = useRef();
    const [labelWidth_2, setLabelWidth_2] = useState(0);

    useEffect(() => {
        inputLabel_2.current && setLabelWidth_2(inputLabel_2.current.offsetWidth);
    }, [inputLabel_2]);

    return (
        <StyledCreate>
            {redirect}
            <Loader visible={loaderFieldsGroups || loaderCreateGroups} />
            <Portlet>
                <PortletBody>
                    <Formik
                        initialValues={{
                            name: fields?.fields?.name || '',
                            description: fields?.fields?.description || '',
                            supervisor: '',
                            staff: [],
                            external_id: fields?.fields?.id || undefined,
                        }}
                        validate={values => {
                            const temp = {};

                            if (!values.name) {
                                temp.name = 'This value should not be blank'
                            } else if (values.name.length > 50) {
                                temp.name = 'Field is not valid'
                            } else if (values.name.length < 2) {
                                temp.name = 'The Name should be at least 2 of characters'
                            } else if (/[^A-Za-z0-9 &:.,-_()]/g.test(values.name)) {
                                temp.name = 'Field is not valid'
                            }

                            if (values.description && (values.description.length > 50 || values.description.length < 2)) {
                                errors.description = 'Field is not valid'
                            }

                            setErrors(temp)
                            return temp;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            values.supervisor = supervisorData;
                            values.staff = staffData;
                            if (fields?.id) {
                                values.external_id = fields.id;
                            };

                            setLoaderCreateGroups(true)
                            createGroups(values)
                                .then(res => {
                                    setLoaderCreateGroups(false)
                                    setStatus(false)
                                    setRedirect(<Redirect to={`/group-list`} />)
                                    dispatch(AlertState.actions.alert({
                                        text: 'Group is created',
                                        variant: true
                                    }));
                                })
                                .catch(({ response }) => {
                                    response &&
                                        response.data &&
                                        dispatch(AlertState.actions.alert({
                                            text: response.data.message || response.data.error,
                                            variant: false
                                        }));
                                    response &&
                                        response.data &&
                                        dispatch(AlertState.actions.alert({
                                            text: response.data.message || response.data.error,
                                            variant: false
                                        }));
                                    setLoaderCreateGroups(false)
                                    if (response && (response.status === 401)) {
                                        setRedirect(<Logout />)
                                    } else if (response && (response.status === 403)) {
                                        setRedirect(<Redirect to="/profile-list" />)
                                    }
                                    setErrors({
                                        name: response.data.errors.name && response.data.errors.name[0],
                                        description: response.data.errors.description && response.data.errors.description[0],
                                        supervisor: response.data.errors.supervisor && response.data.errors.supervisor[0],
                                        staff: response.data.errors.staff && response.data.errors.staff[0]
                                    })
                                    setSubmitting(false);
                                    setStatus(response.data.message)
                                })
                        }}
                    >
                        {({
                            values,
                            status,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form
                                noValidate={true}
                                autoComplete="off"
                                className="kt-form root"
                                onSubmit={handleSubmit}
                            >

                                <div className="row">
                                    <div className="col-12 col-md-6 info-block">
                                        {status && (
                                            <div role="alert" className="alert alert-danger">
                                                <div className="alert-text">{status}</div>
                                            </div>
                                        )}
                                        <div className="form-group">
                                            <TextField
                                                key='41'
                                                variant="outlined"
                                                type="text"
                                                label="Name"
                                                margin="normal"
                                                className="kt-width-full"
                                                name="name"
                                                inputProps={{
                                                    maxLength: 50
                                                }}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                helperText={touched.name && errors.name}
                                                error={Boolean(touched.name && errors.name)}
                                            />

                                            <TextField
                                                key='42'
                                                variant="outlined"
                                                type="text"
                                                label="Description"
                                                margin="normal"
                                                multiline
                                                className="kt-width-full"
                                                name="description"
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                helperText={touched.description && errors.description}
                                                error={Boolean(touched.description && errors.description)}
                                            />

                                            <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                                                <FormControl
                                                    className='formControl'
                                                    error={Boolean(touched.supervisor && errors.supervisor)}
                                                >
                                                    <InputLabel variant="outlined" ref={inputLabel} htmlFor="select-multiple-checkbox">Supervisor</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={supervisorData}
                                                        onChange={e => setSupervisorData(e.target.value)}
                                                        input={<OutlinedInput labelWidth={labelWidth} id="select-multiple-checkbox" />}
                                                        renderValue={selected => selected.map(item => supervisor.find(elem => elem.id === item).title).join(', ')}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 250,
                                                                    width: 250,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {supervisor.map(item => (
                                                            <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                                        ))}
                                                        {
                                                            supervisor.length === 0 && (
                                                                <MenuItem disabled>None</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                    <FormHelperText>Select one or more</FormHelperText>
                                                </FormControl>
                                            </div>

                                            <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                                                <FormControl className='formControl'>
                                                    <InputLabel variant="outlined" ref={inputLabel_2} htmlFor="select-multiple-checkbox2">Staff</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={staffData}
                                                        onChange={e => setStaffData(e.target.value)}
                                                        input={<OutlinedInput labelWidth={labelWidth_2} id="select-multiple-checkbox" />}
                                                        renderValue={selected => selected.map(item => staff.find(elem => elem.id === item).title).join(', ')}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 250,
                                                                    width: 250,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {staff.map(item => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                <ListItemText primary={item.title} />
                                                            </MenuItem>
                                                        ))}
                                                        {
                                                            staff.length === 0 && (
                                                                <MenuItem disabled>None</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                    <FormHelperText>Select one or more</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-login__actions" style={{ marginTop: 20 }}>
                                    <button
                                        type="submit"
                                        className="btn btn-blue btn-elevate kt-login__btn-primary"
                                        disabled={isSubmitting}
                                    >
                                        Create Group
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </PortletBody>
            </Portlet>

        </StyledCreate >
    )
}

const StyledCreate = styled.div`

    .nav.nav-tabs {
        margin: 0;
        font-size: 14px;
        font-weight: 450;
    }

`;
