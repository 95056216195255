import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
// import { Redirect } from "react-router-dom";
import * as AlertState from '../store/ducks/auth.duck'
import styled from 'styled-components'
import { Portlet } from "../partials/content/Portlet"
import { getProfile } from '../crud/profile.crud'
import { getImg } from '../crud/media.crud'
import Logout from '../pages/auth/Logout'
import moment from 'moment'
import { Avatar } from '@material-ui/core'
import { Badge } from 'react-bootstrap'
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelCircle from '@material-ui/icons/Cancel';
import ErrorCircle from '@material-ui/icons/Error';

export default ({ id, otherData, getUser }) => {
  const [data, setData] = useState({})
  const [logout, setLogout] = useState(null)
  const refresh = useSelector(state => state.auth.refreshStatistic)
  const dispatch = useDispatch();

  useEffect(() => {
    getProfile(id)
      .then(res => {
        setData(res.data.data)
        getUser && getUser(res.data.data)
      })
      .catch(({ response }) => {
        if (response && (response.status === 401)) {
          setLogout(<Logout />)
        }
        response && response.data && response.data.error && dispatch(AlertState.actions.alert({
          text: response.data.error,
          variant: false
        }));
        // setLogout(<Redirect to="/error/error-v1" />)
      })
  }, [id, otherData, refresh]) // eslint-disable-line

  useEffect(() => {
    otherData && setData(otherData);
  }, [otherData]);

  const [pic, setPic] = useState('')

  useEffect(() => {
    if (data.pic) {
      getImg(data.pic)
        .then(res => setPic(res))
    } else {
      setPic('');
    }
  }, [data]);

  const getYear = () => {
    let df = moment(data.birthday, 'YYYY-MM-DD').toDate();
    let dt = new Date();
    let allYears = dt.getFullYear() - df.getFullYear();
    let partialMonths = dt.getMonth() - df.getMonth();
    let partialDay = dt.getDate() - df.getDate();
    if (partialMonths === 0 && partialDay < 0) {
      partialMonths--;
    }
    if (partialMonths < 0) {
      allYears--;
    }
    return allYears;
  };

  return (
    <StyledUserInfo className='col-12 col-md-6'>
      {logout}
      <Portlet>
        <div className='user-info'>
          <div className='user-info__pic'>
            {pic ? (
              <img src={pic} alt='pic' />
            ) : (
              <Avatar className='profile-photo'>
                {data.first_name && data.first_name[0]}
                {data.last_name && data.last_name[0]}
              </Avatar>
            )}
          </div>
          <div className='user-info__name'>
            <div className='user-info__name-title'>
              {data.first_name} {data.last_name}
            </div>
            <div style={{ display: 'flex' }}>
              <div className='user-info__name-info' style={{ marginRight: 23 }}>
                {data.gender}
              </div>
              <div className='user-info__name-info'>
                Age: {data.birthday ? getYear() : ''}
              </div>
            </div>
          </div>
          <div className='user-info__parq'>
            <div className='action-template'>
              {data.status === 'Active' ? (
                <Badge pill className='active' /* variant='success' */>
                  {data.status}
                </Badge>
              ) : data.status === 'Primary' ? (
                <Badge pill className='active' /* variant='success' */>
                  {data.status}
                </Badge>
              ) : data.status === 'Template' ? (
                <Badge pill className='template'>
                  {data.status}
                </Badge>
              ) : data.status === 'Archived' ? (
                <Badge pill className='archive'>
                  {data.status}
                </Badge>
              ) : (
                <Badge pill variant='danger'>
                  {data.status}
                </Badge>
              )}
            </div>
            <div className='action-par'>
              PAR-Q
              {data.is_parq_completed === 1 ? (
                <CheckCircle />
              ) : data.is_parq_completed === 0 ? (
                <ErrorCircle />
              ) : (
                <CancelCircle />
              )}
            </div>
          </div>
        </div>
      </Portlet>
    </StyledUserInfo>
  );
};

const StyledUserInfo = styled.div`
  height: 100%;
  padding-bottom: 5px;

  .kt-portlet {
    margin-bottom: 0;
  }

  .MuiAvatar-root {
    font-size: 28px;
    font-weight: 700;
    height: 100%;
    width: 100%;
  }

  .profile-photo {
    text-transform: uppercase;
  }

  .user-info {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;

    &__pic {
      height: 100px;
      width: 100px;
      min-height: 100px;
      min-width: 100px;
      border-radius: 50px;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &__parq {
      min-width: 63px;
      padding: 5px 0;
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;

      .action {
        &-template {
          font-weight: 600;
          font-size: 13px;
          line-height: 19px;
          text-align: center;

          .active {
            background-color: #1dcccc;
            color: #000;
          }
          .archive {
            background-color: #f2dede;
            color: #000;
          }

          .template {
            background-color: #B5C6FF;
            color: #fff;
          }
        }

        &-par {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #595d6e;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;

          svg {
            margin-left: 5px;
          }
        }
      }
    }

    &__name {
      width: calc(100% - 100px - 63px);
      padding: 5px 0;
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;

      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #595d6e;
        word-break: break-word;
      }

      &-info {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #595d6e;
      }
    }
  }
`;
