import React, { useState, useEffect } from 'react';
import { fetchCRMList } from '../../../../crud/crm.crud';
import { useDispatch } from 'react-redux';
import { setCRMAction } from '../../../../store/actions';
import Loader from "../../../../components/Loader";

export const CRMLimits = ({ crm }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        fetchCRMList()
            .then(res => {
                setLoader(false);
                dispatch(setCRMAction(res.data.data))
            })
            .catch(err => {
                setLoader(false);
                console.log('fetchCRMList', err);
            })
    }, []);

    return (
        <div className="crm-info">
            <Loader visible={loader} />

            <div className="crm-info__row">
                <div className="crm-info__cell">API Call Usage</div>
                <div className="crm-info__cell crm-info__limit-cell">
                    <span>{crm?.limits?.today_api_calls || 0}</span>
                    <span className="crm-info__text">{` / ${crm?.limits?.limit_day_api_calls || 0}`}</span>
                </div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">API Call Threshold</div>
                <div className="crm-info__cell crm-info__limit-cell">
                    <span>{crm?.limits?.limit_day_api_calls || 0}</span>
                </div>
            </div>
        </div>
    )
};