import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck';
import { Redirect, useLocation } from "react-router-dom";
import styled from 'styled-components';
import {
    Portlet,
    PortletBody,
} from "../../partials/content/Portlet";
import {
    TextField,
    Select,
    FormHelperText,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    OutlinedInput
} from '@material-ui/core';
import {
    Button
} from 'react-bootstrap';
import { Formik } from "formik";
import moment from 'moment';
import PARQ from './ProfileInfoTabs/PARQ';
import MetricManagement from './ProfileInfoTabs/MetricManagement';
import DropZone from '../../components/DropZone';
import Logout from '../auth/Logout';
import UserInfo from '../../components/UserInfo';
import DataOutput from '../../components/DataOutput';
import ProfileNotepad from './ProfileInfoTabs/ProfileNotepad';
import { CRMTab } from './ProfileInfoTabs/CRMTab';
import {
    updateProfile,
    getProfile,
    getProfileMetric,
    updateProfileMetric,
    sendMailAccess,
    saveProfileNote,
} from '../../crud/profile.crud';
import {
    getUserPrivileges,
    getStatusList,
    editStatus,
} from '../../crud/info.crud'
import { getUserByToken } from "../../crud/auth.crud";
import { getAllGroup, getAllUsers } from '../../crud/info.crud';
import Cancel from '@material-ui/icons/Cancel';
import Loader from '../../components/Loader';
import * as media from '../../crud/media.crud';
import { fetchProfileMapping, postRefreshProfile } from '../../crud/crm.crud';
import { setCurrentMapping, resetForms } from '../../store/actions';
import { postProfilePhoto } from '../../crud/file.crud';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import question from '../../../_metronic/_assets/media/svg-icon/question.svg';
import { Intake } from './ProfileInfoTabs/Intake/Intake';
import { updateProfileForm, getProfileForms, emailForm, storeForm } from '../../crud/forms';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const ImgComponent = ({ src, alt, className }) => {
    const [url, setUrl] = useState('')
    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} />
};

export default (props) => {
    document.title = 'TrainerMetrics - Profile Info';
    let role = useSelector(store => store.auth.user.roles);
    const dispatch = useDispatch();
    const location = useLocation();
    const id = props.match.params.id;
    const [errors, setErrors] = useState({});
    const [key, setKey] = useState(0);
    const [redirect, setRedirect] = useState(null);
    const [touchImg, setTouchImg] = useState(0);
    const [parq, setParq] = useState([]);
    const [sendEmail, setSendEmail] = useState('');
    const [statusId, setStatusId] = useState(null);
    const [statusIdD, setStatusIdD] = useState(null);
    const [statusList, setStatusList] = useState([]);
    const [groupsList, setGroupsList] = useState([]);
    const [staffsList, setStaffsList] = useState([]);
    const plan = useSelector(state => state.user_info.subscription?.plan?.name);
    const [isPhotoLoading, setIsPhotoLoading] = useState(false);
    const [forms, setForms] = useState([]);
    const [currentForm, setCurrentForm] = useState(null);
    const savedForm = useSelector(state => {
        if (state.appData.forms) {
            return state.appData.forms[currentForm?.id]
        } else return null
    });
    const savedForms = useSelector(state => state.appData.forms);
    let formFromEmail = document.querySelector(`#form-${location.search.split('form=')[1]}`)

    useEffect(() => {
        if (location?.search && formFromEmail) {
            formFromEmail.click();
        }
    }, [location, formFromEmail]);

    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        birthday: '',
        birthday_day: moment().format('DD'),
        birthday_month: moment().format('MM'),
        birthday_year: moment().format('YYYY'),
        gender: '',
        heightFt: '',
        heightIn: '',
        heightM: '',
        heightCm: '',
        email: '',
        client_access: '0',
        can_update: true,
        styku_email: '',
        inbody_user_token: '',
        groups: [],
        staffs: [],
        notepad_text: '',
        measurement_system: '',
    });

    useEffect(() => {
        setData(data => ({
            ...data,
            parq
        }))
    }, [parq]);

    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.activation?.is_activate === true));
    const mapping = useSelector(state => state.user_info.mapping);
    const [isMappingLoaded, setIsMappingLoaded] = useState(false);
    const [metrics, setMetrics] = useState([]);
    const [loaderProfile, setLoaderProfile] = useState(false);
    const [loaderStatusList, setLoaderStatusList] = useState(false);
    const [loaderProfileUpdate, setLoaderProfileUpdate] = useState(false);
    const [loaderProfileMetric, setLoaderProfileMetric] = useState(false);
    const [loaderProfileMetricUpdate, setLoaderProfileMetricUpdate] = useState(false);
    const [inBody, setInBody] = useState(false);
    const [styku, setStyku] = useState(false);
    const [archived, setArchived] = useState(false);
    const [loader, setLoader] = useState(false);
    const [photo, setPhoto] = useState();

    useEffect(() => {
        dispatch(resetForms());

        setLoader(true);
        getProfileForms(id)
            .then(res => {
                const sorted = res.data.forms.sort((a, b) => {
                    let first = a.name.toLowerCase();
                    let second = b.name.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                });
                setForms(sorted);
                getProfileForms(id);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            })
    }, []);

    const loadMappings = () => {
        fetchProfileMapping(id)
            .then(res => {
                dispatch(setCurrentMapping(res.data.data));
                setIsMappingLoaded(true);
            })
            .catch(() => {
                dispatch(setCurrentMapping(null));
                setIsMappingLoaded(true);
            })
    };

    useEffect(() => {
        loadMappings();
    }, []);

    useEffect(() => {
        loadData()
    }, [id, key === 0]);

    const loadData = () => {
        setLoaderStatusList(true)
        getStatusList()
            .then(res => {
                setLoaderStatusList(false)
                setStatusList(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderStatusList(false)
            });

        getUserByToken()
            .then(res => {
                setInBody(!!res.data.data.enableInbody)
                setStyku(!!res.data.data.enableStyku)
            })

        setLoaderProfile(true)
        getProfile(id)
            .then(res => {
                setLoaderProfile(false)
                setArchived(res.data.data.status_id === 3)
                let temp_b = res.data.data.birthday.split('-')
                setData(data => ({
                    ...data,
                    ...res.data.data,
                    staffs: res.data.data.users,
                    email: res.data.data.email || '',
                    birthday_day: temp_b[2],
                    birthday_month: temp_b[1],
                    birthday_year: temp_b[0],
                    can_update: +res.data.data.can_update !== 1,
                    heightM: `${res.data.data.heightM}`,
                }))
                setStatusId(res.data.data.status_id)
                setStatusIdD(res.data.data.status_id)
                setSendEmail(res.data.data.email)
                setParq(res.data.data.parq)
            })
            .catch(({ response }) => {
                setLoaderProfile(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                }
            })

        setLoaderProfileMetric(false)
        getProfileMetric(id)
            .then(res => {
                setLoaderProfileMetric(false)
                setMetrics(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderProfileMetric(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                }
            })
    };

    useEffect(() => {
        getAllGroup()
            .then(res => {
                setGroupsList(res.data.data)
            })
        getAllUsers()
            .then(res => {
                setStaffsList(res.data.data)
            })
        getUserPrivileges()
            .then(res => {
                saveAccess(res.data.data.permissions)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                }
            });
    }, []);

    const [access, setAccess] = useState({
        create: false,
        activate: false,
        archive: false,
        delete: false,
        access: false,
        updateTesting: false
    });

    const saveAccess = arr => {
        const status = name => arr.findIndex(item => item === name) !== -1

        setAccess({
            create: status('create profiles'),
            activate: status('activate profiles'),
            archive: status('archive profiles'),
            delete: status('delete profiles'),
            access: status('access profiles'),
            updateTesting: status('update testing metrics')
        })
    };

    const updateMetrics45 = (data) => {
        setLoaderProfileMetricUpdate(true)
        updateProfileMetric(id, { is_show: data.is_show, metric_id: 4 })
            .then(() => {
                updateProfileMetric(id, { is_show: data.is_show, metric_id: 5 })
                    .then(() => {
                        dispatch(AlertState.actions.alert({
                            text: 'Metric Management is updated',
                            variant: true
                        }));
                        setLoaderProfileMetricUpdate(false)
                        loadData()
                    })
                    .catch(({ response }) => {
                        response &&
                            response.data &&
                            response.data.error &&
                            dispatch(AlertState.actions.alert({
                                text: response.data.error,
                                variant: false
                            }));
                        setLoaderProfileMetricUpdate(false)
                        if (response && (response.status === 401)) {
                            setRedirect(<Logout />)
                        } else if (response && (response.status === 403)) {
                            if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                        }
                        if (
                            response &&
                            response.data &&
                            response.data.success === false
                        ) {
                            loadData()
                        }
                    })
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    response.data.error &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.error,
                        variant: false
                    }));
                setLoaderProfileMetricUpdate(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                }
                if (
                    response &&
                    response.data &&
                    response.data.success === false
                ) {
                    loadData()
                }
            })
    }

    const updateMetrics = (data) => {
        setLoaderProfileMetricUpdate(true)
        updateProfileMetric(id, data)
            .then(res => {
                dispatch(AlertState.actions.alert({
                    text: 'Metric Management is updated',
                    variant: true
                }));
                setLoaderProfileMetricUpdate(false)
                loadData()
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    response.data.error &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.error,
                        variant: false
                    }));
                setLoaderProfileMetricUpdate(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                }
                if (
                    response &&
                    response.data &&
                    response.data.success === false
                ) {
                    loadData()
                }
            })
    };

    const resendMail = () => {
        sendMailAccess(id, { client_access: 1 })
            .then(res => {
                dispatch(AlertState.actions.alert({
                    text: res.data.data,
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    response.data.error &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.error,
                        variant: false
                    }));
            })
    };

    const inputLabel = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    const inputLabel2 = useRef();
    const [labelWidth2, setLabelWidth2] = useState(0);

    useEffect(() => {
        inputLabel2.current && setLabelWidth2(inputLabel2.current.offsetWidth);
    }, [inputLabel2]);

    const inputLabel3 = useRef();
    const [labelWidth3, setLabelWidth3] = useState(0);

    useEffect(() => {
        inputLabel3.current && setLabelWidth3(inputLabel3.current.offsetWidth);
    }, [inputLabel3]);

    const inputLabel4 = useRef();
    const [labelWidth4, setLabelWidth4] = useState(0);

    useEffect(() => {
        inputLabel4.current && setLabelWidth4(inputLabel4.current.offsetWidth);
    }, [inputLabel4]);

    const inputLabel5 = useRef();
    const [labelWidth5, setLabelWidth5] = useState(0);

    useEffect(() => {
        inputLabel5.current && setLabelWidth5(inputLabel5.current.offsetWidth);
    }, [inputLabel5, statusId, statusIdD]);

    const inputLabel6 = useRef();
    const [labelWidth6, setLabelWidth6] = useState(0);

    useEffect(() => {
        inputLabel6.current && setLabelWidth6(inputLabel6.current.offsetWidth);
    }, [inputLabel6, groupsList, role, plan]);

    const inputLabel7 = useRef();
    const [labelWidth7, setLabelWidth7] = useState(0);

    useEffect(() => {
        inputLabel7.current && setLabelWidth7(inputLabel7.current.offsetWidth);
    }, [inputLabel7, staffsList, role, plan]);

    const handleRefresh = () => {
        setLoader(true);
        postRefreshProfile(location.pathname.split('/')[2])
            .then(() => {
                setTimeout(() => {
                    loadMappings();
                    dispatch(AlertState.actions.refreshStatistic())
                }, 10000);
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: `Sync has started`,
                    variant: true,
                }));
                loadMappings();
            })
            .catch(err => {
                setLoader(false);
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    const handleSwitch = e => {
        setData(prev => ({
            ...prev,
            measurement_system: e.target.value,
        }))
    };

    const handleEmailForm = () => {
        setLoader(true);
        emailForm(id, currentForm.id)
            .then(() => {
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'Email sent',
                    variant: true,
                }));
            })
            .catch((err) => {
                let errorMessage = err?.response?.data?.message;
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: errorMessage || 'Email form error',
                    variant: false,
                }));
            })
    };

    return (
        <StyledProfileCreate>
            <Loader visible={loaderProfile || loaderStatusList || loaderProfileUpdate || loaderProfileMetric || loaderProfileMetricUpdate || loader} />
            {redirect}

            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} otherData={data} />
            </div>

            <Portlet>
                <PortletBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={data}
                        validate={values => {
                            const temp = {};
                            values.birthday = `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`

                            if (!values.first_name) {
                                temp.first_name = 'This value should not be blank'
                            } else if (values.first_name.length > 50) {
                                temp.first_name = 'Field is not valid'
                            } else if (values.first_name.length < 2) {
                                temp.first_name = 'The First Name should be at least 2 of characters'
                            } else if (/[^A-Za-z0-9 ]/g.test(values.first_name)) {
                                temp.first_name = 'Field is not valid'
                            }

                            if (!values.last_name) {
                                temp.last_name = 'This value should not be blank'
                            } else if (values.last_name.length > 50) {
                                temp.last_name = 'Field is not valid'
                            } else if (values.last_name.length < 2) {
                                temp.last_name = 'The Last Name should be at least 2 of characters'
                            } else if (/[^A-Za-z0-9 ]/g.test(values.last_name)) {
                                temp.last_name = 'Field is not valid'
                            }

                            if (!values.birthday) {
                                temp.birthday = 'Please, fill all birhsday fields'
                            } else if (
                                (moment(values.birthday, 'YYYY-MM-DD').toDate() >= moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').toDate()) ||
                                `${moment(values.birthday, 'YYYY-MM-DD').toDate()}` === 'Invalid Date'
                            ) {
                                temp.birthday = 'Please fill the valid data'
                            }

                            if (!values.gender) {
                                temp.gender = 'This value should not be blank'
                            }

                            if (data.measurement_system === 'imperial') {
                                if (!values.heightFt && `${values.heightFt}` !== '0') {
                                    temp.heightFt = 'This value should not be blank'
                                } else if (/[^0-9]/g.test(values.heightFt)) {
                                    temp.heightFt = 'The value can be from 1 to 8'
                                } else if (parseInt(values.heightFt) > 8 || parseInt(values.heightFt) < 1) {
                                    temp.heightFt = 'The value can be from 1 to 8'
                                }

                                if (!values.heightIn && `${values.heightIn}` !== '0') {
                                    temp.heightIn = 'This value should not be blank'
                                } else if (/[^0-9]/g.test(values.heightIn)) {
                                    temp.heightIn = 'The value can be from 0 to 11'
                                } else if (parseInt(values.heightIn) > 11 || parseInt(values.heightIn) < 0) {
                                    temp.heightIn = 'The value can be from 0 to 11'
                                }
                            } else {
                                if (!values.heightM && `${values.heightM}` !== '0') {
                                    temp.heightM = 'This value should not be blank'
                                } else if (+values.heightM < 1 || +values.heightM > 3) {
                                    temp.heightM = 'The value can be from 1 to 3';
                                }
                            }

                            if (values.client_access === '1') {
                                if (!values.email) {
                                    temp.email = 'This value should not be blank'
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    temp.email = 'Invalid email address'
                                }
                            } else if (values.email !== '') {
                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    temp.email = 'Invalid email address'
                                }
                            }

                            if (styku && !!values.styku_email !== false) {
                                if (!values.styku_email) {
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.styku_email)) {
                                    temp.styku_email = 'Invalid email address'
                                }
                            }

                            if (inBody && !!values.inbody_user_token !== false) {
                                if (!values.inbody_user_token) {
                                } else if (values.inbody_user_token.length > 16) {
                                    temp.inbody_user_token = 'Field is not valid'
                                } else if (values.inbody_user_token.length < 3) {
                                    temp.inbody_user_token = 'The Inbody User Token should be at least 2 of characters'
                                } else if (/[^A-Za-z0-9]/g.test(values.inbody_user_token)) {
                                    temp.inbody_user_token = 'Field is not valid'
                                }
                            }

                            setErrors(temp);
                            return temp;
                        }}

                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if (key === 3) {
                                setSubmitting(true);
                                setLoaderProfileUpdate(true);
                                saveProfileNote(id, { notepad_text: values.notepad_text })
                                    .then(() => {
                                        setSubmitting(false);
                                        setLoaderProfileUpdate(false)
                                        dispatch(AlertState.actions.alert({
                                            text: 'Note is updated',
                                            variant: true
                                        }))
                                    })
                                    .catch(() => {
                                        setSubmitting(false);
                                        setLoaderProfileUpdate(false);
                                        dispatch(AlertState.actions.alert({
                                            text: 'Error',
                                            variant: false,
                                        }))
                                    })
                                return;
                            }

                            let birthday = `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`
                            let temp = { ...values, birthday: birthday }
                            let temp_pic = values.pic

                            if (!!values.pic && values.pic?.toLocaleLowerCase().indexOf('http') === -1) {
                                temp.pic = values.pic;
                            } else if (!!values.pic || photo) {
                                delete temp.pic;
                            }

                            delete temp.parq;
                            delete temp.birthday_day;
                            delete temp.birthday_month;
                            delete temp.birthday_year;
                            delete temp.overdue_metrics;

                            if (data.measurement_system === 'imperial') {
                                temp.heightM = '';
                                temp.heightCm = '';
                            } else {
                                temp.heightFt = '';
                                temp.heightIn = '';

                                if ((temp.heightM + '').split('.').length > 1) {
                                    temp.heightCm = (temp.heightM + '').split('.')[1];
                                } else {
                                    temp.heightCm = '0';
                                }

                                temp.heightM = (temp.heightM + '').split('.')[0] || '';
                            }

                            setLoaderProfileUpdate(true)

                            const handleUpdate = () => {
                                let answersArray = [];
                                if (savedForms) {
                                    let isValid = true;
                                    const keys = Object.keys(savedForms);
                                    answersArray = keys.map(key => {
                                        return {
                                            form_id: key,
                                            id: savedForms[key].form_id,
                                            answers: savedForms[key].answers
                                                .filter(answer => answer.type !== 'section')
                                                .map(answer => {
                                                    if (answer.hasError === true) isValid = false;
                                                    if (answer.type === 'number') {
                                                        return {
                                                            input_id: answer.input_id,
                                                            value: answer.value && answer.value !== '' ? +answer.value : null,
                                                        }
                                                    } else {
                                                        return {
                                                            input_id: answer.input_id,
                                                            value: answer.value === 0 || answer.value === '0'
                                                                ? 0
                                                                : answer.value === '' || answer.value === undefined || answer.value === null
                                                                    ? null
                                                                    : answer.value
                                                        }
                                                    }
                                                })
                                        }
                                    });

                                    if (isValid === false) {
                                        setLoader(false);
                                        dispatch(AlertState.actions.alert({
                                            text: 'Some of filled forms are invalid',
                                            variant: false
                                        }));
                                        return;
                                    }
                                };

                                updateProfile(id, temp)
                                    .then(res => {
                                        loadMappings();
                                        let temp_b = res.data.data.birthday.split('-')
                                        setData(data => ({
                                            ...data,
                                            ...res.data.data,
                                            email: res.data.data.email || '',
                                            birthday_day: temp_b[2],
                                            birthday_month: temp_b[1],
                                            birthday_year: temp_b[0],
                                            can_update: +res.data.data.can_update !== 1,
                                            heightM: `${res.data.data.heightM}`,
                                        }))
                                        values.client_access = `${res.data.data.client_access}`;
                                        setSendEmail(res.data.data.email)

                                        editStatus({
                                            status_id: statusId,
                                            profiles: [id]
                                        })
                                            .then(() => {
                                                setArchived(`${statusId}` === `${3}`)
                                                if (`${statusId}` === `${4}` && role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                                                setStatusIdD(statusId)
                                                setSubmitting(false)
                                                setLoaderProfileUpdate(false)
                                                setStatus(false)
                                                dispatch(AlertState.actions.refreshStatistic())
                                                dispatch(AlertState.actions.alert({
                                                    text: 'Profile info is updated',
                                                    variant: true
                                                }));
                                            })
                                            .catch(({ response }) => {
                                                role !== 'client' &&
                                                    response &&
                                                    response.data &&
                                                    dispatch(AlertState.actions.alert({
                                                        text: response.data.message || response.data.error,
                                                        variant: false
                                                    }));
                                                setLoaderProfileUpdate(false)
                                                if (response && (response.status === 401)) {
                                                    setRedirect(<Logout />)
                                                } else if (response && (response.status === 403)) {
                                                    if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                                                }
                                                setSubmitting(false);
                                            })

                                        if (savedForms) {
                                            answersArray.forEach(elem => {
                                                let answers = [...elem.answers];
                                                if (!elem.id) {
                                                    let obj = { ...elem };
                                                    delete obj.id;
                                                    storeForm(res.data.data.id, { ...obj, answers })
                                                        .then(() => {
                                                            dispatch(AlertState.actions.alert({
                                                                text: `${answersArray.length > 1 ? 'Forms' : 'Form'} saved successfully`,
                                                                variant: true
                                                            }));
                                                        })
                                                        .catch(() => {
                                                            setTimeout(() => {
                                                                dispatch(AlertState.actions.alert({
                                                                    text: `Form ${obj.name} update error`,
                                                                    variant: false
                                                                }));
                                                            }, 2000);
                                                        })
                                                } else {
                                                    let obj = { ...elem };
                                                    obj.form_id = obj.id;
                                                    delete obj.id;
                                                    updateProfileForm(res.data.data.id, { ...obj, answers })
                                                        .catch(() => {
                                                            setTimeout(() => {
                                                                dispatch(AlertState.actions.alert({
                                                                    text: `Form ${obj.name} update error`,
                                                                    variant: false
                                                                }));
                                                            }, 2000);
                                                        })
                                                }
                                            });
                                        };
                                    })
                                    .catch(({ response }) => {
                                        response &&
                                            response.data &&
                                            dispatch(AlertState.actions.alert({
                                                text: response.data.message || response.data.error,
                                                variant: false
                                            }));
                                        setLoaderProfileUpdate(false)
                                        if (response && (response.status === 401)) {
                                            setRedirect(<Logout />)
                                        } else if (response && (response.status === 403)) {
                                            if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                                        }
                                        setErrors({
                                            first_name: response.data.errors.first_name && response.data.errors.first_name[0],
                                            last_name: response.data.errors.last_name && response.data.errors.last_name[0],
                                            birthday: response.data.errors.birthday && response.data.errors.birthday[0],
                                            gender: response.data.errors.gender && response.data.errors.gender[0],
                                            heightFt: response.data.errors.heightFt && response.data.errors.heightFt[0],
                                            heightIn: response.data.errors.heightIn && response.data.errors.heightIn[0],
                                            heightM: response.data.errors.heightM && response.data.errors.heightM[0],
                                            email: response.data.errors.email && response.data.errors.email[0],
                                            client_access: response.data.errors.client_access && response.data.errors.client_access[0],
                                            parq: response.data.errors.parq && response.data.errors.parq[0],
                                            pic: response.data.errors.pic && response.data.errors.pic[0]
                                        })
                                        setSubmitting(false);
                                    })
                            }

                            if (!archived) {
                                if (photo) {
                                    postProfilePhoto(id, photo.id || null)
                                        .then(() => {
                                            setTimeout(() => {
                                                dispatch(AlertState.actions.alert({
                                                    text: 'Profile photo is updated',
                                                    variant: true
                                                }));
                                            }, 2000);
                                            handleUpdate();
                                            setPhoto(null);
                                        })
                                        .catch(() => {
                                            setTimeout(() => {
                                                dispatch(AlertState.actions.alert({
                                                    text: 'Profile photo update error',
                                                    variant: false
                                                }));
                                            }, 2000);
                                            handleUpdate();
                                        })
                                } else {
                                    handleUpdate();
                                    setPhoto(null);
                                }
                            } else {
                                editStatus({
                                    status_id: statusId,
                                    profiles: [id]
                                })
                                    .then(() => {
                                        setArchived(`${statusId}` === `${3}`)
                                        setLoaderProfileUpdate(false)
                                        setStatus(false)
                                        setSubmitting(false)
                                        dispatch(AlertState.actions.refreshStatistic())
                                        dispatch(AlertState.actions.alert({
                                            text: 'Status is updated',
                                            variant: true
                                        }));
                                    })
                                    .catch(({ response }) => {
                                        role !== 'client' &&
                                            response &&
                                            response.data &&
                                            dispatch(AlertState.actions.alert({
                                                text: response.data.message || response.data.error,
                                                variant: false
                                            }));
                                        setLoaderProfileUpdate(false)
                                        if (response && (response.status === 401)) {
                                            setRedirect(<Logout />)
                                        } else if (response && (response.status === 403)) {
                                            if (role !== 'client') setRedirect(<Redirect to="/profile-list" />)
                                        }
                                        setSubmitting(false);
                                    })
                            }

                            if (!(temp_pic && temp_pic?.toLocaleLowerCase().indexOf('http') === -1)) {
                                values.pic = temp_pic
                            }
                        }}
                    >
                        {({
                            values,
                            status,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form
                                noValidate={true}
                                autoComplete="off"
                                className="kt-form root"
                                onSubmit={handleSubmit}
                            >
                                <div className="acord-none-mob" style={{ marginBottom: '30px' }}>

                                    <div className={`d-flex justify-content-between align-items-center ${crm && mapping && key === 4 ? 'profile-info-tabs' : 'profile-info-tabs-full'}`}>
                                        <Tabs
                                            value={key}
                                            onChange={(e, value) => setKey(value)}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            scrollButtons="auto"
                                            variant="scrollable"
                                        >
                                            <Tab label="Profile Info" />
                                            <Tab label="Metric Management" />
                                            <Tab label="PAR-Q" />
                                            <Tab label="Notepad" />
                                            {mapping && <Tab label="CRM" />}

                                            {forms.length > 0 && isMappingLoaded &&
                                                forms
                                                    .filter(elem => !!elem.pages?.find(item => item === 'profile-info'))
                                                    .map(elem => {
                                                        return (
                                                            <Tab
                                                                label={elem.tab_name}
                                                                key={elem.name}
                                                                onClick={() => setCurrentForm(elem)}
                                                                id={`form-${elem.id}`}
                                                            />
                                                        )
                                                    })}
                                        </Tabs >

                                        {(mapping && key >= 5 && role !== 'client') && (
                                            <Button
                                                className='btn btn-blue btn-elevate kt-login__btn-primary'
                                                style={{ height: 36, whiteSpace: 'nowrap' }}
                                                onClick={handleEmailForm}
                                            >Email Form</Button>
                                        )}

                                        {(!mapping && key >= 4 && role !== 'client') && (
                                            <Button
                                                className='btn btn-blue btn-elevate kt-login__btn-primary'
                                                style={{ height: 36, whiteSpace: 'nowrap' }}
                                                onClick={handleEmailForm}
                                            >Email Form</Button>
                                        )}
                                    </div>

                                    {crm && mapping && key === 4 && (
                                        <div className='last-sync-container'>
                                            {mapping.last_sync_started_at
                                                ? (
                                                    <div className='d-flex align-items-center'>
                                                        <div className="last-sync-block">
                                                            {`Last Sync Started: ${moment(mapping.last_sync_started_at).format('MM/DD/YYYY hh:mm:ss a')}`}
                                                        </div>
                                                        <StyledToolTip
                                                            title={<>Synchronization may take some time. <br />
                                                                To see if the synchronization is complete, please refresh the page and observe the changes in this table.
                                                            </>}
                                                            placement="bottom"
                                                        >
                                                            <img src={question} style={{ cursor: 'pointer', margin: '0 5px 0 0', height: '18px' }} alt="tooltip" />
                                                        </StyledToolTip>
                                                    </div>
                                                )
                                                : <div />
                                            }
                                            <Button
                                                variant="info"
                                                className="button"
                                                onClick={handleRefresh}
                                            >Refresh</Button>
                                        </div>
                                    )}
                                </div >

                                <div className="acord-title" onClick={() => setKey(key === 0 ? null : 0)}>
                                    Profile Info
                                </div>
                                {
                                    key === 0 &&
                                    <>
                                        <div className="row">
                                            <div className="col-12 col-md-6 info-block">
                                                <h4>Profile Information</h4>
                                                <div className="form-group">
                                                    <TextField
                                                        key='23'
                                                        variant="outlined"
                                                        disabled={data.can_update || archived}
                                                        type="text"
                                                        label="First Name"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="first_name"
                                                        onBlur={a => {
                                                            handleBlur(a)
                                                        }}
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        onChange={handleChange}
                                                        value={values.first_name}
                                                        helperText={touched.first_name && errors.first_name}
                                                        error={Boolean(touched.first_name && errors.first_name)}
                                                    />

                                                    <TextField
                                                        key='24'
                                                        variant="outlined"
                                                        disabled={data.can_update || archived}
                                                        type="text"
                                                        label="Last Name"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="last_name"
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.last_name}
                                                        helperText={touched.last_name && errors.last_name}
                                                        error={Boolean(touched.last_name && errors.last_name)}
                                                    />

                                                    <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root" error={Boolean(touched.gender && errors.gender)}>
                                                        <InputLabel ref={inputLabel} htmlFor="gender-simple">Sex</InputLabel>
                                                        <Select
                                                            disabled={data.can_update || archived}
                                                            value={values.gender
                                                                ? values.gender.charAt(0).toUpperCase() + values.gender.slice(1)
                                                                : ''
                                                            }
                                                            onChange={handleChange}
                                                            input={<OutlinedInput labelWidth={labelWidth} />}
                                                            inputProps={{
                                                                name: 'gender',
                                                                id: 'gender-simple',
                                                            }}
                                                        >
                                                            <MenuItem value={'Male'}>Male</MenuItem>
                                                            <MenuItem value={'Female'}>Female</MenuItem>
                                                        </Select>
                                                        {
                                                            touched.gender && errors.gender &&
                                                            <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                        }
                                                    </FormControl>

                                                    <div className="birthday-row">
                                                        <FormControl variant="outlined" error={Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday)}>
                                                            <InputLabel ref={inputLabel3} htmlFor="birthday_month">
                                                                Birth Month
                                                            </InputLabel>
                                                            <Select
                                                                disabled={data.can_update || archived}
                                                                value={values.birthday_month} onChange={e => {
                                                                    handleChange(e)
                                                                    touched.birthday_month = true
                                                                }}
                                                                input={<OutlinedInput labelWidth={labelWidth3} name="birthday_month" id="birthday_month" />}
                                                            >
                                                                <MenuItem value={'01'}>January</MenuItem>
                                                                <MenuItem value={'02'}>February</MenuItem>
                                                                <MenuItem value={'03'}>March</MenuItem>
                                                                <MenuItem value={'04'}>April</MenuItem>
                                                                <MenuItem value={'05'}>May</MenuItem>
                                                                <MenuItem value={'06'}>June</MenuItem>
                                                                <MenuItem value={'07'}>July</MenuItem>
                                                                <MenuItem value={'08'}>August</MenuItem>
                                                                <MenuItem value={'09'}>September</MenuItem>
                                                                <MenuItem value={'10'}>October</MenuItem>
                                                                <MenuItem value={'11'}>November</MenuItem>
                                                                <MenuItem value={'12'}>December</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl variant="outlined" error={Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday)}>
                                                            <InputLabel ref={inputLabel4} htmlFor="birthday_day">
                                                                Birth Day
                                                            </InputLabel>
                                                            <Select
                                                                disabled={data.can_update || archived}
                                                                value={values.birthday_day}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                    touched.birthday_day = true
                                                                }}
                                                                input={<OutlinedInput labelWidth={labelWidth4} name="birthday_day" id="birthday_day" />}
                                                            >
                                                                {
                                                                    [...new Array(31)].map((item, index) => <MenuItem value={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`} key={index}>{1 + index}</MenuItem>)
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl variant="outlined" error={Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday)}>
                                                            <InputLabel ref={inputLabel2} htmlFor="birthday_year">
                                                                Birth Year
                                                            </InputLabel>
                                                            <Select
                                                                disabled={data.can_update || archived}
                                                                value={values.birthday_year}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                    touched.birthday_year = true
                                                                }}
                                                                input={<OutlinedInput labelWidth={labelWidth2} name="birthday_year" id="birthday_year" />}
                                                            >
                                                                {
                                                                    [...new Array(121)].map((item, index) => <MenuItem value={2020 - index} key={index}>{2020 - index}</MenuItem>)
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                    </div>
                                                    {
                                                        Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday) &&
                                                        <div className="birthday-row__error">
                                                            {errors.birthday}
                                                        </div>
                                                    }

                                                    <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root">
                                                        <InputLabel ref={inputLabel5} htmlFor="system" className="MuiFormLabel-root">System</InputLabel>
                                                        <Select
                                                            value={data.measurement_system}
                                                            onChange={handleSwitch}
                                                            input={<OutlinedInput id="system" name="system" />}
                                                        >
                                                            <MenuItem value={"imperial"}>Imperial</MenuItem>
                                                            <MenuItem value={"metric"}>Metric</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    {
                                                        data.measurement_system === 'imperial'
                                                            ? (
                                                                <div style={{ display: 'flex' }}>
                                                                    <TextField
                                                                        disabled={data.can_update || archived}
                                                                        key='25'
                                                                        style={{ marginRight: 10 }}
                                                                        variant="outlined"
                                                                        type="number"
                                                                        label="Height Ft"
                                                                        margin="normal"
                                                                        className="kt-width-full"
                                                                        name="heightFt"
                                                                        onBlur={handleBlur}
                                                                        onChange={e => {
                                                                            let temp = { ...e }
                                                                            temp.target.value = Math.floor(e.target.value)
                                                                            handleChange(temp)
                                                                        }}
                                                                        value={values.heightFt}
                                                                        helperText={touched.heightFt && errors.heightFt}
                                                                        error={Boolean(touched.heightFt && errors.heightFt)}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            max: 8,
                                                                            min: 1,
                                                                        }}
                                                                    />

                                                                    <TextField
                                                                        disabled={data.can_update || archived}
                                                                        key='26'
                                                                        variant="outlined"
                                                                        type="number"
                                                                        label="Height In"
                                                                        margin="normal"
                                                                        className="kt-width-full"
                                                                        name="heightIn"
                                                                        onBlur={handleBlur}
                                                                        onChange={e => {
                                                                            let temp = { ...e }
                                                                            temp.target.value = Math.floor(e.target.value)
                                                                            handleChange(temp)
                                                                        }}
                                                                        value={values.heightIn}
                                                                        helperText={touched.heightIn && errors.heightIn}
                                                                        error={Boolean(touched.heightIn && errors.heightIn)}
                                                                        inputProps={{
                                                                            max: 11,
                                                                            min: 0,
                                                                        }}
                                                                    />
                                                                </div >
                                                            )

                                                            : (
                                                                <div style={{ display: 'flex' }}>
                                                                    <TextField
                                                                        disabled={data.can_update || archived}
                                                                        key='25'
                                                                        variant="outlined"
                                                                        label="Height M"
                                                                        margin="normal"
                                                                        className="kt-width-full"
                                                                        name="heightM"
                                                                        onBlur={handleBlur}
                                                                        onChange={e => {
                                                                            let temp = { ...e }
                                                                            temp.target.value = e.target.value
                                                                            handleChange(temp)
                                                                        }}
                                                                        value={values.heightM}
                                                                        helperText={touched.heightM && errors.heightM}
                                                                        error={Boolean(touched.heightM && errors.heightM)}
                                                                        inputProps={{
                                                                            type: "number",
                                                                            step: "0.1",
                                                                            min: 1,
                                                                            max: 3,
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}

                                                    {
                                                        ((`${statusIdD}` === `${2}`) || (`${statusIdD}` === `${3}`)) && (role !== 'client') &&
                                                        <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root">
                                                            <InputLabel ref={inputLabel5}>Status</InputLabel>
                                                            <Select
                                                                value={`${statusId}`}
                                                                onChange={e => setStatusId(e.target.value)}
                                                                input={<OutlinedInput labelWidth={labelWidth5} />}
                                                            >
                                                                {
                                                                    statusList.filter(item => (item.id === 2) || (item.id === 3) || (item.id === 4)).map((item) => {
                                                                        return (
                                                                            <MenuItem key={item.id} value={`${item.id}`}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    }

                                                    <TextField
                                                        key='27'
                                                        variant="outlined"
                                                        disabled={data.can_update || archived}
                                                        type="email"
                                                        label="Email"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        helperText={touched.email && errors.email}
                                                        error={Boolean(touched.email && errors.email)}
                                                    />
                                                    {
                                                        ((role === 'supervisor') || (role === 'admin') || (role === 'super-admin')) && (plan === 'Enterprise') && <FormControl
                                                            variant="outlined"
                                                            error={Boolean(touched.groups && errors.groups)}
                                                            className="MuiFormControl-marginNormal MuiTextField-root"
                                                        >
                                                            <InputLabel ref={inputLabel6} htmlFor="groups">
                                                                Groups
                                                            </InputLabel>
                                                            <Select
                                                                multiple
                                                                value={values.groups}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                    touched.groups = true
                                                                }}
                                                                input={<OutlinedInput labelWidth={labelWidth6} id="groups" name="groups" />}
                                                            >
                                                                {
                                                                    groupsList.map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    }

                                                    {
                                                        ((role === 'supervisor') || (role === 'admin') || (role === 'super-admin')) && ((plan === 'Gym') || (plan === 'Enterprise')) && <FormControl
                                                            variant="outlined"
                                                            error={Boolean(touched.groups && errors.groups)}
                                                            className="MuiFormControl-marginNormal MuiTextField-root"
                                                        >
                                                            <InputLabel ref={inputLabel7} htmlFor="staffs">
                                                                Staffs
                                                            </InputLabel>
                                                            <Select
                                                                multiple
                                                                value={values.staffs}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                    touched.staffs = true
                                                                }}
                                                                input={<OutlinedInput labelWidth={labelWidth7} id="staffs" name="staffs" />}
                                                            >
                                                                {
                                                                    staffsList.filter(item => item.type === 'staff').map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    }

                                                    {
                                                        `${data.client_access}` === '1' && (role !== 'client') && (
                                                            <div>
                                                                Invitation sent to {sendEmail}. <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={resendMail}>Click here to resend.</span>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        access.access && (role !== 'client') && (
                                                            <FormControl className="" error={Boolean(touched.client_access && errors.client_access)}>
                                                                <RadioGroup
                                                                    aria-label="Gender"
                                                                    id="client_access-simple"
                                                                    className='group'
                                                                    style={{ flexDirection: 'row' }}
                                                                    value={`${values.client_access}`}
                                                                    onChange={handleChange}
                                                                >
                                                                    <FormControlLabel disabled={archived} value={'1'} name="client_access" control={<Radio color="primary" />} label="Allow access to Client Profile" />
                                                                    <FormControlLabel disabled={archived} value={'0'} name="client_access" control={<Radio color="primary" />} label="Do not allow access to Client Profile" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )
                                                    }

                                                    {
                                                        inBody ? <TextField
                                                            key='28'
                                                            variant="outlined"
                                                            disabled={archived}
                                                            type="text"
                                                            label="InBody User Token"
                                                            margin="normal"
                                                            className="kt-width-full"
                                                            name="inbody_user_token"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                handleChange({ ...e, target: { id: e.target.id, name: e.target.name, value: `${parseInt(e.target.value) || ''}` } })
                                                            }}
                                                            value={values.inbody_user_token}
                                                            inputProps={{
                                                                maxLength: 16,
                                                            }}
                                                            helperText={touched.inbody_user_token && errors.inbody_user_token}
                                                            error={Boolean(touched.inbody_user_token && errors.inbody_user_token)}
                                                        /> : null
                                                    }
                                                    {
                                                        styku ? <TextField
                                                            key='29'
                                                            variant="outlined"
                                                            disabled={archived}
                                                            type="email"
                                                            label="Styku Email"
                                                            margin="normal"
                                                            className="kt-width-full"
                                                            name="styku_email"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.styku_email}
                                                            helperText={touched.styku_email && errors.styku_email}
                                                            error={Boolean(touched.styku_email && errors.styku_email)}
                                                        /> : null
                                                    }

                                                </div >
                                            </div >

                                            <div className="col-12 col-md-6 info-block">
                                                <h4>
                                                    Profile Photo
                                                </h4>
                                                <div className="info-block__photo">
                                                    {
                                                        values.pic && values.pic?.toLocaleLowerCase().indexOf('http') !== -1 && touchImg >= 0
                                                            ? (<div className="info-block__photo-preview">
                                                                <ImgComponent
                                                                    src={values.pic}
                                                                    alt="profile"
                                                                />
                                                                <div className="delete"
                                                                    onClick={() => {
                                                                        setTouchImg(prev => prev + 1)
                                                                        values.pic = null;
                                                                        setPhoto(null);
                                                                    }}>
                                                                    <Cancel />
                                                                </div>
                                                            </div>)
                                                            : archived
                                                                ? values.pic
                                                                    ? <ImgComponent
                                                                        src={values.pic}
                                                                        alt="profile"
                                                                    />
                                                                    : <div
                                                                        style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '100px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            background: '#bdbdbd',
                                                                            color: '#fafafa',
                                                                            fontSize: '28px',
                                                                            fontWeight: '700'
                                                                        }}
                                                                    >
                                                                        {values.first_name && values.first_name[0].toUpperCase()}
                                                                        {values.last_name && values.last_name[0].toUpperCase()}
                                                                    </div>
                                                                : (<DropZone
                                                                    setIsLoading={setIsPhotoLoading}
                                                                    submit={e => {
                                                                        setPhoto(e);
                                                                    }}
                                                                    photo={values.pic}
                                                                    onDelete={() => {
                                                                        values.pic = null
                                                                        setPhoto(null);
                                                                    }}
                                                                    Content={() => (
                                                                        <div
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                                borderRadius: '100px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                background: '#bdbdbd',
                                                                                color: '#fafafa',
                                                                                fontSize: '28px',
                                                                                fontWeight: '700'
                                                                            }}
                                                                        >
                                                                            {values.first_name && values.first_name[0].toUpperCase()}
                                                                            {values.last_name && values.last_name[0].toUpperCase()}
                                                                        </div>
                                                                    )}
                                                                />)
                                                    }
                                                    <div style={{ color: '#fd397a' }}>
                                                        {
                                                            touched.pic && errors.pic
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="kt-login__actions" style={{ marginLeft: 40 }}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-blue btn-elevate kt-login__btn-primary"
                                                        disabled={isSubmitting || isPhotoLoading}
                                                    >Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </>
                                }
                                <div className="acord-title" onClick={() => setKey(key === 1 ? null : 1)}>
                                    Metric Management
                                </div>

                                {key === 1 && <div className="row" style={{
                                    overflow: 'hidden',
                                    overflowX: 'auto'
                                }}>
                                    <MetricManagement
                                        data={metrics}
                                        updateMetrics={updateMetrics}
                                        updateMetrics45={updateMetrics45}
                                    />
                                </div>
                                }

                                <div className="acord-title" onClick={() => setKey(key === 2 ? null : 2)}>
                                    PAR-Q
                                </div>

                                {key === 2 && <div className="row" style={{
                                    overflow: 'hidden',
                                    overflowX: 'auto'
                                }}>
                                    <PARQ
                                        id={id}
                                        parqTemp={parq}
                                        setParqTemp={setParq}
                                    />
                                </div>
                                }

                                <div className="acord-title" onClick={() => setKey(key === 3 ? null : 3)}>
                                    Notepad
                                </div>

                                {key === 3 && <div className="" style={{
                                    overflow: 'hidden',
                                    overflowX: 'auto'
                                }}>
                                    <ProfileNotepad
                                        value={values.notepad_text}
                                        setValue={value => handleChange({ target: { value: value, name: 'notepad_text', id: 'notepad_text' } })}
                                        data={data}
                                    />
                                </div>
                                }

                                {crm && mapping && <div className="acord-title" onClick={() => setKey(key === 4 ? null : 4)}>
                                    CRM
                                </div>}

                                {!!(mapping && +key === 4) &&
                                    <div>
                                        <div className='last-sync-container__mobile'>
                                            {mapping.last_sync_started_at
                                                ? (
                                                    <div className='d-flex align-items-center' style={{ marginRight: '15px' }}>
                                                        <div className="last-sync-block__mobile">
                                                            {`Last Sync Started: ${moment(mapping.last_sync_started_at).format('MM/DD/YYYY hh:mm:ss a')}`}
                                                        </div>
                                                    </div>
                                                )
                                                : <div />
                                            }
                                            <Button
                                                variant="info"
                                                className="button"
                                                onClick={handleRefresh}
                                            >Refresh</Button>
                                        </div>

                                        <CRMTab
                                            setLoader={setLoaderProfile}
                                            mapping={mapping}
                                            setKey={setKey}
                                            loadMappings={loadMappings}
                                        />
                                    </div>
                                }

                                {forms.length > 0 && isMappingLoaded &&
                                    forms
                                        .filter(elem => !!elem.pages?.find(item => item === 'profile-info'))
                                        .map((elem, index) => {
                                            return (
                                                <>
                                                    <div
                                                        className="acord-title"
                                                        onClick={() => {
                                                            setKey(prev => {
                                                                if (mapping && prev === 5 + index) return null;
                                                                if (!mapping && prev === 4 + index) return null
                                                                return mapping ? 5 + index : 4 + index
                                                            });
                                                            setCurrentForm(elem);
                                                        }}
                                                    >
                                                        {elem.tab_name}
                                                    </div>

                                                    {(mapping && key === 5 + index) && (
                                                        <Intake
                                                            currentForm={currentForm}
                                                            setLoader={setLoader}
                                                            handleSubmit={handleSubmit}
                                                            savedForm={savedForm}
                                                            profileId={id}
                                                        />
                                                    )}

                                                    {(!mapping && key === 4 + index) && (
                                                        <Intake
                                                            currentForm={currentForm}
                                                            setLoader={setLoader}
                                                            handleSubmit={handleSubmit}
                                                            savedForm={savedForm}
                                                            profileId={id}
                                                        />
                                                    )}
                                                </>
                                            )
                                        })}
                            </form >
                        )
                        }
                    </Formik >
                </PortletBody >
            </Portlet >
        </StyledProfileCreate >
    )
};

const StyledProfileCreate = styled.div`
    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }
    
    .birthday-row {
        display: flex;
        justify-content: space-between;    
        margin-top: 16px;
        margin-bottom: 8px;

        &__error {
            color: #fd397a;
            margin: 8px 14px 0;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1em;
        }

        & > div {
            width: 30%;
        }
    }

    .info-block {
        padding: 30px 50px;

        &__photo {
            margin-top: 30px;

            & > div:first-child {
                height: 200px;
            }

            &-preview {
                border: 1px dashed grey;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 300px;
                width: 100%;
                padding: 30px;
                position: relative;

                .delete {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .metric-switcher {
        display: flex;
        align-items: center;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background-color: #fff;
    }

    .profile-info-tabs-full {
        width: 100%;
    }

    .profile-info-tabs {
        width: calc(100% - 400px);

        @media (max-width: 1360px) {
            width: calc(100% - 250px);
        }

        @media (max-width: 1180px) {
            width: calc(100% - 195px);
        }

        @media (max-width: 1110px) {
            width: calc(100% - 160px);
        }
    }

    .last-sync-container {
        width: calc(100% - 670px);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 1360px) {
            width: 250px;
        }

        @media (max-width: 1180px) {
            width: 195px;
        }

        @media (max-width: 1110px) {
            width: 160px;
        }
    }

    .last-sync-container__mobile {
        display: none;

        @media (max-width: 600px) {
            display: flex;
        }
    }

    .last-sync-block {
        margin-right: 5px;
        white-space: nowrap;

        @media (max-width: 1346px) {
            white-space: normal;
        }

        @media (max-width: 1180px) {
            font-size: 10px;
        }
    }
`;
