import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import styled from 'styled-components'
import { Checkbox, TextField } from '@material-ui/core'
import Logout from '../../../pages/auth/Logout'
import { getMetric, updateMetric, accountAsses, updateAccountAsses } from "../../../crud/info.crud";


export default ({ setLoader, setRedirect }) => {

    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const [title, setTitle] = useState({})
    const [defaultTitle, setDefaultTitle] = useState({})

    const [loaderMetric, setLoaderMetric] = useState(false)
    const [loaderUpdateMetric, setLoaderUpdateMetric] = useState(false)
    const [loaderUpdateData, setLoaderUpdateData] = useState(false)
    const [loaderTitle, setLoaderTitle] = useState(false)

    const [touched, setTouched] = useState({})
    const [errors, setErrors] = useState({})

    useEffect(() => {
        updateData()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoader(loaderMetric || loaderUpdateMetric || loaderUpdateData || loaderTitle)
        // eslint-disable-next-line
    }, [loaderMetric, loaderUpdateMetric, loaderUpdateData, loaderTitle])

    const updateData = () => {
        setLoaderUpdateData(true)
        getMetric()
            .then(res => {
                setData(res.data.data)
                setLoaderUpdateData(false)
            })
            .catch(({ response }) => {
                setLoaderUpdateData(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            });

        accountAsses()
            .then(res => {
                setTitle(res.data.data[0])
                setDefaultTitle(res.data.data[0])
            })
            .catch(({ response }) => {
                setLoaderMetric(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            });
    }

    const upMetric = (id, obj) => {
        setLoaderUpdateMetric(true)

        updateMetric({
            metric_id: id,
            ...obj
        })
            .then(res => {
                setLoaderUpdateMetric(false)
                updateData()
                dispatch(AlertState.actions.alert({
                    text: 'Testing Management is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderUpdateMetric(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            });
    }

    const upTitle = (value) => {
        setLoaderTitle(true)
        updateAccountAsses(value)
            .then(res => {
                setLoaderTitle(false)
                dispatch(AlertState.actions.alert({
                    text: 'Testing Management is updated',
                    variant: true
                }));
                setDefaultTitle(res.data.data)
            })
            .catch(({ response }) => {
                updateData()
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderTitle(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            });
    }

    const editTouch = (name) => {
        setTouched({
            ...touched,
            [name]: true
        })
    }

    const editError = (name, value) => {
        if (!value) {
            setErrors({
                ...errors,
                [name]: 'Assessment name should not be blank'
            })
        } else if (value.length < 2) {
            setErrors({
                ...errors,
                [name]: 'Assessment name should be at least 2 of characters'
            })
        } else if (Object.values(title).filter(item => item === value).length > 0) {
            setErrors({
                ...errors,
                [name]: 'Assessment name must be unique'
            })
        } else {
            setErrors({
                ...errors,
                [name]: ''
            })
        }
    }

    return (
        <TabWrap className="settingtable">
            <thead>
                <tr>
                    <td>
                        Category
                    </td>
                    <td style={{textAlign: 'start'}}>Metric</td>
                    <td>
                        <TextField
                            variant="outlined"
                            style={{
                                minWidth: 80
                            }}
                            type="text"
                            className='textField'
                            name="assessment1"
                            value={title.assessment1 || ''}
                            onChange={e => {
                                editError('assessment1', e.target.value)
                                setTitle({
                                    ...title,
                                    assessment1: e.target.value
                                })
                            }}
                            onBlur={e => {
                                editTouch('assessment1')
                                setTitle({
                                    ...title,
                                    assessment1: e.target.value
                                })
                                if (!errors.assessment1) {
                                    upTitle({assessment1: e.target.value})
                                } else {
                                    dispatch(AlertState.actions.alert({
                                        text: errors.assessment1,
                                        variant: false
                                    }))
                                    setTitle({
                                        ...title,
                                        assessment1: defaultTitle.assessment1
                                    })
                                }
                            }}
                            margin="normal"
                            inputProps={{
                                style: {
                                    textAlign: 'center'
                                },
                                maxLength: 15
                            }}
                        />
                    </td>
                    <td>
                        <TextField
                            variant="outlined"
                            style={{
                                minWidth: 80
                            }}
                            name="assessment2"
                            type="text"
                            className='textField'
                            value={title.assessment2 || ''}
                            onChange={e => {
                                editError('assessment2', e.target.value)
                                setTitle({
                                    ...title,
                                    assessment2: e.target.value
                                })
                            }}
                            onBlur={e => {
                                editTouch('assessment2')
                                setTitle({
                                    ...title,
                                    assessment2: e.target.value
                                })
                                if (!errors.assessment2) {
                                    upTitle({assessment2: e.target.value})
                                } else {
                                    dispatch(AlertState.actions.alert({
                                        text: errors.assessment2,
                                        variant: false
                                    }))
                                    setTitle({
                                        ...title,
                                        assessment2: defaultTitle.assessment2
                                    })
                                }

                            }}
                            margin="normal"
                            inputProps={{
                                style: {
                                    textAlign: 'center'
                                },
                                maxLength: 15
                            }}
                        />
                    </td>
                    <td>
                        <TextField
                            variant="outlined"
                            style={{
                                minWidth: 80
                            }}
                            name="assessment3"
                            type="text"
                            className='textField'
                            value={title.assessment3 || ''}
                            onChange={e => {
                                editError('assessment3', e.target.value)
                                setTitle({
                                ...title,
                                assessment3: e.target.value
                                })
                            }}
                            onBlur={e => {
                                editTouch('assessment3')
                                setTitle({
                                    ...title,
                                    assessment3: e.target.value
                                })
                                if (!errors.assessment3) {
                                    upTitle({assessment3: e.target.value})
                                } else {
                                    dispatch(AlertState.actions.alert({
                                        text: errors.assessment3,
                                        variant: false
                                    }))
                                    setTitle({
                                        ...title,
                                        assessment3: defaultTitle.assessment3
                                    })
                                }
                            }}
                            margin="normal"
                            inputProps={{
                                style: {
                                    textAlign: 'center'
                                },
                                maxLength: 15
                            }}
                        />
                    </td>
                    <td>
                        <TextField
                            variant="outlined"
                            style={{
                                minWidth: 80
                            }}
                            name="assessment4"
                            type="text"
                            className='textField'
                            value={title.assessment4 || ''}
                            onChange={e => {
                                editError('assessment4', e.target.value)
                                setTitle({
                                    ...title,
                                    assessment4: e.target.value
                                })
                            }}
                            onBlur={e => {
                                editTouch('assessment4')
                                setTitle({
                                    ...title,
                                    assessment4: e.target.value
                                })
                                if (!errors.assessment4) {
                                    upTitle({assessment4: e.target.value})
                                } else {
                                    dispatch(AlertState.actions.alert({
                                        text: errors.assessment4,
                                        variant: false
                                    }))
                                    setTitle({
                                        ...title,
                                        assessment4: defaultTitle.assessment4
                                    })
                                }
                            }}
                            margin="normal"
                            inputProps={{
                                style: {
                                    textAlign: 'center'
                                },
                                maxLength: 15
                            }}
                        />
                    </td>
                    <td>
                        <TextField
                            variant="outlined"
                            style={{
                                minWidth: 80
                            }}
                            name="assessment5"
                            type="text"
                            className='textField'
                            value={title.assessment5 || ''}
                            onChange={e => {
                                editError('assessment5', e.target.value)
                                setTitle({
                                ...title,
                                assessment5: e.target.value
                                })
                            }}
                            onBlur={e => {
                                editTouch('assessment5')
                                setTitle({
                                    ...title,
                                    assessment5: e.target.value
                                })
                                if (!errors.assessment5) {
                                    upTitle({assessment5: e.target.value})
                                } else {
                                    dispatch(AlertState.actions.alert({
                                        text: errors.assessment5,
                                        variant: false
                                    }))
                                    setTitle({
                                        ...title,
                                        assessment5: defaultTitle.assessment5
                                    })
                                }
                            }}
                            margin="normal"
                            inputProps={{
                                style: {
                                    textAlign: 'center'
                                },
                                maxLength: 15
                            }}
                        />
                    </td>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => {
                    return ( 
                        <TableRow   
                            key={`testing-${index}`}
                            item={item}
                            change={upMetric}
                        />
                    )
                })}
            </tbody>
        </TabWrap>
    )
}

const TabWrap = styled.table`
`

const TableRow = ({item, change}) => {
    return (
        <tr>
            <td>{item.category}</td>
            <td style={{textAlign: 'start'}}>{item.metric}</td>
            <td>
                <Checkbox
                    checked={item.pivot.assessment1 === 1}
                    onChange={e => change(item.id, {assessment1: e.target.value})}
                    value={item.pivot.assessment1 === 1 ? 0 : 1}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
            <td>
                <Checkbox
                    checked={item.pivot.assessment2 === 1}
                    onChange={e => change(item.id, {assessment2: e.target.value})}
                    value={item.pivot.assessment2 === 1 ? 0 : 1}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
            <td>
                <Checkbox
                    checked={item.pivot.assessment3 === 1}
                    onChange={e => change(item.id, {assessment3: e.target.value})}
                    value={item.pivot.assessment3 === 1 ? 0 : 1}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
            <td>
                <Checkbox
                    checked={item.pivot.assessment4 === 1}
                    onChange={e => change(item.id, {assessment4: e.target.value})}
                    value={item.pivot.assessment4 === 1 ? 0 : 1}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
            <td>
                <Checkbox
                    checked={item.pivot.assessment5 === 1}
                    onChange={e => change(item.id, {assessment5: e.target.value})}
                    value={item.pivot.assessment5 === 1 ? 0 : 1}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
        </tr>
    )
}