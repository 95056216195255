import React, { useState, useEffect, useRef } from 'react';
import './schedule.scss';
import { Chart } from "chart.js";

export const Schedule = ({ data }) => {
    const ref = useRef()
    const [color] = useState(['54, 105, 255, ', '255, 162, 85, '])
    const [borderColor] = useState(['54, 105, 255, 1', '255, 162, 85, 1'])

    useEffect(() => {

        if (data.length) {
            const datasetsOptions = {
                fill: true,
                lineTension: 0.1,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
            }

            const datasets = {
                labels: data.map(item => item.profile),
                datasets: [{
                    ...datasetsOptions,
                    label: '%',
                    backgroundColor: data.map((item) => item.change_percent > 0 ? `rgba(${color[0]}0.4)` : `rgba(${color[1]}0.4)`),
                    borderColor: data.map((item) => item.change_percent > 0 ? `rgba(${borderColor[0]})` : `rgba(${borderColor[1]})`),
                    borderWidth: 2,
                    data: data.map(item => parseFloat(item.change_percent)),
                }]
            }

            const chart = new Chart(ref.current, {
                type: 'horizontalBar',
                data: datasets,
                options: {
                    maintainAspectRatio: false,
                    legend: { display: false },
                    title: { display: false },
                    animation: false,
                    tooltips: {
                        enabled: true,
                        intersect: false,
                        mode: "nearest",
                        bodySpacing: 5,
                        yPadding: 10,
                        xPadding: 10,
                        caretPadding: 0,
                        displayColors: false,
                        backgroundColor: "#1e1e2d",
                        titleFontColor: "#ffffff",
                        cornerRadius: 4,
                        footerSpacing: 0,
                        titleSpacing: 0
                    },
                    scales: { 
                        xAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: value => {
                                    return isFinite(value) ? value + " %" : ''
                                },
                                /*min: Math.min.apply(null, data.map((item) => {
                                    return Math.min.apply(null, [parseInt(item.change_percent)].filter(item => (item === 0) || !!item))
                                })) - 10,*/
                                /*max: Math.max.apply(null, data.map((item) => {
                                    return Math.max.apply(null, [parseInt(item.change_percent)].filter(item => (item === 0) || !!item))
                                })) + 10,*/
                                height:50
                            }
                        }] 
                    }  
                },
                responsive: true,
            })

            return () => {
                chart.destroy();
            };
        }
    // eslint-disable-next-line
    }, [data]); 

    return (
        <div className="canvasR-wrap" style={{height:`${data.length ===1 ? 70 : 45 * data.length}px`}}>
            {data.length 
                ?  <canvas
                    style={{height:`480px`}}
                    ref={ref}
                    id="kt_chart_order_statistics"    
                />
                : <div
                    style={{
                        margin: '20px auto',
                        textAlign: 'center'
                    }}
                >
                    No data for the selected parameters
                </div>
            }
        </div>
    )
}