import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Button } from 'react-bootstrap'
import * as AlertState from '../../store/ducks/auth.duck'
import { 
  Redirect 
} from "react-router-dom";
import {  
  injectIntl 
} from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { requestPassword } from "../../crud/auth.crud";
import Logo from '../../../_metronic/_assets/media/logo.svg'
import './style.scss'
import clsx from "clsx";
import Footer from '../../../_metronic/layout/footer/FooterAuth'

import BG from '../../../_metronic/_assets/media/grid.png'

class ForgotPassword extends Component {
  constructor (props) {
    super (props)
    this.state = { 
      isRequested: false,
      loading: false,
      loadingButtonStyle: {
        paddingRight: "2.5rem"
      }
    };
  }
  

  componentDidMount() {
    document.title = 'TrainerMetrics - Forgot Password'
  }

  enableLoading () {
    this.setState({ loading: true })
    this.setState({ loadingButtonStyle: { paddingRight: "3.5rem" } })
  };

  disableLoading () {
    this.setState({ loading: false })
    this.setState({ loadingButtonStyle: { paddingRight: "2.5rem" } })
  };

  afterSend () {
    const { alert } = this.props; 
    alert({
      text: 'Password reset link sent, please check your mailbox',
      variant: true
    })
  }


  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
    <Formik
      initialValues={{ email: "" }}
      validate={values => {
        const errors = {};

        if (!values.email) {
          errors.email = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_FIELD"
          });
        }

        return errors;
      }}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        this.enableLoading()
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute('6LeJua4ZAAAAACQMDff1rwrfBCzLxe8fu7R7zUi6', { action: 'passwordReset' })
            .then((token) => {
              requestPassword(values.email, token, 'passwordReset')
                .then(() => {
                  this.disableLoading()
                  this.setState({ isRequested: true });
                  this.afterSend()
                })
                .catch(() => {
                  this.disableLoading()
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage(
                      { id: "AUTH.VALIDATION.NOT_FOUND" },
                      { name: values.email }
                    )
                  );
                });
              })
            })
            .catch(() => {
              this.disableLoading();
              setSubmitting(false);
            })
      }}
    >
      {({
        values,
        status,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (<>
        <div className="login" style={{backgroundImage: `url(${BG})`}}>
          <Link to="/"><img src={Logo} alt="logo" className="login-logo" /></Link>
          <form 
            noValidate={true}
            autoComplete="off"
            onSubmit={handleSubmit} 
            className="kt-form login-wrap forgot"
          >
            <div className="login-top">
              Reset Password
            </div>
            <div className="login-main">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group">
                <TextField
                  key='7'
                  variant="outlined"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  className="kt-width-full"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="kt-login__actions" style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  value="Continue"
                  className={`btn btn-elevate kt-login__btn-primary btn-login btn-blue ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                  style={this.state.loadingButtonStyle}
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
        <Footer style={{position: 'fixed', bottom: 0}}/>
        </>
      )}
    </Formik>
    );
  }
}

export default injectIntl(connect(null, {...auth.actions, ...AlertState.actions})(ForgotPassword));
