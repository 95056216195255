import React from 'react'
import styled from 'styled-components'
import male from '../../../../_metronic/_assets/media/male.png'
import female from '../../../../_metronic/_assets/media/female.png'

export default ({ circumferences, gender }) => {
    const findItem = (title) => {
        return circumferences.find(item => item.label === title) || { value: '' }
    }

    return (
        <StyledCircumferences>
            <div className="left-col custom-col">
                <div className="info-line line-1">Neck: <span className="info-line__result">{Number(findItem('Neck').value).toFixed(2) || '0.00'}</span></div>
                <div className="info-line line-2">Chest: <span className="info-line__result">{Number(findItem('Chest').value).toFixed(2) || '0.00'}</span></div>
                <div className="info-line line-3">Waist: <span className="info-line__result">{Number(findItem('Waist').value).toFixed(2) || '0.00'}</span></div>
                <div className="info-line line-4">Hip: <span className="info-line__result">{Number(findItem('Hip').value).toFixed(2) || '0.00'}</span></div>
            </div>
            <div className="center-col">
                <img src={gender === 'Male' ? male : gender === 'Female' ? female : male} alt='male' />
            </div>
            <div className="rigth-col custom-col">
                <div className="info-line line-1">Bicep: <span className="info-line__result">{Number(findItem('Bicep').value).toFixed(2) || '0.00'}</span></div>
                <div className="info-line line-2">Forearm: <span className="info-line__result">{Number(findItem('Forearm').value).toFixed(2) || '0.00'}</span></div>
                <div className="info-line line-3">Thigh: <span className="info-line__result">{Number(findItem('Thigh').value).toFixed(2) || '0.00'}</span></div>
                <div className="info-line line-4">Calf: <span className="info-line__result">{Number(findItem('Calf').value).toFixed(2) || '0.00'}</span></div>
            </div>
        </StyledCircumferences>
    )
}

const StyledCircumferences = styled.div`
    display: flex;
    justify-content: space-between;

    & > div {
        width: 33.33%;
    }

    .custom-col {
        position: relative;

        .info-line {
            width: 100%;
            padding: 3px 5px;
            border-bottom: 1px solid #dee2e6;
            position: absolute;

            &__result {
                font-weight: 600;
            }
        }
    }
    

    .left-col {
        .info-line {
            text-align: left;

            &.line-1 {
                top: 6%;
            }
            &.line-2{
                top: 19%;
            }
            &.line-3{
                top: 32%;
            }
            &.line-4{
                top: 45%;
            }
        }
    }

    .rigth-col {
        .info-line {
            text-align: right;

            &.line-1{
                bottom: 68%;
            }
            &.line-2{
                bottom: 56%;
            }
            &.line-3{
                bottom: 40%;
            }
            &.line-4{
                bottom: 20%;
            }
        }
    }


    .center-col {

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`