import React, { useState, useEffect, useRef } from "react"
import styled from 'styled-components'
import { Redirect, Link } from 'react-router-dom'
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    Checkbox,
    Typography,
} from '@material-ui/core'
import { 
    Button,
    Dropdown,
    Pagination,
    Modal
} from 'react-bootstrap'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import { getProtocol, deleteTesting } from '../../../crud/profile.crud'
import DatePicker from '../../../components/DatePicker'
import Loader from '../../../components/Loader'
import Logout from '../../auth/Logout'
import moment from 'moment'
import ProtocolModal from '../OverviewTable/ProtocolModal'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import ModalAddMetrics from '../MetricsTabs/ModalAddMetrics'
import ModalAddGoals from '../MetricsTabs/ModalAddGoals'
import ModalUpdateMetrics from '../MetricsTabs/ModalUpdateMetrics'
import ModalUpdateGoals from '../MetricsTabs/ModalUpdateGoals'

export default (props) => {
    const dispatch = useDispatch();

    const defRow = [
        {
          label: "Test Date",
          value: 'date'
        },
        {
          label: `Metric Value`,
          value: 'result'
        },
        {
          label: "Goal Date",
          value: 'date'
        },
        {
          label: `Goal Value`,
          value: 'goal'
        }
    ]

    const { data, loadData, dataMetric } = props
    const inputLabel = useRef()
    const [protocolData, setProtocolData] = useState([]);
    const [labelWidth, setLabelWidth] = useState(0);
    const [addSkinfold, setAddSkinfold] = useState(false)
    const [bodyFat, setBodyFat] = useState(false)
    const [logout, setLogout] = useState(null)
    const [protocol, setProtocol] = useState(false)
    const [loaderProtocol, setLoaderProtocol] = useState(false)

    const [sort, setSort] = useState(["date", "asc"])
    const [check, setCheck] = useState([])
    const [show, setShow] = useState(10)
    const [totelPage, setTotalPage] = useState(0)
    const [totalRow, setTotalRow] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [modalUpdateMetrics, setModalUpdateMetrics] = useState(false)
    const [modalUpdateGoals, setModalUpdateGoals] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)

    useEffect(() => {
        if (data && data.metrics && data.metrics.length) {
            setTotalRow(data.metrics.length)
            setTotalPage(Math.floor(data.metrics.length / show) + 1)
        }
    }, [data, show]) //eslint-disable-line

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel, props.dataMetric, props.typeOutputMetric]);

    const onChange = (date, index) => {
        let arr = [...props.date]
        arr[index] = date
        props.setDate(arr)
        // console.log(arr)
    }

    const getCheck = (item) => check.findIndex(id => id === item.id) !== -1

    const editPagination = (page) => {
        setCurrentPage(page)
        setCheck([])
    }

    const editCheck = (item) => {
        let temp = [...check]
        temp.splice(check.indexOf(item.id), 1)
        getCheck(item)
            ? setCheck(temp)
            : setCheck([...check, item.id])
    }

    const sortEdit = item => {
        setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    }

    const chbAll = () => {
        if (check.length === filterData().length) {
            setCheck([])
        } else {
            setCheck(filterData().map(item => item.id))
        }
    }

    const filterData = () => {
        let temp = (data && data.metrics) ? [...data.metrics] : []
        if (sort[0] === 'date') {
            temp = temp.sort((prev, next) => moment(prev[sort[0]], 'MM/DD/YYYY').format('YYYYMMDD') > moment(next[sort[0]], 'MM/DD/YYYY').format('YYYYMMDD') ? 1 : -1 )
        } else {
            temp = temp.sort((prev, next) => parseInt(prev[sort[0]]) > parseInt(next[sort[0]]) ? 1 : -1 )
        }

        if (sort[1] === 'desc') {
            temp = temp.reverse()
        } 
        return temp.slice((currentPage - 1) * show, currentPage * show)
    }

    const statusAll = () => {
        const datat = (data && data.metrics) ? filterData() : [] // eslint-disable-line
        return (check.length === datat.length) && (datat.length !== 0)
    }

    const loadProtocol = () => {
        setLoaderProtocol(true)
        getProtocol(props.type)
            .then(res => {
                setLoaderProtocol(false)
                setProtocolData(res.data.data)
                setProtocol(true)
            })
            .catch(({ response }) => {
                dispatch(AlertState.actions.alert({
                  text: typeof response.data.error === 'string' && response.data.error,
                  variant: false
                }));
                setLoaderProtocol(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if(response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                } 
            });
    }

    const getPdf = () => {
        return `/report/${props.id}/name/${props.type}`
    }

    const getTitle = (data) => {
        if (data && data.childs) {
            const child = data.childs.find(item => item.metric === props.type)
            return child ? child.label : ''
        }
        return data ? data.title : ''
    }

    const deleteMetric = () => {
        setLoaderProtocol(true)
        deleteTesting(props.id, {
            id: check,
            metric: props.type
        })
            .then(res => {
                setLoaderProtocol(false)
                setCheck([])
                setModalDelete(false)
                props.loadData()
                dispatch(AlertState.actions.alert({
                    text: 'Data is deleted',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderProtocol(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } 
            })
    }

    return <TableOverview>

        { logout }
        <Loader visible={loaderProtocol} />

        <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Delete selected data?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalDelete(false)}>
                    No
                </Button>
                <Button variant="primary" onClick={deleteMetric}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>

        <ProtocolModal 
            modal={protocol}
            setModal={setProtocol}
            data={protocolData}
            label={getTitle(props.dataMetric)}
        />

        <ModalAddMetrics
            id={props.id}
            metric={props.type}
            modal={addSkinfold}
            setModal={setAddSkinfold}
            label={getTitle(props.dataMetric)}
            loadData={loadData}
            category={props.dataMetric && props.dataMetric.category}
        />

        <ModalAddGoals
            id={props.id}
            metric={props.type}
            modal={bodyFat}
            setModal={setBodyFat}
            label={getTitle(props.dataMetric)}
            loadData={loadData}
        />

        <ModalUpdateMetrics
            id={props.id}
            metric={props.type}
            modal={modalUpdateMetrics}
            setModal={setModalUpdateMetrics}
            label={dataMetric.title}
            loadData={loadData}
            category={dataMetric.category}
            check={check}
            setCheck={setCheck}
        />

        <ModalUpdateGoals
            id={props.id}
            metric={props.type}
            modal={modalUpdateGoals}
            setModal={setModalUpdateGoals}
            label={dataMetric.title}
            loadData={loadData}
            category={dataMetric.category}
            check={check}
            setCheck={setCheck}
        />

        <div className="nav-menu">
            <div className="select">
                {
                    (props.dataMetric && props.dataMetric.childs) &&
                    (<>
                        <FormControl variant="outlined" className="formControl">
                            <InputLabel ref={inputLabel}>SELECT CALCULATION</InputLabel>
                            <Select
                                value={props.type}
                                input={<OutlinedInput labelWidth={labelWidth}/>}
                                onChange={e => {
                                    props.setType(e.target.value)
                                }}
                            >
                                {
                                    props.dataMetric.childs.map((item, index) => {
                                        return (
                                            <MenuItem value={item.metric} key={index}>{item.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </>)
                }
            </div>
            <div className="data">
                <div>
                    <DatePicker
                        label="Start"
                        future={true}
                        format={'MM/DD/YYYY'}
                        value={props.date[0]}
                        onChange={(value) => {
                            onChange(value, 0)
                        }}
                    />
                </div>
                <div>
                    <DatePicker
                        label="End"
                        future={true}
                        format={'MM/DD/YYYY'}
                        value={props.date[1]}
                        onChange={(value) => {
                            onChange(value, 1)
                        }}
                    />
                </div>
            </div>
        </div>
        <div className="nav-menu select-group">

            {check.length === 0 ? <Dropdown className="btn-diagram">
                <Dropdown.Toggle>
                    Add Data
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setAddSkinfold(true)}>Metrics</Dropdown.Item>
                    <Dropdown.Item onClick={() => setBodyFat(true)}>Goals</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> : null }

            {check.length === 1 ? <Dropdown className="btn-diagram">
                <Dropdown.Toggle>
                    Edit Data
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setModalUpdateMetrics(true)}>
                        Metrics
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModalUpdateGoals(true)}>
                        Goals
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> : null }

            {
                check.length > 0 && (
                    <Button 
                        variant="primary"
                        onClick={deleteMetric}
                        className="btn-diagram"
                    >Delete Data</Button>
                )
            }

            {check.length === 0 ? <><div className="btn-diagram">
                <Link to={getPdf()} target="_blank">
                    <Button 
                        variant="primary"
                    >
                        Print
                    </Button>
                </Link>
            </div>

            <Button className="btn-diagram" variant="primary" onClick={loadProtocol}>Protocol</Button></> : null }

        </div>
        
        <div className="row">
            <div className="col settingtable nowrap">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <Checkbox
                                    checked={statusAll()}
                                    onChange={chbAll}
                                    color="primary"
                                />
                            </td>
                            {
                                defRow.map((item, index) => {
                                    return (
                                        <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content'  : ''}${sort[0] === item.value ? ' active' : ''}`}>
                                            { item.label }             
                                            <ArrowDown 
                                                className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`} 
                                            />
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filterData().map((item, index) =>{
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Checkbox
                                                checked={getCheck(item)}
                                                onChange={() => editCheck(item)}
                                                color="primary"
                                            />
                                        </td>
                                        <td>
                                            {item.date}
                                        </td>
                                        <td>
                                            {item.result}
                                        </td>
                                        <td>
                                            {item.date}
                                        </td>
                                        <td>
                                            {item.goal}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    data && data.metrics && data.metrics.length === 0
                        ? (
                            <div
                            style={{
                                margin: '20px auto',
                                textAlign: 'center'
                            }}
                            >
                            No data available in table
                            </div>
                        ) : null
                }
            </div>
            {
                props.data && props.data.metrics && data.metrics.length !== 0
                    ? (
                        <div className="pagination-wrap">
                            <Pagination>
                                <Pagination.First onClick={() => editPagination(1) }  disabled={currentPage === 1}/>
                                <Pagination.Prev onClick={() => editPagination(currentPage - 1) } disabled={currentPage === 1}/>

                                <Pagination.Item>{currentPage}</Pagination.Item>

                                <Pagination.Next onClick={() => editPagination(currentPage + 1) } disabled={currentPage === totelPage}/>
                                <Pagination.Last onClick={() => editPagination(totelPage) } disabled={currentPage === totelPage}/>
                            </Pagination>
                            <div className="pagination-show">
                                <FormControl variant="outlined" className="formControl">
                                    <InputLabel ref={inputLabel}>Show Entries</InputLabel>
                                    <Select
                                        value={show.toString()}
                                        input={<OutlinedInput labelWidth={labelWidth}/>}
                                        onChange={e => {
                                            setShow(parseInt(e.target.value))
                                            setCurrentPage(1)
                                        }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography variant="body1" gutterBottom>
                                    {
                                        `Showing ${(currentPage - 1) * show + 1} - ${currentPage * show > totalRow ? totalRow : currentPage * show} fo ${totalRow}`
                                    }
                                </Typography>
                            </div>
                        </div>
                    ) : null
            }
        </div>
    </TableOverview>
}

const TableOverview = styled.div`
    .nav-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        &.select-group {
            justify-content: flex-start;
        }

        .btn-diagram {
            margin-right: 15px;
            margin-bottom: 10px;
        }
    }

    .data {
        display: flex;
        flex-wrap: wrap;

        & > div {
            margin: 0 20px 0 0;
        }

        .MuiFormControl-marginNormal {
            margin: 0;
        }
    }

    .select {
        display: flex;
        align-items: center;
    
        &-wrap {
            height: 30px;
            display: flex;

            & > div {
                min-width: 300px;
            }
        }
    }


    td.active {
        font-weight: 600;
    }
    
    .sort-arrow {
        opacity: 0.7;
        transition: all .3s ease-out;

        &.active {
            opacity: 1;
        }

        &.reverse {
            transform: rotate(180deg)
        }
    }

    .pagination {
        margin: 0;

        &-wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .MuiSelect-select {
                padding: 7px;
            }
        }

        &-show {
            display: flex;
            align-items: center;
            
            .formControl {
                width: 150px;
                margin: 5px 10px;
            }
        }
    }

    
`