import objectPath from "object-path";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import LayoutConfig from "../layout/LayoutConfig";
import * as MenuConfig from "../layout/MenuConfig";
// import { updateAccount } from "../../app/crud/info.crud";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";

export const actionTypes = {
  SetMenuConfig: "builder/SET_MENU_CONFIG",
  SetLayoutConfigs: "builder/SET_LAYOUT_CONFIGS",
  SetLayoutConfigsWithPageRefresh: "builder/SET_LAYOUT_CONFIGS_WITH_PAGE_REFRESH",
  SetHtmlClassService: "builder/SET_HTML_CLASS_SERVICE"
};

export const selectors = {
  getClasses: (store, params) => {
    const { htmlClassServiceObjects } = store.builder;

    return htmlClassServiceObjects
      ? htmlClassServiceObjects.getClasses(params.path, params.toString)
      : "";
  },
  getAttributes: (store, params) => {
    if (params.path) {
      // if path is specified, get the value within object
      const { htmlClassServiceObjects } = store.builder;

      return htmlClassServiceObjects
        ? htmlClassServiceObjects.getAttributes(params.path)
        : [];
    }

    return [];
  },
  getConfig: (state, path) => {
    const { layoutConfig } = state.builder;

    if (path) {
      // if path is specified, get the value within object
      return objectPath.get(layoutConfig, path);
    }

    return "";
  },

  getLogo: ({ builder: { layoutConfig } }) => {
    let logo;
    if (layoutConfig.custom_logo) {
      logo = toAbsoluteUrl(layoutConfig.custom_logo);
      return logo;
    } else {
      return process.env.REACT_APP_MEDIA_URL + 'trainermetrics_logo.png';
    }
  },

  getStickyLogo: store => {
    const { layoutConfig } = store.builder;
    let logo = objectPath.get(layoutConfig, "self.logo.sticky");
    if (typeof logo === "undefined") {
      logo = selectors.getLogo(store);
    }
    return logo + "";
  }
};

const initialState = {
  menuConfig: MenuConfig.client_profiles,
  layoutConfig: LayoutConfig,
  htmlClassServiceObjects: undefined
};

export const reducer = persistReducer(
  {
    storage,
    key: "build-demo1",
    blacklist: ["htmlClassServiceObjects"]
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.SetMenuConfig:
        return { ...state, menuConfig: payload };

      case actionTypes.SetLayoutConfigs:
        let new_state = { ...state, ...{ layoutConfig: { ...state.layoutConfig, ...payload } } };
        return new_state;

      case actionTypes.SetLayoutConfigsWithPageRefresh: {
        return { ...state, layoutConfig: payload };
      }
      case actionTypes.SetHtmlClassService:
        return { ...state, htmlClassServiceObjects: payload };

      default:
        return state;
    }
  }
);

export const actions = {
  setMenuConfig: payload => ({ payload, type: actionTypes.SetMenuConfig }),

  setLayoutConfigs: (payload) => {
    return {
      payload,
      type: actionTypes.SetLayoutConfigs
    }
  },

  setLayoutConfigsWithPageRefresh: payload => ({
    payload,
    type: actionTypes.SetLayoutConfigsWithPageRefresh
  }),

  setHtmlClassService: payload => ({
    payload,
    type: actionTypes.SetHtmlClassService
  })
};
