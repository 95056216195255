const catchErrorMessage = err => {
    let errorText = 'Request failed';

    if (
        (
            err.response?.data?.error === 'The given data was invalid.' ||
            err.response?.data?.message === 'The given data was invalid.'
        ) &&
        err.response?.data?.errors &&
        typeof err.response?.data?.errors === 'object'
    ) {
        let keys = Object.keys(err.response?.data?.errors);
        err.response?.data?.errors[keys[0]].length
            ? errorText = err.response?.data?.errors[keys[0]][0]
            : errorText = err.response?.data?.error || err.response?.data?.message;
    } else {
        errorText = err.response?.data?.error || err.response?.data?.message;
    }

    return errorText;
};

export default catchErrorMessage;
