import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { fetchCRMStrategy, patchCRMStrategy } from '../../../../crud/crm.crud';
import { useSelector } from 'react-redux';
import Loader from "../../../../components/Loader";
import { useDispatch } from 'react-redux';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { fetchCRMList } from '../../../../crud/crm.crud';
import { setCRMAction } from '../../../../store/actions';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const CRMSettings = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState({
        is_delete_records: false,
        is_delete_groups: false,
        is_delete_users: false,
        is_delete_profiles: false,
    });
    const plan = useSelector(state => state.user_info.subscription?.plan?.name);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        fetchCRMList()
            .then(res => {
                setLoader(false);
                dispatch(setCRMAction(res.data.data))
            })
            .catch(err => {
                setLoader(false);
                console.log('fetchCRMList', err);
            })

        if (plan === 'Gym') {
            setCheckBoxes([
                { name: 'Users', value: 'is_delete_users' },
                { name: 'Profiles', value: 'is_delete_profiles' }
            ]);
        } else if (plan === 'Enterprise') {
            setCheckBoxes([
                { name: 'Groups', value: 'is_delete_groups' },
                { name: 'Users', value: 'is_delete_users' },
                { name: 'Profiles', value: 'is_delete_profiles' }
            ]);
        };
    }, [plan]);

    useEffect(() => {
        fetchCRMStrategy()
            .then(res => {
                const { is_delete_groups, is_delete_users, is_delete_profiles, is_delete_records } = res.data.data;
                setChecked({
                    is_delete_records,
                    is_delete_groups,
                    is_delete_users,
                    is_delete_profiles,
                })
            })
            .catch(err => console.log('fetchCRMStrategy', err))
    }, []);

    const handleSwitch = () => {
        if (!checked.is_delete_records) {
            let data = {
                is_delete_records: true,
                is_delete_groups: true,
                is_delete_users: true,
                is_delete_profiles: true,
            }
            patchCRMStrategy(data)
                .then(res => {
                    setChecked(res.data.data);
                    setLoader(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Success',
                        variant: true
                    }));
                })
                .catch(err => {
                    dispatch(AlertState.actions.alert({
                        text: err?.response?.data?.error || 'failed request',
                        variant: false
                    }));
                })
        } else {
            let data = {
                is_delete_records: false,
                is_delete_groups: false,
                is_delete_users: false,
                is_delete_profiles: false,
            }
            patchCRMStrategy(data)
                .then(res => {
                    setChecked(res.data.data);
                    setLoader(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Success',
                        variant: true
                    }));
                })
                .catch(err => {
                    dispatch(AlertState.actions.alert({
                        text: err?.response?.data?.error || 'failed request',
                        variant: false
                    }));
                })
        };
    };

    const handleCheck = name => {
        let data = {
            ...checked,
            [name]: !checked[name]
        }

        setLoader(true)
        patchCRMStrategy(data)
            .then(res => {
                setChecked(res.data.data);
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'Success',
                    variant: true
                }));
            })
            .catch(err => {
                dispatch(AlertState.actions.alert({
                    text: err?.response?.data?.error || 'failed request',
                    variant: false
                }));
            })
    };

    return (
        <div className="crm-info">
            <Loader visible={loader} />

            <div className="crm-info__row">
                <div className="crm-info__cell">
                    Sync Deleted Records
                </div>
                <div className="crm-info__cell">
                    <div className="switcher">
                        <Switch
                            checked={checked.is_delete_records}
                            onChange={handleSwitch}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
            </div>
            <div className="crm-info__row crm-info__row-settings">
                Delete TrainerMetrics record when the corresponding record is deleted in CRM.
            </div>
            <div>
                {checkBoxes.map(elem => {
                    return (
                        <div className="crm-info__row">
                            <StyledToolTip
                                title={!checked.is_delete_records
                                    ? "Sync Deleted Records should be enabled"
                                    : ""
                                }
                                placement="bottom"
                            >
                                <div className="crm-info__cell crm-info__wide-cell crm-info__checkbox-cell">
                                    <Checkbox
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        checked={checked[elem.value]}
                                        onChange={() => handleCheck(elem.value)}
                                        disabled={!checked.is_delete_records}
                                    />
                                </div>
                            </StyledToolTip>
                            <div className="crm-info__cell">
                                <span
                                    className={checked.is_delete_records ? 'pointer' : ''}
                                    onClick={
                                        checked.is_delete_records
                                            ? () => handleCheck(elem.value)
                                            : () => { }
                                    }
                                >
                                    {elem.name}
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}