import React, { useState, useEffect } from 'react';
import { fetchForm } from '../../../../crud/forms';
import { useFormik } from 'formik';
import { TextField, Checkbox } from "@material-ui/core";
import { CustomRadio } from '../../../../components/CustomRadio/CustomRadio';
import { Button } from 'react-bootstrap';
import { setTemporaryForms, resetTemporaryForm } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import {
    handleChangeInput,
    validateCurrentInput,
    validateFullForm,
} from './validations';
import { isArray } from 'lodash';
import * as AlertState from '../../../../store/ducks/auth.duck';
import './intake.scss';

export const Intake = ({ currentForm, setLoader, savedForm, handleSubmit, validateForm }) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState(null);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
    });

    const loadData = (isReset = false) => {
        setLoader(true);
        fetchForm(currentForm.id)
            .then(res => {
                setForm(res.data.data);
                if (savedForm && !isReset) {
                    formik.setValues(savedForm)
                } else {
                    formik.setValues({
                        form_id: res.data.data?.id,
                        answers: res.data.data?.inputs.map(elem => {
                            if (elem.type === 'checkbox') {
                                return {
                                    type: elem.type,
                                    input_id: elem.id,
                                    value: [],
                                }
                            } else if (elem.type === 'survey') {
                                return {
                                    type: elem.type,
                                    input_id: elem.id,
                                    value: '',
                                }
                            } else if (elem.type === 'short') {
                                return {
                                    type: elem.type,
                                    input_id: elem.id,
                                    value: '',
                                }
                            } else if (elem.type === 'number') {
                                return {
                                    type: elem.type,
                                    input_id: elem.id,
                                    value: '',
                                    hasError: false,
                                    touched: false,
                                    errorText: ''
                                }
                            } else if (elem.type === 'radio') {
                                return {
                                    type: elem.type,
                                    input_id: elem.id,
                                    value: '',
                                }
                            } else {
                                return {
                                    type: elem.type,
                                    input_id: elem.id,
                                    value: '',
                                }
                            }
                        })
                    })
                }
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            })
    };

    useEffect(() => {
        if (currentForm) {
            loadData();
        }
    }, [currentForm]);

    const handleSwitch = (index, answer) => {
        let answers = [...formik.values.answers];
        if (answer === 'Yes') {
            answers[index].value = 1;
        } else if (answer === 'No') {
            answers[index].value = 0;
        } else {
            answers[index].value = '';
        }

        formik.setValues({
            form_id: form?.id,
            answers: [...answers],
        });

        dispatch(setTemporaryForms({
            [form?.id]: {
                form_id: form?.id,
                answers: [...answers],
            }
        }))
    };

    const handleCheck = (optionId, index) => {
        let answers = JSON.parse(JSON.stringify(formik.values.answers));
        let checkedIndex = answers[index].value.findIndex(elem => elem === optionId);
        if (checkedIndex === -1) {
            answers[index].value.push(optionId)
        } else {
            answers[index].value.splice(checkedIndex, 1);
        }
        formik.setValues({
            form_id: form?.id,
            answers: [...answers],
        });

        dispatch(setTemporaryForms({
            [form?.id]: {
                form_id: form?.id,
                answers: [...answers],
            }
        }))
    };

    const handleRadio = (value, elem, index) => {
        let answers = JSON.parse(JSON.stringify(formik.values.answers));
        answers[index] = {
            ...answers[index],
            value: elem.options.find(option => option.label === value).id,
        }

        formik.setValues({
            form_id: form?.id,
            answers: [...answers],
        });

        dispatch(setTemporaryForms({
            [form?.id]: {
                form_id: form?.id,
                answers: [...answers],
            }
        }))
    };

    const handleReset = () => {
        loadData(true);
        dispatch(resetTemporaryForm(currentForm.id));
    };

    const submit = () => {
        handleSubmit();
    };

    return (
        <div className='view-form'>
            {form?.inputs.map((elem, index) => {
                if (elem.type === 'section') {
                    return <div className='view-form__section' key={elem.id}>{elem.label}</div>
                } else if (elem.type === 'survey') {
                    return (
                        <div key={elem.id} className="view-form__question view-form__question-survey">
                            <div className="view-form__question-text">{elem.label}</div>
                            <CustomRadio
                                data={['Yes', 'No']}
                                onChange={(value) => handleSwitch(index, value)}
                                value={formik.values?.answers
                                    ? formik.values?.answers[index]?.value === 0
                                        ? 'No'
                                        : formik.values?.answers[index]?.value === 1
                                            ? 'Yes'
                                            : null
                                    : null
                                }
                            />
                        </div>
                    )
                } else if (elem.type === 'short') {
                    return (
                        <div key={elem.id} className="view-form__question">
                            <div className="view-form__question-text" style={{ marginBottom: 10 }}>{elem.label}</div>
                            <TextField
                                variant="outlined"
                                type="text"
                                label='Your answer'
                                inputProps={{ maxLength: 255 }}
                                value={formik.values?.answers ? formik.values?.answers[index]?.value : ''}
                                onChange={(e) => handleChangeInput(e, index, formik, form, elem, dispatch)}
                            />
                        </div>
                    )
                } else if (elem.type === 'checkbox') {
                    return (
                        <div key={elem.id} className="view-form__question view-form__question-checkbox">
                            <div className="view-form__question-text">{elem.label}</div>
                            <div style={{ marginLeft: '-11px' }}>
                                {elem.options.map(option => {
                                    return (
                                        <div className='d-flex align-items-center' key={option.id}>
                                            <Checkbox
                                                color="primary"
                                                checked={formik.values?.answers
                                                    ? isArray(formik.values.answers[index]?.value) && !!formik.values.answers[index]?.value.find(id => +id === +option.id)
                                                    : false
                                                }
                                                onChange={() => handleCheck(option.id, index)}
                                            />
                                            <div className='pointer' onClick={() => handleCheck(option.id, index)}>{option.label}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                } else if (elem.type === 'number') {
                    return (
                        <div key={elem.id} className="view-form__question">
                            <div className="view-form__question-text" style={{ marginBottom: 10 }}>{elem.label}</div>
                            <TextField
                                style={{ width: 130 }}
                                variant="outlined"
                                type="number"
                                label='Your answer'
                                inputProps={{
                                    min: +elem.from,
                                    max: +elem.to,
                                }}
                                value={formik.values?.answers && formik.values?.answers[index]?.value ? formik.values.answers[index].value : ''}
                                onChange={(e) => handleChangeInput(e, index, formik, form, elem, dispatch)}
                                onBlur={() => validateCurrentInput(index, formik, form, dispatch, elem)}
                                error={formik.values?.answers && formik.values?.answers[index] && formik.values?.answers[index].touched && formik.values.answers[index].hasError}
                                helperText={formik.values?.answers && formik.values?.answers[index] && formik.values?.answers[index].errorText}
                            />
                        </div>
                    )
                } else if (elem.type === 'radio') {
                    return (
                        <div key={elem.id} className="view-form__question">
                            <div className="view-form__question-text">{elem.label}</div>
                            <CustomRadio
                                data={elem.options.map(elem => {
                                    return elem.label
                                })}
                                value={formik.values?.answers
                                    ? elem.options.find(option => option.id === formik.values.answers[index]?.value)?.label
                                    : ''}
                                onChange={(value) => handleRadio(value, elem, index)}
                                variant='column'
                            />
                        </div>
                    )
                }
            })}

            <div style={{ marginTop: 20 }}>
                <Button
                    className='btn'
                    style={{ height: 36 }}
                    onClick={handleReset}
                >Reset Form</Button>
            </div>

            <div>
                <Button
                    className='btn btn-blue btn-elevate kt-login__btn-primary'
                    style={{ height: 36, marginTop: 20 }}
                    onClick={() => {
                        validateForm()
                            .then((res) => {
                                const errors = Object.keys(res);
                                if (errors.length) {
                                    dispatch(AlertState.actions.alert({
                                        text: 'Some fields in Profile Info tab are invalid',
                                        variant: false
                                    }));
                                }

                                if (validateFullForm(formik, form, dispatch)) {
                                    setTimeout(() => {
                                        submit();
                                    }, 1000);
                                }
                            })
                    }}
                >Create Profile</Button>
            </div>
        </div>
    )
};
