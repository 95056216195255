import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import './UserProfile.scss';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.wrap = React.createRef();

    this.state = {
      show: false,
      role: props.user.roles,
    }
  };

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  };

  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  };

  handleClickOutside = (e) => {
    const block = this.wrap && this.wrap.current
    if (e.path.includes(block)) {
    } else {
      this.setState({ show: false })
    }
  };

  render() {
    const { user, showHi, showBadge, crm_list } = this.props;
    const crm = crm_list.find(elem => elem.activation?.is_activate === true);

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user user-profile"
        drop="down"
        ref={this.wrap}
        alignRight
        show={this.state.show}
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div
            className="kt-header__topbar-user"
            onClick={() => {
              this.setState({ show: !this.state.show })
            }}
          >
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi,
              </span>
            )}
            {showHi && (<span className="kt-header__topbar-username kt-hidden-mobile">{user.firstName} {user.lastName}</span>)}

            {/* {showAvatar && <img alt="Pic" src={user.pic} />} */}

            {false && showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                John Doe
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl" style={{ width: '260px', transform: 'translate3d(-260px, 64px, 0px)' }}>

          <div className="kt-notification">
            <Link to="/account/info" className="kt-notification__item" onClick={() => { this.setState({ show: false }) }}>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Account Info
                </div>
              </div>
            </Link>
            {
              ((this.state.role === 'super-admin') || (this.state.role === 'admin')) &&
              <Link to="/account/setting" className="kt-notification__item" onClick={() => { this.setState({ show: false }) }}>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    Settings
                  </div>
                </div>
              </Link>
            }

            {
              ((this.state.role === 'super-admin') || (this.state.role === 'admin')) && crm &&
              <Link
                to="/account/crm/connect"
                className="kt-notification__item"
                onClick={() => {
                  this.setState({ show: false })
                }}
              >
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    CRM
                  </div>
                </div>
              </Link>
            }

            {
              ((this.state.role === 'super-admin') || (this.state.role === 'admin')) &&
              <Link to="/account/biling" className="kt-notification__item" onClick={() => { this.setState({ show: false }) }}>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    {'Billing'}
                  </div>
                </div>
              </Link>
            }

            <Link to="/logout" className="kt-notification__item" onClick={() => { this.setState({ show: false }) }}>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Logout
                </div>
              </div>
            </Link>

            {((this.state.role === 'super-admin') || (this.state.role === 'admin')) &&
              <div className="profile-line">
                <div className="profile-line__title">Active Profile Limit</div>
                <div className="profile-line__elem">
                  <div className="profile-line__value" style={{ width: `${100 / this.props.profileLimit * this.props.active_profiles}%` }} />
                </div>
              </div>
            }

          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user }, user_info, appData }) => ({
  user,
  crm_list: user_info.CRM_list,
  plan: user_info.subscription?.plan?.name,
  profileLimit: user_info.subscription?.plan?.active_profiles || 1,
  active_profiles: appData.account.active_profiles
});

export default connect(mapStateToProps)(UserProfile);
