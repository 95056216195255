import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    Slider,
    Typography,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel
} from '@material-ui/core';
import {
    Button
} from 'react-bootstrap';
import {
    Portlet, PortletBody, PortletHeader
} from "../../../partials/content/Portlet";
import ModalPayment from './ModalPayment';
import ModalPlan from './ModalPlan';
import ModalCoupon from './ModalCoupon';
import moment from 'moment';
import ModalAddCRM from './ModalAddCRM';
import ModalCRMSync from './ModalCRMSync';
import { useSelector } from 'react-redux';

const marks = [
    {
        value: 0,
        label: 3,
    },
    {
        value: 5,
        label: 10,
    },
    {
        value: 15,
        label: 25,
    },
    {
        value: 30,
        label: 50,
    },
    {
        value: 50,
        label: 100,
    },
    {
        value: 75,
        label: 150,
    },
    {
        value: 90,
        label: 250,
    },
    {
        value: 100,
        label: 500,
    }
];

export default ({
    plan,
    currentPlan,
    setCurrentPlan,
    setLoaderEditSubscription,
    setLogout,
    invoices,
    refreshCurrentSubscription,
    setLoader,
}) => {
    const [value, setValue] = useState(0)
    const [paid, setPaid] = useState('month')
    const [gym, setGum] = useState({})
    const [trainer, setTrainer] = useState({})
    const [modal, setModal] = useState(false)
    const [coupon, setCoupon] = useState('')
    const [modalCoupon, setModalCoupon] = useState(false)
    const [modalPlan, setModalPlan] = useState(false)
    const [modalPlanType, setModalPlanType] = useState('Upgrade')
    const [modalCRM, setModalCRM] = useState(false)
    const [modalCRMSync, setModalCRMSync] = useState(false)
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.details?.is_active === true));
    const role = useSelector(state => state.auth.user.roles);

    useEffect(() => {
        setCoupon(currentPlan?.subscription_data?.coupon || '');
    }, [currentPlan]);

    useEffect(() => {
        if (currentPlan && currentPlan.plan) {
            const val = marks.findIndex(item => {
                return item.label === currentPlan.plan.active_profiles
            })
            setValue(val === -1 ? 0 : marks[val].value)
        }
    }, [currentPlan]);

    useEffect(() => {
        plan.gym && plan.gym[paid] && setGum(returnPlan('gym'))
        plan.trainer && plan.trainer[paid] && setTrainer(returnPlan('trainer'))
        // eslint-disable-next-line
    }, [value, paid, plan]);

    function valuetext(value) {
        return `${value}`;
    };

    function valueLabelFormat(value) {
        const temp = marks.find((mark) => mark.value === value);
        return temp && temp.label
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const returnPlan = (type) => {
        const active = marks.find((mark) => mark.value === value);
        let current = plan[type][paid][0]

        plan[type][paid].forEach((item, index) => {
            if (item.active_profiles === active.label) {
                current = item
            } else if (item.active_profiles < active.label) {
                current = plan[type][paid][index + 1]
            }
        })

        return current
    };

    const [tempPlan, setTempPlan] = useState([]);

    const editPlan = (id, type) => {
        if (currentPlan && currentPlan.payment_source) {
            setModalPlan(id)
            setModalPlanType(type)
        } else {
            setModal(true)
            setTempPlan([id, type])
        }
    };

    return (
        <StyledPlan>
            {window.location.hostname !== 'localhost' &&
                <ModalPayment
                    modal={modal}
                    setModal={setModal}
                    data={currentPlan && currentPlan.payment_source}
                    setCurrentPlan={setCurrentPlan}
                    tempPlan={tempPlan}
                    setTempPlan={setTempPlan}
                    setModalPlan={setModalPlan}
                    setModalPlanType={setModalPlanType}
                />
            }

            {currentPlan &&
                <ModalPlan
                    modal={modalPlan}
                    setModal={setModalPlan}
                    modalPlanType={modalPlanType}
                    setLoaderEditSubscription={setLoaderEditSubscription}
                    setCurrentPlan={setCurrentPlan}
                    currentPlan={currentPlan}
                    setLogout={setLogout}
                    invoices={invoices}
                />
            }

            <ModalCoupon
                modal={modalCoupon}
                setModal={setModalCoupon}
                coupon={coupon}
                refreshCurrentSubscription={refreshCurrentSubscription}
            />

            <ModalAddCRM
                modal={modalCRM}
                setModal={setModalCRM}
                setModalCRMSync={setModalCRMSync}
                setLoader={setLoader}
            />

            <ModalCRMSync
                modal={modalCRMSync}
                setModal={setModalCRMSync}
                crm={crm}
                setLoader={setLoader}
            />

            <div className="row">
                <div className="col-4">
                    <Portlet>
                        <PortletHeader title={'Calculate New Plan'} />
                        <PortletBody>

                            <div className="root" style={{ marginBottom: 15 }}>
                                <Typography variant="h4" id="discrete-slider-restrict" gutterBottom>
                                    Active Profiles {valueLabelFormat(value)}
                                </Typography>
                                <Slider
                                    value={value}
                                    valueLabelFormat={valueLabelFormat}
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider-restrict"
                                    step={null}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    onChange={handleChange}
                                />
                            </div>

                            <FormControl className={'formControl'}>
                                <FormLabel style={{ fontSize: 16 }}>Paid</FormLabel>
                                <RadioGroup
                                    value={paid}
                                    onChange={e => setPaid(e.target.value)}
                                >
                                    <FormControlLabel value="month" control={<Radio color="primary" />} label="Monthly" />
                                    <FormControlLabel value="year" control={<Radio color="primary" />} label="Annually (Save 20%)" />
                                </RadioGroup>
                            </FormControl>
                        </PortletBody>
                    </Portlet>
                    <Portlet>
                        <PortletHeader title={'Current Plan'} />
                        <PortletBody>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="subtitle1-bold">{`Plan: `}</span> {currentPlan && currentPlan.plan && currentPlan.plan.title} {currentPlan && currentPlan.plan && `(${currentPlan.plan.period})`}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="subtitle1-bold">{`Profile Limit: `}</span> {currentPlan && currentPlan.plan && currentPlan.plan.active_profiles}
                            </Typography>

                            {coupon &&
                                <Typography variant="subtitle1" gutterBottom>
                                    {'Coupon: ' + coupon}
                                </Typography>
                            }

                            {currentPlan && currentPlan.subscription_data && currentPlan.subscription_data.trial_end && (currentPlan.subscription_data.plan_amount !== 0) &&
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="subtitle1-bold">{`On free trial until: `}</span> {moment(new Date(currentPlan.subscription_data.trial_end * 1000)).format('MM/DD/YYYY')}
                                </Typography>
                            }

                            {!crm?.activation?.is_activate && (
                                <Typography variant="subtitle1" gutterBottom>
                                    {crm && <><span className="subtitle1-bold">{`Addon: `}</span> {crm?.name === 'Mindbody' ? 'Mindbody Online' : crm?.name}</>}
                                    {crm?.name === 'MindBody'
                                        ? <>
                                            {` `}<span
                                                onClick={() => {
                                                    if (crm.name === 'MindBody') {
                                                        setModalCRMSync(true);
                                                    }
                                                }}
                                                className="pending-activation"
                                            >(Pending Activation)</span>
                                        </>
                                        : !crm ? <></> : <span>{` (Pending Activation)`}</span>
                                    }
                                </Typography>
                            )}

                            {crm?.activation?.is_activate && (
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="subtitle1-bold">{`Addon: `}</span> {crm?.name === 'Mindbody' ? 'Mindbody Online' : crm?.name}
                                </Typography>
                            )}

                            <div className="mb-2">
                                {
                                    !crm &&
                                    (currentPlan?.plan?.name === "Gym" || currentPlan?.plan?.name === "Enterprise") &&
                                    role !== 'supervisor' &&
                                    role !== 'staff' &&
                                    (
                                        <div style={{ marginBottom: '10px' }}>
                                            <Button
                                                variant="primary"
                                                className="btn-blue desktop-vis"
                                                style={{ whiteSpace: 'nowrap' }}
                                                onClick={() => setModalCRM(true)}
                                            >Add CRM</Button>{' '}
                                        </div>
                                    )
                                }

                                {!coupon &&
                                    <Button
                                        variant="primary"
                                        className="btn-blue desktop-vis"
                                        style={{ whiteSpace: 'nowrap' }}
                                        onClick={() => setModalCoupon(true)}
                                    >Add Coupon</Button>
                                }
                            </div>
                        </PortletBody>
                    </Portlet>
                    <Portlet>
                        <PortletHeader title={'Payment'} />
                        <PortletBody>
                            <Typography variant="subtitle1" gutterBottom>
                                {currentPlan && currentPlan.payment_source && currentPlan.payment_source.masked_number}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {currentPlan && currentPlan.payment_source && currentPlan.payment_source.card_type}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {currentPlan && currentPlan.payment_source && `${currentPlan.payment_source.month}/${currentPlan.payment_source.year}`}
                            </Typography>
                            {currentPlan && currentPlan.plan && (currentPlan.plan.id !== 1) && (currentPlan.subscription_data.plan_amount !== 0) && <Typography variant="subtitle1" gutterBottom>
                                Next due: {(currentPlan && currentPlan.next_billing_at) ? moment(new Date(currentPlan.next_billing_at * 1000)).format('MM/DD/YYYY') : '--'}
                                {(currentPlan && currentPlan.payment_source) ? <span style={{ cursor: 'pointer' }}>{` `}<Link to="/account/cancel">(Cancel)</Link></span> : null}
                            </Typography>}
                            <div className="payment-btn">
                                <Button onClick={() => setModal(true)}>
                                    Update
                                </Button>
                            </div>
                        </PortletBody>
                    </Portlet>
                </div>
                <div className="col-4">
                    <Portlet>
                        <PortletHeader title={trainer ? trainer.title : '--'} />
                        <PortletBody>
                            <Typography variant="h6" gutterBottom>
                                Pricing
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                {
                                    trainer
                                        ? `$${trainer.price >= 0 ? trainer.price : '--'}/mo`
                                        : '--'
                                }
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Paid {paid}ly
                            </Typography>
                            <div className="payment-btn">
                                {
                                    currentPlan &&
                                        currentPlan.plan &&
                                        trainer &&
                                        trainer.id === currentPlan.plan.id
                                        ? (
                                            <Button disabled={true} variant="secondary">
                                                Current Plan
                                            </Button>
                                        ) :
                                        (currentPlan &&
                                            currentPlan.plan &&
                                            trainer &&
                                            trainer.active_profiles < currentPlan.plan.active_profiles)
                                            ||
                                            (currentPlan &&
                                                currentPlan.plan &&
                                                ((currentPlan.plan.name === "Gym") || (currentPlan.plan.name === "Enterprise")))
                                            ? (
                                                <Button variant="danger" onClick={() => editPlan(trainer, 'Downgrade')}>
                                                    Downgrade
                                                </Button>
                                            ) : (
                                                <Button onClick={() => editPlan(trainer, 'Upgrade')}>
                                                    Upgrade
                                                </Button>
                                            )
                                }
                            </div>
                            <Typography variant="h6" gutterBottom>
                                Includes
                            </Typography>
                            {
                                trainer && trainer.capability && trainer.capability.split('|').map((item, index) => {
                                    return (
                                        <Typography variant="subtitle1" gutterBottom key={index}>
                                            {item}
                                        </Typography>
                                    )
                                })
                            }
                        </PortletBody>
                    </Portlet>
                </div>
                <div className="col-4">
                    <Portlet>
                        <PortletHeader title={gym ? gym.title : '--'} />
                        <PortletBody>
                            <Typography variant="h6" gutterBottom>
                                Pricing
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                {
                                    gym
                                        ? `$${gym.price >= 0 ? gym.price : '--'}/mo`
                                        : '--'
                                }
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Paid {paid}ly
                            </Typography>
                            <div className="payment-btn">
                                {

                                    currentPlan &&
                                        currentPlan.plan &&
                                        gym &&
                                        gym.id === currentPlan.plan.id
                                        ? (
                                            <Button disabled={true} variant="secondary">
                                                Current Plan
                                            </Button>
                                        ) :
                                        ((currentPlan &&
                                            currentPlan.plan &&
                                            gym &&
                                            gym.active_profiles < currentPlan.plan.active_profiles)
                                            &&
                                            (currentPlan &&
                                                currentPlan.plan &&
                                                currentPlan.plan.name === "Gym"))
                                            ||
                                            (currentPlan &&
                                                currentPlan.plan &&
                                                currentPlan.plan.name === "Enterprise")
                                            ? (
                                                <Button variant="danger" onClick={() => editPlan(gym, 'Downgrade')}>
                                                    Downgrade
                                                </Button>
                                            ) : (
                                                <Button onClick={() => editPlan(gym, 'Upgrade')}>
                                                    Upgrade
                                                </Button>
                                            )
                                }
                            </div>
                            <Typography variant="h6" gutterBottom>
                                Includes
                            </Typography>
                            {
                                gym && gym.capability && gym.capability.split('|').map((item, index) => {
                                    return (
                                        <Typography variant="subtitle1" gutterBottom key={index}>
                                            {item}
                                        </Typography>
                                    )
                                })
                            }
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        </StyledPlan>
    )
}

const StyledPlan = styled.div`
    .payment-btn {
        display: flex;
        margin: 10px 0;
    }

    .pending-activation {
        color: #3669FF;
        cursor: pointer;
    }

    .subtitle1-bold {
        font-weight: 800;
    }
`;
