import {
    TYPE_NOTEPAD_TEXT,
    SET_ACCOUNT,
    SET_TEMPORARY_FORMS,
    RESET_TEMPORARY_FORM,
    RESET_FORMS,
} from '../constants';

const initialState = {
    noteText: '',
    account: {},
    forms: null,
};

export const applicationDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            }
        case TYPE_NOTEPAD_TEXT:
            return {
                ...state,
                noteText: action.payload,
            }
        case SET_TEMPORARY_FORMS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    ...action.payload,
                }
            }
        case RESET_TEMPORARY_FORM:
            if (state?.forms && state.forms[action.payload]) delete state.forms[action.payload];
            return {
                ...state,
            }
        case RESET_FORMS:
            return {
                ...state,
                forms: null,
            }
        default:
            return { ...state }
    }
};
