export const validateCurrentInput = (inputId, inputsStatus, setInputsStatus) => {
    let isValid = true;

    if (!inputsStatus[inputId].value) {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: true,
                errorText: 'Required',
                value: inputsStatus[inputId].value,
            }
        }))
    } else if (inputsStatus[inputId].value.length < 2) {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: true,
                errorText: 'Too short - should be at least 2 characters',
                value: inputsStatus[inputId].value,
            }
        }))
    } else if (inputsStatus[inputId].value.length > 255) {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: true,
                errorText: 'Too long - should not exceed 255 characters',
                value: inputsStatus[inputId].value,
            }
        }))
    } else {
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: false,
                errorText: '',
                value: inputsStatus[inputId].value,
            }
        }))
    }

    return isValid;
};

export const validateCurrentOption = (inputId, optionIndex, inputsStatus, setInputsStatus) => {
    let isValid = true;

    if (!inputsStatus[inputId].options[optionIndex].value) {
        isValid = false;

        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: true,
                errorText: 'Required',
                value: inputsStatus[inputId].options[optionIndex].value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    } else if (inputsStatus[inputId].options[optionIndex].value.length < 2) {
        isValid = false;

        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: true,
                errorText: 'Too short - should be at least 2 characters',
                value: inputsStatus[inputId].options[optionIndex].value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    } else if (inputsStatus[inputId].options[optionIndex].value.length > 255) {
        isValid = false;

        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: true,
                errorText: 'Too long - should not exceed 255 characters',
                value: inputsStatus[inputId].options[optionIndex].value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    } else {
        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: false,
                errorText: '',
                value: inputsStatus[inputId].options[optionIndex].value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    }

    return isValid;
};

export const handleChangeInput = (e, inputId, setInputsStatus) => {
    const value = e.target.value;

    if (!value) {
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: true,
                errorText: 'Required',
                value: value,
            }
        }))
    } else if (value.length < 2) {
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: true,
                errorText: 'Too short - should be at least 2 characters',
                value: value,
            }
        }))
    } else if (value.length > 255) {
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: true,
                errorText: 'Too long - should not exceed 255 characters',
                value: value,
            }
        }))
    } else {
        setInputsStatus(prev => ({
            ...prev,
            [inputId]: {
                ...prev[inputId],
                touched: true,
                hasError: false,
                errorText: '',
                value: value,
            }
        }))
    }
};

export const handleChangeOption = (e, inputId, optionIndex, setInputsStatus) => {
    const value = e.target.value;

    if (!value) {
        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: true,
                errorText: 'Required',
                value: value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    } else if (value.length < 2) {
        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: true,
                errorText: 'Too short - should be at least 2 characters',
                value: value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    } else if (value.length > 255) {
        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: true,
                errorText: 'Too long - should not exceed 255 characters',
                value: value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    } else {
        setInputsStatus(prev => {
            let arr = [...prev[inputId].options];
            arr[optionIndex] = {
                touched: true,
                hasError: false,
                errorText: '',
                value: value,
            };

            return {
                ...prev,
                [inputId]: {
                    ...prev[inputId],
                    options: arr,
                }
            }
        })
    }
};

export const handleChangeRange = (e, itemId, key, inputsStatus, setInputsStatus) => {
    const value = e.target.value;

    if (!value) {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                [key]: {
                    value: value,
                    touched: true,
                    hasError: true,
                    errorText: 'Required',
                }
            }
        }))
    } else if (key === 'from' && +value > +inputsStatus[itemId].to.value) {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                from: {
                    value: value,
                    touched: true,
                    hasError: true,
                    errorText: 'Can not be more then "To"',
                },
                to: {
                    ...prev[itemId].to,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    } else if (key === 'to' && +value < +inputsStatus[itemId].from.value) {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                to: {
                    value: value,
                    touched: true,
                    hasError: true,
                    errorText: 'Can not be less then "From"',
                },
                from: {
                    ...prev[itemId].from,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    } else if (key === 'to' && +value > 200) {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                to: {
                    value: value,
                    touched: true,
                    hasError: true,
                    errorText: 'Max value 200',
                },
                from: {
                    ...prev[itemId].from,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    } else {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                [key]: {
                    value: value,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    }
};

export const validateCurrentRange = (itemId, inputsStatus, setInputsStatus, key) => {
    let isValid = true;

    if (!inputsStatus[itemId][key].value) {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                [key]: {
                    ...prev[itemId][key],
                    touched: true,
                    hasError: true,
                    errorText: 'Required',
                }
            }
        }))
    } else if (key === 'from' && +inputsStatus[itemId].from.value >= +inputsStatus[itemId].to.value) {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                from: {
                    ...prev[itemId].from,
                    touched: true,
                    hasError: true,
                    errorText: 'Can not be more or equal to "To"',
                },
                to: {
                    ...prev[itemId].to,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    } else if (key === 'to' && +inputsStatus[itemId].to.value <= +inputsStatus[itemId].from.value) {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                to: {
                    ...prev[itemId].to,
                    touched: true,
                    hasError: true,
                    errorText: 'Can not be less or equal to "From"',
                },
                from: {
                    ...prev[itemId].from,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    } else {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                touched: true,
                hasError: false,
                errorText: '',
                value: inputsStatus[itemId].value,
                to: {
                    ...prev[itemId].to,
                    touched: true,
                    hasError: false,
                    errorText: '',
                },
                from: {
                    ...prev[itemId].from,
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    };

    return isValid;
};

export const handleValidateTypeInput = (itemId, inputsStatus, setInputsStatus) => {
    let isValid = true;

    if (inputsStatus[itemId].type === 'question') {
        isValid = false;
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                status: {
                    touched: true,
                    hasError: true,
                    errorText: 'Select question type',
                }
            }
        }))
    } else {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                status: {
                    touched: true,
                    hasError: false,
                    errorText: '',
                }
            }
        }))
    };

    return isValid;
};

export const validateFullForm = (values, inputsStatus, setInputsStatus, showError) => {
    let isValid = true;

    // need fix error popup

    if (!values?.inputs?.length) isValid = false;
    if (values?.inputs[values?.inputs?.length - 1]?.type === 'section') {
        // if section is the last input
        showError('There must be at least one question after section');
        isValid = false;
    } else {
        let arr = values?.inputs?.filter(item => item.type !== 'section');
        if (arr?.length < 1) {
            isValid = false;
            showError('There must be at least one question');
        }
    }
    //

    Object.keys(inputsStatus).forEach(key => {
        // validate questions
        if (inputsStatus[key].hasError === true) {
            if (!validateCurrentInput(key, inputsStatus, setInputsStatus)) {
                isValid = false;
            }
        };

        // validate input type
        let sectionsArray = [];
        // The section name has already been taken

        values.inputs.forEach(elem => {
            if (elem.type === 'question') {
                showError('Question types are not selected');
                if (!handleValidateTypeInput(key, inputsStatus, setInputsStatus)) {
                    isValid = false;
                }
            }

            if (elem.type === 'section') {
                if (sectionsArray.find(item => item?.toLowerCase() === inputsStatus[elem.id].value?.toLowerCase())) {
                    setInputsStatus(prev => ({
                        ...prev,
                        [elem.id]: {
                            ...prev[elem.id],
                            touched: true,
                            hasError: true,
                            errorText: 'The section name has already been taken',
                        }
                    }))
                    isValid = false;
                }
                sectionsArray.push(inputsStatus[elem.id].value);
            }
        });

        // validate options
        if (inputsStatus[key].options?.length) {
            inputsStatus[key].options.forEach((elem, index) => {
                if (!validateCurrentOption(key, index, inputsStatus, setInputsStatus)) {
                    isValid = false;
                }
            })
        } else if (
            (inputsStatus[key].type === 'checkbox' || inputsStatus[key].type === 'radio') &&
            !inputsStatus[key].options?.length
        ) {
            showError('There must be options in question type "Checkbox" and "Multiple Choice"');
            isValid = false;
        };

        // validate range
        if (inputsStatus[key].type === 'number') {
            if (
                !validateCurrentInput(key, inputsStatus, setInputsStatus) ||
                !validateCurrentRange(key, inputsStatus, setInputsStatus, 'from') ||
                !validateCurrentRange(key, inputsStatus, setInputsStatus, 'to')
            ) {
                isValid = false;
            }
        };
    });

    return isValid;
};
