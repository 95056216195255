import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { useSelector } from "react-redux";

export const MenuList = ({ currentUrl, menuConfig, layoutConfig }) => {
  const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.activation?.is_activate === true));
  const subscription = useSelector(state => state?.user_info?.subscription);

  if (!menuConfig) return <></>

  return menuConfig?.aside.items.map((child, index) => {
    if (child.title === 'Forms') {
      return null
    };

    if (child.title === 'CRM' && !crm) {
      return null;
    };

    if (child.title === 'CRM' && subscription?.plan?.name?.toLowerCase() === 'gym') {
      child.submenu = child.submenu?.filter(elem => elem.title !== 'Group')
    } else if (child.title === 'CRM') {
      child.submenu = [
        {
          title: 'Connect',
          page: 'account/crm/connect',
        },
        {
          title: 'Group',
          page: 'account/crm/group',
        },
        {
          title: 'User',
          page: 'account/crm/user',
        },
        {
          title: 'Profile',
          page: 'account/crm/profile',
        },
      ]
    }

    return (
      <React.Fragment key={`menuList${index}`}>
        {child.section && <MenuSection item={child} />}
        {child.separator && <MenuItemSeparator item={child} />}
        {child.title && (
          <MenuItem
            item={child}
            currentUrl={currentUrl}
            layoutConfig={layoutConfig}
          />
        )}
      </React.Fragment>
    );
  });
};
