import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import './table.scss'
import { Tabs, Tab } from '@material-ui/core'
import Loader from "../../components/Loader"
import {
    Portlet,
    PortletBody,
} from '../../partials/content/Portlet'
import { getPrivileges } from "../../crud/info.crud";
import UP from './SettingTabs/UserPrivilegesTab'
import MM from './SettingTabs/MetricManagementTab'
import TM from './SettingTabs/TestingManagementTab'
import RI from './SettingTabs/ResultsInterpretationTab'
import Branding from './SettingTabs/BrandingTab'
import Integrations from './SettingTabs/IntegrationsTab'
import Logout from '../../pages/auth/Logout'

const Setting = (props) => {

    const [key, setKey] = useState(0);
    const [loader, setLoader] = useState(false)
    const [redirect, setRedirect] = useState(null)

    const [dataPrivileges, setDataPrivileges] = useState([])
    const [privilegesVisible, setPrivilegesVisible] = useState(1)
    const [loaderPrivileges, setLoaderPrivileges] = useState(false)

    document.title = 'TrainerMetrics - Account Settings'

    useEffect(() => {
        if (props.user.roles === 'client') {
            setRedirect(<Redirect to="/profile-list" />)
        }
        setLoaderPrivileges(true)
        getPrivileges()
            .then(res => {
                setPrivilegesVisible(0)
                setLoaderPrivileges(false)
                setDataPrivileges(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderPrivileges(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                }
            });
    }, []);

    return (
        <StyledSetting>
            <Loader visible={loader} />
            {redirect}
            <Portlet>
                <PortletBody>
                    <div className="acord-none-mob">
                        <Tabs
                            value={key}
                            onChange={(e, value) => setKey(value)}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >

                            {
                                privilegesVisible === 1
                                    ? ([
                                        <Tab label="Metrics Management" key={0} />,
                                        <Tab label="Testing Management" key={1} />,
                                        <Tab label="Results Interpretation" key={2} />,
                                        <Tab label="Branding" key={3} />,
                                        <Tab label="Integrations" key={4} />,
                                    ])
                                    : ([
                                        <Tab label="User Privileges" key={5} />,
                                        <Tab label="Metrics Management" key={6} />,
                                        <Tab label="Testing Management" key={7} />,
                                        <Tab label="Results Interpretation" key={8} />,
                                        <Tab label="Branding" key={9} />,
                                        <Tab label="Integrations" key={10} />
                                    ])
                            }
                        </Tabs>
                    </div>

                    {
                        !loaderPrivileges && (
                            <>
                                {privilegesVisible === 0 &&
                                    <div
                                        className="acord-title acord-none-dt"
                                        onClick={() => setKey(key - privilegesVisible === 0 ? null : 0)}
                                    >
                                        User Privileges
                                    </div>
                                }
                                {key + privilegesVisible === 0 &&
                                    <div className="wrap-setting" style={{ overflow: 'auto' }}>
                                        <UP
                                            key="UP"
                                            setLoader={setLoader}
                                            setRedirect={setRedirect}
                                            data={dataPrivileges}
                                            setData={setDataPrivileges}
                                            loaderPrivileges={loaderPrivileges}
                                            setLoaderPrivileges={setLoaderPrivileges}
                                        />
                                    </div>
                                }
                                <div className="acord-title acord-none-dt" onClick={() => setKey(key + privilegesVisible === 1 ? -2 : 1 - privilegesVisible)}>
                                    Metrics Management
                                </div>

                                {key + privilegesVisible === 1 && <div className="wrap-setting" style={{ overflow: 'auto' }}><MM key="MM" setLoader={setLoader} setRedirect={setRedirect} /></div>}
                                <div className="acord-title acord-none-dt" onClick={() => setKey(key + privilegesVisible === 2 ? -2 : 2 - privilegesVisible)}>
                                    Testing Management
                                </div>

                                {key + privilegesVisible === 2 && <div className="wrap-setting" style={{ overflow: 'auto' }}><TM key={"TM"} setLoader={setLoader} setRedirect={setRedirect} /></div>}
                                <div className="acord-title acord-none-dt" onClick={() => setKey(key + privilegesVisible === 3 ? -2 : 3 - privilegesVisible)}>
                                    Results Interpretation
                                </div>

                                {key + privilegesVisible === 3 && <div className="wrap-setting" style={{ overflow: 'auto' }}><RI key={"RI"} setLoader={setLoader} setRedirect={setRedirect} /></div>}
                                <div className="acord-title acord-none-dt" onClick={() => setKey(key + privilegesVisible === 4 ? -2 : 4 - privilegesVisible)}>
                                    Branding
                                </div>

                                {key + privilegesVisible === 4 && <div className="wrap-setting" style={{ minHeight: '400px', overflow: 'auto' }}><Branding key={"Branding"} setLoader={setLoader} setRedirect={setRedirect} /></div>}
                                <div className="acord-title acord-none-dt" onClick={() => setKey(key + privilegesVisible === 5 ? -2 : 5 - privilegesVisible)}>
                                    Integrations
                                </div>
                                {key + privilegesVisible === 5 && <div className="wrap-setting" style={{ overflow: 'auto' }}><Integrations key={"Integrations"} setLoader={setLoader} setRedirect={setRedirect} /></div>}
                            </>
                        )
                    }
                </PortletBody>
            </Portlet>
        </StyledSetting>
    )
}

const StyledSetting = styled.div`
    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;

       
    }
`;

const mapStateToProps = ({ auth: { user } }) => ({
    user,
});

export default connect(mapStateToProps)(Setting);
