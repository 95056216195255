import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import DataOutput from '../../components/DataOutput'
import UserInfo from '../../components/UserInfo'
import './table.scss'
import { Modal, Button } from 'react-bootstrap'
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { Tabs, Tab } from '@material-ui/core'
import Loader from "../../components/Loader"
import ModalBodyInfo from './ProgramTabs/ModalProgram'
import { getProgramList, getProgram } from '../../crud/profile.crud'
import Logout from '../auth/Logout'
import { Redirect } from 'react-router-dom'

const Program = (props) => {
    document.title = 'TrainerMetrics - Program'
    const id = props.match.params.id
    const [key, setKey] = useState('');
    const [keyIndex, setKeyIndex] = useState(0);
    const [logout, setLogout] = useState(null)
    const [nav, setNav] = useState([])
    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])
    const [currentModal, setCurrentModal] = useState({})
    const [loaderProgram, setLoaderProgram] = useState(false)
    const [loaderProgramList, setLoaderProgramList] = useState(false)

    useEffect(() => {
        currentModal.title && setModal(true)
    }, [currentModal])

    useEffect(() => {
        setKey(nav[keyIndex] && nav[keyIndex].name)
    }, [keyIndex, nav])

    useEffect(() => {
        setLoaderProgramList(true)
        getProgramList(id)
            .then(res => {
                // setKey(res.data.data[0].name)
                setNav(res.data.data)
                setLoaderProgramList(false)
            })
            .catch(({ response }) => {
                setLoaderProgramList(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
    }, [id])

    useEffect(() => {
        if (key) {
            setLoaderProgram(true)
            getProgram(id, key)
                .then(res => {
                    setLoaderProgram(false)
                    // console.log(res.data.data)
                    setData(res.data.data)
                })
                .catch(({ response }) => {
                    setLoaderProgram(false)
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    } else if (response && (response.status === 403)) {
                        setLogout(<Redirect to="/profile-list" />)
                    }
                })
        }
    }, [key, id])

    const maxCol = item => {
        let maxIndex = 0

        item.cols.forEach((elem, index) => {
            if (elem.values.length > maxIndex) {
                maxIndex = index
            }
        })

        return item.cols[maxIndex].values
    }

    const getData = () => data.length > 0
        ? data.map((item, index2) => {
            return (
                <div className={data.length === 1 ? `col-12` : data.length === 2 ? 'col-12 col-md-6' : 'col-12 col-md-6 col-xl-4'} key={`${index2}`}>
                    <Portlet>
                        {console.log('ProgramData', data)}
                        <PortletHeader title={item.title} />
                        <PortletBody className={`${data.length === 1 && 'settingtable'}`}>
                            <table className="settingtable">
                                <thead>
                                    <tr>
                                        {item.cols.map((title, index3) => {
                                            return (
                                                <td
                                                    key={`${index2}${index3}`} style={{ cursor: 'auto' }}
                                                    style={{ textAlign: item.title === 'Corrective Exercises' || item.title === 'Muscular Imbalance' ? 'center' : '' }}
                                                >
                                                    {title.title}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {maxCol(item).map((label, index3) => {
                                        return (
                                            <tr key={`${index2}${index3}`}>
                                                {item.cols.map((col, index4) => {
                                                    return typeof col.values[index3] !== 'object'
                                                        ? (
                                                            <td
                                                                className={index4 > 0 ? "theme-data" : ''}
                                                                key={`${index2}${index3}${index4}`}
                                                                style={{ textAlign: item.title === 'Corrective Exercises' || item.title === 'Muscular Imbalance' ? 'center' : '' }}
                                                            >
                                                                { col.values[index3]}
                                                            </td>
                                                        )
                                                        : (
                                                            <td
                                                                className={col.values[index3] && col.values[index3].title ? "theme-data" : ''}
                                                                key={`${index2}${index3}${index4}`}
                                                                style={{
                                                                    textAlign: item.title === 'Corrective Exercises' || item.title === 'Muscular Imbalance' ? 'center' : '',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => setCurrentModal(col.values[index3])}
                                                            >
                                                                { !!col.values[index3] && (col.values[index3].name || '')}
                                                            </td>
                                                        )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </PortletBody>
                    </Portlet>
                </div>
            )
        })
        : null

    return (
        <StyledProgram>
            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} />
            </div>
            <Loader visible={loaderProgram || loaderProgramList} />
            { logout}

            <Modal
                size="lg"
                show={modal}
                onHide={e => {
                    setModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{currentModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalBodyInfo data={currentModal} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={e => {
                            setModal(false)
                        }}
                    >
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
            <Portlet>
                <PortletBody>
                    <div className="acord-none-mob">
                        <Tabs
                            value={keyIndex}
                            onChange={(e, value) => setKeyIndex(value)}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                            style={{ marginBottom: 20 }}
                        >
                            {
                                nav.map((item, index) => {
                                    return (
                                        <Tab label={item.title} key={index} />
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                    <div className="row acord-none-mob">
                        {getData()}
                    </div>

                    {
                        nav.map((item, index) => {
                            return (
                                <div className="row acord-none-dt" key={index}>
                                    <div className="acord-title" onClick={() => setKeyIndex(keyIndex === index ? null : index)}>
                                        {item.title}
                                    </div>
                                    {keyIndex === index && getData()}
                                </div>
                            )

                        })
                    }

                </PortletBody>
            </Portlet>

        </StyledProgram>
    )
}

export default Program

const StyledProgram = styled.div`
    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }
`
