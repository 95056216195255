import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchCRMList = () => {
    return axios.get(`${BASE_URL}crm/list`);
};

export const postActivateCRM1 = (CRMId, number_locations, site_id, login, password) => {
    return axios.post(`${BASE_URL}crm/control/${CRMId}/activate`, {
        number_locations,
        site_id,
        login,
        password,
    });
};

export const postActivateCRM2 = (CRMId, site_id) => {
    return axios.post(`${BASE_URL}crm/control/${CRMId}/activate`, {
        site_id,
    });
};

export const postActivateCRM3 = (CRMId, club_numbers) => {
    return axios.post(`${BASE_URL}crm/control/${CRMId}/activate`, {
        club_numbers,
    });
};

export const postDeactivateCRM = () => {
    return axios.post(`${BASE_URL}crm/control/deactivate`);
};

export const fetchCRMStrategy = () => {
    return axios.get(`${BASE_URL}crm/setting/strategy`);
};

export const patchCRMStrategy = (data) => {
    return axios.patch(`${BASE_URL}crm/setting/strategy`, { ...data });
};

export const fetchCRMSync = module => {
    return axios.get(`${BASE_URL}crm/setting/sync/${module}`);
};

export const patchSyncUsers = data => {
    return axios.patch(`${BASE_URL}crm/setting/sync/users`, data);
};

export const patchSyncGroups = data => {
    return axios.patch(`${BASE_URL}crm/setting/sync/groups`, data);
};

export const patchSyncProfiles = data => {
    return axios.patch(`${BASE_URL}crm/setting/sync/profiles`, data);
};

export const postAddMapping = (module, data) => {
    return axios.post(`${BASE_URL}crm/setting/mapping/${module}`, data);
};

export const fetchMappings = (module, perPage = 100, page = 1, order_by, order = 'asc', withoutMapping = 0, search = '', withMapping = 0, withoutDeleted = 0) => {
    if (search) {
        return axios.get(`${BASE_URL}crm/setting/mapping/${module}?perPage=${perPage}&page=${page}&sortBy[0][name]=${order_by}&sortBy[0][dir]=${order}&withoutMapping=${withoutMapping}&search=${search}&withMapping=${withMapping}&withoutDeleted=${withoutDeleted}`);
    } else {
        return axios.get(`${BASE_URL}crm/setting/mapping/${module}?perPage=${perPage}&page=${page}&sortBy[0][name]=${order_by}&sortBy[0][dir]=${order}&withoutMapping=${withoutMapping}&withMapping=${withMapping}&withoutDeleted=${withoutDeleted}`);
    }
};

export const fetchProfileMapping = (profileID) => {
    return axios.get(`${BASE_URL}profile/${profileID}/mapping`);
};

export const fetchFromCRM = module => {
    return axios.get(`${BASE_URL}crm/setting/mapping/${module}/crm/list?perPage=100&page=1`);
};

export const deleteCRM = (module, mappingID) => {
    return axios.delete(`${BASE_URL}crm/setting/mapping/${module}/${mappingID}`);
};

export const fetchCRMFields = module => {
    return axios.get(`${BASE_URL}crm/setting/fields/${module}/list?perPage=100&page=1`)
};

export const postCRMFields = arr => {
    return axios.post(`${BASE_URL}crm/setting/fields/massUpdate`, arr);
};

export const postRefresh = (module) => {
    return axios.post(`${BASE_URL}crm/refresh/${module}`)
};

export const postRefreshProfile = id => {
    return axios.post(`${BASE_URL}crm/refresh/profiles/${id}`)
};

export const postCreateFromCRM = (module, id) => {
    return axios.post(`${BASE_URL}crm/create/from/${module}/${id}`)
};

export const checkActivation = () => {
    return axios.get(`${BASE_URL}crm/control/check-activation`);
};

export const getFields = (module, id) => {
    return axios.get(`${BASE_URL}crm/setting/mapping/${module}/crm/fields/${id}`);
};

export const fetchActivity = (perPage = '10', page = '1', search, activity_type, date_start, date_end) => {
    if (date_start === '  /  /    ') date_start = '';
    if (date_end === '  /  /    ') date_end = '';
    return axios.get(`${BASE_URL}crm/activity/list?perPage=${perPage}&page=${page}${search ? `&search=${search}` : ''}&activity_type=${activity_type}&date_start=${date_start}&date_end=${date_end}`);
};

export const updateMappings = module => {
    return axios.post(`${BASE_URL}crm/update/${module}`)
};
