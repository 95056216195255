import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
    TextField
} from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { Formik } from "formik";
import DropZone from '../../../components/DropZone';
import Logout from '../../auth/Logout';
import { updateUserInfo } from '../../../crud/info.crud';
import { getUserByToken } from '../../../crud/auth.crud';
import Cancel from '@material-ui/icons/Cancel';
import Loader from '../../../components/Loader';
import * as media from '../../../crud/media.crud';
import { postUserePhoto } from '../../../crud/file.crud';

const ImgComponent = ({ src, alt, className, style }) => {
    const [url, setUrl] = useState('')

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} style={style} />
}

export default () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.auth.user.id);
    const [redirect, setRedirect] = useState(null);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        // username: "",
        email: "",
        pic: "",
        password: '',
        password_check: ''

    });
    const [photo, setPhoto] = useState('');
    const [touchImg, setTouchImg] = useState(true);
    const [loaderUserByToken, setLoaderUserByToken] = useState(true);
    const [loaderUpdateUser, setLoaderUpdateUser] = useState(false);
    const [photoId, setPhotoId] = useState(null);

    useEffect(() => {
        setLoaderUserByToken(true)
        getUserByToken()
            .then(res => {
                setLoaderUserByToken(false)
                setData({
                    first_name: res.data.data.firstName || '',
                    last_name: res.data.data.lastName || '',
                    email: res.data.data.email || '',
                    pic: res.data.data.pic || '',
                    password: '',
                    password_check: ''
                })
                setPhoto(res.data.data.pic)
            })
            .catch(({ response }) => {
                setLoaderUserByToken(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
            })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setData(data => ({
            ...data,
            pic: photo
        }))
    }, [photo])

    return (
        <StyledUserInfo>
            <Loader visible={loaderUserByToken || loaderUpdateUser} />
            {redirect}
            <Formik
                initialValues={data}
                enableReinitialize={true}
                validate={values => {
                    const temp = {};

                    if (!values.first_name) {
                        temp.first_name = 'This value should not be blank'
                    } else if (values.first_name.length > 15) {
                        temp.first_name = 'Field is not valid'
                    } else if (values.first_name.length < 2) {
                        temp.first_name = 'The First name should be at least 2 of characters'
                    } else if (/[^A-Za-z0-9]/g.test(values.first_name)) {
                        temp.first_name = 'Field is not valid'
                    }

                    if (!values.last_name) {
                        temp.last_name = 'This value should not be blank'
                    } else if (values.last_name.length > 15) {
                        temp.last_name = 'Field is not valid'
                    } else if (values.last_name.length < 2) {
                        temp.last_name = 'The Last name should be at least 2 of characters'
                    } else if (/[^A-Za-z0-9]/g.test(values.last_name)) {
                        temp.last_name = 'Field is not valid'
                    }

                    // if (!values.username) {
                    //     temp.username = 'This value should not be blank'
                    // }

                    if (values.password && (values.password.length > 32 || values.password.length < 8)) {
                        temp.password = 'Field is not valid'
                    } else if (values.password && (
                        !/[\W_]/.test(values.password) ||
                        !/[0-9]/.test(values.password) ||
                        !/[a-z]/.test(values.password) ||
                        !/[A-Z]/.test(values.password)
                    )) {
                        temp.password = 'Field is not valid'
                    }

                    if (!values.email) {
                        temp.email = 'This value should not be blank'
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        temp.email = 'Invalid email address'
                    }

                    if (values.password !== values.password_check) {
                        temp.password_check = 'The password fields must match'
                    }

                    setErrors(temp)
                    return temp;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    let temp = { ...values }
                    let temp_pic = values.pic
                    temp.pic = null;

                    if (!values.password) {
                        delete temp.password
                    }
                    setLoaderUpdateUser(true);

                    if (photoId) {
                        postUserePhoto(id, photoId)
                            .then(() => {
                                setTimeout(() => {
                                    dispatch(AlertState.actions.alert({
                                        text: 'User photo is updated',
                                        variant: true
                                    }));
                                }, 2000);
                            })
                            .catch(() => {
                                setTimeout(() => {
                                    dispatch(AlertState.actions.alert({
                                        text: 'User photo update error',
                                        variant: false
                                    }));
                                }, 2000);
                            })
                    }

                    updateUserInfo(temp)
                        .then(res => {
                            setLoaderUpdateUser(false)
                            setStatus(false)
                            setSubmitting(false)
                            values.password = ''
                            values.password_check = ''
                            dispatch(AlertState.actions.alert({
                                text: 'Account info is updated',
                                variant: true
                            }));
                            dispatch(AlertState.actions.editUser({
                                firstName: res.data.data.first_name,
                                lastName: res.data.data.last_name,
                                email: res.data.data.email
                            }));
                        })
                        .catch(({ response }) => {
                            response &&
                                response.data &&
                                dispatch(AlertState.actions.alert({
                                    text: response.data.message || response.data.error,
                                    variant: false
                                }));
                            setLoaderUpdateUser(false)
                            if (response && (response.status === 401)) {
                                setRedirect(<Logout />)
                            } else if (response && (response.status === 403)) {
                                setRedirect(<Redirect to="/profile-list" />)
                            }
                            setErrors({
                                first_name: response.data.errors.first_name && response.data.errors.first_name[0],
                                last_name: response.data.errors.last_name && response.data.errors.last_name[0],
                                // username: response.data.errors.username && response.data.errors.username[0],
                                email: response.data.errors.email && response.data.errors.email[0],
                                pic: response.data.errors.pic && response.data.errors.pic[0],
                                password: response.data.errors.password && response.data.errors.password[0],
                            })
                            setSubmitting(false);
                            setStatus(response.data.message)
                        })
                    if (!(photo && photo.toLocaleLowerCase().indexOf('http') === -1)) {
                        values.pic = temp_pic
                    }
                }}
            >
                {({
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form
                        noValidate={true}
                        autoComplete="off"
                        className="kt-form root"
                        onSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-12 col-md-6 info-block">
                                <h4>User Information</h4>
                                <div className="form-group">
                                    <TextField
                                        key='36'
                                        variant="outlined"
                                        type="text"
                                        label="First Name"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="first_name"
                                        inputProps={{
                                            maxLength: 15
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.first_name}
                                        helperText={touched.first_name && errors.first_name}
                                        error={Boolean(touched.first_name && errors.first_name)}
                                    />

                                    <TextField
                                        key='37'
                                        variant="outlined"
                                        type="text"
                                        label="Last Name"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="last_name"
                                        inputProps={{
                                            maxLength: 15
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.last_name}
                                        helperText={touched.last_name && errors.last_name}
                                        error={Boolean(touched.last_name && errors.last_name)}
                                    />

                                    <TextField
                                        key='38'
                                        variant="outlined"
                                        type="text"
                                        label="Email"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="email"
                                        inputProps={{
                                            maxLength: 40
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        helperText={touched.email && errors.email}
                                        error={Boolean(touched.email && errors.email)}
                                    />

                                    <TextField
                                        key='39'
                                        variant="outlined"
                                        type="password"
                                        label="New Password"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{
                                            maxLength: 32
                                        }}
                                        value={values.password}
                                        helperText={touched.password && errors.password}
                                        error={Boolean(touched.password && errors.password)}
                                    />

                                    {Boolean(touched.password && errors.password) && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">
                                                Your password must:
                                                <ul>
                                                    <li>Be at least 8 characters in length</li>
                                                    <li>Contain at least 1 lowercase and 1 uppercase letter</li>
                                                    <li>Contain at least 1 special character {'(!@#$%^&*)'}</li>
                                                    <li>Contain at least 1 number (0–9)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    <TextField
                                        key='40'
                                        variant="outlined"
                                        type="password"
                                        label="Confirm New Password"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="password_check"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{
                                            maxLength: 32
                                        }}
                                        value={values.password_check}
                                        helperText={touched.password && touched.password_check && errors.password_check}
                                        error={Boolean(touched.password && touched.password_check && errors.password_check)}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 info-block">
                                <h4>User Photo</h4>
                                <div className="info-block__photo">
                                    {
                                        values.pic && values.pic?.toLocaleLowerCase().indexOf('http') !== -1 && touchImg
                                            ? (<div className="info-block__photo-preview">
                                                <ImgComponent src={values.pic} alt="propfile" />
                                                <div className="delete">
                                                    <Cancel onClick={() => setTouchImg(false)} />
                                                </div>
                                            </div>)
                                            : (<DropZone
                                                submit={(e) => {
                                                    setPhoto(e?.thumbnail_path);
                                                    setPhotoId(e.id);
                                                    values.pic = e?.thumbnail_path;
                                                }}
                                                photo={values.pic?.thumbnail_path}
                                            />)
                                    }
                                    <div style={{ color: '#fd397a' }}>
                                        {
                                            touched.pic && errors.pic
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-login__actions" style={{ marginTop: 20 }}>
                            <Button
                                className="btn-blue"
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </StyledUserInfo>
    )
}

const StyledUserInfo = styled.div`
    .info-block {
        padding: 30px 50px;

        &__photo {

            &-preview {
                border: 1px dashed grey;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 300px;
                width: 100%;
                padding: 30px;
                position: relative;

                .delete {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

`