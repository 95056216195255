import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, Redirect } from 'react-router-dom'
import {
    Typography,
    Radio,
    FormControlLabel,
    FormControl,
    RadioGroup,
    TextField
} from '@material-ui/core'
import {
    Button
} from 'react-bootstrap'
import {
    Portlet, PortletBody
} from "../../partials/content/Portlet";
import { cancelSubscription } from '../../crud/biling.crud'
import Loader from '../../components/Loader'
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck'

export default () => {

    const dispatch = useDispatch()

    const [step, setStep] = useState(1)
    const [select, setSelect] = useState('')
    const [text, setText] = useState('')
    const [redirect, setRedirect] = useState(null)
    const [loader, setLoader] = useState(false)

    const cancelPlan = () => {
        setLoader(true)
        cancelSubscription()
            .then(res => {
                setLoader(false)
                dispatch(AlertState.actions.refreshStatistic())
                setRedirect(<Redirect to="/account/biling" />)
            })
            .catch(() => {
                setLoader(false)
            })
    }

    return (
        <Portlet>
            { redirect }
            <Loader visible={loader} />
            <PortletBody style={{display: 'flex', alignItems: 'center'}}>
                {
                    step === 1 && (
                        <CancelWrap>
                            <Typography variant="h4" gutterBottom>
                                Thinking of leaving us?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom style={{textAlign: 'center'}}>
                                We would love to show you how we can help before you cancel. If this is something
                                we can work together to fix please <a href="https://trainermetrics.zendesk.com/hc/en-us/requests/new">contact us</a>
                            </Typography>
                            <div className="cancel-action">
                                <Link to="/profile-list">
                                    <Button>
                                        No, Take Me Back   
                                    </Button>
                                </Link>
                                    <Button onClick={() => setStep(2)}>
                                        Yes, Cancel My Account
                                    </Button>
                            </div>
                        </CancelWrap>
                    )
                }
                
                {
                    step === 2 && (
                        <CancelWrap>
                            <Typography variant="h4" gutterBottom>
                                Why do you want to cancel?
                            </Typography>
                            <div className="cancel-input">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="Gender"
                                        name="gender1"
                                        value={select}
                                        onChange={e => {
                                            setSelect(e.target.value)
                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="I couldn't figure things out" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} label="I had an issue" />
                                        <FormControlLabel value="3" control={<Radio color="primary" />} label="It costs too much" />
                                        <FormControlLabel value="4" control={<Radio color="primary" />} label="I found another app that I like better" />
                                        <FormControlLabel value="5" control={<Radio color="primary" />} label="I need to pause my billing" />
                                        <FormControlLabel value="6" control={<Radio color="primary" />} label="I just want to close my account" />
                                        <FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
                                    </RadioGroup>
                                </FormControl>
                                {
                                    select === 'Other' && (
                                        <TextField
                                            key='17'
                                            label="Why are you looking to close the account?"
                                            multiline
                                            rows="4"
                                            value={text}
                                            onChange={e => { setText(e.target.value) }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    )
                                }
                               
                            </div>
                            {
                                select !== '' && (
                                    <Button onClick={() => cancelPlan()}>
                                        Cancel Account
                                    </Button>
                                )
                            }
                            
                        </CancelWrap>
                    )
                }
            </PortletBody>
        </Portlet>
    )
}

const CancelWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    text-align: center;

    .cancel-action {
        display: flex;
        align-items: center;

        a, button {
            margin: 15px;
        }
    }

    .cancel-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`