/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import { getProfile, saveProfileNote } from '../../../crud/profile.crud'
import styled from 'styled-components'
import Loader from '../../../components/Loader'

export default ({
    id,
    setTextNode,
    textNode,
    setShowExitPromt,
    visibleButton,
    setVisibleButton,
    textChanged,
    setTextChanged,
}) => {
    const textarea = useRef();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({});

    useEffect(() => {
        if (!textNode && !textChanged) {
            getDefaultValue();
        } else {
            setVisibleButton(true);
            textarea.current.value = textNode;
        }

        if (visibleButton) textarea.current.focus()
    }, []);

    const getDefaultValue = () => {
        setTextChanged(false)
        setShowExitPromt(false)
        setLoader(true)
        setVisibleButton(false)
        getProfile(id)
            .then(res => {
                setLoader(false)
                setData({ first_name: res.data.data.first_name, last_name: res.data.data.last_name })
                if (res.data.data.notepad_text) {
                    setTextNode(res.data.data.notepad_text);
                    textarea.current.value = res.data.data.notepad_text;
                } else textarea.current.value = '';
                if (!textarea.current.value) setVisibleButton(false)
            })
            .catch(() => {
                setLoader(false)
            })
    };

    const saveNote = () => {
        setLoader(true);
        saveProfileNote(id, { notepad_text: textarea.current.value })
            .then(() => {
                setLoader(false);
                setVisibleButton(false);
                setShowExitPromt(false);
                setTextChanged(false);
                dispatch(AlertState.actions.alert({
                    text: 'Note is updated',
                    variant: true
                }));
            })
            .catch(() => {
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'Error',
                    variant: false,
                }));
            })
    };

    const changeTextarea = () => {
        setVisibleButton(true);
        setShowExitPromt(true);
        setTextNode(textarea.current.value);
        setTextChanged(true);
    };

    const getPlaceholder = () => {
        if (data.first_name && data.last_name) {
            return `Add a note about ${data.first_name} ${data.last_name}`
        }
        return '';
    };

    return <StyledNotepad>
        <Loader visible={loader} />
        <textarea
            className="notepad-textarea"
            ref={textarea}
            maxLength="1000"
            onChange={changeTextarea}
            placeholder={getPlaceholder()}
        />
        <div className="notepad-btn">
            {visibleButton &&
                < ButtonToolbar >
                    <Button variant="primary" style={{ marginRight: 15 }} onClick={getDefaultValue}>Cancel</Button>
                    <Button variant="primary" className="btn-blue" style={{ marginLeft: 10 }} onClick={saveNote}>Save</Button>
                </ButtonToolbar>
            }
        </div>
    </StyledNotepad >
};

const StyledNotepad = styled.div`
    .notepad {
        &-textarea {
            width: 100%;
            height: 100%;
            min-height: 380px;
            outline: none;
            resize: none;
            padding: 30px;
            margin-top: 20px;
            font-size: 16px;
            border: 2px solid #595d6e;
            border-radius: 3px;
        }

        &-btn {
            margin-top: 25px;
        }
    }
`
