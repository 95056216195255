import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import {
    TextField
} from '@material-ui/core'
import { Button } from 'react-bootstrap'
import { Formik } from "formik";
import { getAccount, updateAccount } from "../../../crud/info.crud";
import Logout from '../../auth/Logout'
import Loader from '../../../components/Loader'
import MuiPhoneNumber from 'material-ui-phone-number';
import { setAccount } from '../../../../app/store/actions';

export default (props) => {
    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState(null)
    const [data, setData] = useState({
        company_name: '',
        phone: '',
        city: '',
        addressLine: '',
        state: '',
        postCode: '',
        website: ''
    })
    const [errors, setErrors] = useState({})

    const [loaderAccount, setLoaderAccount] = useState(true)
    const [loaderUpdateAccount, setLoaderUpdateAccount] = useState(false)

    useEffect(() => {
        setLoaderAccount(true)
        getAccount()
            .then(res => {
                setLoaderAccount(false);
                dispatch(setAccount(res.data.data));
                setData({
                    company_name: res.data.data.company_name,
                    phone: res.data.data.phone,
                    city: res.data.data.address && res.data.data.address.city ? res.data.data.address.city : '',
                    addressLine: res.data.data.address && res.data.data.address.addressLine ? res.data.data.address.addressLine : '',
                    state: res.data.data.address && res.data.data.address.state ? res.data.data.address.state : '',
                    postCode: res.data.data.address && res.data.data.address.postCode ? res.data.data.address.postCode : '',
                    website: res.data.data.address && res.data.data.address.website ? res.data.data.address.website : ''
                })
            })
            .catch(({ response }) => {
                setLoaderAccount(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
            })
        // eslint-disable-next-line
    }, [])

    return (
        <StyledBusinessInfo>
            <Loader visible={loaderAccount || loaderUpdateAccount} />
            {redirect}
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validate={values => {
                    const temp = {};
                    // company_name: '',
                    // phone: '',
                    // city: '',
                    // addressLine: '',
                    // state: '',
                    // postCode: '',
                    // website: ''
                    if (values.company_name && (values.company_name.length > 50)) {
                        temp.company_name = 'Field is not valid'
                    } else if (values.company_name && (values.company_name.length < 2)) {
                        temp.company_name = 'The Company name should be at least 2 of characters'
                    }
                    if (values.city && (values.city.length > 50)) {
                        temp.city = 'Field is not valid'
                    } else if (values.city && (values.city.length < 2)) {
                        temp.city = 'Please fill the valid City'
                    }
                    if (values.addressLine && (values.addressLine.length > 120)) {
                        temp.addressLine = 'Field is not valid'
                    } else if (values.addressLine && (values.addressLine.length < 2)) {
                        temp.addressLine = 'Please fill the valid Address'
                    }
                    if (values.state && (values.state.length > 50)) {
                        temp.state = 'Please fill the valid State'
                    } else if (values.state && (values.state.length < 2)) {
                        temp.state = 'Please fill the valid State'
                    }

                    if (values.website && (values.website.length > 253 || values.website.length < 2)) {
                        temp.website = 'Field is not valid'
                    } else if (values.website && (
                        values.website.indexOf('https://') !== 0 &&
                        values.website.indexOf('http://') !== 0 &&
                        values.website.indexOf('www.') !== 0
                    )) {
                        temp.website = 'Invalid website address'
                    }

                    if (values.postCode && (values.postCode.length > 5 || values.postCode.length < 1)) {
                        temp.postCode = 'Field is not valid'
                    } else if (/[^0-9]/g.test(values.postCode)) {
                        temp.phone = 'Invalid value'
                    }

                    if (values.phone && (values.phone.length > 19 || values.phone.length < 7)) {
                        temp.phone = 'The phone must be between 7 and 15 characters.'
                    } else if (/[^0-9 ()+-]/g.test(values.phone)) {
                        temp.phone = 'Phone number is invalid'
                    }

                    setErrors(temp)
                    return temp;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setLoaderUpdateAccount(true)
                    updateAccount({
                        company_name: values.company_name,
                        phone: values.phone,
                        address: {
                            addressLine: values.addressLine,
                            city: values.city,
                            state: values.state,
                            postCode: values.postCode,
                            website: values.website
                        }
                    })
                        .then(res => {
                            setLoaderUpdateAccount(false)
                            setSubmitting(false)
                            dispatch(AlertState.actions.alert({
                                text: 'Account info is updated',
                                variant: true
                            }));
                        })
                        .catch(({ response }) => {
                            response &&
                                response.data &&
                                dispatch(AlertState.actions.alert({
                                    text: response.data.message || response.data.error,
                                    variant: false
                                }));
                            setLoaderUpdateAccount(false)
                            if (response && (response.status === 401)) {
                                setRedirect(<Logout />)
                            }
                            setErrors({
                                phone: response.data.errors.phone && response.data.errors.phone[0]
                            })
                            setSubmitting(false);
                            setStatus(response.data.message)
                        })
                }}
            >
                {({
                    values,
                    status,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form
                        noValidate={true}
                        autoComplete="off"
                        className="kt-form root col-12 col-md-6"
                        onSubmit={handleSubmit}
                    >

                        <TextField
                            key='29'
                            variant="outlined"
                            type="text"
                            label="Company Name"
                            margin="normal"
                            className="kt-width-full"
                            name="company_name"
                            inputProps={{
                                maxLength: 50
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.company_name}
                            helperText={touched.company_name && errors.company_name}
                            error={Boolean(touched.company_name && errors.company_name)}
                        />
                        <MuiPhoneNumber
                            key='30'
                            variant="outlined"
                            type="text"
                            label="Phone"
                            margin="normal"
                            className="kt-width-full"
                            name="phone"
                            inputProps={{
                                maxLength: 19
                            }}
                            onBlur={handleBlur}
                            onChange={(value) => handleChange({ target: { value, name: 'phone', id: 'phone' } })}
                            value={values.phone}
                            helperText={touched.phone && errors.phone}
                            error={Boolean(touched.phone && errors.phone)}
                        />
                        <TextField
                            key='31'
                            variant="outlined"
                            type="text"
                            label="Address"
                            margin="normal"
                            className="kt-width-full"
                            name="addressLine"
                            inputProps={{
                                maxLength: 120
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.addressLine}
                            helperText={touched.addressLine && errors.addressLine}
                            error={Boolean(touched.addressLine && errors.addressLine)}
                        />
                        <TextField
                            key='32'
                            variant="outlined"
                            type="text"
                            label="City"
                            margin="normal"
                            className="kt-width-full"
                            name="city"
                            inputProps={{
                                maxLength: 50
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            helperText={touched.city && errors.city}
                            error={Boolean(touched.city && errors.city)}
                        />
                        <TextField
                            key='33'
                            variant="outlined"
                            type="text"
                            label="State"
                            margin="normal"
                            className="kt-width-full"
                            name="state"
                            inputProps={{
                                maxLength: 50
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.state}
                            helperText={touched.state && errors.state}
                            error={Boolean(touched.state && errors.state)}
                        />
                        <TextField
                            key='34'
                            variant="outlined"
                            type="text"
                            label="Zipcode"
                            margin="normal"
                            className="kt-width-full"
                            name="postCode"
                            inputProps={{
                                maxLength: 5
                            }}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                handleChange({ target: { value: e.target.value.replace(/[^0-9]/gim, ''), name: 'postCode', id: 'postCode' } })
                            }}
                            value={values.postCode}
                            helperText={touched.postCode && errors.postCode}
                            error={Boolean(touched.postCode && errors.postCode)}
                        />
                        <TextField
                            key='35'
                            variant="outlined"
                            type="url"
                            label="Website"
                            margin="normal"
                            className="kt-width-full"
                            name="website"
                            inputProps={{
                                maxLength: 253
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.website}
                            helperText={touched.website && errors.website}
                            error={Boolean(touched.website && errors.website)}
                        />
                        <div className="kt-login__actions" style={{ marginTop: 20 }}>
                            <Button
                                className="btn-blue"
                                variant="primary"
                                disabled={isSubmitting}
                                type="submit"
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </StyledBusinessInfo>
    )
}

const StyledBusinessInfo = styled.div`

    display: flex;
    justify-content: space-between;
`