import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck';
import { Checkbox } from '@material-ui/core';
import { addPrivileges, removePrivileges } from "../../../crud/info.crud";
import Logout from '../../../pages/auth/Logout';
import { Redirect } from 'react-router-dom';

export default ({ setLoader, setRedirect, data, setData, loaderPrivileges }) => {
    const dispatch = useDispatch();
    const [loaderEditPrivileges, setLoaderEditPrivileges] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.activation?.is_activate === true));
    const [title, setTitle] = useState([
        {
            title: 'Create New Profiles',
            id: "create profiles"
        },
        {
            title: 'Activate Profiles',
            id: "activate profiles"
        },
        {
            title: 'Archive Profiles',
            id: "archive profiles"
        },
        {
            title: 'Delete Profiles',
            id: "delete profiles"
        },
        {
            title: 'Grant Client Access to Profiles',
            id: "access profiles"
        },
        {
            title: 'Update Testing Metrics',
            id: "update testing metrics"
        },
    ]);

    useEffect(() => {
        if (crm) {
            setTitle(prev => {
                return [
                    ...prev,
                    {
                        title: 'Create Profile from CRM',
                        id: "create_profile_from_crm"
                    },
                    {
                        title: 'Update Profile Info CRM Mapping',
                        id: "update_profile_info_crm_mapping"
                    },
                ]
            })
        }
    }, [crm]);

    useEffect(() => {
        setLoader(loaderPrivileges || loaderEditPrivileges)
    }, [loaderPrivileges, loaderEditPrivileges]);

    const onChange = (role, typeId, action) => {
        setLoaderEditPrivileges(true)
        let req = {
            role: role,
            permission: typeId
        }
        action ?
            removePrivileges(req)
                .then(res => {
                    setLoaderEditPrivileges(false)
                    setData(res.data.data)
                    dispatch(AlertState.actions.alert({
                        text: 'Privilege is removed',
                        variant: true
                    }));
                })
                .catch(({ response }) => {
                    response &&
                        response.data &&
                        dispatch(AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false
                        }));
                    setLoaderEditPrivileges(false)
                    if (response && (response.status === 401)) {
                        setRedirect(<Logout />)
                    } else if (response && (response.status === 403)) {
                        setRedirect(<Redirect to="/profile-list" />)
                    }
                })
            :
            addPrivileges(req)
                .then(res => {
                    setLoaderEditPrivileges(false)
                    setData(res.data.data)
                    dispatch(AlertState.actions.alert({
                        text: 'Privilege is added',
                        variant: true
                    }));
                })
                .catch(({ response }) => {
                    response &&
                        response.data &&
                        dispatch(AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false
                        }));
                    setLoaderEditPrivileges(false)
                    if (response && (response.status === 401)) {
                        setRedirect(<Logout />)
                    } else if (response && (response.status === 403)) {
                        setRedirect(<Redirect to="/profile-list" />)
                    }
                });
    }

    return (
        <table className="settingtable">
            <thead>
                <tr>
                    <td>Privilege</td>
                    {data.map((item, index) => <td key={index}>{item.title}</td>)}
                </tr>
            </thead>
            <tbody>
                {title.map((item, index) => {
                    return (
                        <TableRow
                            key={`privileges-${index}`}
                            item={item}
                            data={data}
                            change={onChange}
                        />
                    )
                })}
            </tbody>
        </table>
    )
};

const TableRow = ({ item, data, change }) => {
    const getCheck = (name, key) => {
        const perm = data.find(elem => elem.name === name)
        return perm?.permissions?.find(elem => elem.name === item.id)[key]
    };

    return (
        <tr>
            <td>{item.title}</td>
            {
                ['admin', 'supervisor', 'staff'].map(elem => {
                    return (
                        <td key={elem + item.id}>
                            <Checkbox
                                checked={!!getCheck(elem, elem)}
                                onChange={e => change(elem, item.id, !!getCheck(elem, elem))}
                                value='false'
                                color="primary"
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                            />
                        </td>
                    )
                })
            }
        </tr>
    )
};
