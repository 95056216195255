import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import Datepicker from '../../CreateReport/DatePicker';
import { getReport } from '../../../../../crud/analytics.crud';
import moment from 'moment'
import './updatemodal.scss';

export const UpdateModal = ({ isUpdateModal, setIsUpdateModal, handleUpdate, check, errors,setErrorsUpdate }) => {
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [disabled,setDisabled] = useState(false)

    useEffect(() => {
        check[0] && getReport(check[0])
            .then(res => {
                setDate(moment(res.data.data.report_date, 'YYYY-MM-DD').format('MM/DD/YYYY'))
                console.log("Data",date)
                setName(res.data.data.name)
                setDescription(res.data.data.description)
                setDisabled(true)
            })
          .catch(({response}) =>{
              if(response.status === 404 ){
                  //setUserExist(true)
                  //setOpenModal(true)
                  //getData()
              }
          })
    }, [check])

    return (
        <Modal
            show={isUpdateModal}
            onHide={() => {
                setErrorsUpdate({})
                setIsUpdateModal(false)}}
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Report</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Datepicker
                    value={date}
                    onChange={setDate}
                    label={'Report Date'}
                    format={'MM/DD/YYYY'}
                    width={'100%'}
                    helperText={errors.report_date ? errors.report_date : ''}
                    error={Boolean(errors.report_date)}
                    disabled = {disabled}
                />

                <TextField
                    key={"name"}
                    className="formControl mobile-vis"
                    label="Report Name"
                    margin="normal"
                    inputProps={{
                        maxLength: 120
                    }}
                    variant="outlined"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    error={errors.name}
                    helperText={errors.name ? errors.name : ''}
                />

                <TextField
                    multiline
                    rows={4}
                    key={"description"}
                    className="formControl mobile-vis"
                    label="Description"
                    margin="normal"
                    inputProps={{
                        maxLength: 300
                    }}
                    variant="outlined"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                      setErrorsUpdate({})
                      setIsUpdateModal(false)
                    }}
                >Close</Button>
                <button
                    className="btn-blue btn btn-primary"
                    onClick={() => handleUpdate(date, name, description)}
                >Save</button>
            </Modal.Footer>
        </Modal>
    )
}