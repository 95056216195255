import {
    // eslint-disable-next-line
    Portlet, PortletBody, PortletHeader
} from "../../partials/content/Portlet";
import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import DataOutput from '../../components/DataOutput'
import UserInfo from '../../components/UserInfo'
import {
  getGoalsList,
  getTestingCategories,
  fieldGoalsByCategory,
  getGoalsById,
  updateGoalsByCategory,
  deleteGoals
} from '../../crud/profile.crud'
import Logout from '../auth/Logout'
import Loader from '../../components/Loader'
import RecordsList from './Records/RecordsList'
import queryString from 'query-string'


export default (props) => {
  const queryParams=queryString.parse(props.location.search);
  const id = props.match.params.id
  const [logout, setLogout] = useState(null)
  const [visLoader, setVisLoader] = useState(false)
  const [menu, setMenu] = useState([])
  const [header, setHeader] = useState([])
  const [data, setData] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [addInputs, setAddInputs] = useState([])
  const [updateInputs, setUpdateInputs] = useState([])
  const [par, setPar] = useState({
    category: '',
    page: 1,
    perPage: '10',
    sort: 'name',
    sortBy: 'asc'
  })
  
  useEffect(() => {
    loadMenu();
    loadData();
    // eslint-disable-next-line
  },[]);
  
  
  const loadMenu = () => {
    setVisLoader(true)
    getTestingCategories(id)
      .then(res => {
        // setCheck([])
        setVisLoader(false)
        setMenu(res.data.data)
      })
      .catch(({response}) => {
        setVisLoader(false)
        if (response && (response.status === 401)) {
          setLogout(<Logout/>)
        }
      })
  }
  
  const loadData = () => {
    if(!par.category) return false
    setVisLoader(true)
    getGoalsList(id, par)
      .then(res => {
        // setCheck([])
        setVisLoader(false)
        setHeader(res.data.data.testing.header)
        setData(res.data.data.testing.rows)
        setLastPage(res.data.data.last_page)
      })
      .catch(({response}) => {
        setVisLoader(false)
        if (response && (response.status === 401)) {
          setLogout(<Logout/>)
        }
      })
  }
  
  const addModalFields = (category, subcategory ='') => {
      category && fieldGoalsByCategory(id, category, subcategory)
        .then(res => {
          setAddInputs(res.data.data)
        })
        .catch(({ response }) => {
          setVisLoader(false)
          if (response && (response.status === 401)) {
            setLogout(<Logout />)
          }
        });
  }
  const updateModalFields = (goalId, category, subcategory ='') => {
      category && getGoalsById(id, goalId, category, subcategory)
        .then(res => {
          // console.log(res.data.data)
          setUpdateInputs(res.data.data)
        })
        .catch(({ response }) => {
          setVisLoader(false)
          if (response && (response.status === 401)) {
            setLogout(<Logout />)
          }
        });
  }
  
  
  
  const createRecord = (category, data) => {
    if(!data) return false;
    setVisLoader(true)
    updateGoalsByCategory(id, category, data)
      .then(res => {
        setVisLoader(false)
        // console.log(res)
        loadData()
        // status && setLogout(<Redirect to={`/profile/${id}/goals`} />)
      })
      .catch(({ response }) => {
        setVisLoader(false)
        if (response && (response.status === 401)) {
          setLogout(<Logout />)
        }
      });
  }
  
  const updateRecord = (category, data) => {
    if(!data) return false;
    setVisLoader(true)
    updateGoalsByCategory(id, category, data)
      .then(res => {
        setVisLoader(false)
        // console.log(res)
        loadData()
        // status && setLogout(<Redirect to={`/profile/${id}/goals`} />)
      })
      .catch(({ response }) => {
        setVisLoader(false)
        if (response && (response.status === 401)) {
          setLogout(<Logout />)
        }
      });
  }
  
  const deleteRecord = (data) => {
    if(!data) return false;
    setVisLoader(true)
    deleteGoals(id, data)
      .then(res => {
        setVisLoader(false)
        // console.log(res)
        loadData()
        // status && setLogout(<Redirect to={`/profile/${id}/goals`} />)
      })
      .catch(({ response }) => {
        setVisLoader(false)
        if (response && (response.status === 401)) {
          setLogout(<Logout />)
        }
      });
  }
  return(
    <StyledMetrics>
      {logout}
      <Loader visible={visLoader}/>
      
      <div className="row flex-md-row-reverse" style={{ marginBottom: '20px'}}>
        <DataOutput id={id} />
        <UserInfo id={id} />
      </div>
    
      <Portlet>
        <PortletBody>
          <RecordsList
            data={data}
            header={header}
            loadData={loadData}
            menu={menu}
            lastPage={lastPage}
            par={par}
            setPar={setPar}
            addInputs={addInputs}
            addModalFields={addModalFields}
            updateInputs={updateInputs}
            updateModalFields={updateModalFields}
            modalLabel={'Add/Edit Goal'}
            createRecord={createRecord}
            updateRecord={updateRecord}
            deleteRecord={deleteRecord}
            category={queryParams.category ?? '' }
            subcategory={queryParams.subcategory ?? '' }
          >
          </RecordsList>
        </PortletBody>
      </Portlet>
    </StyledMetrics>
  )
}

const StyledMetrics = styled.div`
`

