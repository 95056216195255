import React, { useState } from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap'

export default ({
    keys,
    setKey,
    index,
    item,
    Content,
    setMetric,
    setId
}) => {
    const [modal, setModal] = useState(false)

    return <div>
        <div 
            className="acord-title acord-none-dt" 
            onClick={() => {
                setModal(true)
            }}>
            {item.label}
        </div>
        { keys === index && <Content />}

        <Modal 
            size="lg" 
            show={modal} 
            onHide={() => {
                setModal(false)
                setKey(null)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{item.label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {item.childs && item.childs.map((elem, index2) => {
                    return (
                        <div 
                            className="acord-title"
                            key={index2} 
                            onClick={() => {
                                setKey(index)
                                setMetric(elem.metric ? elem.metric : elem.childs[0].metric)
                                setModal(false)
                                setId([index, index2])
                            }}
                        >
                            {elem.label}
                        </div>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={() => {
                        setModal(false)
                        setKey(null)
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}