import React, { useState } from 'react';
import { TableRow } from './TableRow';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

const defRow = [
    {
        label: 'TrainerMetrics User',
        left: true,
    },
    {
        label: 'CRM User',
        left: true,
    },
    {
        label: 'Updates In',
        left: true,
        sort: false
    },
    {
        label: 'Updates Out',
        left: true,
        sort: false
    },
];

export const Table = ({ data, setData, setChanged }) => {
    const [sort, setSort] = useState([]);

    const sortEdit = item => {
        item.sort && setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    };

    return (
        <div className="settingtable nowrap">
            <table>
                <thead>
                    <tr>
                        {defRow.map((item, index) => {
                            return (
                                <td
                                    key={index}
                                    onClick={() => sortEdit(item)}
                                    className={`${item.left ? 'left-content' : ''}${sort[0] === item.value ? ' active' : ''}`}
                                    style={{ cursor: !item.sort && 'default' }}
                                >
                                    {item.label}
                                    {item.sort &&
                                        <ArrowDown
                                            className={`sort-arrow
                                                ${sort[0] === item.value
                                                    ? ' active'
                                                    : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}
                                            `}
                                        />
                                    }
                                </td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item) => {
                        return (
                            <TableRow
                                item={item}
                                setData={setData}
                                setChanged={setChanged}
                            />
                        )
                    })}
                </tbody>
            </table>

            {!data.length &&
                <div
                    style={{
                        margin: '20px auto',
                        textAlign: 'center'
                    }}
                >
                    No data available in table
                </div>
            }
        </div>
    )
};