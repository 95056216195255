import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL

export function getPdfName(id, check) {
    return axios(`${BASE_URL}profile/${id}/report/pdf?metric=${check}`)
}
export function getPdfAccessenent(id, accessenent) {
    return axios(`${BASE_URL}profile/${id}/report/pdf/${accessenent}`)
}
export function getPdfCard(id, accessenent) {
    return axios(`${BASE_URL}profile/${id}/testings/card?assessment=${accessenent}`)
}