import React, { useState } from 'react';
import './details.scss';
import { ReportDetails } from './ReportDetails/ReportDetails';
import { Schedule } from './Schedule/Schedule';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

export const Details = ({ data, meta, setMeta, role, usersVis, groupsVis }) => {
    const [isReportDetails, setIsReportDetails] = useState(true);

    return (
        <>
            <div className="kt-portlet generated-report-details">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">REPORT DETAILS</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="buttons-container">
                            <div className="toolbarm">
                                <div className={`toolbarm-elem ${!isReportDetails ? 'active' : ''}`} onClick={() => setIsReportDetails(false)}>
                                    <EqualizerIcon />
                                </div>
                                <div className={`toolbarm-elem ${isReportDetails ? 'active' : ''}`} onClick={() => setIsReportDetails(true)}>
                                    <FormatAlignJustifyIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="kt-portlet__body">
                    {isReportDetails
                        ? (
                            <ReportDetails
                                data={data}
                                meta={meta}
                                setMeta={setMeta}
                                role={role}
                                usersVis={usersVis}
                                groupsVis={groupsVis}
                            />
                        )
                        : (
                            <Schedule
                                data={data}
                            />
                        )}
                </div>
            </div>

        </>
    )
}