import React,{useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 160,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

export const DropdownMultiselect = ({ label, options, error, setData, name, data }) => {

    const classes = useStyles();
    const handleChange = (e, value) => {
        if (value.findIndex(i => i.id === 0) !== -1) {
            setData(prev => ({ ...prev, [name]: options }));
        } else {
            setData(prev => ({ ...prev, [name]: value }));
        }
    };
    useEffect(()=>{
        const element = document.querySelectorAll("#tags-outlined")
            if(options.length === data?.groups?.length && options.length && name === 'groups' ) {
                if(element[0]) element[0].style.display = "none"
            }
            if (options.length === data.users?.length && options.length && name === 'users' ) {
                if (data.users.length === options.length && name === 'users') {
                    if (element.length === 2) {
                        element[1].style.display = "none";
                    } else {
                        element[0].style.display = "none";
                    }
                }
            }
    },[data, options])

    return (
        <div className={classes.root}>
            <Autocomplete
                key={data[name] ? data[name].map(i => i.id).join('-') : '0'}
                multiple
                limitTags = {1}
                style={{ width: 260 }}
                id="tags-outlined"
                value={data[name] || []}
                options={[{ title: 'Select all', id: 0 }, ...options] || []}
                getOptionLabel={(option) => option.title}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, startAdornment: {} }}
                        variant="outlined"
                        label={label || 'select'}
                        placeholder="Search"
                        error={!!error}
                        helperText={error ? error : ''}
                    />
                )}
            />
        </div>
    );
}