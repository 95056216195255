import React, { useState } from 'react';
import styled from 'styled-components'
import { Redirect } from "react-router-dom";
import { Checkbox } from '@material-ui/core/';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

export default ({ data, check, setCheck, sort, setSort }) => {
  const [redirect, setRedirect] = useState(null)

  const defRow = [
    {
      label: "Group Name",
      value: 'name',
      left: true
    },
    {
      label: "Group Description",
      value: 'description',
      left: true
    },
    {
      label: "Supervisors",
      value: 'supervisor',
      left: true
    },
    {
      label: "Total Staff",
      value: 'total_staff',
      left: true
    },
    {
      label: "Total Profiles",
      value: 'total_profiles',
      left: true
    }
  ]

  const sortEdit = item => {
    setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
  }

  const chbAll = () => {
    if (check.length === data.length) {
      setCheck([])
    } else {
      setCheck(data.map(item => item.id))
    }
  }
  
  return (
    <StyledTablePl className="settingtable nowrap">
      { redirect }
      <table>
        <thead>
          <tr>
            <td>
              <Checkbox
                checked={check.length === data.length}
                onChange={chbAll}
                color="primary"
              />
            </td>
            {
              defRow.map((item, index) => {
                return (
                  <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content'  : ''}${sort[0] === item.value ? ' active' : ''}`}>
                    { item.label }          
                    <ArrowDown 
                      className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`} 
                    />
                  </td>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => {
              return (
                <TableRow
                  item={item}
                  key={index + item.name}
                  check={check}
                  setCheck={setCheck}
                  setRedirect={setRedirect}
                />
              )
            })
          }
          
        </tbody>
      </table>
      {
        data.length === 0
          ?  (
            <div
              style={{
                margin: '20px auto',
                textAlign: 'center'
              }}
            >
              No data available in table
            </div>
          ) : null
      }
    </StyledTablePl>
  )
}

const StyledTablePl = styled.div`
  cursor: pointer;
  
  td.active {
    font-weight: 600;
  }
  
  .sort-arrow {
    opacity: 0.7;
    transition: all .3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg)
    }
  }
  table thead tr td.left-content {
    text-align: left;
  }
  table tbody tr td.left-content {
    text-align: left;
  }
`

const TableRow = ({item, check, setCheck, setRedirect}) => {
  const getCheck = () => {
    return check.findIndex(id => id === item.id) !== -1
  }

  const editCheck = () => {
    let temp = [...check]
    temp.splice(check.indexOf(item.id), 1)
    getCheck()
      ? setCheck(temp)
      : setCheck([...check, item.id])
  }

  const goTo = () => {
    setRedirect(<Redirect to={`/user-list?group=${item.id}`} />)
  }

  return (
    <Row>
      <td>
        <Checkbox
          checked={getCheck()}
          onChange={() => editCheck()}
          color="primary"
        />
      </td>
      <td className="left-content" onClick={goTo}>    
        {item.name}
      </td>
      <td onClick={goTo} className="left-content">
        {item.description || ''}
      </td>
      <td onClick={goTo} className="left-content">    
        { item.supervisor.join(', ')}
      </td>
      <td className="left-content" onClick={goTo}>
      { item.total_staff || '0' }
      </td>
      <td className="left-content" onClick={goTo}>
        { item.total_profiles || '0' }
      </td>
    </Row>
  )
}

const Row = styled.tr`
  .profile-photo {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
  }
`;
