import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pp: false,
      tos: false
    }
  }

  editpp = (status) => {
    this.setState({pp: status})
  }
  edittos = (status) => {
    this.setState({tos: status})
  }

  render() {
    const today = new Date().getFullYear();
    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop footer-auth-hidden`}
        id="kt_footer"
        style={{
          display: 'flex',
          flexDirection: 'row',
          background: 'white',
          justifyContent: 'center',
          width: '100%',
          ...this.props.style
        }}
      >

        <div className={`kt-container ${this.props.footerContainerClasses}`} style={{width: 'auto'}}>
          <div className="kt-footer__copyright" style={{padding: 0, fontFamily: 'Raleway'}}>
            {today.toString()}&nbsp;&copy;&nbsp;
            <a
              href="https://www.trainermetrics.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-link"
              style={{fontFamily: 'Raleway'}}
            >
              TrainerMetrics
            </a>
          </div>
          <div className="kt-footer__menu" style={{padding: 0}}>
            <a
              href="https://www.trainermetrics.com/privacy-policy/"
              style={{cursor: 'pointer'}}
              rel="noopener noreferrer"
              target="_blank"
              className="kt-footer__menu-link kt-link"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.trainermetrics.com/terms-of-service/"
              style={{cursor: 'pointer'}}
              onClick={() => this.setState({pp: true})}
              rel="noopener noreferrer"
              target="_blank"
              className="kt-footer__menu-link kt-link"
            >
              Terms of Service
            </a>
            <a
              href="https://trainermetrics.zendesk.com/hc/en-us"
              style={{cursor: 'pointer'}}
              onClick={() => this.setState({pp: true})}
              rel="noopener noreferrer"
              target="_blank"
              className="kt-footer__menu-link kt-link"
            >
              Contact 
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
