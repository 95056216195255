import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import styled from 'styled-components'
import PARrow from './PARRow'
import Logout from '../../../pages/auth/Logout'
import Loader from '../../../components/Loader'
import { getParQ, updateProfile } from '../../../crud/profile.crud'

export default ({ parqTemp, id, setParqTemp }) => {

    const dispatch = useDispatch();
    const [parqData, setParqData] = useState([])
    const [logout, setLogout] = useState(null)
    const [parq, setParq] = useState([])

    const [loaderParQ, setLoaderParQ] = useState(false)
    const [loaderUpdateParQ, setLoaderUpdateParQ] = useState(false)

    useEffect(() => {
        setParq(parqTemp)
    }, [parqTemp])

    useEffect(() => {
        updateParq()
    }, [])

    const updateParq = () => {
        setLoaderParQ(true)
        getParQ()
            .then(res => {
                setLoaderParQ(false)  
                setParqData(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderParQ(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if(response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                } 
            })
    }

    const update = (id, value) => {
        let tempData = parq && [...parq].filter(item => !!item)
        // eslint-disable-next-line
        let index = tempData.findIndex(item => item.id == id)
        index >= 0
            ? tempData[index] = {
                    id: id,
                    value: value
                }
            : tempData.push({
                    id: id,
                    value: value
                })

        setParq(tempData)
    }

    const submit = (e) => {
        e.preventDefault()
        setLoaderUpdateParQ(true)
        updateProfile(id, {parq: parq.map(item => {
            return {
                parq_id: item.id,
                value: item.value
            }
        })})
            .then(({response}) => {
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderUpdateParQ(false)
                // console.log(res)
                setParqTemp(parq)
                dispatch(AlertState.actions.refreshStatistic())
                dispatch(AlertState.actions.alert({
                    text: 'PAR-Q is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                setLoaderUpdateParQ(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if(response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                } 
            })
    }

    return (
        <StyledPARQ className="par">
            <Loader visible={ loaderParQ || loaderUpdateParQ } />
            { logout }

            <div className="par-row">
                <div className="par-row__col col1">
                    Yes
                </div>
                <div className="par-row__col col2">
                    No
                </div>
                <div className="par-row__col col3">
                    Questions
                </div>
            </div>
            {parqData.map((item, index) => {
                return (
                    <PARrow
                        key={`par-${index}`}
                        data={item}
                        parq={parq}
                        update={update}
                    />
                )
            })}
            <div className="kt-login__actions" style={{marginTop: 20}}>
                <button
                    className="btn btn-blue btn-elevate kt-login__btn-primary"
                    onClick={submit}
                >
                    Save
                </button>
            </div>
        </StyledPARQ>
    )
}

const StyledPARQ = styled.div`
    min-width: 600px;
    
    .par {

        &-row {
            display: flex;
            height: 50px;
            border-bottom: 1px solid #dee2e6;
            margin-bottom: 10px;

            &__col {
                font-size: 14px;
                font-weight: 450;
                display: flex;
                align-items: center;
                justify-content: center;

                &.col1 {
                    min-width: 50px;
                    width: 50px;
                }

                &.col2 {
                    min-width: 50px;
                    width: 50px;

                }

                &.col3 {
                    width: 100%;
                    justify-content: flex-start;
                    padding-left: 20px;
                }
            }
        }
    }
`
