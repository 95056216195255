import React from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'react-bootstrap'

export default ({modal, setModal, handleDelete}) => {
    return <PreviewModalStyled>
        <Modal
            show={modal}
            onHide={() => setModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this file?
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setModal(false)}
                >Cancel</Button>
                <Button
                    className="btn-blue btn btn-primary"
                    onClick={() => handleDelete()}
                >Delete</Button>
            </Modal.Footer>
        </Modal>
    </PreviewModalStyled>
}

const PreviewModalStyled = styled.div`

`
