import React from 'react'
import styled from 'styled-components'

export default ({data}) => {
    return (
        <StyledHPM className="settingtable">
            <thead>
                <tr>
                    <td>METRIC</td>
                    <td>CLASSIFICATION</td>
                    <td>DATE</td>
                    <td>DAYS</td>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.metric}</td>
                            <td className={"theme-data"}>{item.classification || "--"}</td>
                            <td>{item.date || "--"}</td>
                            <td className={"theme-data"}>{item.days || "--"}</td>
                        </tr>
                    )
                })

                }
            </tbody>
        </StyledHPM>
    )
}

const StyledHPM = styled.table`

`
