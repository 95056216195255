import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Select,
    FormHelperText
} from '@material-ui/core'
import { 
    getIntegration,
    updateIntegration
} from '../../../crud/info.crud'
import { 
    Portlet,
    PortletBody,
    PortletHeader
} from '../../../partials/content/Portlet'
import Loader from '../../../components/Loader'
import Logout from '../../auth/Logout'

export default () => {
    const dispatch = useDispatch();
    const [input, setInput] = useState([])
    const [initialValues, setInitialValues] = useState({})
    const [loader, setLoader] = useState(true)
    const [redirect, setRedirect] = useState(null)
    const [errors, setErrors] = useState({})

    const refreshIntegration = () => {
        getIntegration()
            .then(res => {
                setLoader(false)
                let temp = {
                    [res.data.data[0].name]: {},
                    [res.data.data[1].name]: {}
                }

                for (let i = 0; i < res.data.data[0].inputs.length; i++) {
                    temp[res.data.data[0].name][res.data.data[0].inputs[i].name] = res.data.data[0].inputs[i].value
                }

                for (let i = 0; i < res.data.data[1].inputs.length; i++) {
                    temp[res.data.data[1].name][res.data.data[1].inputs[i].name] = res.data.data[1].inputs[i].value
                }
                setInitialValues(temp)
                setInput(res.data.data)

            })
            .catch(({ response }) => {
                setLoader(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            })
    }

    useEffect(() => {
        refreshIntegration()
    }, [])

    const handleChange = (newValue, name, load) => {
        const req = {
            ...initialValues,
            [name[0]]: {
                ...initialValues[name[0]],
                [name[1]]: newValue
            }
        }
        setInitialValues(req)

        load && submit(req, name[0])
    }

    const submit = (req, name) => {
        setErrors({})
        setLoader(true)
        updateIntegration(req)
            .then(res => {
                setLoader(false)
                dispatch(AlertState.actions.alert({
                    text: 'Integrations is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                setInitialValues({
                    ...initialValues,
                    [name]: {
                        ...initialValues[name],
                        enabled: 0
                    }
                })
                response && 
                response.data && 
                response.data.errors &&
                setErrors(response.data.errors)
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoader(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            })
    }
    

    return (
        <TabWrap className="row" style={{display: 'flex', justifyContent: 'space-between', margin: '0px'}}>
            <Loader visible={loader} />
            { redirect }
            {
                input.map((item, index) => {
                    return (
                        <Portlet className="info-block" key={index} style={{margin: '20px'}}>
                            <PortletHeader title={item.title} />
                            <PortletBody>
                                {
                                    item.inputs.map((elem, index2) => {
                                        return elem.type === 'checkbox'
                                            ? (
                                                <FormControl 
                                                    error={true}
                                                    className="form-control MuiFormControl-marginNormal"
                                                    key={`${index}${index2}`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={initialValues[item.name][elem.name] == 1} //eslint-disable-line
                                                                onChange={e => handleChange(parseInt(e.target.value), e.target.name.split('|'), true)}
                                                                value={initialValues[item.name][elem.name] == 1 ? 0 : 1} //eslint-disable-line
                                                                color="primary"
                                                                name={`${item.name}|${elem.name}`}
                                                            />
                                                        }
                                                        label={elem.title}
                                                    />
                                                </FormControl>
                                            ) : elem.type === 'select'
                                                ? (
                                                    <SelectComponent
                                                        initialValues={initialValues} 
                                                        handleChange={handleChange}
                                                        index={index}
                                                        index2={index2}
                                                        item={item}
                                                        elem={elem}
                                                        key={`${index}${index2}`}
                                                        error={!!errors[`${item.name}.${elem.name}`]}
                                                        helperText={errors[`${item.name}.${elem.name}`]}
                                                    />
                                                ) : (
                                                    <TextField
                                                        variant="outlined"
                                                        name={`${item.name}|${elem.name}`}
                                                        label={elem.title}
                                                        className='textField'
                                                        value={initialValues[item.name][elem.name]}
                                                        onBlur={() => submit(initialValues, item.name)}
                                                        onChange={e => handleChange(e.target.value, e.target.name.split('|'))}
                                                        margin="normal" 
                                                        inputProps={{
                                                            maxLength: 500
                                                        }}
                                                        key={`${index}${index2}`}
                                                        error={!!errors[`${item.name}.${elem.name}`]}
                                                        helperText={errors[`${item.name}.${elem.name}`]}
                                                    />
                                                )
                                    })
                                }
                            </PortletBody>
                        </Portlet>
                    )
                })
            }
        </TabWrap>
    )
}

const TabWrap = styled.div`
    .info-block {
    }
`

const SelectComponent = ({initialValues, handleChange, item, elem, index, index2, error, helperText}) => {
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    return (
        <FormControl variant="outlined" className='form-control MuiFormControl-marginNormal' key={`${index}${index2}`} error={error}>
            <InputLabel ref={inputLabel} htmlFor={`outlined-age-simple`}>
                {elem.title}
            </InputLabel>
            <Select
                value={initialValues[item.name][elem.name]}
                onChange={e => handleChange(e.target.value, e.target.name.split('|'), true)}
                input={<OutlinedInput labelWidth={labelWidth} name={`${item.name}|${elem.name}`} id={`outlined-age-simple`} />}
                className='selectEmpty'
            >
                {
                    elem.values.map((child, index3) => {
                        return (
                            <MenuItem value={child} key={`${index}${index2}${index3}`}>{child}</MenuItem>
                        )
                    })
                }
            </Select>
            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}