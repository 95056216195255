import React, { useState } from 'react';
import styled from 'styled-components'
import { Redirect } from "react-router-dom";
import { Checkbox } from '@material-ui/core/';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

export default ({ data, check, setCheck, sort, setSort, plan }) => {
  const [redirect, setRedirect] = useState(null)

  const defRow = [
    {
      label: "Name",
      value: 'name',
      left: true
    },
    {
      label: "Type",
      value: 'type',
      left: true
    },
    {
      label: "Group",
      value: 'groups',
      left: true,
      plan: 'Enterprise'
    },
    {
      label: "Last Login",
      value: 'last_login',
      left: true
    },
    {
      label: "Email",
      value: 'email',
      left: true
    }
  ]

  const sortEdit = item => {
    setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
  }

  const chbAll = () => {
    const admin = data.find(item => !item.is_super_admin)
    if (check.length === (!!admin ? data.length - 1 : data.length)) {
      setCheck([])
    } else {
      setCheck(data.filter(item => !item.is_super_admin).map(item => item.id))
    }
  }

  const chbStatus = () => {
    const admin = data.find(item => !item.is_super_admin)
    return check.length === (!!admin ? data.length - 1 : data.length)
  }

  return (
    <StyledTablePl className="settingtable nowrap">
      { redirect}
      <table>
        <thead>
          <tr>
            <td>
              <Checkbox
                checked={chbStatus()}
                onChange={chbAll}
                color="primary"
              />
            </td>
            {
              defRow.map((item, index) => {
                return item.plan
                  ? plan === item.plan
                    ? <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content' : ''}${sort[0] === item.value ? ' active' : ''}`}>
                      {item.label}
                      <ArrowDown
                        className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`}
                      />
                    </td>
                    : null
                  : <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content' : ''}${sort[0] === item.value ? ' active' : ''}`}>
                    {item.label}
                    <ArrowDown
                      className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`}
                    />
                  </td>
              })
            }
          </tr>
        </thead>
        <tbody>

          {data.map((item, index) => {
            return (
              <TableRow
                item={item}
                key={index + item.email}
                check={check}
                setCheck={setCheck}
                setRedirect={setRedirect}
                plan={plan}
              />
            )
          })
          }

        </tbody>
      </table>
      {
        data.length === 0
          ? (
            <div
              style={{
                margin: '20px auto',
                textAlign: 'center'
              }}
            >
              No data available in table
            </div>
          ) : null
      }
    </StyledTablePl>
  )
}

const StyledTablePl = styled.div`
  cursor: pointer;

  td.active {
    font-weight: 600;
  }
  
  .sort-arrow {
    opacity: 0.7;
    transition: all .3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg)
    }
  }
  table thead tr td.left-content {
    text-align: left;
  }
  table tbody tr td.left-content {
    text-align: left;
  }
`

const TableRow = ({ item, check, setCheck, setRedirect, plan }) => {
  const getCheck = () => {
    return check.findIndex(id => id === item.id) !== -1
  }

  const editCheck = () => {
    let temp = [...check]
    temp.splice(check.indexOf(item.id), 1)
    getCheck()
      ? setCheck(temp)
      : setCheck([...check, item.id])
  }

  const goTo = () => {
    (item.type === 'staff') && setRedirect(<Redirect to={`/profile-list?user=${item.id}`} />)
  }

  return (
    <Row>
      <td>
        {!item.is_super_admin ?
          <Checkbox
            checked={getCheck()}
            onChange={() => editCheck()}
            color="primary"
          /> : <div style={{ height: '38px' }} />
        }
      </td>
      <td className="left-content" onClick={goTo}>
        {item.name}
      </td>
      <td onClick={goTo} className="left-content">
        {
          item.type === 'staff'
            ? 'Staff'
            : item.type === 'admin'
              ? 'Admin'
              : item.type === 'supervisor'
                ? 'Supervisor'
                : ''
        }
      </td>
      { plan === 'Enterprise' && <td onClick={goTo} className="left-content">
        {item.groups || ''}
      </td>}
      <td className="left-content" onClick={goTo}>
        {item.last_login || ''}
      </td>
      <td className="left-content" onClick={goTo}>
        {item.email || ''}
      </td>
    </Row>
  )
}

const Row = styled.tr`
  .profile-photo {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
  }
`