import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useIsMount } from '../../../../hooks/useIsMount';
import { Redirect } from 'react-router';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { Button } from 'react-bootstrap';
import { TextField } from "@material-ui/core";
import Dropdown from '../../../../components/DropdownComponent';
import { Table } from './Table/Table';
import Loader from '../../../../components/Loader';
import { fetchForms, deleteForms } from '../../../../crud/forms';
import Pagination from '../../../../components/Pagination';
import { getUsers } from '../../../../crud/info.crud';
import ConfirmModal from '../../../../components/ConfirmationModal';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import { FormViewModal } from './FormViewModal/FormViewModal';
import './formslist.scss';

export const FormsList = () => {
    const history = useHistory();
    const isFirstRender = useIsMount();
    const dispatch = useDispatch();
    const subscription = useSelector(state => state.user_info.subscription);
    const [redirect, setRedirect] = useState(false);
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [show, setShow] = useState(10);
    const [page, setPage] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [check, setCheck] = useState([]);
    const [filter, setFilter] = useState(null);
    const [users, setUsers] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [sort, setSort] = useState([null, null]);
    const [formViewModal, setFormViewModal] = useState(false);
    const [currentForm, setCurrentForm] = useState(null);

    // protects route
    useEffect(() => {
        if (subscription.plan && subscription.plan.name !== 'Gym' && subscription.plan.name !== 'Enterprise') {
            let errorText = 'User does not have the right plan';
            setRedirect(true);
            dispatch(AlertState.actions.alert({
                text: errorText,
                variant: false,
            }));
        }
    }, [subscription]);

    const loadData = () => {
        setLoader(true);
        fetchForms({ search, page: pagination, perPage: show, creator_id: filter?.value, sort: sort[0], sortBy: sort[1] })
            .then(res => {
                setData(res.data.forms);
                setLastPage(res.data.last_page);
                setLoader(false);
                setPage({
                    current_page: res.data.current_page,
                    last_page: res.data.last_page,
                    per_page: res.data.per_page,
                    total: res.data.total,
                })
            })
            .catch(() => {
                setLoader(false);
            })
    };

    useEffect(() => {
        loadData();
    }, [pagination, show, filter, sort]);

    useEffect(() => {
        if (isFirstRender) return;
        const handler = setTimeout(() => {
            setLoader(true);
            fetchForms({ search, page: 1, perPage: show, creator_id: filter?.value, sort: sort[0], sortBy: sort[1] })
                .then(res => {
                    setPagination(1);
                    setPage({
                        current_page: res.data.current_page,
                        last_page: res.data.last_page,
                        per_page: res.data.per_page,
                        total: res.data.total,
                    })
                    setData(res.data.forms);
                    setLastPage(res.data.last_page);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                })
        }, 1000);
        return () => clearTimeout(handler);
    }, [search]);

    const handleSearch = e => {
        setSearch(e.target.value);
    };

    const fetchOptions = search => {
        getUsers(search, 0)
            .then(res => {
                const list = res.data?.data?.users
                    .map(elem => ({ ...elem, title: elem.name, value: elem.id }));
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                setUsers(sorted);
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    const handleDelete = () => {
        setLoader(true);
        deleteForms(check)
            .then(() => {
                setLoader(false);
                loadData();
                setDeleteModal(false);
                dispatch(AlertState.actions.alert({
                    text: check.length === 1 ? 'Form deleted successfully' : 'Forms deleted successfully',
                    variant: true,
                }));
            })
            .catch(err => {
                setLoader(false);
                setDeleteModal(false);
                let errText = catchErrorMessage(err) || 'Delete forms error';
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    if (subscription.plan && subscription.plan.name !== 'Gym' && subscription.plan.name !== 'Enterprise') {
        return <Loader visible={true} />
    } else {
        return (
            <div className='forms-list'>
                {redirect && <Redirect to="/profile-list" />}
                <Loader visible={loader} />

                <ConfirmModal
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    handleConfirm={handleDelete}
                    title="Are you sure you want to delete selected forms?"
                    submitText="Yes"
                    isCancelButton={true}
                />

                {formViewModal && currentForm && (
                    <FormViewModal
                        currentForm={currentForm}
                        formViewModal={formViewModal}
                        setFormViewModal={setFormViewModal}
                    />
                )}


                <div className='forms-list__btn-container'>
                    <div className='forms-list__page-title'>Form List</div>
                    <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={() => history.push('/library/forms/create')}
                    >
                        Create Form
                    </Button>
                </div>
                <div className='forms-list__sort-container'>
                    <div className='forms-list__sort-wrapper'>
                        <div className='forms-list__filter-dropdown'>
                            <Dropdown
                                fetchOptions={fetchOptions}
                                options={users}
                                value={filter}
                                label="Created by"
                                setFormData={val => setFilter(val)}
                                width={'100%'}
                            />
                        </div>

                        {check.length === 1 && (
                            <>
                                <Link to={`/form/${check[0]}`} target="_blank" style={{ marginLeft: 15 }}>
                                    <Button
                                        variant="secondary"
                                        style={{ height: '100%' }}
                                    >
                                        Print
                                    </Button>
                                </Link>

                                <Button
                                    variant="secondary"
                                    onClick={() => history.push(`/library/forms/${check[0]}`)}
                                    style={{ marginLeft: 15 }}
                                >
                                    Edit
                                </Button>
                            </>
                        )}

                        {check.length > 0 && (
                            <Button
                                variant="secondary"
                                onClick={() => setDeleteModal(true)}
                                style={{ marginLeft: 15 }}
                            >
                                Delete
                            </Button>
                        )}
                    </div>

                    <TextField
                        variant="outlined"
                        label="Search"
                        value={search}
                        onChange={handleSearch}
                    />
                </div>

                <Table
                    data={data}
                    setData={setData}
                    loadData={loadData}
                    check={check}
                    setCheck={setCheck}
                    sort={sort}
                    setSort={setSort}
                    setCurrentForm={setCurrentForm}
                    setFormViewModal={setFormViewModal}
                />

                <Pagination
                    show={show}
                    setShow={setShow}
                    page={page}
                    setPage={setPage}
                    pagination={pagination}
                    setPagination={setPagination}
                    lastPage={lastPage}
                    data={data}
                />
            </div>
        )
    }
};
