import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, TextField } from '@material-ui/core';
import {
    Modal,
    Button
} from 'react-bootstrap';
import Switch from '@material-ui/core/Switch';
import { postActivateCRM1, postActivateCRM2, postActivateCRM3, fetchCRMList } from '../../../crud/crm.crud';
import { useDispatch } from 'react-redux';
import { setCRMAction } from '../../../store/actions';
import * as AlertState from '../../../store/ducks/auth.duck';
import Loader from '../../../components/Loader';
import { useHistory } from 'react-router-dom';
import catchErrorMessage from '../../../helpers/errorCatcher';

const ModalAddCRM = ({
    modal,
    setModal,
    setModalCRMSync,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [switched, setSwitched] = useState([false, false, false]);
    const [number_locations, set_number_locations] = useState('');
    const [site_id, set_site_id] = useState('');
    const [club_numbers, set_club_numbers] = useState('');
    const [errors, setErrors] = useState({});
    const CRMID = switched.findIndex(elem => elem === true) + 1;
    const [loader, setLoader] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleClose = () => {
        setSwitched([false, false, false]);
        set_number_locations('');
        set_site_id('');
        set_club_numbers('');
        setErrors({});
        setModal(false);
    };

    const handleSwitch = index => {
        setErrors({});
        setSwitched(prev => {
            const arr = [...prev].map(() => false)
            arr[index] = !prev[index]
            return arr;
        })
    };

    const validate = () => {
        setErrors({});
        let isValid = true;
        const numbersReg = /^\d+$/;
        const commaSeparateReg = /^\w+(?:(?:,\s\w+)+(?:)+)$/gm;

        if (switched[0] && !site_id) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    site_id: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (switched[0] && !login) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    login: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (switched[0] && !password) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    password: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (switched[0] && !numbersReg.test(number_locations)) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    number_locations: {
                        error: true,
                        helperText: 'Only numbers allowed',
                    }
                }
            })
        };

        if (switched[0] && (+number_locations < 1 || number_locations > 1000)) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    number_locations: {
                        error: true,
                        helperText: 'Min value = 1, Max value = 1000',
                    }
                }
            })
        };

        if (switched[0] && !number_locations) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    number_locations: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (switched[1] && !site_id) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    site_id: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (switched[2] && club_numbers.split(' ').length > 1 && !commaSeparateReg.test(club_numbers.trim())) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    club_numbers: {
                        error: true,
                        helperText: 'Not valid format',
                    }
                }
            })
        };

        if (switched[2]) {
            let arr = club_numbers.split(',').join('').split(' ');
            arr = arr.map(elem => elem.trim());
            arr.forEach(elem => {
                if (!numbersReg.test(elem)) {
                    isValid = false;
                    setErrors(prev => {
                        return {
                            ...prev,
                            club_numbers: {
                                error: true,
                                helperText: 'Only numbers allowed',
                            }
                        }
                    })
                }
            })
        };

        if (switched[2] && !club_numbers) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    club_numbers: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        return isValid;
    };

    const handleContinue = () => {
        if (validate()) {
            setLoader(true);
            if (+CRMID === 1) {
                postActivateCRM1(CRMID, number_locations, site_id, login, password)
                    .then(() => {
                        dispatch(AlertState.actions.alert({
                            text: "CRM is added and pending activation",
                            variant: true
                        }));
                        fetchCRMList()
                            .then((res) => {
                                dispatch(setCRMAction(res.data.data))
                                setModal(false);
                                setModalCRMSync(true);
                                setLoader(false);
                            })
                            .catch(() => setLoader(false))
                    })
                    .catch(err => {
                        setLoader(false);
                        let errorMessage = catchErrorMessage(err);

                        if (err.response?.data?.error === `Trying to get property 'site_id' of non-object`) {
                            setErrors(prev => {
                                return {
                                    ...prev,
                                    site_id: {
                                        error: true,
                                        helperText: 'This value is not found in CRM',
                                    }
                                }
                            })
                        } else if (err.response?.data?.error === 'The account with the specified site_id was not found in crm') {
                            setErrors(prev => {
                                return {
                                    ...prev,
                                    site_id: {
                                        error: true,
                                        helperText: 'Site Id was not found in crm',
                                    }
                                }
                            })
                        } else if (errorMessage === 'The site id has already been taken') {
                            setErrors(prev => {
                                return {
                                    ...prev,
                                    site_id: {
                                        error: true,
                                        helperText: 'The site id has already been taken',
                                    }
                                }
                            })
                        };

                        dispatch(AlertState.actions.alert({
                            text: errorMessage,
                            variant: false,
                        }));
                    })
            } else if (+CRMID === 2) {
                postActivateCRM2(CRMID, site_id)
                    .then(() => {
                        dispatch(AlertState.actions.alert({
                            text: "CRM is added",
                            variant: true
                        }));
                        fetchCRMList()
                            .then((res) => {
                                dispatch(setCRMAction(res.data.data))
                                setModal(false);
                                setLoader(false);
                                history.push('/account/crm/connect');
                            })
                            .catch(() => setLoader(false))
                    })
                    .catch(err => {
                        setLoader(false);
                        let errorMessage = catchErrorMessage(err);

                        if (err.response?.data?.error === `Trying to get property 'site_id' of non-object`) {
                            setErrors(prev => {
                                return {
                                    ...prev,
                                    site_id: {
                                        error: true,
                                        helperText: 'This value is not found in CRM',
                                    }
                                }
                            })
                        }

                        if (err.response?.data?.error === 'The account with the specified site_id was not found in crm') {
                            errorMessage = 'Customer Id was not found in crm';
                            setErrors(prev => {
                                return {
                                    ...prev,
                                    site_id: {
                                        error: true,
                                        helperText: 'Customer Id was not found in crm',
                                    }
                                }
                            })
                        }

                        if (errorMessage === 'The site id has already been taken') {
                            errorMessage = 'The customer id has already been taken';
                            setErrors(prev => {
                                return {
                                    ...prev,
                                    site_id: {
                                        error: true,
                                        helperText: 'The customer id has already been taken',
                                    }
                                }
                            })
                        };

                        dispatch(AlertState.actions.alert({
                            text: errorMessage,
                            variant: false,
                        }));
                    })
            } else if (+CRMID === 3) {
                const clubNumbers = club_numbers.trim().split(',').join('').split(' ');
                postActivateCRM3(CRMID, [...clubNumbers])
                    .then(() => {
                        dispatch(AlertState.actions.alert({
                            text: "CRM is added",
                            variant: true
                        }));
                        fetchCRMList()
                            .then((res) => {
                                dispatch(setCRMAction(res.data.data))
                                setModal(false);
                                setLoader(false);
                            })
                            .catch(err => {
                                console.log('fetchCRMList - error', err);
                                setLoader(false);
                            })
                    })
                    .catch(err => {
                        setLoader(false);
                        let errText = 'request failed';
                        if (err.response?.data?.error) {
                            errText = err.response?.data?.error;
                        } else if (err.response?.data?.message) {
                            errText = err.response.data.message;
                        } else {
                            errText = 'request failed';
                        }
                        dispatch(AlertState.actions.alert({
                            text: errText,
                            variant: false,
                        }));
                    })
            }
        }
    };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader} />
            <StyledModalAddCRM>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>
                <Modal.Title>
                    <h5
                        className="title"
                    >Add CRM Sync</h5>
                </Modal.Title>
                <Modal.Body>
                    <Typography>
                        <div className="switcher">
                            <div onClick={() => handleSwitch('0')} className={!switched[0] && `pointer`}>
                                Mindbody Online: $15/location/month
                            </div>
                            <Switch
                                checked={switched[0]}
                                onChange={() => handleSwitch('0')}
                                color="primary"
                                name="checkedA"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </Typography>
                    <div className={`switcher-inputs mindbody ${switched[0] ? 'active' : ''}`}>
                        <Typography>
                            <div className="textfield-container">
                                <TextField
                                    id="outlined-basic"
                                    label="Site ID"
                                    variant="outlined"
                                    value={site_id}
                                    onChange={(e) => set_site_id(e.target.value)}
                                    helperText={errors.site_id?.helperText}
                                    error={errors.site_id?.error}
                                    inputProps={{
                                        maxLength: 12,
                                    }}
                                />
                            </div>
                        </Typography>
                        <Typography>
                            <div className="textfield-container">
                                <TextField
                                    id="outlined-basic"
                                    label="Number of location"
                                    variant="outlined"
                                    value={number_locations}
                                    onChange={(e) => set_number_locations(e.target.value)}
                                    helperText={errors.number_locations?.helperText}
                                    error={errors.number_locations?.error}
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                />
                            </div>
                        </Typography>

                        <Typography>
                            <div className="textfield-container">
                                <TextField
                                    id="outlined-basic"
                                    label="Mindbody username"
                                    variant="outlined"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    helperText={errors.login?.helperText}
                                    error={errors.login?.error}
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    autoComplete={false}
                                />
                            </div>
                        </Typography>

                        <Typography>
                            <div className="textfield-container">
                                <TextField
                                    id="outlined-basic"
                                    label="Mindbody password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    helperText={errors.password?.helperText}
                                    error={errors.password?.error}
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    type='password'
                                    autoComplete={false}
                                />
                            </div>
                        </Typography>
                    </div>

                    <Typography>
                        <div className="switcher">
                            <div
                                className={!switched[1] && `pointer`}
                                onClick={() => handleSwitch('1')}
                            >
                                Motionsoft: $20/location/month
                            </div>
                            <Switch
                                checked={switched[1]}
                                onChange={() => handleSwitch('1')}
                                color="primary"
                                name="checkedC"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </Typography>
                    <div className={`switcher-inputs motionsoft ${switched[1] ? 'active' : ''}`}>
                        <Typography>
                            <div className="textfield-container">
                                <TextField
                                    id="outlined-basic"
                                    label="Customer ID"
                                    variant="outlined"
                                    value={site_id}
                                    onChange={(e) => set_site_id(e.target.value)}
                                    helperText={errors.site_id?.helperText}
                                    error={errors.site_id?.error}
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                />
                            </div>
                        </Typography>
                    </div>

                    <Typography>
                        <div className="switcher">
                            <div
                                className={!switched[2] && `pointer`}
                            // onClick={() => handleSwitch('2')}
                            >
                                ABC Fitness: $20/location/month
                            </div>
                            <Switch
                                checked={switched[2]}
                                onChange={() => handleSwitch('2')}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                disabled
                            />
                        </div>
                    </Typography>
                    <div
                        className={`switcher-inputs ${switched[2] ? 'active' : ''}`}
                        style={{ height: `${switched[2] ? '95px' : '0px'}` }}
                    >
                        <Typography>
                            <div className="textfield-container">
                                <TextField
                                    id="outlined-basic"
                                    label="Club Numbers"
                                    variant="outlined"
                                    value={club_numbers}
                                    onChange={(e) => set_club_numbers(e.target.value)}
                                    helperText={errors.club_numbers?.helperText}
                                    error={errors.club_numbers?.error}
                                />
                            </div>
                        </Typography>

                        <p className='club-numbers-helper-text'>
                            Please enter club numbers <br />
                            separated by commas with spaces like: 1, 20, 300
                        </p>
                    </div>

                    <hr />
                    <Typography className="static-text">
                        In addition to your current TrainerMetrics <br />
                        subscription, your payment method on file will be <br />
                        charged an additional amount to sync your CRM with <br />
                        TrainerMetrics
                    </Typography>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleContinue}
                        className="btn-blue"
                        disabled={!CRMID}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </StyledModalAddCRM>
        </Modal >
    )
};

const StyledModalAddCRM = styled.div`
    .close-button {
        position: absolute;
        top: 7px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 20px 0 0 20px;
    }

    .pointer {
        cursor: pointer;
    }

    .switcher {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 5px;
    }

    .switcher-inputs {
        height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: visibility  0.5s, opacity 0.5s, height 1s;
    }

    .switcher-inputs.active {
        height: 115px;
        opacity: 1;
        visibility : visible;
        transition: visibility  1.5s, opacity 1.5s, height 1s;
    }

    .switcher-inputs.active.mindbody {
        height: 230px;
    }

    .switcher-inputs.active.motionsoft {
        height: 50px;
    }

    .static-text {
        margin-left: 20px;
    }

    label {
        background-color: #fff;
        padding-right: 5px;
    }

    .textfield-container {
        height: 60px;
    }

    .club-numbers-helper-text {
        color: rgb(131,131,131);
        margin-left: 5px;
        font-size: 11px;
    }
`;

export default ModalAddCRM;
