import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';

export default ({
    modal,
    setModal,
    item,
    activePreview,
    setActivePreview,
    data,
    viewUrl
}) => {
    const prevPreview = () => {
        const currentIndex = data.findIndex(item => item.file.id === activePreview)
        if (currentIndex > 0) {
            setActivePreview(data[currentIndex - 1].file.id)
        }
    };

    const nextPreview = () => {
        const currentIndex = data.findIndex(item => item.file.id === activePreview)
        if (currentIndex < data.length - 1) {
            setActivePreview(data[currentIndex + 1].file.id)
        }
    };

    const activePreviewIndex = () => {
        return data.findIndex(item => item.file.id === activePreview)
    };

    return <div>
        <Modal
            size="lg"
            show={modal}
            onHide={() => {
                setModal(false)
                setActivePreview(0)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Preview Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PreviewModalStyled className="photomodal">
                    {activePreviewIndex() > 0 &&
                        <ChevronLeftIcon
                            className="photomodal-left"
                            onClick={prevPreview}
                        />
                    }
                    {activePreviewIndex() < data.length - 1 &&
                        <ChevronRightIcon
                            className="photomodal-right"
                            onClick={nextPreview}
                        />
                    }
                    <div className="photomodal-preview">
                        <video
                            playsInline={true}
                            controls={true}
                            poster={item?.file?.thumbnail_path}
                            src={viewUrl}
                        />
                    </div>
                    <div>
                        <p className="photomodal-filename">{item?.file.file_name}</p>
                        <p className="photomodal-testdate">{moment(item?.test_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</p>
                    </div>
                </PreviewModalStyled>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModal(false)
                        setActivePreview(0)
                    }}
                >Close</Button>
            </Modal.Footer>
        </Modal>
    </div>
};

const PreviewModalStyled = styled.div`
    .photomodal {
        display: flex;
        flex-direction: column;
        position: relative;
        
        &-left {
            position: absolute;
            top: 55%;
            left: 16px;
            transform: translateY(-50%);
            height: 40px;
            width: 40px;
            border-radius: 20px;
            background: #646c9a;

            path {
                fill: #ffffff;
            }
        }

        &-right {
            position: absolute;
            top: 55%;
            right: 16px;
            transform: translateY(-50%);
            height: 40px;
            width: 40px;
            border-radius: 20px;
            background: #646c9a;

            path {
                fill: #ffffff;
            }
        }

        &-name {
            font-size: 18px;
            margin: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                width: 30%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-preview {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            video {
                width: calc(100% - 100px);
                border: 1px solid grey;
            }
        }

        &-filename {
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            margin: 20px 0 0;
        }

        &-testdate {
            text-align: center;
            font-size: 15px;
            margin: 10px 0 0;
        }

    }
`;
