import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router-dom";
import styled from 'styled-components';
import {
    Portlet,
    PortletBody,
} from "../../partials/content/Portlet";
import {
    TextField,
    Select,
    FormHelperText,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    OutlinedInput,
    Tab,
    Tabs
} from '@material-ui/core';
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck';
import {
    Button,
    Modal
} from 'react-bootstrap';
import { Formik } from "formik";
import moment from 'moment';
import PARQ from './ProfileCreateTabs/PARQ';
import DropZone from '../../components/DropZone';
import Logout from '../auth/Logout';
import { createProfile, getParQ } from '../../crud/profile.crud';
import { getAllGroup, getAllUsers, getUserPrivileges, getAccount } from '../../crud/info.crud';
import Cancel from '@material-ui/icons/Cancel';
import Loader from '../../components/Loader';
import { getUserByToken, REACT_APP_URL } from "../../crud/auth.crud";
import { useSelector } from 'react-redux';
import * as media from '../../crud/media.crud';
import ProfileNotepad from './ProfileInfoTabs/ProfileNotepad';
import { postProfilePhoto } from '../../crud/file.crud';
import { Intake } from './ProfileCreateTabs/Intake/Intake';
import { fetchForms, storeForm } from '../../crud/forms';
import { resetForms, setAccount } from '../../store/actions';

const ImgComponent = ({ src, alt, className }) => {
    const [url, setUrl] = useState('')

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} />
};

export default () => {
    document.title = 'TrainerMetrics - Create Profile'
    const [parq, setParq] = useState([])
    const [photo, setPhoto] = useState('')
    const [errors, setErrors] = useState({})
    const [key, setKey] = useState(0);
    const [redirect, setRedirect] = useState(null);
    const [modal, setModal] = useState(false);
    const [parData, setParData] = useState([]);
    const [tempData, setTempData] = useState();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const [inBody, setInBody] = useState(false);
    const [styku, setStyku] = useState(false);
    const [groupsList, setGroupsList] = useState([]);
    const [staffsList, setStaffsList] = useState([]);
    const [role, setRole] = useState('');
    const plan = useSelector(state => state.user_info.subscription?.plan?.name);
    const accountMeasurementSystem = useSelector(state => state.appData.account.measurement_system);
    const [measurement_system, set_measurement_system] = useState('imperial');
    const fields = useSelector(state => state.crmFields || '');
    const [touchImg, setTouchImg] = useState(true);
    const [archived] = useState(false);
    const [forms, setForms] = useState([]);
    const [currentForm, setCurrentForm] = useState(null);
    const savedForm = useSelector(state => {
        if (state.appData.forms) {
            return state.appData.forms[currentForm?.id]
        } else return null
    });
    const savedForms = useSelector(state => state.appData.forms);

    useEffect(() => {
        dispatch(resetForms());

        setLoader(true);
        fetchForms({ perPage: 100 })
            .then(res => {
                const sorted = res.data.forms.sort((a, b) => {
                    let first = a.name.toLowerCase();
                    let second = b.name.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                });
                setForms(sorted);
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
            })
    }, []);

    useEffect(() => {
        if (accountMeasurementSystem) {
            set_measurement_system(accountMeasurementSystem);
        }
    }, [accountMeasurementSystem]);

    useEffect(() => {
        getAllGroup()
            .then(res => {
                setGroupsList(res.data.data)
            })
        getAllUsers()
            .then(res => {
                setStaffsList(res.data.data)
            })
        getUserByToken()
            .then(res => {
                setRole(res.data.data.roles)
                setInBody(!!res.data.data.enableInbody)
                setStyku(!!res.data.data.enableStyku)
            })

        getUserPrivileges()
            .then(res => {
                saveAccess(res.data.data.permissions)
                res.data.data.permissions.findIndex(item => item === 'create profiles') === -1 && setRedirect(<Redirect to="/profile-list" />)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
            });
    }, []);

    const [access, setAccess] = useState({
        create: false,
        activate: false,
        archive: false,
        delete: false,
        access: false,
        updateTesting: false
    })

    const saveAccess = arr => {
        const status = name => arr.findIndex(item => item === name) !== -1

        setAccess({
            create: status('create profiles'),
            activate: status('activate profiles'),
            archive: status('archive profiles'),
            delete: status('delete profiles'),
            access: status('access profiles'),
            updateTesting: status('update testing metrics')
        })
    };

    useEffect(() => {
        setLoader(true)
        getParQ()
            .then(res => {
                setLoader(false)
                setParData(res.data.data)
            })
            .catch(({ response }) => {
                setLoader(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if (response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
            })
    }, []);


    const changeParQ = (id, value) => {
        let tempData = [...parq]
        let index = tempData.findIndex(item => item.parq_id === id)
        index >= 0
            ? tempData[index] = {
                parq_id: id,
                value: value
            }
            : tempData.push({
                parq_id: id,
                value: value
            })

        setParq(tempData)
    };

    const submitForm = (values) => {
        setLoader(true)
        let birthday = `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`;
        let temp = { ...values }
        temp.pic = temp.pic.path;
        delete temp.birthday_day;
        delete temp.birthday_month;
        delete temp.birthday_year;
        if (fields?.fields?.profile_photo && !photo) {
            temp.pic = fields.fields.profile_photo
        };

        let answersArray = [];
        if (savedForms) {
            let isValid = true;
            const keys = Object.keys(savedForms);
            answersArray = keys.map(key => {
                return {
                    form_id: savedForms[key].form_id,
                    id: key,
                    answers: savedForms[key].answers
                        .filter(answer => answer.type !== 'section')
                        .map(answer => {
                            if (answer.hasError === true) isValid = false;
                            if (answer.type === 'number') {
                                return {
                                    input_id: answer.input_id,
                                    value: answer.value && answer.value !== '' ? +answer.value : null,
                                }
                            } else {
                                return {
                                    input_id: answer.input_id,
                                    value: answer.value === 0 || answer.value === '0'
                                        ? 0
                                        : answer.value === '' || answer.value === undefined || answer.value === null
                                            ? null
                                            : answer.value
                                }
                            }
                        })
                }
            });

            if (isValid === false) {
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'Some of filled forms are invalid',
                    variant: false
                }));
                return;
            }
        };

        createProfile({ ...temp, birthday: birthday })
            .then(res => {
                setLoader(false);
                if (savedForms) {
                    answersArray.forEach(elem => {
                        let answers = [...elem.answers];
                        let obj = { ...elem };
                        obj.form_id = obj.id;
                        delete obj.id;
                        storeForm(res.data.data.id, { ...obj, answers })
                            .catch(() => {
                                setTimeout(() => {
                                    dispatch(AlertState.actions.alert({
                                        text: `Form ${obj.name} update error`,
                                        variant: false
                                    }));
                                }, 2000);
                            })
                    });
                };

                if (!photo.id && fields.fields?.is_base64) {
                    function urltoFile(url, mimeType) {
                        return (fetch(url)
                            .then(function (res) { return res.arrayBuffer(); })
                            .then(function (buf) { return new File([buf], 'file', { type: mimeType }); })
                        );
                    }

                    urltoFile(`data:image/png;base64,${fields.fields.profile_photo}`, 'image/png')
                        .then(resFile => {
                            const formData = new FormData();
                            formData.append('file', resFile)
                            media.uploadImg(formData)
                                .then(uploadedFile => {
                                    postProfilePhoto(res.data.data.id, uploadedFile.data.data.id);
                                    setTimeout(() => {
                                        setRedirect(<Redirect to={`/profile/${res.data.data.id}/testing`} />)
                                    }, 2000)
                                })
                        })
                        .catch(() => {
                            setTimeout(() => {
                                setRedirect(<Redirect to={`/profile/${res.data.data.id}/testing`} />)
                            }, 2000)
                        })
                } else if (photo?.id) {
                    postProfilePhoto(res.data.data.id, photo.id)
                        .then(() => {
                            setTimeout(() => {
                                dispatch(AlertState.actions.alert({
                                    text: 'Profile photo is updated',
                                    variant: true
                                }));
                                setRedirect(<Redirect to={`/profile/${res.data.data.id}/testing`} />)
                            }, 2000);
                        })
                        .catch(() => {
                            setTimeout(() => {
                                dispatch(AlertState.actions.alert({
                                    text: 'Profile photo update error',
                                    variant: false
                                }));
                                setRedirect(<Redirect to={`/profile/${res.data.data.id}/testing`} />)
                            }, 2000);
                        })
                } else {
                    setTimeout(() => {
                        setRedirect(<Redirect to={`/profile/${res.data.data.id}/testing`} />)
                    }, 2000)
                }
            })
            .catch(err => {
                setLoader(false)
                err?.response?.data &&
                    dispatch(AlertState.actions.alert({
                        text: err?.response?.data.message || err?.response?.data.error,
                        variant: false
                    }));
                if (`${err?.response?.data.admin}` === '1') {
                    setRedirect(<Redirect to="/account/biling" />)
                } else if (`${err?.response?.data.admin}` === '0') {
                    setRedirect(<Redirect to="/profile-list" />)
                }
                if (err?.response && (err?.response?.status === 401)) {
                    setRedirect(<Logout />)
                } else if (err?.response && (err?.response?.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                }
                err?.response?.data.errors && setErrors({
                    first_name: err?.response?.data.errors.first_name && err?.response?.data.errors.first_name[0],
                    last_name: err?.response?.data.errors.last_name && err?.response?.data.errors.last_name[0],
                    birthday: err?.response?.data.errors.birthday && err?.response?.data.errors.birthday[0],
                    gender: err?.response?.data.errors.gender && err?.response?.data.errors.gender[0],
                    heightFt: err?.response?.data.errors.heightFt && err?.response?.data.errors.heightFt[0],
                    heightIn: err?.response?.data.errors.heightIn && err?.response?.data.errors.heightIn[0],
                    heightM: err?.response?.data.errors.heightM && err?.response?.data.errors.heightM[0],
                    email: err?.response?.data.errors.email && err?.response?.data.errors.email[0],
                    client_access: err?.response?.data.errors.client_access && err?.response?.data.errors.client_access[0],
                    parq: err?.response?.data.errors.parq && err?.response?.data.errors.parq[0],
                    pic: err?.response?.data.errors.pic && err?.response?.data.errors.pic[0]
                })
                // setStatus(response.data.message)
            });
    };

    const inputLabel = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    const inputLabel2 = useRef();
    const [labelWidth2, setLabelWidth2] = useState(0);

    useEffect(() => {
        inputLabel2.current && setLabelWidth2(inputLabel2.current.offsetWidth);
    }, [inputLabel2]);


    const inputLabel3 = useRef();
    const [labelWidth3, setLabelWidth3] = useState(0);

    useEffect(() => {
        inputLabel3.current && setLabelWidth3(inputLabel3.current.offsetWidth);
    }, [inputLabel3]);


    const inputLabel4 = useRef();
    const [labelWidth4, setLabelWidth4] = useState(0);

    useEffect(() => {
        inputLabel4.current && setLabelWidth4(inputLabel4.current.offsetWidth);
    }, [inputLabel4]);


    const inputLabel5 = useRef();
    const [labelWidth5, setLabelWidth5] = useState(0);

    useEffect(() => {
        inputLabel5.current && setLabelWidth5(inputLabel5.current.offsetWidth);
    }, [inputLabel5, role, plan]);

    const inputLabel6 = useRef();
    const [labelWidth6, setLabelWidth6] = useState(0);

    useEffect(() => {
        inputLabel6.current && setLabelWidth6(inputLabel6.current.offsetWidth);
    }, [inputLabel6, role, plan]);

    const handleSwitch = (e) => {
        set_measurement_system(e.target.value);
    };

    const base64Image = (base64) => {
        return <img src={`data:image/png;base64,${base64}`} alt='' />
    };

    return (
        <StyledProfileCreate>
            {redirect}
            <Loader visible={loader} />

            <Modal
                show={modal}
                onHide={e => {
                    setModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>PAR-Q</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Click Continue to save the Profile without PAR-Q information.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={e => {
                            setModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" className="btn-blue" onClick={e => {
                        submitForm(tempData)
                        setModal(false)
                    }}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>

            <Portlet>
                <PortletBody>
                    <Formik
                        initialValues={{
                            first_name: fields?.name?.split(' ')[0] || '',
                            last_name: fields?.name?.split(' ')[1] || '',
                            birthday_day: fields?.fields?.birthday ? moment(fields?.fields?.birthday).format('DD') : '',
                            birthday_month: fields?.fields?.birthday ? moment(fields?.fields?.birthday).format('MM') : '',
                            birthday_year: fields?.fields?.birthday ? moment(fields?.fields?.birthday).format('YYYY') : '',
                            gender: fields?.fields?.gender ? fields.fields.gender.charAt(0).toUpperCase() + fields.fields.gender.slice(1) : '',
                            heightFt: '',
                            heightIn: '',
                            email: fields?.fields?.email?.trim() || '',
                            heightM: '',
                            heightCm: '',
                            client_access: '0',
                            parq: [],
                            pic: fields?.fields?.profile_photo
                                ? fields?.fields?.profile_photo.toLocaleLowerCase().indexOf('http') !== -1
                                    ? fields?.fields?.profile_photo
                                    : `${REACT_APP_URL}${fields?.fields?.profile_photo}`
                                : '',
                            styku_email: '',
                            inbody_user_token: '',
                            groups: [],
                            staffs: [],
                            external_id: fields?.id || undefined,
                            notepad_text: '',
                            measurement_system: 'imperial',
                        }}
                        validate={values => {
                            const temp = {};
                            values.parq = parq
                            values.pic = fields?.fields?.profile_photo && !photo ? values.pic : photo
                            values.birthday = values.birthday_year && values.birthday_month && values.birthday_day
                                ? `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`
                                : ''

                            if (!values.first_name) {
                                temp.first_name = 'This value should not be blank'
                            } else if (values.first_name.length > 50) {
                                temp.first_name = 'Field is not valid'
                            } else if (values.first_name.length < 2) {
                                temp.first_name = 'The First Name should be at least 2 of characters'
                            } else if (/[^A-Za-z0-9 ]/g.test(values.first_name)) {
                                temp.first_name = 'Field is not valid'
                            }

                            if (!values.last_name) {
                                temp.last_name = 'This value should not be blank'
                            } else if (values.last_name.length > 50) {
                                temp.last_name = 'Field is not valid'
                            } else if (values.last_name.length < 2) {
                                temp.last_name = 'The Last Name should be at least 2 of characters'
                            } else if (/[^A-Za-z0-9 ]/g.test(values.last_name)) {
                                temp.last_name = 'Field is not valid'
                            }

                            if (!values.birthday) {
                                temp.birthday = 'Please, fill all birhsday fields'
                            } else if (
                                (moment(values.birthday, 'YYYY-MM-DD').toDate() >= moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').toDate()) ||
                                `${moment(values.birthday, 'YYYY-MM-DD').toDate()}` === 'Invalid Date'
                            ) {
                                temp.birthday = 'Please fill the valid data'
                            }

                            if (!values.gender) {
                                temp.gender = 'This value should not be blank'
                            }

                            values.measurement_system = measurement_system;

                            if (measurement_system === 'imperial') {
                                if (!values.heightFt && `${values.heightFt}` !== '0') {
                                    temp.heightFt = 'This value should not be blank'
                                } else if (/[^0-9]/g.test(values.heightFt)) {
                                    temp.heightFt = 'The value can be from 1 to 8'
                                } else if (parseInt(values.heightFt) > 8 || parseInt(values.heightFt) < 1) {
                                    temp.heightFt = 'The value can be from 1 to 8'
                                }

                                if (!values.heightIn && `${values.heightIn}` !== '0') {
                                    temp.heightIn = 'This value should not be blank'
                                } else if (/[^0-9]/g.test(values.heightIn)) {
                                    temp.heightIn = 'The value can be from 0 to 11'
                                } else if (parseInt(values.heightIn) > 11 || parseInt(values.heightIn) < 0) {
                                    temp.heightIn = 'The value can be from 0 to 11'
                                }
                            } else {
                                if (!values.heightM && `${values.heightM}` !== '0') {
                                    temp.heightM = 'This value should not be blank'
                                } else if (+values.heightM < 1 || +values.heightM > 3) {
                                    temp.heightM = 'The value can be from 1 to 3'
                                }
                            }

                            if (values.client_access === '1') {
                                if (!values.email) {
                                    temp.email = ''
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    temp.email = 'Invalid email address'
                                }
                            } else if (values.email !== '') {
                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    temp.email = 'Invalid email address'
                                }
                            }

                            if (styku) {
                                if (!values.styku_email) {
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.styku_email)) {
                                    temp.styku_email = 'Invalid email address'
                                }
                            }

                            if (inBody) {
                                if (!values.inbody_user_token) {
                                } else if (values.inbody_user_token.length > 16) {
                                    temp.inbody_user_token = 'Field is not valid'
                                } else if (values.inbody_user_token.length < 3) {
                                    temp.inbody_user_token = 'The Inbody User Token should be at least 3 of characters'
                                } else if (/[^A-Za-z0-9]/g.test(values.inbody_user_token)) {
                                    temp.inbody_user_token = 'Field is not valid'
                                }
                            }

                            if (!!temp && key === 1) {
                                setKey(0)
                            }

                            setErrors(temp)
                            return temp;
                        }}
                        onSubmit={(values) => {
                            values.parq = parq
                            !!photo && (values.pic = photo);

                            if (!values.notepad_text) delete values.notepad_text;

                            if (measurement_system === 'imperial') {
                                values.heightM = '';
                                values.heightCm = '';
                            } else {
                                values.heightFt = '';
                                values.heightIn = '';

                                if ((values.heightM + '').split('.').length > 1) {
                                    values.heightCm = (values.heightM + '').split('.')[1];
                                } else {
                                    values.heightCm = '0';
                                }
                                values.heightM = (values.heightM + '').split('.')[0] || '';
                            }

                            if (values.parq.length === 0) {
                                setTempData(values);
                                setModal(true);
                            } else {
                                submitForm({ ...values, birthday: `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}` })
                            }
                        }}
                    >
                        {({
                            values,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            validateForm,
                        }) => (
                            <form
                                noValidate={true}
                                autoComplete="off"
                                className="kt-form root"
                                onSubmit={handleSubmit}
                            >
                                <Tabs
                                    value={key}
                                    onChange={(e, value) => setKey(value)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    scrollButtons="auto"
                                    variant="scrollable"
                                >
                                    <Tab label="Profile Info" />
                                    <Tab label="PAR-Q" />
                                    <Tab label="Notepad" />

                                    {forms.length > 0 &&
                                        forms
                                            .filter(elem => !!elem.page?.find(item => item === 'create-profile'))
                                            .map(elem => {
                                                return (
                                                    <Tab
                                                        label={elem.tab_name}
                                                        key={elem.name}
                                                        onClick={() => setCurrentForm(elem)}
                                                    />
                                                )
                                            })}
                                </Tabs>

                                {key === 2 && <ProfileNotepad
                                    value={values.notepad_text}
                                    setValue={value => handleChange({ target: { value: value, name: 'notepad_text', id: 'notepad_text' } })}
                                />}
                                {key === 1 && <PARQ
                                    parq={parq}
                                    parData={parData}
                                    change={changeParQ}
                                    error={errors.parq}
                                    touched={touched.parq}
                                />}
                                {key >= 3 && (
                                    <Intake
                                        currentForm={currentForm}
                                        setLoader={setLoader}
                                        handleSubmit={handleSubmit}
                                        savedForm={savedForm}
                                        validateForm={validateForm}
                                    />
                                )}
                                {key === 0 && (
                                    <div className="row">
                                        <div className="col-12 col-md-6 info-block">
                                            <h4>Profile Information</h4>
                                            <div className="form-group">
                                                <TextField
                                                    key='18'
                                                    variant="outlined"
                                                    type="text"
                                                    label="First Name"
                                                    margin="normal"
                                                    className="kt-width-full"
                                                    name="first_name"
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.first_name}
                                                    helperText={touched.first_name && errors.first_name}
                                                    error={Boolean(touched.first_name && errors.first_name)}
                                                />

                                                <TextField
                                                    key='19'
                                                    variant="outlined"
                                                    type="text"
                                                    label="Last Name"
                                                    margin="normal"
                                                    className="kt-width-full"
                                                    name="last_name"
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.last_name}
                                                    helperText={touched.last_name && errors.last_name}
                                                    error={Boolean(touched.last_name && errors.last_name)}
                                                />

                                                <FormControl
                                                    variant="outlined"
                                                    className="MuiFormControl-marginNormal MuiTextField-root"
                                                    error={Boolean(touched.gender && errors.gender)}
                                                >
                                                    <InputLabel ref={inputLabel}>Sex</InputLabel>
                                                    <Select
                                                        value={values.gender}
                                                        input={<OutlinedInput labelWidth={labelWidth} />}
                                                        onChange={handleChange}
                                                        name="gender"
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value={'Male'}>Male</MenuItem>
                                                        <MenuItem value={'Female'}>Female</MenuItem>
                                                    </Select>
                                                    {
                                                        touched.gender && errors.gender &&
                                                        <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                    }
                                                </FormControl>

                                                <div className="birthday-row">
                                                    <FormControl variant="outlined" error={Boolean((touched.birthday_year && touched.birthday_month && touched.birthday_day) && errors.birthday)}>
                                                        <InputLabel ref={inputLabel3} htmlFor="birthday_month">
                                                            Birth Month
                                                        </InputLabel>
                                                        <Select
                                                            value={values.birthday_month}
                                                            onChange={e => {
                                                                handleChange(e)
                                                                touched.birthday_month = true
                                                            }}
                                                            input={<OutlinedInput labelWidth={labelWidth3} name="birthday_month" id="birthday_month" />}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value={'01'}>January</MenuItem>
                                                            <MenuItem value={'02'}>February</MenuItem>
                                                            <MenuItem value={'03'}>March</MenuItem>
                                                            <MenuItem value={'04'}>April</MenuItem>
                                                            <MenuItem value={'05'}>May</MenuItem>
                                                            <MenuItem value={'06'}>June</MenuItem>
                                                            <MenuItem value={'07'}>July</MenuItem>
                                                            <MenuItem value={'08'}>August</MenuItem>
                                                            <MenuItem value={'09'}>September</MenuItem>
                                                            <MenuItem value={'10'}>October</MenuItem>
                                                            <MenuItem value={'11'}>November</MenuItem>
                                                            <MenuItem value={'12'}>December</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined" error={Boolean((touched.birthday_year && touched.birthday_month && touched.birthday_day) && errors.birthday)}>
                                                        <InputLabel ref={inputLabel4} htmlFor="birthday_day">
                                                            Birth Day
                                                        </InputLabel>
                                                        <Select
                                                            value={values.birthday_day}
                                                            onChange={e => {
                                                                handleChange(e)
                                                                touched.birthday_day = true
                                                            }}
                                                            input={<OutlinedInput labelWidth={labelWidth4} name="birthday_day" id="birthday_day" />}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {
                                                                [...new Array(31)].map((item, index) => <MenuItem value={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`} key={index}>{1 + index}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined" error={Boolean((touched.birthday_year && touched.birthday_month && touched.birthday_day) && errors.birthday)}>
                                                        <InputLabel ref={inputLabel2} htmlFor="birthday_year">
                                                            Birth Year
                                                        </InputLabel>
                                                        <Select
                                                            value={values.birthday_year}
                                                            onChange={e => {
                                                                handleChange(e)
                                                                touched.birthday_year = true
                                                            }}
                                                            input={<OutlinedInput labelWidth={labelWidth2} name="birthday_year" id="birthday_year" />}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {
                                                                [...new Array(121)].map((item, index) => <MenuItem value={2020 - index} key={index}>{2020 - index}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {
                                                    Boolean((touched.birthday_year && touched.birthday_month && touched.birthday_day) && errors.birthday) &&
                                                    <div className="birthday-row__error">
                                                        {errors.birthday}
                                                    </div>
                                                }

                                                <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root">
                                                    <InputLabel ref={inputLabel5} htmlFor="system" className="MuiFormLabel-root">System</InputLabel>
                                                    <Select
                                                        value={measurement_system}
                                                        onChange={handleSwitch}
                                                        input={<OutlinedInput id="system" name="system" />}
                                                    >
                                                        <MenuItem value={"imperial"}>Imperial</MenuItem>
                                                        <MenuItem value={"metric"}>Metric</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                {measurement_system === 'imperial'
                                                    ? (
                                                        <div style={{ display: 'flex' }}>
                                                            <TextField
                                                                key='20'
                                                                style={{ marginRight: 10 }}
                                                                variant="outlined"
                                                                type="number"
                                                                label="Height Ft"
                                                                margin="normal"
                                                                className="kt-width-full"
                                                                name="heightFt"
                                                                onBlur={handleBlur}
                                                                onChange={e => {
                                                                    let temp = { ...e }
                                                                    temp.target.value = Math.floor(e.target.value)
                                                                    handleChange(temp)
                                                                }}
                                                                value={values.heightFt}
                                                                helperText={touched.heightFt && errors.heightFt}
                                                                error={Boolean(touched.heightFt && errors.heightFt)}
                                                                inputProps={{
                                                                    type: 'number',
                                                                    max: 8,
                                                                    min: 1,
                                                                }}
                                                            />

                                                            <TextField
                                                                key='21'
                                                                variant="outlined"
                                                                type="number"
                                                                label="Height In"
                                                                margin="normal"
                                                                className="kt-width-full"
                                                                name="heightIn"
                                                                onBlur={handleBlur}
                                                                onChange={e => {
                                                                    let temp = { ...e }
                                                                    temp.target.value = Math.floor(e.target.value)
                                                                    handleChange(temp)
                                                                }}
                                                                value={values.heightIn}
                                                                helperText={touched.heightIn && errors.heightIn}
                                                                error={Boolean(touched.heightIn && errors.heightIn)}
                                                                inputProps={{
                                                                    type: 'number',
                                                                    max: 11,
                                                                    min: 0,
                                                                }}
                                                            />
                                                        </div>
                                                    )

                                                    : (
                                                        <div style={{ display: 'flex' }}>
                                                            <TextField
                                                                key='20'
                                                                variant="outlined"
                                                                label="Height M"
                                                                margin="normal"
                                                                className="kt-width-full"
                                                                name="heightM"
                                                                onBlur={handleBlur}
                                                                onChange={e => {
                                                                    let temp = { ...e }
                                                                    temp.target.value = e.target.value
                                                                    handleChange(temp)
                                                                }}
                                                                value={values.heightM}
                                                                helperText={touched.heightM && errors.heightM}
                                                                error={Boolean(touched.heightM && errors.heightM)}
                                                                inputProps={{
                                                                    type: "number",
                                                                    step: "0.1",
                                                                    min: 1,
                                                                    max: 3,
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                }

                                                <TextField
                                                    key='22'
                                                    variant="outlined"
                                                    type="email"
                                                    label="Email"
                                                    margin="normal"
                                                    className="kt-width-full"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    helperText={touched.email && errors.email}
                                                    error={Boolean(touched.email && errors.email)}
                                                />

                                                {((role === 'supervisor') || (role === 'admin') || (role === 'super-admin')) && (plan === 'Enterprise') && <FormControl
                                                    variant="outlined"
                                                    error={Boolean(touched.groups && errors.groups)}
                                                    className="MuiFormControl-marginNormal MuiTextField-root"
                                                >
                                                    <InputLabel ref={inputLabel5} htmlFor="groups">
                                                        Groups
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        value={values.groups}
                                                        onChange={e => {
                                                            handleChange(e)
                                                            touched.groupsList = true
                                                        }}
                                                        input={<OutlinedInput labelWidth={labelWidth5} id="groups" name="groups" />}
                                                    >
                                                        {
                                                            groupsList.map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>}

                                                {((role === 'supervisor') || (role === 'admin') || (role === 'super-admin')) && ((plan === 'Gym') || (plan === 'Enterprise')) && <FormControl
                                                    variant="outlined"
                                                    error={Boolean(touched.groups && errors.groups)}
                                                    className="MuiFormControl-marginNormal MuiTextField-root"
                                                >
                                                    <InputLabel ref={inputLabel6} htmlFor="staffs">
                                                        Staffs
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        value={values.staffs}
                                                        onChange={e => {
                                                            handleChange(e)
                                                            touched.staffs = true
                                                        }}
                                                        input={<OutlinedInput labelWidth={labelWidth6} id="staffs" name="staffs" />}
                                                    >
                                                        {
                                                            staffsList.filter(item => item.type === 'staff').map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>}

                                                {
                                                    access.access && (
                                                        <FormControl className="" error={Boolean(touched.client_access && errors.client_access)}>
                                                            <RadioGroup
                                                                aria-label="Gender"
                                                                id="client_access-simple"
                                                                style={{ flexDirection: 'row' }}
                                                                className='group'
                                                                value={values.client_access}
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value={'1'} name="client_access" control={<Radio color="primary" />} label="Allow access to Client Profile" />
                                                                <FormControlLabel value={'0'} name="client_access" control={<Radio color="primary" />} label="Do not allow access to Client Profile" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )
                                                }

                                                {
                                                    inBody ? <TextField
                                                        key='24'
                                                        variant="outlined"
                                                        type="text"
                                                        label="InBody User Token"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="inbody_user_token"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange({ ...e, target: { id: e.target.id, name: e.target.name, value: `${parseInt(e.target.value) || ''}` } })
                                                        }}
                                                        value={values.inbody_user_token}
                                                        inputProps={{
                                                            maxLength: 16,
                                                        }}
                                                        helperText={touched.inbody_user_token && errors.inbody_user_token}
                                                        error={Boolean(touched.inbody_user_token && errors.inbody_user_token)}
                                                    /> : null
                                                }
                                                {
                                                    styku ? <TextField
                                                        key='25'
                                                        variant="outlined"
                                                        type="email"
                                                        label="Styku Email"
                                                        margin="normal"
                                                        className="kt-width-full"
                                                        name="styku_email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.styku_email}
                                                        helperText={touched.styku_email && errors.styku_email}
                                                        error={Boolean(touched.styku_email && errors.styku_email)}
                                                    /> : null
                                                }

                                            </div >
                                        </div >
                                        <div className="col-12 col-md-6 info-block">
                                            <h4>
                                                Profile Photo
                                            </h4>

                                            <div className="info-block__photo">
                                                {
                                                    values.pic && typeof values.pic === 'string' && touchImg
                                                        ? (<div className="info-block__photo-preview">
                                                            {fields?.fields?.is_base64
                                                                ? <>{base64Image(fields.fields?.profile_photo)}</>
                                                                : <ImgComponent
                                                                    src={values.pic}
                                                                    alt="propfile"
                                                                />}
                                                            <div className="delete"
                                                                onClick={() => {
                                                                    setTouchImg(false)
                                                                    values.pic = null
                                                                }}>
                                                                <Cancel />
                                                            </div>
                                                        </div>)
                                                        : archived
                                                            ? values.pic
                                                                ? <ImgComponent
                                                                    src={values.pic}
                                                                    alt="propfile"
                                                                />
                                                                : <div
                                                                    style={{
                                                                        width: '100px',
                                                                        height: '100px',
                                                                        borderRadius: '100px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        background: '#bdbdbd',
                                                                        color: '#fafafa',
                                                                        fontSize: '28px',
                                                                        fontWeight: '700'
                                                                    }}
                                                                >
                                                                    {values.first_name && values.first_name[0].toUpperCase()}
                                                                    {values.last_name && values.last_name[0].toUpperCase()}
                                                                </div>
                                                            : (<DropZone
                                                                submit={(e) => {
                                                                    setPhoto(e);
                                                                    values.pic = e.thumbnail_path;
                                                                }}
                                                                photo={values.pic}
                                                                onDelete={() => {
                                                                    values.pic = null
                                                                }}
                                                                Content={() => (
                                                                    <div
                                                                        style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '100px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            background: '#bdbdbd',
                                                                            color: '#fafafa',
                                                                            fontSize: '28px',
                                                                            fontWeight: '700'
                                                                        }}
                                                                    >
                                                                        {values.first_name && values.first_name[0].toUpperCase()}
                                                                        {values.last_name && values.last_name[0].toUpperCase()}
                                                                    </div>
                                                                )}
                                                            />)
                                                }
                                                <div style={{ color: '#fd397a' }}>
                                                    {touched.pic && errors.pic}
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                )
                                }
                                {key < 3 && (
                                    <div className="kt-login__actions" style={{ marginTop: 20 }}>
                                        <button
                                            type="submit"
                                            className="btn btn-blue btn-elevate kt-login__btn-primary"
                                        >
                                            Create Profile
                                        </button>
                                    </div>
                                )}
                            </form >
                        )}
                    </Formik >
                </PortletBody >
            </Portlet >
        </StyledProfileCreate >
    )
};

const StyledProfileCreate = styled.div`
    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }

    .info-block {
        padding: 30px 50px;

        &__photo {
            margin-top: 30px;
        }
    }

    .birthday-row {
        display: flex;
        justify-content: space-between;    
        margin-top: 16px;
        margin-bottom: 8px;

        &__error {
            color: #fd397a;
            margin: 8px 14px 0;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1em;
        }

        & > div {
            width: 30%;
        }
    }

    .metric-switcher {
        display: flex;
        align-items: center;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background-color: #fff;
    }
`;
