import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './infomodal.scss';

export const InfoModal = ({ setOpenModal,onClose,openModal }) => {

  return (
    <Modal
      // show={() => setOpenModal(true)}
      show={openModal}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title> Report does not exist </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="info-report-modal-body">
          Report does not exist
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
        >Close</Button>
      </Modal.Footer>
    </Modal>
  )
}