import React from 'react'
import styled from 'styled-components'
import { Checkbox } from '@material-ui/core/';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {getReport,getReports} from '../../../../crud/analytics.crud'

export const ReportsTable = ({ data, sort, setSort, check, setCheck,userExist}) => {
    const defRow = [
        {
            label: "Report Name",
            value: 'name',
            left: true,
        },
        {
            label: "Created By",
            value: 'created_by',
            left: true,
        },
        {
            label: "Updated On",
            value: 'updated_at',
            left: true,
        }
    ];

    const sortEdit = item => {
        setSort(prev => {
            return {
                value: item.value,
                dir: prev.value === item.value && prev.dir === 'desc' ? 'asc' : 'desc',
            }
        })
    };

    const chbAll = () => {
        if (check.length === data.length) {
            setCheck([])
        } else {
            setCheck(data.map(item => item.id))
        };
    };

    return (
        <ReportTableStyled className="settingtable">
            <table>
                <thead>
                    <tr>
                        <td>
                            <Checkbox
                                checked={(check.length === data.length) && (data.length !== 0)}
                                onChange={chbAll}
                                color="primary"
                            />
                        </td>

                        {defRow.map((item, index) => {
                            return (
                                <td
                                    key={index}
                                    onClick={() => sortEdit(item)}
                                    className={`${item.left ? 'left-content' : ''}${sort === item.value ? ' active' : ''}`}
                                >
                                    { item.label}
                                    <ArrowDown
                                        className={`sort-arrow${sort === item.value ? ' active' : ''}${sort.value === item.value && sort.dir === 'desc' ? ' reverse' : ''}`}
                                    />
                                </td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        return (
                            <TableRow
                                item={item}
                                key={index}
                                check={check}
                                setCheck={setCheck}
                                userExist={userExist}
                            />
                        )
                    })}
                </tbody>
            </table>

            {data.length === 0
                ? (
                    <div
                        style={{
                            margin: '20px auto',
                            textAlign: 'center'
                        }}
                    >
                      No data available in table
                    </div>
                ) : null}
        </ReportTableStyled>
    )
}

const ReportTableStyled = styled.div`
    td.active {
        font-weight: 600;
    }
    
    .sort-arrow {
        opacity: 0.7;
        transition: all .3s ease-out;

        &.active {
        opacity: 1;
        }

        &.reverse {
        transform: rotate(180deg)
        }
    }

    table thead tr td.left-content {
        text-align: left;
    }

    table tbody tr td.left-content {
        text-align: left;
    }
`
 const TableRow = ({ item, check, setCheck,userExist }) => {
    const getCheck = () => {
        return check.findIndex(id => id === item.id) !== -1
    };

    const editCheck = () => {
        let temp = [...check]
        temp.splice(check.indexOf(item.id), 1)
        getCheck()
            ? setCheck(temp)
            : setCheck([...check, item.id])
    };

    const handleClick = (e) =>{
        e.stopPropagation();
      userExist(item.id,2,item)
    }
    return (
        <Row  >
            <td>
                <Checkbox
                    checked={getCheck()}
                    onChange={() => editCheck()}
                    color="primary"
                />
            </td>
            <td className="left-content" onClick ={handleClick} >
                {item.name}
            </td>
            <td className="left-content" onClick ={handleClick}  >
                {`${item.created_by.first_name} ${item.created_by.last_name}`}
            </td>
            <td className="left-content" onClick ={handleClick} >
                {moment(item.updated_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
            </td>
        </Row>
    )
}

const Row = styled.tr`
    .profile-photo {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 15px;
        margin-right: 10px;
    }
`