import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Portlet } from "../../../../../partials/content/Portlet"
import UpdateModal from './UpdateModal'
import PreviewModal from './PreviewModal'
import DeleteModal from './DeleteModal'
import moment from 'moment'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../../../store/ducks/auth.duck'
import * as media from '../../../../../crud/media.crud'
import { deleteFile } from '../../../../../crud/file.crud'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
    Dropdown,
} from 'react-bootstrap'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { getViewVideoUrl } from '../../../../../crud/file.crud';

export default (props) => {
    return <CardStyled>
        {props.data.map(item => {
            return <Card
                key={item.id}
                item={item}
                {...props}
            />
        })}
    </CardStyled>
};

const ImgComponent = ({ src, alt, className }) => {
    const [url, setUrl] = useState('');
    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src]);
    return url && <img src={url} alt={alt} className={className} />
};

const Card = ({
    item,
    loader,
    setLoader,
    id,
    fetchData,
    activePreview,
    setActivePreview,
    data
}) => {
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewUrl, setViewUrl] = useState('');

    useEffect(() => {
        if (activePreview === item.file.id) {
            view();
        } else {
            setPreviewModal(false)
        }
    }, [activePreview, item.file.id]);

    const view = () => {
        setLoader(true);
        getViewVideoUrl(item.file.id)
            .then(res => {
                setLoader(false);
                setViewUrl(res.data.data.url);
                setActivePreview(item.file.id);
                setPreviewModal(true);
            })
            .catch(err => {
                setLoader(false);
                console.log('getViewVideoUrl - err', { ...err })
            })
    };

    const update = () => {
        setUpdateModal(true)
    };

    const download = () => {
        setLoader(true)
        media.getImg(item.file.path)
            .then(res => {
                setLoader(false)
                const link = document.createElement('a');
                const full_name = item.file.file_name + '.' + item.file.extension
                link.href = res;
                link.target = '_blank';
                link.setAttribute('download', `${full_name}`)
                document.body.appendChild(link)
                if (link.hasAttribute('download')) {
                    link.click();
                    setTimeout(() => {
                        link.remove();
                    }, 100)
                } else {
                    link.download = full_name
                    link.click();
                    setTimeout(() => {
                        link.remove();
                    }, 100)
                }
            })
            .catch(err => {
                setLoader(false)
            })
    };

    const remove = () => {
        setDeleteModal(true)
    };

    const handleDelete = () => {
        setLoader(true)
        deleteFile(id, item.id)
            .then(() => {
                setDeleteModal(false)
                setLoader(false)
                fetchData()
                dispatch(AlertState.actions.alert({
                    text: 'Video is deleted',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                setLoader(false)
                dispatch(AlertState.actions.alert({
                    text: response.data.error,
                    variant: false
                }));
            })
    };

    return <div>
        <UpdateModal
            modal={updateModal}
            setModal={setUpdateModal}
            loader={loader}
            setLoader={setLoader}
            item={item}
            id={id}
            fetchData={fetchData}
        />

        <PreviewModal
            modal={previewModal}
            setModal={setPreviewModal}
            item={item}
            activePreview={activePreview}
            setActivePreview={setActivePreview}
            data={data}
            viewUrl={viewUrl}
        />

        <DeleteModal
            modal={deleteModal}
            setModal={setDeleteModal}
            handleDelete={handleDelete}
        />

        <Portlet
            key={item.id}
            className="portlet carditem"
            onClick={() => setMenu(!menu)}
            onMouseLeave={() => setMenu(false)}
        >
            <div onClick={view}>
                <ImgComponent
                    src={item.file?.thumbnail_path}
                    alt='poster'
                    style={{ objectFit: 'contain' }}
                    className="carditem-pre"
                />
            </div>

            <PlayCircleFilledWhiteIcon onClick={view} className="carditem-play" />
            <div className="carditem-name">{item.file.file_name}</div>
            <div className="carditem-date">{moment(item.test_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</div>

            <Dropdown className="carditem-dd">
                <Dropdown.Toggle>
                    <MoreHorizIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={view}>View</Dropdown.Item>
                    <Dropdown.Item onClick={update}>Update</Dropdown.Item>
                    <Dropdown.Item onClick={download}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={remove}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Portlet>
    </div>
};

const CardStyled = styled.div`
    padding: 25px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .carditem {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
        margin: 15px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &-play {
            position: absolute;
            top: 90px;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 50px;
            width: 50px;
            cursor: pointer;
        }

        &-dd {
            position: absolute;
            top: 0;
            right: 0;

            .dropdown {

                &-menu {
                    right: 0px!important;
                    left: auto!important;
                }

                &-toggle{
                    background: transparent!important;

                    &::after {
                        display: none;
                    }

                    .MuiSvgIcon-root {
                        fill: #646c9a!important;
                    }
                }
            }
        }

        &-pre {
            cursor: pointer;
            height: 150px;
            width: 150px;
            margin: 5px;
            margin-bottom: 10px;
            background: #eeeeee;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &-image {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        &-name {
            font-weight: 500;
        }

        .carditem-name {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }
    }
`;
