import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import { DropdownComponent } from './DropdownComponent';
import { connect, useSelector } from 'react-redux';
import './create-report.scss';
import { getGroup, getMetric, getAllUsers } from '../../../../crud/info.crud';
import { runReport, saveReport, exportReport, getReport } from '../../../../crud/analytics.crud';
import DatePicker from './DatePicker';
import { Details } from './Details/Details';
import { SaveModal } from './SaveModal/SaveModal';
import { DropdownMultiselect } from './DropdownMultiselect';
import moment from 'moment'
import Loader from '../../../../components/Loader';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../../store/ducks/auth.duck'

const CreateReport = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let role = useSelector(store => store.auth.user.roles)
    let currentUser = useSelector(store => store.auth.user);
    const [errors, setErrors] = useState({})
    const [data, setData] = useState({
        sex: null,
        date_start: moment().add('years', -1).format('MM/DD/YYYY'),
        date_end: moment().format('MM/DD/YYYY')
    });
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [groupsVis, setGroupsVis] = useState(false);
    const [usersVis, setUsersVis] = useState(false);
    const [isReportDetails, setIsReportDetails] = useState(false);
    const [isSaveModal, setIsSaveModal] = useState(false);
    const [metrics, setMetrics] = useState([]);
    const [tableData, setTableData] = useState([])
    const [tableMeta, setTableMeta] = useState({
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 0,
        total: 0,
        sort: 'change_percent',
        dir: 'desc'
    })

    const [loader, setLoader] = useState(false);
    const [loaderSub, setLoaderSub] = useState(false);
    const [loaderMetric, setLoaderMetric] = useState(false);
    const [loaderUser, setLoaderUser] = useState(false);
    const [report, setReport] = useState(false);
    const countForUpdate = useRef({});
    const response = useRef({})
    const zero = useRef(0)

    useEffect(() => {
        if (location.state?.item.id && data.metric) {
            if ((response.current?.groups?.length === data?.groups?.length) && (response.current?.users?.length === data?.users?.length)) {
                countForUpdate.current = data.metric
                if (countForUpdate.current.id === data.metric.id) {
                    if (zero.current === 0) {
                        handleRun();
                        zero.current += 1;
                    }

                }
            }
        }
    });

    useEffect(() => {
        if (location.state?.item.id && !data.metric) {
            if (countForUpdate.current.id ? false : true) {
                getReport(location.state.item.id)
                    .then(res => {
                        response.current = res.data.data
                        countForUpdate.current += 1;
                        let data = res.data.data;
                        let formatData = (data) => {
                            if (data) {
                                return moment(data).format('MM/DD/YYYY')
                            }
                        };

                        setReport(res.data.data)

                        setData(prev => ({
                            ...prev,
                            sex: { title: data.gender, value: data.gender },
                            age_from: { title: data.age_from, value: data.age_from },
                            age_to: { title: data.age_to, value: data.age_to },
                            date_start: formatData(data.date_start),
                            date_end: formatData(data.date_end)
                        }));
                        let metric = metrics.find((elem) => elem.id === data.metric_id);
                        if (metric) {
                            setData(prev => ({ ...prev, metric: { ...metric, title: metric.metric } }))
                        }
                        if (data?.groups ? data?.groups?.length : false) {
                            let groupsSort = []
                            data.groups.forEach((group) => {
                                let result = groups.find(elem => elem.id === group);

                                if (result) {
                                    groupsSort.push({ ...result, title: result.name })
                                }
                            }
                            )

                            setData(prev => ({ ...prev, groups: groupsSort }))
                            if (groupsSort.length) response.current.groups = groupsSort
                        } else {
                            if (JSON.stringify(data.groups) === undefined) {
                            } else {
                                setData((prev) => ({ ...prev, groups: [] }))
                            }

                        }
                        if (data?.users ? data?.users?.length : false) {
                            let userSort = []
                            data.users.forEach((user) => {
                                let result = users.find(elem => elem.id === user);

                                if (result) {
                                    userSort.push({ ...result, title: result.name })
                                }
                            }
                            )
                            if (userSort.length) response.current.users = userSort
                            setData(prev => ({ ...prev, users: userSort }))
                        } else {
                            if (JSON.stringify(data?.users) === undefined) {
                            } else {
                                setData((prev) => ({ ...prev, users: [] }))
                            }
                        }
                    })
            }
        }

    }, [metrics, location.state?.item.id, data.metric, role, users, groups, response])
    useEffect(() => {

        setLoaderSub(true)
        setLoaderMetric(true)
        setLoaderUser(true)

        setLoaderSub(true)
        getGroup()
            .then(res => {
                setLoaderSub(false)
                setGroupsVis(true)
                let arr = res.data.data.map(elem => ({ ...elem, title: elem.name }))
                setGroups(arr)

            })
            .catch(err => {
                setLoaderSub(false)
                console.log(err)
            })

        getMetric('analytic')
            .then(res => {
                setLoaderMetric(false)
                let arr = res.data.data.filter(i => i.pseudonym !== 'progress_photo').map(elem => ({ ...elem, title: elem.metric }))
                setMetrics(arr);
            })
            .catch(err => {
                setLoaderMetric(false)
                console.log(err)
            })
        return () => {
            location.state = null
        }
    }, []);

    useEffect(() => {
        getAllUsers()
            .then(res => {
                setLoaderUser(false)
                setUsersVis(true)
                let arr = res.data.data.map(elem => ({ ...elem, title: elem.name }));
                if (role !== 'supervisor') {
                    setUsers(arr.filter(item => (
                        (item.type !== 'admin') &&
                        (item.is_super_admin !== 1) &&
                        (currentUser.firstName + ' ' + currentUser.lastName !== item.title)
                    )))
                } else {
                    setUsers(arr.filter(item => (
                        (item.type !== 'admin') &&
                        (item.is_super_admin !== 1) &&
                        (item.type !== 'supervisor') &&
                        (currentUser.firstName + ' ' + currentUser.lastName !== item.title)
                    )))
                }
            })
            .catch(err => {
                setLoaderUser(false)
                console.log(err)
            })
    }, [role])

    const getAgeArr = type => {
        const arr = [];
        let val = 18;
        if (type === 'age_to') val = data.age_from?.title || 18;
        for (let i = val; i <= 120; i++) arr.push({ title: i + '', value: i });
        return arr;
    };

    const validate = () => {
        let tempError = {}

        if (!data.metric) {
            tempError = { ...tempError, metric_id: 'Metric field is required' }
        }

        // if (!data.users || !data.users.length) {
        //     tempError = { ...tempError, users: 'Users field is required' }
        // }

        // if (plan === 'Enterprise') {
        //     if (!data.groups || !data.groups.length) {
        //         tempError = { ...tempError, groups: 'Groups field is required' }
        //     }
        // }

        // if (!data.sex) {
        //     tempError = { ...tempError, sex: 'Sex field is required' }
        // }

        // if (!data.age_to) {
        //     tempError = { ...tempError, age_to: 'Age to field is required' }
        // }

        // if (!data.age_from) {
        //     tempError = { ...tempError, age_from: 'Age from field is required' }
        // }

        if (data.date_start && (
            (parseInt(moment(data.date_start, 'MM/DD/YYYY').format('YYYY')) < 1970) ||
            (moment(data.date_start, 'MM/DD/YYYY') === 'Invalid date') ||
            data.date_start.indexOf(' ') !== -1
        )) {
            tempError = { ...tempError, date_start: 'The minimum value of the year is 1970' }
        }

        if (data.date_end && (
            (parseInt(moment(data.date_end, 'MM/DD/YYYY').format('YYYY')) < 1970) ||
            (moment(data.date_end, 'MM/DD/YYYY') === 'Invalid date') ||
            data.date_end.indexOf(' ') !== -1
        )) {
            tempError = { ...tempError, date_end: 'The minimum value of the year is 1970' }
        }

        setErrors(tempError)
        return JSON.stringify(tempError) === '{}'
    };

    useEffect(() => {
        //setLoader(true);
        isReportDetails && handleRun()
        // eslint-disable-next-line
    }, [tableMeta]);

    const handleRun = () => {
        if (!validate()) {
            return;
        } else {
            setErrors({})
            setLoader(true)
            runReport({
                "report": {
                    "metric_id": data.metric ? data.metric.id : null,
                    "age_from": data.age_from ? data.age_from.value : null,
                    "age_to": data.age_to ? data.age_to.value : null,
                    "gender": data.sex ? data.sex.value : null,
                    "date_start": data.date_start,
                    "date_end": data.date_end
                },
                "groups": groupsVis ? data.groups ? data.groups.map(i => i.id) : [] : null,
                "users": usersVis ? data.users ? data.users.map(i => i.id) : [] : null
            }, tableMeta)
                .then(res => {
                    setLoader(false)
                    setTableData(res.data.data.map(item => ({ ...item, change_percent: parseFloat(item.change_percent).toFixed(2) })))
                    if (JSON.stringify(tableMeta) !== JSON.stringify({ ...tableMeta, ...res.data.meta })) {
                        setTableMeta({ ...tableMeta, ...res.data.meta })
                    }
                    if (res.data?.meta?.last_page < res.data?.meta?.current_page) {
                        setTableMeta({ current_page: res.data.meta.last_page })
                    }
                    if (!res.data?.meta) tableMeta.total && setTableMeta(prev => ({ ...prev, total: 0 }))
                    setIsReportDetails(true)
                })
                .catch(({ response }) => {
                    setLoader(false)
                    if (response && response.data && response.data.errors) {
                        const er = Object.keys(response.data.errors)
                        let err = {}
                        er.forEach(key => {
                            let keys = key.split('.')
                            if ((keys[0] === 'report') && keys[1]) {
                                err[keys[1]] = typeof response.data.errors[key] === 'string' ? response.data.errors[key] : response.data.errors[key][0]
                            } else {
                                err[keys[0]] = typeof response.data.errors[key] === 'string' ? response.data.errors[key] : response.data.errors[key][0]
                            }
                        })
                        setErrors(err)
                    }
                })
        };
    };

    const handleExport = () => {
        if (!validate()) {
            return;
        } else {
            setErrors({})
            setLoader(true)
            exportReport({
                "report": {
                    "metric_id": data.metric ? data.metric.id : null,
                    "age_from": data.age_from ? data.age_from.value : null,
                    "age_to": data.age_to ? data.age_to.value : null,
                    "gender": data.sex ? data.sex.value : null,
                    "date_start": data.date_start,
                    "date_end": data.date_end
                },
                "groups": groupsVis ? data.groups ? data.groups.map(i => i.id) : [] : [],
                "users": usersVis ? data.users ? data.users.map(i => i.id) : [] : []
            }, tableMeta)
                .then(response => {
                    setLoader(false)
                    const nameMetric = data.metric.metric;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Analytics-Report-${nameMetric}-${moment().format('YYYY.MM.DD')}.csv`);
                    link.click();
                })
                .catch(({ response }) => {
                    setLoader(false)
                    if (response && response.data && response.data.errors) {
                        const er = Object.keys(response.data.errors)
                        let err = {}
                        er.forEach(key => {
                            let keys = key.split('.')
                            if ((keys[0] === 'report') && keys[1]) {
                                err[keys[1]] = typeof response.data.errors[key] === 'string' ? response.data.errors[key] : response.data.errors[key][0]
                            } else {
                                err[keys[0]] = typeof response.data.errors[key] === 'string' ? response.data.errors[key] : response.data.errors[key][0]
                            }
                        })
                        setErrors(err)
                    }
                })
        };
    };

    const onOpenSaveModal = () => {
        if (!validate()) {
            return;
        } else {
            setIsSaveModal(true);
        };
    };

    const [redirect, setRedirect] = useState(null)

    const handleSave = () => {
        const fData = {
            "report": {
                "name": data.name ? data.name : null,
                "description": data.description ? data.description : null,
                "metric_id": data.metric ? data.metric.id : null,
                "age_from": data.age_from ? data.age_from.value : null,
                "age_to": data.age_to ? data.age_to.value : null,
                "gender": data.sex ? data.sex.value : null,
                "report_date": data.report_date,
                "date_start": data.date_start,
                "date_end": data.date_end
            },
            "groups": groupsVis ? data.groups ? data.groups.map(i => i.id) : [] : [],
            "users": usersVis ? data.users ? data.users.map(i => i.id) : [] : []
        }
        setLoader(true)
        setErrors({})
        saveReport(fData)
            .then(() => {
                setLoader(false)
                setIsSaveModal(false);
                setRedirect(<Redirect to={`/analytics/reports-list`} />)
                dispatch(AlertState.actions.alert({
                    text: 'Report is saved',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                setLoader(false)
                if (response && response.data && response.data.errors) {
                    const er = Object.keys(response.data.errors)
                    let err = {}
                    er.forEach(key => {
                        let keys = key.split('.')
                        if ((keys[0] === 'report') && keys[1]) {
                            err[keys[1]] = typeof response.data.errors[key] === 'string' ? response.data.errors[key] : response.data.errors[key][0]
                        } else {
                            err[keys[0]] = typeof response.data.errors[key] === 'string' ? response.data.errors[key] : response.data.errors[key][0]
                        }
                    })
                    setErrors(err)
                }
            })
    }

    return (
        <>
            {redirect}
            <Loader visible={loaderSub || loaderMetric || loaderUser || loader} />

            {isSaveModal && (
                <SaveModal
                    setModal={setIsSaveModal}
                    handleSave={handleSave}
                    name={data.name}
                    description={data.description}
                    setData={v => { setData({ ...data, ...v }) }}
                    errors={errors}
                />
            )}

            <div className="create-report-page">
                <div className="kt-portlet">
                    <div className="kt-portlet__body">
                        <div className="top-buttons-wrapper">
                            <div className="page-title">{report?.name || 'New Report'}</div>
                            <div className="button-wrapper">
                                <Button
                                    variant="info"
                                    style={{ margin: 5 }}
                                    onClick={handleExport}
                                    disabled={!isReportDetails || (tableData.length === 0)}
                                >Export</Button>
                                <Button
                                    variant="info"
                                    style={{ margin: 5 }}
                                    onClick={onOpenSaveModal}
                                    disabled={!isReportDetails}
                                >Save</Button>
                                <Button
                                    variant="info"
                                    style={{ margin: 5 }}
                                    onClick={handleRun}
                                >Run</Button>
                            </div>
                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">REPORT FILTERS</h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="body-wrapper">
                                    <div className="column">
                                        <div className="elem-container">
                                            <div className="elem-title">Metric:</div>
                                            <DropdownComponent
                                                label="Select Metric"
                                                error={errors.metric_id}
                                                setData={setData}
                                                options={metrics.length ? metrics : []}
                                                name={'metric'}
                                                value={data.metric?.title || ''}
                                                data={data}
                                            />
                                        </div>

                                        <div className="elem-container">
                                            <div className="elem-title">Age from:</div>
                                            <DropdownComponent
                                                label="Select"
                                                error={errors.age_from}
                                                setData={setData}
                                                options={getAgeArr()}
                                                name="age_from"
                                                value={data.age_from?.title || ''}
                                                data={data}
                                            />
                                        </div>

                                        <div className="elem-container">
                                            <div className="elem-title">Age to:</div>
                                            <DropdownComponent
                                                label="Select"
                                                error={errors.age_to}
                                                setData={setData}
                                                options={getAgeArr('age_to')}
                                                name="age_to"
                                                value={data.age_to?.title || ''}
                                                data={data}
                                            />
                                        </div>

                                        <div className="elem-container">
                                            <div className="elem-title">Sex:</div>
                                            <DropdownComponent
                                                label="Select a sex"
                                                error={errors.sex}
                                                options={[{ title: 'Male', value: 'Male' }, { title: 'Female', value: 'Female' }]}
                                                setData={setData}
                                                name="sex"
                                                value={data.sex?.title || ''}
                                                data={data}
                                            />
                                        </div>
                                        {groupsVis && (
                                            <div className="elem-container">
                                                <div className="elem-title">Group:</div>
                                                <DropdownMultiselect
                                                    label="Select group"
                                                    error={errors.groups}
                                                    options={groups.length
                                                        ? groups.sort((a, b) => {
                                                            const textA = a.name.toLowerCase();
                                                            const textB = b.name.toLowerCase();
                                                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                                        })
                                                        : []
                                                    }
                                                    setData={setData}
                                                    multiple={true}
                                                    name='groups'
                                                    data={data}
                                                    value={data.groups || []}
                                                />
                                            </div>
                                        )}
                                        {usersVis &&
                                            <div className={`elem-container ${groupsVis ? "elem-container--margin" : ""}`}>
                                                <div className="elem-title">User:</div>
                                                <DropdownMultiselect
                                                    label="Select user"
                                                    error={errors.users}
                                                    setData={setData}
                                                    name='users'
                                                    options={users.length
                                                        ? users.sort((a, b) => {
                                                            const textA = a.name.toLowerCase();
                                                            const textB = b.name.toLowerCase();
                                                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                                        })
                                                        : []
                                                    }
                                                    data={data}
                                                    value={data.user || []}
                                                />
                                            </div>
                                        }
                                    </div>

                                    <div className="column" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div className="date-container">
                                            <div className="elem-title">Date start:</div>
                                            <DatePicker
                                                label="Start"
                                                future={true}
                                                format={'MM/DD/YYYY'}
                                                onChange={(value) => {
                                                    if (value === '  /  /    ') {
                                                        setData(prev => ({ ...prev, date_start: null }))
                                                    } else setData(prev => ({ ...prev, date_start: value }))
                                                }}
                                                value={data.date_start || ''}
                                                error={!!errors.date_start}
                                                helperText={errors.date_start ? errors.date_start : ''}
                                            />
                                        </div>

                                        <div className="date-container">
                                            <div className="elem-title">Date end:</div>
                                            <DatePicker
                                                label="End"
                                                future={true}
                                                format={'MM/DD/YYYY'}
                                                onChange={(value) => {
                                                    if (value === '  /  /    ') {
                                                        setData(prev => ({ ...prev, date_end: null }))
                                                    } else setData(prev => ({ ...prev, date_end: value }))
                                                }}
                                                value={data.date_end || ''}
                                                error={!!errors.date_end}
                                                helperText={errors.date_end ? errors.date_end : ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-wrapper">
                            {isReportDetails && (
                                <Details
                                    data={tableData}
                                    meta={tableMeta}
                                    setMeta={v => setTableMeta({ ...tableMeta, ...v })}
                                    role={role}
                                    usersVis={usersVis}
                                    groupsVis={groupsVis}
                                />
                            )}
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

const mapState = state => ({
    roles: state.auth.user.roles,
})

export default connect(mapState)(CreateReport);
