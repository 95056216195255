import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const getFiles = (id, { per_page = '10', current_page = '1', sort = 'created_at', dir = "desc", search = "", media_type = "images" }) => {
    return axios.get(`${BASE_URL}profile/${id}/gallery?sortBy[0][name]=${sort}&sortBy[0][dir]=${dir}`, {
        params: {
            search: !!search ? search : null,
            perPage: per_page || 10,
            page: current_page || 1,
            media_type: media_type
        }
    })
};

export const deleteFile = (profileID, galleryID) => {
    return axios.delete(`${BASE_URL}profile/${profileID}/gallery/${galleryID}`)
};

export const deleteFiles = (profileID, galleryArr) => {
    return axios.delete(`${BASE_URL}profile/${profileID}/gallery/mass/destroy`, {
        data: { ids: galleryArr }
    });
};

export const downloadFiles = (profileID, galleryArr) => {
    return axios({
        url: `${BASE_URL}profile/${profileID}/gallery/mass/download`,
        methods: 'GET',
        responseType: 'blob',
        params: { ids: [...galleryArr] }
    })
};

export const setFile = (id, data, is_progress) => {
    if (is_progress) {
        return axios.post(`${BASE_URL}profile/${id}/gallery`, { ...data, is_progress: 1 })
    } else {
        return axios.post(`${BASE_URL}profile/${id}/gallery`, data)
    }
};

export const updateFile = (id, data) => {
    return axios.put(`${BASE_URL}profile/${id}/gallery/${data.id}`, data)
};

export const deleteReports = arr => {
    return axios.delete(`${BASE_URL}analytic/report-mass-destroy`, { data: { "ids": [...arr] } })
};

export const getViewVideoUrl = id => {
    return axios.get(`${BASE_URL}storage/video/signed-url/${id}`);
};

export const postProfilePhoto = (id, file_id) => {
    return axios.post(`${BASE_URL}profile/${id}/avatar`, { file_id })
};

export const postUserePhoto = (id, file_id) => {
    return axios.post(`${BASE_URL}user/${id}/avatar`, { file_id })
};
