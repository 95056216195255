import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { FormComponent } from './FormComponent/FormComponent';
import { fetchForm } from '../../../../crud/forms';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import './createform.scss';

export const CreateForm = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const subscription = useSelector(state => state.user_info.subscription);
    const [redirect, setRedirect] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState(null);

    // protects route
    useEffect(() => {
        if (subscription.plan && subscription.plan.name !== 'Gym' && subscription.plan.name !== 'Enterprise') {
            let errorText = 'User does not have the right plan';
            setRedirect(true);
            dispatch(AlertState.actions.alert({
                text: errorText,
                variant: false,
            }));
        }
    }, [subscription]);

    // edit form
    useEffect(() => {
        if (match.params.id) {
            setLoader(true);
            fetchForm(match.params.id)
                .then(res => {
                    setData(res.data.data);
                    setLoader(false);
                })
                .catch(err => {
                    setLoader(false);
                    let errText = catchErrorMessage(err) === 'Element does not found' ? 'Form does not exist' : 'Save form error';
                    dispatch(AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    }));
                    history.push('/library/forms/list');
                })
        }
    }, [match.params.id]);

    if (subscription.plan && subscription.plan.name !== 'Gym' && subscription.plan.name !== 'Enterprise') {
        return <Loader visible={true} />;
    } else {
        return (
            <div className='create-form'>
                {redirect && <Redirect to="/profile-list" />}
                <Loader visible={loader} />
                <div className='create-form__page-title'>{data?.name ? 'Edit Form' : 'Create Form'}</div>
                <FormComponent setLoader={setLoader} data={data} formId={match.params.id} />
            </div>
        )
    }
};
