import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../components/Spacer';
import ModalDeactivateCRM from './ModalDeactivateCRM';
import { fetchCRMList } from '../../../../crud/crm.crud';
import { useDispatch } from 'react-redux';
import { setCRMAction } from '../../../../store/actions';
import Loader from "../../../../components/Loader";

export const CRMConnections = ({ crm }) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        fetchCRMList()
            .then(res => {
                setLoader(false);
                dispatch(setCRMAction(res.data.data))
            })
            .catch(err => {
                setLoader(false);
            })
    }, []);

    return (
        <div className="crm-info">
            <Loader visible={loader} />

            <ModalDeactivateCRM
                modal={modal}
                setModal={setModal}
                crm={crm}
            />
            <div className="crm-info__row">
                <div className="crm-info__cell">CRM</div>
                <div className="crm-info__cell">{crm?.name}</div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">Number of Locations</div>
                <div className="crm-info__cell">{crm?.details?.number_locations}</div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">Status</div>
                <div className="crm-info__cell">{crm?.activation?.is_activate ? 'Active' : 'Not Active'}</div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">Last Sync</div>
                <div className="crm-info__cell">
                    {crm?.last_sync
                        ? moment(crm.last_sync).format('MM/DD/YYYY hh:mm:ss a')
                        : 'sync has not happened yet'
                    }
                </div>
            </div>
            <SpacerHorizontal height="30px" />
            <Button
                variant="info"
                className="button"
                onClick={() => setModal(true)}
            >Deactivate</Button>
        </div>
    )
};
