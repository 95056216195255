import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default ({ isDeleteModal, setIsDeleteModal, handleDelete }) => {
    return (
        <Modal
            show={isDeleteModal}
            onHide={() => setIsDeleteModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete selected notes?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{fontWeight: '400', fontSize: '1.3rem', color: '#48465b'}}>
                    If you proceed with this action,
                    the selected notes will be deleted permanently.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setIsDeleteModal(false)}
                >Cancel</Button>
                <button
                    className="btn-blue btn btn-primary"
                    onClick={handleDelete}
                >Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}