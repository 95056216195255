import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import Logout from '../../../pages/auth/Logout'
import { getInterpretations, updateInterpretations } from "../../../crud/info.crud";

export default ({ setLoader, setRedirect }) => {

    const dispatch = useDispatch();
    const [data, setData] = useState(['Update Testing Metrics'])

    const [loaderInt, setLoaderInt] = useState(false)
    const [loaderUpdateInt, setLoaderUpdateInt] = useState(false)

    useEffect(() => {
        update()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoader(loaderInt || loaderUpdateInt)
        // eslint-disable-next-line
    }, [loaderInt, loaderUpdateInt])

    const update = () => {
        setLoaderInt(true)
        getInterpretations()
            .then(res => {
                setData(res.data.data)
                setLoaderInt(false)
            })
            .catch(({ response }) => {
                setLoaderInt(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            });
    }

    const upResult = (id, value) => {
        setLoaderUpdateInt(true)

        updateInterpretations({id, value})
            .then(res => {
                setLoaderUpdateInt(false)
                update()
                dispatch(AlertState.actions.alert({
                    text: 'Results Interpretation is updated',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response && 
                response.data && 
                dispatch(AlertState.actions.alert({
                    text: response.data.message || response.data.error,
                    variant: false
                }));
                setLoaderUpdateInt(false)
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else if(response && (response.status === 403)) {
                    setRedirect(<Redirect to="/profile-list" />)
                } 
            });
    }

    return (
        <TabWrap className="settingtable">
            <thead>
                <tr>
                    <td>
                        Category
                    </td>
                    <td style={{textAlign: 'start'}}>Metric</td>
                    <td style={{textAlign: 'start'}}>Interpretation</td>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => {
                    return ( 
                        <TableRow
                            key={`privileges-${index}`}   
                            item={item}
                            change={upResult}
                        />
                    )
                })}
            </tbody>
        </TabWrap>
    )
}

const TabWrap = styled.table`
    
`

const TableRow = ({item, change}) => {
    const [elem, setElem] = useState({})
    const [touched, setTouched] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        setElem(item)
    }, [item])

    return (
        <tr>
            <td className="min">{elem.category}</td>                    
            <td className="min" style={{textAlign: 'start'}}>
                {elem.metric}
            </td>
            <td className="min" style={{width: '65%'}}>
                <TextField
                    variant="outlined"
                    type="text"
                    className='textField'
                    value={elem.interpretation || ''}
                    onBlur={e => {
                        setElem({
                            ...elem,
                            interpretation: e.target.value
                        })
                        setTouched(true)
                        if (e.target.value.length < 20) {
                            setError('The value should be at least 20 of characters.')
                        } else {
                            setError('')
                            change(elem.id, e.target.value)
                        }
                    }}
                    inputProps={{
                        maxLength: 1500
                    }} 
                    onChange={e => setElem({
                        ...elem,
                        interpretation: e.target.value
                    })}
                    helperText={touched && error}
                    error={Boolean(touched && error)}
                    margin="normal"
                />
            </td>
        </tr>
    )
}