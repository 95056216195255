import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import { fetchMappings } from '../../../crud/crm.crud';
import DropdownComponent from '../../../components/DropdownComponent';
import { getFields, updateMappings, fetchCRMList } from '../../../crud/crm.crud';
import { useDispatch, useSelector } from 'react-redux';
import { setCRMFields, resetCRMFields, setCRMAction } from '../../../store/actions';
import { useHistory } from 'react-router';
import Loader from '../../../components/Loader';
import * as AlertState from '../../../../app/store/ducks/auth.duck';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import question from '../../../../_metronic/_assets/media/svg-icon/question.svg';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const ModalCreateFromCRM = ({
    modal,
    setModal,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [usersList, setUsersList] = useState([]);
    const [formData, setFormData] = useState(null);
    const [error, setError] = useState({
        isError: false,
        message: ''
    });
    const [loader, setLoader] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.activation?.is_activate === true));
    const crmFields = useSelector(state => state.crmFields);

    const loadData = (search) => {
        if (crm?.name === 'Motionsoft' && !search) return;
        setLoader(true)
        fetchMappings('users', 500, 1, null, 'asc', 1, search, 0, 1)
            .then(res => {
                setLoader(false);
                const list = res.data.data.map(elem => ({ ...elem, title: elem.external_name || '', value: elem.external_id }))
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                setUsersList(sorted);
            })
            .catch(err => {
                setLoader(false);
                console.log('fetchFromCRM - err', { ...err })
            })
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (formData) {
            const id = crm?.name === 'MindBody'
                ? formData.id
                : crm.name === 'Motionsoft'
                    ? formData.external_id
                    : '';

            getFields('users', id)
                .then(res => {
                    dispatch(setCRMFields(res.data.data));
                })
                .catch(err => {
                    dispatch(resetCRMFields());
                    console.log('getFields - err', { ...err });
                })
        } else {
            dispatch(resetCRMFields());
        }
    }, [formData]);

    const handleClose = () => {
        setError({
            isError: false,
            message: ''
        });
        setModal(false);
        setFormData(null);
    };

    const validate = () => {
        let isValid = true;

        if (!formData) {
            isValid = false;
            setError({
                isError: true,
                message: 'Check user'
            })
        };

        return isValid;
    };

    const handleContinue = () => {
        if (validate()) {
            history.push('/user-create');
        };
    };

    const handleUpdate = () => {
        setLoader(true);
        updateMappings('users')
            .then(() => {
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'CRM list updating has started',
                    variant: true,
                }));
                fetchCRMList()
                    .then(res => {
                        setLoader(false);
                        dispatch(setCRMAction(res.data.data))
                    })
                    .catch(err => {
                        setLoader(false);
                    });
                loadData();
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
                setLoader(false);
                handleClose()
            })
    };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader} />

            <StyledModalCreateFromCRM>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>
                <Modal.Title>
                    <h4 className="title">Create User from CRM</h4>
                </Modal.Title>
                <Modal.Body>
                    {crm?.name !== "Motionsoft" && (
                        <div className='d-flex align-items-center' style={{ margin: '0 0 10px 4px' }}>
                            {crm?.last_update_started?.users
                                ? (
                                    <div className='d-flex align-items-center'>
                                        <div className="last-sync-block">
                                            {`CRM list last updated ${moment(crm.last_update_started?.users).format('MM/DD/YYYY hh:mm:ss a')}`}
                                        </div>
                                        <StyledToolTip
                                            title={<>Synchronization may take some time. <br />
                                                To see if the synchronization is complete, please refresh the page and observe the changes in this table.
                                            </>}
                                            placement="bottom"
                                        >
                                            <img src={question} style={{ cursor: 'pointer', margin: '0 5px 0 10px', height: '18px' }} alt="tooltip" />
                                        </StyledToolTip>
                                    </div>
                                )
                                : <div style={{ marginRight: '10px' }}>CRM has not been updated yet.</div>
                            }
                            <span onClick={handleUpdate} className='update-link'>
                                Update Now
                            </span>
                        </div>
                    )}

                    <h5 className="subtitle">CRM User</h5>
                    <DropdownComponent
                        options={usersList}
                        setFormData={setFormData}
                        label="Start typing to search CRM User"
                        width="100%"
                        error={error.isError}
                        helperText={error.message}
                        value={formData}
                        fetchOptions={loadData}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleContinue}
                        className="btn-blue"
                        disabled={!formData || !crmFields}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </StyledModalCreateFromCRM>
        </Modal >
    )
};

const StyledModalCreateFromCRM = styled.div`
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        color: #666E9C;
        margin: 10px 0 0 20px;
    }

    .subtitle {
        color: #666E9C;
        margin: 10px 0 20px 5px;
        font-weight: 600;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        height: 50px;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiInputLabel-formControl {
        top: 4px;
    }

    .update-link {
        cursor: pointer;
        color: blue;
    }
`;

export default ModalCreateFromCRM;
