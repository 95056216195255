import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './report-details.scss';
import styled from 'styled-components'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import moment from 'moment'
import { Pagination } from 'react-bootstrap';
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    OutlinedInput,
} from '@material-ui/core';

const defRow = [
    {
        label: 'Profile',
        value: 'profiles.first_name',
        left: true,
    },
    {
        label: 'Last Measurement',
        value: 'last_measurement',
        left: true,
    },
    {
        label: 'Last Date',
        value: 'last_date',
        left: true,
    },
    {
        label: 'First Measurement',
        value: 'first_measurement',
        left: true,
    },
    {
        label: 'First Date',
        value: 'first_date',
        left: true,
    },
    {
        label: 'Absolute Change',
        value: 'absolute_change',
        left: true,
    },
    {
        label: '% Change',
        value: 'change_percent',
        left: true,
    },
    {
        label: 'Duration (days)',
        value: 'duration_days',
        left: true,
    },
    {
        label: 'Total Data Points',
        value: 'total_data_points',
        left: true,
    },
    {
        label: 'Group',
        value: 'group',
        left: true,
        roleHide: 'staff'
    },
    {
        label: 'User',
        value: 'users',
        left: true,
        roleHide: 'staff'
    },
];

export const ReportDetails = ({ data, meta, setMeta, role, usersVis, groupsVis }) => {
    const inputLabel = useRef();
    const [labelWidth, setLabelWidth] = useState(0);
    const plan = useSelector(state => state.user_info.subscription?.plan?.name);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    const sortEdit = item => {
        setMeta({
            sort: item.value,
            dir: meta.sort === item.value && meta.dir === 'desc' ? 'asc' : 'desc',
        })
    };

    return (
        <ReportTableStyled className="settingtable">
            <table>
                <thead>
                    <tr>
                        {defRow.map((item, index) => {
                            if (item.label === 'Group' && plan === 'Gym') {
                                return null;
                            } else if (item.label === 'Group' && role === 'staff') {
                                return null;
                            } else if (item.label === 'Group' && !groupsVis) {
                                return null
                            } else if (item.label === 'User' && role === 'staff') {
                                return null;
                            } else if (item.label === 'User' && (plan !== 'Gym' && plan !== 'Enterprise')) {
                                return null;
                            } else if (item.label === 'User' && !usersVis) {
                                return null;
                            } else return item.roleHide === role ? null : (
                                <td
                                    key={index}
                                    onClick={() => sortEdit(item)}
                                    className={`${item.left ? 'left-content' : ''}${meta.sort === item.value ? ' active' : ''}`}
                                >
                                    <div className="table-sort">
                                        {item.label}
                                        <ArrowDown
                                            className={`sort-arrow${meta.sort === item.value ? ' active' : ''}${meta.sort === item.value && meta.sort.dir === 'desc' ? ' reverse' : ''}`}
                                        />
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="left-content">
                                    {item.profile}
                                </td>

                                <td className="left-content">
                                    {item.last_measurement ?? '--'}
                                </td>

                                <td className="left-content">
                                    {item.last_date ? moment(item.last_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '--'}
                                </td>

                                <td className="left-content">
                                    {item.first_measurement ?? '--'}
                                </td>

                                <td className="left-content">
                                    {item.first_date ? moment(item.first_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '--'}
                                </td>

                                <td className="left-content">
                                    {item.absolute_change ?? '--'}
                                </td>

                                <td className="left-content">
                                    {console.log("Ite", item.change_percent)}
                                    {isNaN(item.change_percent) ? '--' : `${item.change_percent}%`}
                                </td>

                                <td className="left-content">
                                    {item.duration_days ?? '--'}
                                </td>

                                <td className="left-content">
                                    {item.total_data_points ?? '--'}
                                </td>

                                {role === 'staff' || plan === 'Gym' || !groupsVis ? null :
                                    <td className="left-content">
                                        {item.groups.length ? item.groups.join(', ') : '--'}
                                    </td>
                                }

                                {((plan === 'Gym' || plan === 'Enterprise') && role !== 'staff') &&
                                    usersVis &&
                                    <td className="left-content">
                                        {item.users.length ? item.users.join(', ') : '--'}
                                    </td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {data.length === 0
                ? (
                    <div
                        style={{
                            margin: '20px auto',
                            textAlign: 'center'
                        }}
                    >
                        No data for the selected parameters
                    </div>
                ) : null}

            <div className="pagination-wrap">
                <Pagination>
                    <Pagination.First
                        onClick={() => setMeta({ ...meta, current_page: 1 })}
                        disabled={meta.current_page === 1}
                    />
                    <Pagination.Prev
                        onClick={() => setMeta({ ...meta, current_page: meta.current_page - 1 })}
                        disabled={meta.current_page === 1}
                    />
                    <Pagination.Item>{meta.current_page}</Pagination.Item>
                    <Pagination.Next
                        onClick={() => setMeta({ ...meta, current_page: meta.current_page + 1 })}
                        disabled={meta.current_page === meta.last_page}
                    />
                    <Pagination.Last
                        onClick={() => setMeta({ ...meta, current_page: meta.last_page })}
                        disabled={meta.current_page === meta.last_page}
                    />
                </Pagination>
                <div className="pagination-show">
                    <FormControl variant="outlined" className="formControl">
                        <InputLabel ref={inputLabel}>Show Entries</InputLabel>
                        <Select
                            value={meta.per_page}
                            input={<OutlinedInput labelWidth={labelWidth} />}
                            onChange={e => {
                                setMeta({ ...meta, per_page: e.target.value, current_page: 1 })
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant="body1" gutterBottom>
                        {
                            meta.current_page &&
                            `Showing
                                ${(meta.current_page - 1) * meta.per_page + (meta.total ? 1 : 0)} - 
                                ${meta.current_page * meta.per_page > meta.total ? meta.total : meta.current_page * meta.per_page}
                            of ${meta.total}`
                        }
                    </Typography>
                </div>
            </div>
        </ReportTableStyled>
    )
}

const ReportTableStyled = styled.div`

    .table-sort {
        display: flex;
        align-items: center;
    }

    td.active {
        font-weight: 600;
    }
    
    .sort-arrow {
        opacity: 0.7;
        transition: all .3s ease-out;

        &.active {
        opacity: 1;
        }

        &.reverse {
        transform: rotate(180deg)
        }
    }

    table thead tr td.left-content {
        text-align: left;
    }

    table tbody tr td.left-content {
        text-align: left;
    }

    .profile-photo {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 15px;
        margin-right: 10px;
    }

    .pagination-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .desktop-vis {
            display: none;

            @media screen and (min-width: 760px) {
                display: flex;
            }
        }

        .mobile-width-100p {
            @media screen and (max-width: 760px) {
                width: 100%;
            }
        }

        .mobile-vis {
            display: flex;

            @media screen and (min-width: 760px) {
                display: none;
            }
        }

        .header-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .MuiTab-wrapper {
            font-size: 12px;
            font-weight: 700;
        }

        .btn {
            margin-right: 15px;
        }

        .edit-all {
            height: 100%;
            border-radius: 4px;
            background-color: #eef1ff;
            padding: 0 12px;
            font-weight: 600;
            font-size: 13px;
            line-height: 19px;
            color: #5d78ff;
            display: flex;
            align-items: center;
        }

        .pagination {
            margin: 0;

            @media (max-width: 600px) {
                margin: 10px auto;
            }

            &-wrap {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 10px;

                .MuiSelect-select {
                    padding: 7px;
                }
            }

            &-show {
                display: flex;
                align-items: center;
            }
        }

        .formControl {
            width: 150px;
            margin: 5px 10px;
        }
    }
`