import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import { userInfoReducer } from './reducers/userInfoReducer';
import { fieldsCRMReducer } from './reducers/fieldsCRMReducer';
import { applicationDataReducer } from './reducers/applicationDataReducer';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  user_info: userInfoReducer,
  crmFields: fieldsCRMReducer,
  appData: applicationDataReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
};