import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import DropZone from 'react-dropzone-uploader';
import Cancel from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import {
    OverlayTrigger,
    Tooltip,
    ProgressBar
} from 'react-bootstrap'
import 'react-dropzone-uploader/dist/styles.css'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'

export default ({ setData, label, maxFiles = 10, tab }) => {
    const authToken = useSelector(state => state.auth.authToken)
    const dispatch = useDispatch();

    const validate = (file) => {
        const { meta: { size } } = file;
        const supportFileFormat = [
            'jpg', 'jpeg', 'png', 'bmp', 'gif', 'heic',
            'doc', 'dock', 'docx', 'xls', 'xlsx', 'zip', 'pdf', 'txt', 'scv',
            'webm', 'mp4', 'mov',
        ]
        const fileType = file.meta.type.split('/')[0];
        const fileFormat = file.meta.name.split('.').pop();
        if (!supportFileFormat.find(item => item === fileFormat)) {
            dispatch(AlertState.actions.alert({
                text: 'Unknown file format',
                variant: false
            }));
            return true
        } else if (fileType === 'image' && (size > 6291456)) {
            dispatch(AlertState.actions.alert({
                text: 'File size should not exceed 6MB',
                variant: false
            }));
            return true
        } else if (fileType === 'video' && (size > 31457280)) {
            dispatch(AlertState.actions.alert({
                text: 'File size should not exceed 30MB',
                variant: false
            }));
            return true
        } else if (fileType === 'application' && (size > 10485760)) {
            dispatch(AlertState.actions.alert({
                text: 'File size should not exceed 10MB',
                variant: false
            }));
            return true
        }
        return false
    }

    const handleChangeStatus = (param, status) => {
        if ((status === 'done') || (status === 'error_upload') || (status === 'removed') || (status === 'error_validation')) {
            let button = document.querySelector('button.dzu-submitButton');
            if (button) {
                button.click();
            };
        }
    };

    const getUploadParams = ({ file }) => {
        const body = new FormData()
        body.append('file', file)
        return {
            url: `${process.env.REACT_APP_API_URL}upload-file`,
            headers: {
                Authorization: `Bearer ${authToken}`
            },
            body
        }
    }

    const handleSubmit = (files) => {
        const res = files.map(f => {
            let res = {
                success: f.xhr && f.xhr.response && JSON.parse(f.xhr.response) && JSON.parse(f.xhr.response).data,
                id: f.meta.id,
                meta: f.meta
            }
            if (res.success) {
                res.data = JSON.parse(f.xhr.response).data
            } else {
                res.error = f.xhr && f.xhr.response && JSON.parse(f.xhr.response).errors.image ? JSON.parse(f.xhr.response).errors.image[0] : 'Error'
            }
            return res
        })
        setData(res)
    };

    const Layout = (param) => {
        // eslint-disable-next-line
        const { input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } } = param
        return (
            <div>
                <div {...dropzoneProps}>
                    {input}
                </div>
                <div style={{ marginTop: '10px' }}>
                    <OverlayTrigger
                        placement={'right'}
                        overlay={
                            <Tooltip>
                                <div style={{ textAlign: 'right' }}>
                                    {`Max size: PHOTO < 6MB`}<br />{`VIDEO < 30MB`}<br />{`DOCS < 10MB `}
                                </div>
                            </Tooltip>
                        }
                    >
                        <InfoIcon />
                    </OverlayTrigger>
                </div>
                {previews}

                {files.length > 0 && submitButton}
            </div>
        )
    }

    const Preview = (param) => {
        const { meta, fileWithMeta: { remove } } = param
        const { name, percent, status } = meta;

        const type = meta.type.split('/')[0];

        return (
            <div className="fileprogress">
                <span className="fileprogress-name" style={{ color: status === 'error_upload' ? 'red' : '' }}>{name}</span>
                <div className="fileprogress__info">
                    {type === 'image' && (
                        <div className="fileprogress__preview-container">
                            <img src={meta.previewUrl} alt="img" className="fileprogress__preview" />
                        </div>
                    )}
                    <ProgressBar
                        animated={(status !== 'done') && (status !== 'error_upload') && (status !== 'error_validation')}
                        now={status !== 'error_validation' ? Math.round(percent) : 100}
                        className="fileprogress__info-bar"
                        variant={((status === 'error_upload') || (status === 'error_validation')) ? 'danger' : status === 'done' ? 'success' : ''}
                    />
                    <Cancel className="fileprogress__info-remove" onClick={remove} />
                </div>
            </div>
        )
    }

    const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
        return (
            <label style={{
                height: '200px',
                width: '100%',
                color: '#48465b',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '26px',
                fontWeight: '600'
            }}>
                {label || 'Drag Files or Click to Browse'}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={e => {
                        getFilesFromEvent(e)
                            .then(chosenFiles => {
                                onFiles(chosenFiles)
                            })
                    }}
                />
            </label>
        )
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e)
                .then(chosenFiles => {
                    resolve(chosenFiles.map(f => f.fileObject))
                })
        })
    }

    const getAccept = () => {
        if (tab === 0) {
            return '.jpg,.jpeg,.png,.bmp,.gif,.heic';
        } else if (tab === 1) {
            return '.webm,.mp4,.mov'
        } else if (tab === 2) {
            return '.doc,.dock,.docx,.xls,.xlsx,.zip,.pdf,.txt,.scv';
        }
    };

    return (
        <StyledDropZoneFiles>
            <DropZone
                getUploadParams={getUploadParams}
                getFilesFromEvent={getFilesFromEvent}
                LayoutComponent={Layout}
                PreviewComponent={Preview}
                InputComponent={Input}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                validate={validate}
                maxFiles={maxFiles}
                accept={getAccept()}
            />
        </StyledDropZoneFiles>
    )
};

const StyledDropZoneFiles = styled.div`
    position: relative;

    .dzu-dropzone {
        overflow: auto;
    }

    button.dzu-submitButton {
        display: none;
    }

    .fileprogress {
        width: 100%;
        margin: 10px 0;

        &__preview-container {
            width: 60px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }

        &__preview {
            width: 100%;
            height: 25px;
            object-fit: cover;
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-bar {
                width: 100%;
                margin: 2px 10px 2px 0;
            }

            &-remove {
                cursor: pointer;
            }
        }
    }
`;
