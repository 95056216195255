import React, { useState, useEffect } from 'react'
import { Redirect, Link } from 'react-router-dom'
import styled from 'styled-components'
import DataOutput from '../../components/DataOutput'
import UserInfo from '../../components/UserInfo'
import {
    Portlet, PortletBody
} from "../../partials/content/Portlet";
import {
    Button,
    ButtonToolbar
} from 'react-bootstrap'
import ReportTable from './ReportTabs/ReportTable';
import { getReport } from '../../crud/profile.crud'
import Logout from '../auth/Logout'
import Loader from '../../components/Loader'
import './table.scss'

export default (props) => {
    document.title = 'TrainerMetrics - Report'
    const id = props.match.params.id

    const [data, setData] = useState([])
    const [sort, setSort] = useState([])
    const [check, setCheck] = useState([])

    const [logout, setLogout] = useState(null)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        loadData()
        // eslint-disable-next-line
    }, [sort])
    
    const getPdf = (single) => {
        return `/report/${id}/name/${single || check.join(',')}`
    }

    const loadData = () => {
        setLoader(true)
        getReport(id, sort[0], sort[1])
            .then(res => {
                // console.log(res)
                setData(res.data.data.value)
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })


    }

    return (
        <ReportStyled>
            { logout}
            <Loader visible={loader} />

            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} />
            </div>

            <Portlet>
                <PortletBody>
                    <ButtonToolbar className="custom-btn">
                        { check.length !== 0 && <Link to={getPdf()} target="_blank"><Button 
                            variant="primary"
                            disabled={check.length === 0}
                        >
                            Print
                        </Button></Link>}
                    </ButtonToolbar>

                    <ReportTable
                        data={data}
                        sort={sort}
                        setSort={setSort}
                        check={check}
                        setCheck={setCheck}
                        getPdf={getPdf}
                    />

                </PortletBody>
            </Portlet>
        </ReportStyled>
    )
}

const ReportStyled = styled.div`

`