import React, { useEffect, useState } from 'react'
import {
  Modal,
  Button
} from 'react-bootstrap'
import {
  Checkbox,
  FormControlLabel,
  FormGroup, Radio, RadioGroup,
  TextField
} from '@material-ui/core'
// import DateFnsUtils from "@date-io/date-fns";
// import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../partials/content/Portlet";
import styled from "styled-components";
import DatePicker from '../../../components/DatePicker'
import * as media from '../../../crud/media.crud'

const ImgComponent = ({src, alt, className, style}) => {
    const [url, setUrl] = useState('')

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} style={style} />
}

export default ({ modal, setModal, label, inputs, save, category='', setCheck}) => {
  const nowDate = new Date()
  const [date, setDate] = useState(nowDate.getFullYear() + '-'+ (nowDate.getMonth() + 1)  + '-'+ nowDate.getDate())
  const [values, setValues] = useState([])
  
  useEffect(() => {
    initValues()
    // eslint-disable-next-line
  }, [inputs])
  
  const initValues = () => {
    let temp = []
    inputs.forEach((input) => {
      if(input.name && input.name === 'testDate'){
        if(input.value){
          setDate(input.value)
        }
      }
      if(!input.groups && !input.fields){
        temp[input.name] = input.value ?? ''
      }
      if(input.groups){
        input.groups.forEach((group) =>{
          group.forEach((field) => {
            temp[field.name] = field.value ?? ''
          })
        })
      }
      if(input.fields){
        input.fields.forEach((field) => {
          temp[field.name] = field.value ?? ''
        })
      }
    })
    setValues({...values, ...temp})
  }
  
  const changeValue = (e) => {
    const el = e.target
    let temp = []
    temp[el.name] = el.value
    setValues({...values, ...temp})
  }
  const getValueByName = (name)=>{
    if(values[name])
      return values[name]
    return null
  }
  
  const onSave = () => {
    if(!values) return false
    let data = {}
    data = values
    data['testDate'] = date
    save(category, data)
    setCheck([])
    setModal(false)
    initValues()
    
  }
  
  
  
  return (
    <Modal show={modal} onHide={() => setModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalStyled>
        {
          // eslint-disable-next-line
          inputs.map((item, index) => {
            if (item.name === 'testDate') {
              return (
                <DatePicker
                  key={index}
                  label={item.label}
                  value={date}
                  onChange={(value) => {
                    setDate(value)
                  }}
                />
              )
            } else {
              if (item.label) {
                return (
                  <TextField
                    key={index}
                    type="text"
                    className='textField'
                    name={item.name}
                    label={item.label}
                    margin="normal"
                    inputProps={{
                      style: {
                        textAlign: 'center'
                      }
                    }}
                    value={getValueByName(item.name) ?? ''}
                    onChange={(e) => {
                      changeValue(e)
                    }}
                  />
                )
              }
              if (item.groups) {
                return (
                  <Portlet key={item.group_title}>
                    <PortletHeader title={item.group_title}/>
                    <PortletBody>
                      <div className="row">
                        {
                          item.groups.map(groupItem => {
                              return (
                                <div className="col-12" key={groupItem.subtitle || groupItem.group_title}>
                                  <PortletHeader title={groupItem.subtitle || groupItem.group_title}/>
                                  <div className="row">
                                  <div className="col-6 img-wrap">
                                    <ImgComponent src={groupItem.img} alt="test-img"/>
                                  </div>
                                  <div className="col-6">
                                  {
                                    Object.keys(groupItem.fields).map((key,index) => {
                                      const input = groupItem.fields[key]
                                        return (
                                          <FormGroup row key={input.name}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  value={`${getValueByName(input.name)}` === '1' ? 0 : 1}
                                                  onChange={e => changeValue(e)}
                                                  checked={`${getValueByName(input.name)}` === '1'}
                                                  color="primary"
                                                  name={input.name}
                                                />
                                              }
                                              label={input.label}
                                            />
                                          </FormGroup>
                                        )
                                    })
                                  }
                                  </div>
                                  </div>
                                </div>
                              )
                          })
                        }
                      </div>
                    </PortletBody>
                  </Portlet>
                )
              }
              if (item.fields) {
                return(
                <Portlet key={item.group_title}>
                  <PortletHeader title={item.group_title}/>
                  <PortletBody>
                    <div className="row">
                      <div className="col-12" key={index}>
                        <div className={"row"}>
                        <div className="col-6 img-wrap">
                          <ImgComponent src={item.img} alt="test-img"/>
                        </div>
                        <div className={"col-6"}>
                        {
                          item.fields[Object.keys(item.fields)[0]].desc.map((input, index) => {
                            return (
                              <FormGroup row key={input + index}>
                                <RadioGroup
                                  aria-label="Gender"
                                  name="gender1"
                                  className='group'
                                >
                                  <FormControlLabel value="female" control={
                                    <>
                                      {
                                        Object.keys(item.fields).map((key) => {
                                          const fields = item.fields[key]
                                          return fields.type === 'radio' &&
                                            <Radio
                                              checked={`${getValueByName(fields.name)}` === `${fields.values[index]}`}
                                              onChange={e => changeValue(e)}
                                              color="primary"
                                              value={fields.values[index]}
                                              name={fields.name}
                                              key={fields.name}
                                            />
                                        })
                                      }
                                    </>
                                  } label={input} />
                                </RadioGroup>
                              </FormGroup>
      
                            )
                          })
                        }
                          {
                            Object.keys(item.fields).map(key => {
                              const fields = item.fields[key]
                              return fields.type === 'checkbox' &&
                                <FormGroup row key={fields.name}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={`${getValueByName(fields.name)}` === '1' ? 0 : 1}
                                        onChange={e => changeValue(e)}
                                        checked={`${getValueByName(fields.name)}` === '1'}
                                        color="primary"
                                        name={fields.name}
                                      />
                                    }
                                    label={fields.label}
                                  />
                                </FormGroup>
                            })
                          }
                        </div>
                        </div>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
                )
              }
            }
          })
        }
        </ModalStyled>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal(false)}>
          Close
        </Button>
        <Button variant="primary" className="btn-blue" onClick={() => onSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
const ModalStyled = styled.div`
img{
  width: 100%;
  max-width: 250px;
}
`
