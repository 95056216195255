import React from 'react'
import styled from 'styled-components'
import { Checkbox } from '@material-ui/core/';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

export default ({ data, sort, setSort, check, setCheck, getPdf }) => {

    const defRow = [
        {
            label: "Category",
            value: 'category',
            left: true
        },
        {
            label: "Report Name",
            value: 'name',
            left: true
        },
        {
            label: "Last Test Date",
            value: 'test_date',
            left: true
        }
    ]

    const sortEdit = item => {
        setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    }

    const chbAll = () => {
        if (check.length === data.length) {
            setCheck([])
        } else {
            setCheck(data.map(item => item.metric))
        }
    }

    return (
        <ReportTableStyled className="settingtable">
            <table>
                <thead>
                    <tr>
                        <td>
                            <Checkbox
                                checked={check.length === data.length}
                                onChange={chbAll}
                                color="primary"
                            />
                        </td>

                        {defRow.map((item, index) => {
                            return (
                                <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content' : ''}${sort[0] === item.value ? ' active' : ''}`}>
                                    { item.label}
                                    <ArrowDown
                                        className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`}
                                    />
                                </td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => {
                    return (
                        <TableRow
                            item={item}
                            key={index}
                            check={check}
                            setCheck={setCheck}
                            getPdf={getPdf}
                        />
                    )
                    })
                }
                
                </tbody>
            </table>

            {data.length === 0
                ? (
                    <div
                        style={{
                            margin: '20px auto',
                            textAlign: 'center'
                        }}
                    >
                        You do not have saved reports. Create your report now
                    </div>
                ) : null}
        </ReportTableStyled>
    )
}

const ReportTableStyled = styled.div`
    td.active {
        font-weight: 600;
    }
    
    .sort-arrow {
        opacity: 0.7;
        transition: all .3s ease-out;

        &.active {
        opacity: 1;
        }

        &.reverse {
        transform: rotate(180deg)
        }
    }

    table thead tr td.left-content {
        text-align: left;
    }

    table tbody tr td.left-content {
        text-align: left;
    }
`

const TableRow = ({item, check, setCheck, getPdf }) => {

    const handleClick = () => {
      window.open(getPdf(item.metric));
    }

    const getCheck = () => {
        return check.findIndex(id => id === item.metric) !== -1
    }

    const editCheck = () => {
        let temp = [...check]
        temp.splice(check.indexOf(item.metric), 1)
        getCheck()
            ? setCheck(temp)
            : setCheck([...check, item.metric])
    }

    return (
    <Row>
        <td>
            <Checkbox
                checked={getCheck()}
                onChange={() => editCheck()}
                color="primary"
            />
        </td>
        <td className="left-content" onClick={handleClick}>
            { item.category }
        </td>
        <td className="left-content" onClick={handleClick}>
            { item.name }
        </td>
        <td className="left-content" onClick={handleClick}>
            { item.test_date }
        </td>
    </Row>
    )
}

const Row = styled.tr`
    cursor: pointer;

    .profile-photo {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 15px;
        margin-right: 10px;
    }
`