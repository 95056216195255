export const catchError = (response, setFieldError, setConfirmModal, setConfirmText) => {
    // The name has already been taken
    if (
        response?.response?.data?.errors?.name?.length &&
        response?.response?.data?.errors?.tab_name?.length &&
        response?.response?.data?.errors?.name[0] === 'The name has already been taken' &&
        response?.response?.data?.errors?.tab_name[0] === 'The tab name has already been taken'
    ) {
        setConfirmModal(true);
        setConfirmText('There is an existed form with this name and short name in the company. Do you want to continue?')
        setFieldError('name', 'The name has already been taken');
        setFieldError('tab_name', 'The short name has already been taken');
        document.querySelector('.create-form').scrollTo({ top: 0, behavior: 'smooth' });
        return
    };

    // The name has already been taken
    if (
        response?.response?.data?.errors?.name?.length &&
        response?.response?.data?.errors?.name[0] === 'The name has already been taken'
    ) {
        setConfirmModal(true);
        setConfirmText('There is an existed form with this name in the company. Do you want to continue?')
        setFieldError('name', 'The name has already been taken');
        document.querySelector('.create-form').scrollTo({ top: 0, behavior: 'smooth' });
        return
    };

    // The tab name has already been taken
    if (
        response?.response?.data?.errors?.tab_name?.length &&
        response?.response?.data?.errors?.tab_name[0] === 'The tab name has already been taken'
    ) {
        setConfirmModal(true);
        setConfirmText('There is an existed form with this short name in the company. Do you want to continue?')
        setFieldError('tab_name', 'The short name has already been taken');
        document.querySelector('.create-form').scrollTo({ top: 0, behavior: 'smooth' });
        return
    };
};
