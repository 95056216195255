import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { 
    Checkbox, 
    TextField 
} from '@material-ui/core'


const MetricRow = ({item, updateMetrics, updateMetrics45}) => {
    const [elem, setElem] = useState(item)

    useEffect(() => {
        setElem(item)
    }, [item])

    return (
        <StyledMetricRow>
            <td className="min">
                {elem.category}
            </td>
            <td className="min" style={{textAlign: 'start'}}>
                {elem.metric}
            </td>
            <td className="min">
                <TextField
                    variant="outlined"
                    type="number"
                    margin="normal"
                    style={{width: 80}}
                    className="kt-width-full"
                    name="day"
                    onBlur={() => {
                        updateMetrics({
                            metric_id: item.metric_id,
                            days_between: elem.days_between,
                            is_show: elem.is_show
                        })
                    }}
                    onChange={e => {
                        setElem({
                            ...elem,
                            days_between: e.target.value
                        })
                    }}
                    inputProps={{
                        min: 0
                    }}
                    defaultValue={elem.days_between > 0 ? elem.days_between : 0}
                />
            </td>
            <td className="min">
                <Checkbox
                    value={!!elem.is_show}
                    checked={!!elem.is_show}
                    onChange={e => {
                        setElem({
                            ...elem,
                            is_show: e.target.checked ? 1 : 0
                        })
                        if (item.metric_id !== 4 && item.metric_id !== 5) {
                            updateMetrics({
                                metric_id: item.metric_id,
                                days_between: elem.days_between,
                                is_show: e.target.checked ? 1 : 0
                            })
                        } else {
                            updateMetrics45({
                                metric_id: item.metric_id,
                                days_between: elem.days_between,
                                is_show: e.target.checked ? 1 : 0
                            })
                        }
                    }}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />
            </td>
        </StyledMetricRow>
    )
}

const StyledMetricRow = styled.tr`

`

export default MetricRow
