import React from 'react'
import styled from 'styled-components'

export default (props) => {
    return (
        <StyledRMO className='settingtable nowrap'>
            <thead>
                <tr>
                    <td>CLASSIFICATION</td>
                    <td>FROM</td>
                    <td>TO</td>
                </tr>
            </thead>
            <tbody>
                {
                    props.data.map(item => {
                        return (
                            <tr key={item.classification}>
                                <td>
                                    { item.classification }
                                </td>
                                <td>
                                    { item.measurement_from }
                                </td>
                                <td>
                                    { item.measurement_to }
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </StyledRMO>
    )
}

const StyledRMO = styled.table`

`