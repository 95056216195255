export const tableHead = [
    {
        label: 'Name',
        value: 'name',
        left: true,
        sort: true,
    },
    {
        label: 'Access',
        value: 'access',
        left: true,
        sort: true,
    },
    {
        label: 'Pages',
        value: 'page',
        left: true,
        sort: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        left: true,
        sort: true,
    },
    {
        label: 'Created Date',
        value: 'created_at',
        left: true,
        sort: true,
    },
    {
        label: 'Profiles Assigned',
        value: 'profiles_assigned',
        left: true,
        sort: false,
    },
];
