import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const defaultOptions = [{ title: 'No data', value: 'No data' }];

const DropdownComponent = ({
    label,
    error,
    options,
    setFormData,
    value,
    width,
    helperText,
    fetchOptions,
    disabledInput = false,
    disableClearable = false,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [dropwownValue, setDropdownValue] = useState('');

    const handleChange = (e, value) => {
        setFormData(value);
        setDropdownValue(value);
    };

    useEffect(() => {
        if (fetchOptions && dropwownValue?.title !== inputValue) {
            const handler = setTimeout(() => {
                fetchOptions(inputValue);
            }, 500);

            return () => clearTimeout(handler);
        }
    }, [inputValue]);

    return (
        <Autocomplete
            value={value || null}
            options={options || defaultOptions}
            getOptionLabel={option => option.title || ''}
            style={{ width: width || 150 }}
            onChange={handleChange}
            multiple={false}
            inputValue={inputValue}
            onInputChange={(e, newInputValue) => {
                if (!disabledInput) {
                    setInputValue(newInputValue)
                }
            }}
            disableClearable={disableClearable}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, value: params.inputProps.value }}
                        label={label || 'Combo box'}
                        variant="outlined"
                        error={!!error}
                        helperText={helperText ? helperText : error ? error : ''}
                        disabled={disabledInput}
                    />
                )
            }}
        />
    );
};

export default DropdownComponent;
