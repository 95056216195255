import React, { useEffect, useState } from 'react';
import { Table } from './Table/Table';
import Pagination from '../../../../../components/Pagination';
import { Button } from 'react-bootstrap';
import { SpacerVertical } from '../../../../../components/Spacer';
import AddMappingModal from './AddMappingModal';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import { fetchMappings, postRefresh, fetchCRMList } from '../../../../../crud/crm.crud';
import { setCRMAction } from '../../../../../../app/store/actions';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import question from '../../../../../../_metronic/_assets/media/svg-icon/question.svg';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const CRMMappings = ({ setLoader, crm }) => {
    const [show, setShow] = useState('10');
    const [page, setPage] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [sort, setSort] = useState(['internal_name', 'asc']);
    const [lastPage, setLastPage] = useState(1);
    const dispatch = useDispatch();

    const getMappings = () => {
        setLoader(true);
        fetchMappings('groups', show, pagination, sort[0], sort[1], 0, '', 1)
            .then(res => {
                setData(res.data.data);
                setLoader(false);
                setLastPage(res.data.meta.last_page);
                setPage(res.data.meta);
            })
            .catch(err => {
                let errText = 'Not exist data mapped';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response?.data?.message;
                }

                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
                setLoader(false);
            })
    };

    useEffect(() => {
        getMappings();
    }, [show, pagination, sort]);

    const handleRefresh = () => {
        setLoader(true);
        postRefresh('groups')
            .then(() => {
                setTimeout(() => {
                    getMappings();
                }, 10000);
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: `Sync has started`,
                    variant: true,
                }));
                fetchCRMList()
                    .then(res => {
                        setLoader(false);
                        dispatch(setCRMAction(res.data.data))
                    })
                    .catch(err => {
                        setLoader(false);
                    })
            })
            .catch(err => {
                setLoader(false);
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    return (
        <>
            <div className="top-buttons">
                {crm?.last_refresh_started?.groups && (
                    <div className='d-flex align-items-center'>
                        <div className="last-sync-block">
                            {`Last Sync Started: ${moment(crm.last_refresh_started.groups).format('MM/DD/YYYY hh:mm:ss a')}`}
                        </div>
                        <StyledToolTip
                            title={<>Synchronization may take some time. <br />
                                To see if the synchronization is complete, please refresh the page and observe the changes in this table.
                            </>}
                            placement="bottom"
                        >
                            <img src={question} style={{ cursor: 'pointer', margin: '0 5px 0 10px', height: '18px' }} alt="tooltip" />
                        </StyledToolTip>
                    </div>
                )}
                <Button
                    variant="info"
                    className="button"
                    onClick={handleRefresh}
                >Refresh</Button>
                <SpacerVertical width="20px" />
                <Button
                    variant="primary"
                    className="button btn-blue desktop-vis"
                    onClick={() => setModal(true)}
                >Add</Button>
            </div>

            <Table
                data={data}
                setLoader={setLoader}
                pagination={pagination}
                setPagination={setPagination}
                getMappings={getMappings}
                sort={sort}
                setSort={setSort}
            />

            <Pagination
                show={show}
                setShow={setShow}
                page={page}
                pagination={pagination}
                setPagination={setPagination}
                lastPage={lastPage}
                setPage={setPage}
                data={data}
            />

            <AddMappingModal
                modal={modal}
                setModal={setModal}
                getMappings={getMappings}
                setLoader={setLoader}
            />
        </>
    )
};
