import React from "react";
// import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
// import MyCart from "../../../app/partials/layout/MyCart";
// import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
// import QuickPanelToggler from "./QuickPanelToggle";
// import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { Button, Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from "../../utils/utils";
import {
  // Button,
  TextField
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help';
import { sendSuggestion } from '../../../app/crud/info.crud'
import {Formik} from 'formik'
import { connect } from "react-redux";
import Loader from '../../../app/components/Loader'
import * as AlertState from '../../../app/store/ducks/auth.duck'

class Topbar extends React.Component {
  constructor(props) {
    super(props);

    this.wrap = React.createRef()

    this.state = {
      drop: null,
      suggestion: false,
      suggestionStatus: false,
      sendLoader: false
    }
  }

  handleClick = () => {
    this.setState({drop: !this.state.drop});
  }

  handleClose = () => {
    this.setState({drop: null});
  }

  openLoader = () => {
    this.setState({sendLoader: true});
  }

  closeLoader = () => {
    this.setState({sendLoader: false});
  }

  openSuggestion = (val) => {
    this.setState({suggestion: val})
    if (val) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      document.body.style.position = '';
      document.body.style.top = ``;
    }
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }
  
  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }
  
  handleClickOutside = (e) => {
    const block = this.wrap && this.wrap.current
    if (e.path.includes(block)) {
    } else {
      this.setState({ drop: false })
    }
  }

  render() {
    return (
      <div className="kt-header__topbar">
        <Formik
          initialValues={{
            name: this.props.user.firstName + ' ' + this.props.user.lastName,
            email: this.props.user.email,
            message: ''
          }}
          enableReinitialize={true}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required field'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Field is not valid'
            }

            if (!values.name) {
              errors.name = 'Required field'
            }

            if (!values.message) {
              errors.message = 'Required field'
            } else if (values.message.length < 20) {
              errors.message = 'The Suggestion details should be at least 20 of characters'
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            this.openLoader()
            sendSuggestion(values)
              .then(res => {
                this.setState({suggestionStatus: true})
                this.closeLoader()
                setStatus(res.data.message)
                setSubmitting(false);
                values.message = ''
                this.props.action(AlertState.actions.alert({
                  text: res.data.message,
                  variant: true
                }))
                this.openSuggestion(false)
              })
              .catch(({ response }) => {
                this.closeLoader()
                setSubmitting(false);
                setStatus(response.data.message)
                this.setState({suggestionStatus: false})
              })
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Modal 
              show={this.state.suggestion} 
              onHide={e => {
                touched.message = false
                errors.message = false
                this.openSuggestion(false)
                values.message = ''
              }}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Make a Suggestion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Loader visible={this.state.sendLoader} />
                <div>
                  <TextField
                    key="1"
                    label="Suggestion Details"
                    multiline
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="message"
                    inputProps={{
                      maxLength: 500
                    }}
                    value={values.message}
                    helperText={touched.message && errors.message}
                    error={Boolean(touched.message && errors.message)}
                  />
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: '100%', marginRight: 5}}>
                    <TextField
                      key="2"
                      disabled={true}
                      label="Name"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="name"
                      value={values.name}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </div>
                  <div style={{width: '100%', marginLeft: 5}}>
                    <TextField
                      key="3"
                      disabled={true}
                      label="Email"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="email"
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button 
                  onClick={e => {
                    touched.message = false
                    errors.message = false
                    this.openSuggestion(false)
                    values.message = ''
                  }}
                >
                  Close
                </Button>
                <Button onClick={handleSubmit} className="btn-blue">
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>

        {/* <SearchDropdown useSVG="true" /> */}

        <UserProfile showAvatar={true} showHi={true} showBadge={false} />

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div 
          ref={this.wrap}
          aria-controls="simple-menu" 
          aria-haspopup="true" 
          onClick={this.handleClick}
          className="helper-menu-top"
        >
          <HelpIcon />
          {
            this.state.drop &&
              <div className="helper-menu-top__popup kt-notification">
                <div className="helper-menu-top__popup__elem kt-notification__item" onClick={this.handleClose}><a href="https://www.trainermetrics.com/learn/" target="_blank">Training Resources</a></div> {/* eslint-disable-line */}
                <div className="helper-menu-top__popup__elem kt-notification__item" onClick={this.handleClose}><a href="https://trainermetrics.zendesk.com" target="_blank">Knowledge Center</a></div> {/* eslint-disable-line */}
                <div 
                  className="helper-menu-top__popup__elem kt-notification__item" 
                  onClick={() => { 
                    this.handleClose()
                    this.openSuggestion(true)
                  }}
                >
                  Make a Suggestion
                </div>
              </div>
          }
        </div>
      </div>

        <UserNotifications
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="false"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          useSVG="true"
          dot="false"
        />

        {/* <QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="dark"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        /> */}

        {/* <MyCart
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        /> */}

          {/* <QuickPanelToggler /> */}


          {/* <LanguageSelector iconType="" /> */}

      </div>
    );
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

const mapDispatchToProps = store => {
  return { action: store }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
