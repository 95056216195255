import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

export default ({ item, setCategory, setSubcategory }) => {
    
    const [open, setOpen] = useState(false)
    const handleCategory = () => {
        setCategory(item.category)
    }
    
    useEffect(() => {
    
    }, [])
    return (
      <NavTab
        onMouseMove={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
            <span
                onClick={() => {
                    if(!item.childs){
                        handleCategory();
                    }
                }}
            >
                {item.title}
            </span>
          {
              open && item.childs &&
              <div className="drop-menu">
                  {
                      item.childs.map((elem, index) => {
                          return (
                            <Submenu
                              key={index}
                              data={elem}
                              idChild={index}
                              handleCategory={handleCategory}
                              setSubcategory={setSubcategory}
                            />
                          )
                      })
                  }
              </div>
          }
      </NavTab>
    )
}

const Submenu = ({data, idChild, handleCategory, setSubcategory}) => {
    // eslint-disable-next-line
    const [open, setOpen] = useState(false)
    
    return (
      <StyledSubmenu
        onMouseMove={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className="drop-menu__item"
      >
            <span
              onClick={() => {
                  data.subcategory ? setSubcategory(data.subcategory) : setSubcategory('')
                  handleCategory();
              }}
            >
                {data.title}
            </span>
      </StyledSubmenu>
    )
}

const NavTab = styled.div`
    position: relative;
    padding: 7px 13px;

    .drop-menu {
        min-width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 8px 0;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        z-index: 101;
        box-shadow: 0 15px 50px 0 rgba(82, 63, 105, .15);

        &.child {
            top: 0;
            left: 100%;
        }

        &__item {
            position: relative;
            width: 100%;
            padding: 5px 10px;
            white-space: nowrap;

            &:hover {
                background-color: #ebebeb;
            }
        }
    }
`

const StyledSubmenu = styled.div`

`
