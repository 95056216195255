import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TextField, Checkbox } from "@material-ui/core";
import ConfirmModal from '../../../../../../components/ConfirmationModal';
import trash from '../../../../../../../_metronic/_assets/media/svg-icon/delete.svg';
import Dropdown from '../../../../../../components/SimpleDropdown';
import { Button } from 'react-bootstrap';
import { CustomRadio } from '../../../../../../components/CustomRadio/CustomRadio';
import {
    validateFullForm,
    validateCurrentInput,
    handleChangeInput,
    validateCurrentOption,
    handleChangeOption,
    validateCurrentRange,
    handleChangeRange,
    handleValidateTypeInput,
} from './validations';
import './dnd.scss';
import { useDispatch } from 'react-redux';
import * as AlertState from '../../../../../../store/ducks/auth.duck';

export const DragAndDropForm = ({
    inputsStatus,
    setInputsStatus,
    isSubmitting,
    handleSubmit,
    values,
    setValues,
    validateForm,
    setErrors,
    setTouched,
    formId,
}) => {
    const dispatch = useDispatch();
    const [deleteModal, setDeleteModal] = useState(false);
    const [current, setCurrent] = useState(null);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) return;
        const itemsReordered = reorder(
            values?.inputs,
            result.source.index,
            result.destination.index
        );
        setValues({
            ...values,
            inputs: [...itemsReordered],
        })
    };

    const renderButton = () => {
        return (
            <div className='dnd__draggble-btn'>
                <div className='dnd__draggble-point' />
                <div className='dnd__draggble-point' />
                <div className='dnd__draggble-point' />
                <div className='dnd__draggble-point' />
                <div className='dnd__draggble-point' />
                <div className='dnd__draggble-point' />
            </div>
        )
    };

    const handleSelectQuestionType = (value, item, setInputValue) => {
        let data = null;
        setInputValue('');

        if (value === 'Checkbox (Multiple Selection)') {
            data = {
                id: item.id,
                type: "checkbox",
            }

            setInputsStatus(prev => ({
                ...prev,
                [item.id]: {
                    ...prev[item.id],
                    type: "checkbox",
                    options: [],
                    status: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                    }
                }
            }));
        } else if (value === 'Number') {
            data = {
                id: item.id,
                type: "number",
            }

            setInputsStatus(prev => ({
                ...prev,
                [item.id]: {
                    ...prev[item.id],
                    type: "number",
                    status: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                    },
                    from: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                        value: 1,
                    },
                    to: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                        value: 200,
                    }
                }
            }))
        } else if (value === 'Short Answer') {
            data = {
                id: item.id,
                type: "short",
            }

            setInputsStatus(prev => ({
                ...prev,
                [item.id]: {
                    ...prev[item.id],
                    status: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                    },
                    type: "short",
                }
            }))
        } else if (value === 'Yes/No') {
            data = {
                id: item.id,
                type: "survey",
            }

            setInputsStatus(prev => ({
                ...prev,
                [item.id]: {
                    ...prev[item.id],
                    status: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                    },
                    type: "survey",
                }
            }))
        } else if (value === 'Multiple Choice (Single Selection)') {
            data = {
                id: item.id,
                type: "radio",
            }

            setInputsStatus(prev => ({
                ...prev,
                [item.id]: {
                    ...prev[item.id],
                    status: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                    },
                    type: "radio",
                    options: [],
                }
            }))
        };

        if (data) {
            let index = values.inputs.findIndex(elem => elem.id === item.id);
            let arr = [...values.inputs];
            arr[index] = { ...data };

            setValues({
                ...values,
                inputs: [...arr],
            });
        }
    };

    const showError = (text) => {
        dispatch(AlertState.actions.alert({
            text,
            variant: false
        }))
    };

    const handleDeleteVariant = (item, index) => {
        setInputsStatus(prev => {
            const arr = [...prev[item.id].options];
            arr.splice(index, 1);

            return {
                ...prev,
                [item.id]: {
                    ...prev[item.id],
                    options: arr,
                }
            }
        })
    };

    const handleAddVariant = itemId => {
        setInputsStatus(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                options: [
                    ...prev[itemId].options,
                    {
                        value: ''
                    }
                ]
            }
        }))
    };

    const TextFieldHOC = (label, className, item) => (
        <TextField
            variant="outlined"
            type="text"
            onMouseDown={e => e.stopPropagation()}
            label={label}
            className={className}
            error={Boolean(inputsStatus[item.id].hasError && inputsStatus[item.id].touched)}
            helperText={inputsStatus[item.id].errorText}
            onBlur={() => validateCurrentInput(item.id, inputsStatus, setInputsStatus)}
            value={inputsStatus[item.id].value}
            onChange={e => handleChangeInput(e, item.id, setInputsStatus)}
            inputProps={{ maxLength: 255 }}
        />
    );

    const DropdownHOC = (content, item) => (
        <div className='dnd__question-dropdown'>
            <Dropdown
                label={'Type'}
                options={[
                    'Yes/No',
                    'Checkbox (Multiple Selection)',
                    'Short Answer',
                    'Number',
                    'Multiple Choice (Single Selection)'
                ]}
                width="100%"
                onChange={(value, e, setInputValue) => handleSelectQuestionType(value, item, setInputValue)}
                disableClearable={true}
                content={content ? content : null}
                onBlur={() => handleValidateTypeInput(item.id, inputsStatus, setInputsStatus)}
                error={Boolean(inputsStatus[item.id].status.hasError && inputsStatus[item.id].status.touched)}
                helperText={inputsStatus[item.id].status.errorText}
            />
        </div>
    );

    const optionsHOC = (item) => {
        return (
            <>
                {inputsStatus[item.id].options?.map((elem, index) => (
                    <div className='d-flex' style={{ marginTop: '15px', height: '60px' }} key={index}>
                        {item.type === 'radio'
                            ? <div className="custom-radio__elem" style={{ margin: '10px 0 0 0' }}>
                                <div className='custom-radio__checkbox custom-radio__checkbox_checked'>
                                    <div className="custom-radio__dot" />
                                </div>
                            </div>
                            : <div>
                                <Checkbox color="primary" checked />
                            </div>
                        }

                        <TextField
                            style={{ width: '100%' }}
                            variant="outlined"
                            type="text"
                            onMouseDown={e => e.stopPropagation()}
                            label='Type Variant'
                            error={Boolean(elem.hasError && elem.touched)}
                            helperText={elem.errorText}
                            inputProps={{ maxLength: 255 }}
                            value={elem.value}
                            onBlur={() => validateCurrentOption(item.id, index, inputsStatus, setInputsStatus)}
                            onChange={e => handleChangeOption(e, item.id, index, setInputsStatus)}
                        />

                        <img
                            src={trash}
                            className='dnd__delete'
                            alt='delete'
                            onClick={() => handleDeleteVariant(item, index)}
                        />
                    </div>
                ))}
            </>
        )
    };

    const renderFormBody = item => {
        if (item.type === 'question') {
            return (
                <div className='dnd__draggble-section'>
                    <div className='dnd__draggble-question'>
                        {TextFieldHOC('Question', 'dnd__question-textfield', item)}
                        {DropdownHOC(null, item)}
                    </div>
                </div>
            )
        } else if (item.type === 'section') {
            return (
                <div className='dnd__draggble-section'>
                    {TextFieldHOC('Section', 'dnd__textfield', item)}
                </div>
            )
        } else if (item.type === 'checkbox') {
            return (
                <div className='dnd__draggble-section'>
                    <div className='dnd__draggble-question'>
                        {TextFieldHOC('Question', 'dnd__question-textfield', item)}
                        {DropdownHOC(
                            <div className='dnd__draggble-question-content'>
                                <Checkbox color="primary" checked />
                                <span style={{ whiteSpace: 'nowrap' }}>Checkbox (Multiple Selection)</span>
                            </div>,
                            item
                        )}
                    </div>
                    {optionsHOC(item)}
                    <div className='dnd__add_variant'>
                        <span onClick={() => handleAddVariant(item.id)}>+ Add Variant</span>
                    </div>
                </div>
            )
        } else if (item.type === 'survey') {
            return (
                <div className='dnd__draggble-section'>
                    <div className='dnd__draggble-question'>
                        {TextFieldHOC('Question', 'dnd__question-textfield', item)}
                        {DropdownHOC(
                            <div className='dnd__draggble-question-content'>
                                <CustomRadio
                                    data={['Yes', 'No']}
                                    value='Yes'
                                />
                            </div>,
                            item
                        )}
                    </div>
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className='dnd__draggble-section'>
                    <div className='dnd__draggble-question'>
                        {TextFieldHOC('Question', 'dnd__question-textfield', item)}
                        {DropdownHOC(
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    variant='outlined'
                                    type="text"
                                    onMouseDown={e => e.stopPropagation()}
                                    label=' '
                                    className='dnd__question-textfield dnd__question-textfield-number'
                                    disabled
                                />
                                <div>Number</div>
                            </div>,
                            item
                        )}
                    </div>
                    <div className='d-flex align-items-center' style={{ marginTop: '15px' }}>
                        <div className='dnd__range-input'>
                            <TextField
                                label='From'
                                variant='outlined'
                                type="number"
                                inputProps={{
                                    min: 1,
                                    max: 200,
                                }}
                                onChange={value => handleChangeRange(value, item.id, 'from', inputsStatus, setInputsStatus)}
                                value={inputsStatus[item.id].from.value}
                                error={Boolean(inputsStatus[item.id].from.hasError && inputsStatus[item.id].from.touched)}
                                onBlur={() => validateCurrentRange(item.id, inputsStatus, setInputsStatus, 'from')}
                                helperText={inputsStatus[item.id].from.errorText}
                            />
                        </div>
                        <div className='dnd__range-input'>
                            <TextField
                                label='To'
                                variant='outlined'
                                type="number"
                                inputProps={{
                                    min: 1,
                                    max: 200,
                                }}
                                onChange={value => handleChangeRange(value, item.id, 'to', inputsStatus, setInputsStatus)}
                                value={inputsStatus[item.id].to.value}
                                error={Boolean(inputsStatus[item.id].to.hasError && inputsStatus[item.id].to.touched)}
                                onBlur={() => validateCurrentRange(item.id, inputsStatus, setInputsStatus, 'to')}
                                helperText={inputsStatus[item.id].to.errorText}
                            />
                        </div>
                    </div>
                </div>
            )
        } else if (item.type === 'short') {
            return (
                <div className='dnd__draggble-section'>
                    <div className='dnd__draggble-question'>
                        {TextFieldHOC('Question', 'dnd__question-textfield', item)}
                        {DropdownHOC(
                            <div style={{ marginTop: '-22px' }}>
                                <TextField
                                    type="text"
                                    onMouseDown={e => e.stopPropagation()}
                                    label='Short Answer'
                                    className='dnd__question-textfield'
                                    disabled
                                />
                            </div>,
                            item
                        )}
                    </div>
                </div>
            )
        } else if (item.type === 'radio') {
            return (
                <div className='dnd__draggble-section'>
                    <div className='dnd__draggble-question'>
                        {TextFieldHOC('Question', 'dnd__question-textfield', item)}
                        {DropdownHOC(
                            <CustomRadio
                                data={['Multiple Choice (Single Selection)']}
                                value='Multiple Choice (Single Selection)'
                            />,
                            item
                        )}
                    </div>
                    {optionsHOC(item)}
                    <div className='dnd__add_variant'>
                        <span onClick={() => handleAddVariant(item.id)}>+ Add Variant</span>
                    </div>
                </div>
            )
        }
    };

    const handleAddRow = value => {
        if (!value) return;

        if (value === 'Add section') {
            const sectionId = Math.random() + '';
            setValues({
                ...values,
                inputs: [
                    ...values.inputs,
                    {
                        id: sectionId,
                        type: "section",
                    }
                ]
            });
            setInputsStatus(prev => ({
                ...prev,
                [sectionId]: {
                    ...prev[sectionId],
                    type: "section",
                    touched: false,
                    hasError: true,
                    errorText: '',
                }
            }))
        } else {
            const questionId = Math.random() + '';
            setValues({
                ...values,
                inputs: [
                    ...values.inputs,
                    {
                        id: questionId,
                        type: 'question'
                    }
                ]
            });
            setInputsStatus(prev => ({
                ...prev,
                [questionId]: {
                    ...prev[questionId],
                    type: 'question',
                    touched: false,
                    hasError: true,
                    errorText: '',
                    status: {
                        touched: false,
                        hasError: true,
                        errorText: '',
                    },
                }
            }))
        }
    };

    const handleDelete = () => {
        let arr = [...values?.inputs];
        const index = arr.findIndex(elem => elem.id.toString() === current?.id.toString());
        if (index !== -1) arr.splice(index, 1);
        setValues({
            ...values,
            inputs: arr,
        });
        setInputsStatus(prev => {
            let obj = { ...prev };
            delete obj[current?.id];
            return obj;
        })
        setDeleteModal(false);
    };

    return (
        <>
            <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                handleConfirm={handleDelete}
                title="Are you sure you want to delete?"
                submitText="Yes"
            />

            <div className='dnd'>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {values?.inputs?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        className='dnd__draggble'
                                                        style={{ border: snapshot.isDragging ? '3px solid #1E1E2D' : '' }}
                                                    >
                                                        <div className='dnd__draggble-btn-container'>
                                                            <div className='dnd__draggble-spacer' />
                                                            {renderButton()}
                                                            <div className='dnd__draggble-spacer' />
                                                        </div>

                                                        <div className='dnd__draggble-body'>
                                                            {renderFormBody(item)}

                                                            <img
                                                                src={trash}
                                                                className='dnd__delete'
                                                                alt='delete'
                                                                onClick={() => {
                                                                    setCurrent(item);
                                                                    setDeleteModal(true);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <div className='create-form__form-footer'>
                <div style={{ marginTop: '20px' }}>
                    <Dropdown
                        label="Add Section/Question"
                        options={[
                            'Add section',
                            'Add question',
                        ]}
                        width="200px"
                        onChange={handleAddRow}
                        disabledInput={true}
                        disableClearable={true}
                    />
                </div>

                <div className='dnd__submit-container'>
                    <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={() => {
                            validateForm()
                                .then(res => {
                                    setErrors(res);
                                    setTouched({
                                        name: true,
                                        access: true,
                                        tab_name: true,
                                    });
                                    if (validateFullForm(values, inputsStatus, setInputsStatus, showError)) {
                                        handleSubmit()
                                    };
                                })
                        }}
                        disabled={isSubmitting}
                    >
                        {formId ? 'Save Form' : 'Create Form'}
                    </Button>
                </div>
            </div>
        </>
    )
};
