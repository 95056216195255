import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import axios from 'axios'

export default ({ data }) => {
    const defRow = [
        {
            label: "Date",
            value: 'date',
            left: true
        },
        {
            label: "Invoice ID",
            value: 'id',
            left: true
        },
        {
            label: "Product(s)",
            value: 'product',
            left: true
        },
        {
            label: "Total",
            value: 'total',
            left: true
        },
        {
            label: "Status",
            value: 'status',
            left: true
        }
    ];

    return (
        <StyledInvoices className="settingtable">
            <table>
                <thead>
                    <tr>
                        {
                            defRow.map((item, index) => {
                                return (
                                    <td key={index} className={`${item.left ? 'left-content' : ''}`}>
                                        {item.label}
                                    </td>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {!data.data.length
                        ? <div className="clear">No invoice yet</div>
                        : data?.data?.map((item, index) => {
                            return (
                                <TableRow
                                    item={item}
                                    key={index}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
        </StyledInvoices>
    )
}

const StyledInvoices = styled.div`
    .clear {
        display: flex;
        justify-content: center;
        margin: 15px;
    }
    table thead tr td.left-content {
        text-align: left;
    }
    table tbody tr td.left-content {
        text-align: left;
    }
`;

const TableRow = ({ item }) => {
    const getPdf = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}billing/customer/invoices/download?invoice_id=${id}`)
            .then(({ data }) => {
                // цей код просто тоді з блоб файла генерує хтмл з щинкою скачування файла і кликає на нього
                // якщо щось не так з бека приходитиме, то просто в змінній url Object URL зберігай і код нижче буде качать
                const link = document.createElement('a');
                link.href = data.data.download_url;
                link.setAttribute('target', '_blank');
                link.setAttribute('download', `${id}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
    }
    return (
        <Row>
            <td className="left-content">
                {moment(new Date(item.date * 1000)).format('MM/DD/YYYY')}
            </td>
            <td className="left-content">
                <a href="#" onClick={() => getPdf(item.id)}>{item.id}</a>
            </td>
            <td className="left-content">
                {item.product}
            </td>
            <td className="left-content">
                ${parseInt(item.total / 100)}
            </td>
            <td className="left-content">
                {item.status}
            </td>
        </Row>
    )
}

const Row = styled.tr`
    .date .MuiGrid-container {
        width: 150px;
    }

    .value .MuiFormControl-root {
        width: 75px;
    }

    td input {
        border: none !important;
        border-radius: 0 !important;
        text-align: center !important;
        padding: 5px !important;
    }
  `
