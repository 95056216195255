import React from 'react'
import {
  Modal,
  Button
} from 'react-bootstrap'
import styled from "styled-components";

export default ({ title, deleteModal, setDeleteModal, handleDelete }) => {
  
  return (
    <Modal
      show={deleteModal}
      onHide={e => {
        setDeleteModal(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="danger" onClick={e => {
          handleDelete()
        }}>
          Yes
        </Button>
        <Button variant="primary" onClick={e => {
          setDeleteModal(false)
        }}>
          No
        </Button>
    
      </Modal.Footer>
    </Modal>
  )
}
// eslint-disable-next-line
const ModalStyled = styled.div`
img{
  width: 100%;
  max-width: 250px;
}
`
