import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL

export function getPlanList() {
    return axios.get(`${BASE_URL}billing/plans`)
}

export function getInvoicesList() {
    return axios.get(`${BASE_URL}billing/customer/invoices?sort_date=asc`)
}

export const getCurrentSubscription = () => {
    return axios.get(`${BASE_URL}billing/customer/subscription`)
}

export function updateSubscription(id) {
    return axios.put(`${BASE_URL}billing/customer/subscription`, { plan_id: id })
}

export function cancelSubscription(id) {
    return axios.put(`${BASE_URL}billing/customer/subscription`, { plan_id: 1, cancel_reason: 'cancel_reason' })
}

export function getPaymentSource() {
    return axios.get(`${BASE_URL}billing/customer/payment-source`)
}

export function createPaymentSource(data) {
    return axios.post(`${BASE_URL}billing/customer/payment-source`, data)
}

export function createCoupon(coupon) {
    return axios.post(`${BASE_URL}billing/customer/subscription/apply-coupon`, { coupon: coupon })
}