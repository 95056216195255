import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import { JSONViewer } from 'react-json-editor-viewer';

const ModalAction = ({
    modal,
    setModal,
    item,
}) => {

    const handleClose = () => {
        setModal(false);
    };

    return (
        <Modal show={modal !== false} onHide={() => setModal(false)} dialogClassName="my-modal">
            <StyledModalDeactivateCRM>
                <div
                    className="close-button"
                    onClick={() => setModal(false)}
                >×</div>
                <Modal.Title>
                    <h5 className="title">Changes Log</h5>
                </Modal.Title>
                <Modal.Body>
                    <Typography className="static-text">
                        {item?.length > 0
                            ? <JSONViewer data={item} />
                            : '[ ]'
                        }
                    </Typography>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </StyledModalDeactivateCRM>
        </Modal >
    )
};

const StyledModalDeactivateCRM = styled.div`
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 10px 0 0 20px;
    }

    .subtitle {
        margin: 10px 0 0 20px;
        color: #000;
        font-weight: 600;
    }

    .static-text {
        font-size: 1.2em;
        margin: 5px;
        color: #000;
        overflow: hidden;
        word-wrap: break-word;
        width: 100%;

        span {
            overflow: hidden;
            word-wrap: break-word;
        }

        p {
            overflow: hidden;
            word-wrap: break-word; 
        }
    }
`;

export default ModalAction;
